import React from "react";
import { connect } from "react-redux";

// Components
import { Button } from "@petsmart-ui/sparky";

// Actions
import { showOverbookingCancelExceptionModal } from "../overbookingModals/overbookingModalsActions";
import { setHotelOverbookingExceptionsHistoryId } from "../hotelOverbookingExceptionsHistory/hotelOverbookingExceptionsHistoryActions";

// Constants
import { EXCEPTION_STATUS } from "./overBookingExceptionTableDetailsConstants";
import { routePaths } from "@/core/constants/routePaths";
import { history } from "@/dux/utils/browser/browserHistory";

// Utils or Helpers
import { getMoDayYearDate } from "@/core/utils/dateUtils/formatDateTime";
import { color } from "web/common/styles/theme";

// SVG Assets
import HistorySvg from "@/dux/_components/historySvg/HistorySvg";

/**
 * Over Booking Setup Table Row Component
 * @param {Object} props - props object
 * @param {Object} props.room - room information
 * @param {String} props.componentId
 * @param {Boolean} props.isEditMode
 * @param {Function} props.cancelException
 * @param {Function} props.modifyException
 * @returns {(JSX.Element|null)}
 */
const ExceptionTableRow = ({
  componentId,
  room,
  isEditMode = false,
  cancelException,
  modifyException,
  toggleHistoryModal,
}) => {
  const {
    capacityExceptionId,
    endDate,
    overBookingPercentage,
    roomTypeBucketName,
    startDate,
    status,
  } = room;

  const canEditException = status === EXCEPTION_STATUS.scheduled;
  const style = {
    padding: "10px",
  };

  // Handle cancel changes
  const handleCancelChanges = () => {
    cancelException(room);
  };

  // Handle modify changes
  const handleModifyChanges = () => {
    modifyException(room);
  };

  return (
    <tr
      id={componentId}
      style={{
        borderBottom: "1px solid gray",
      }}
    >
      <td style={{ ...style, display: "flex", justifyContent: "space-between" }}>
        <span>{status}</span>
        <Button
          onClick={() => toggleHistoryModal(capacityExceptionId)}
          text={<HistorySvg />}
          variant="bare"
          size="sm"
        />
      </td>
      <td style={style}>{roomTypeBucketName}</td>
      <td style={style}>{getMoDayYearDate(startDate)}</td>
      <td style={style}>{getMoDayYearDate(endDate)}</td>
      <td style={style}>{overBookingPercentage}</td>

      {canEditException && (
        <>
          <td style={style}>
            <Button variant="link" onClick={handleModifyChanges} text="Modify" />
          </td>
          <td style={style}>
            <Button
              variant="link"
              style={{ color: isEditMode ? color.gray400 : color.red700 }}
              onClick={handleCancelChanges}
              text="Cancel"
            />
          </td>
        </>
      )}
    </tr>
  );
};

// CONTAINER(s) ----------------------------------------------------------------------------------------
export const OverBookingExceptionTableRow = connect(
  (_, { room, key }) => {
    return {
      componentId: `OverBookingExceptionTableRow__${key}`,
      isEditMode: false,
      room,
    };
  },

  (dispatch, { room }) => {
    return {
      cancelException: () => {
        dispatch(showOverbookingCancelExceptionModal({ data: room }));
      },
      modifyException: () => {
        history.push(
          `${routePaths.MANAGE_OVERBOOKING_MODFIY_EXCEPTION_DATE}/${room?.capacityExceptionId}`,
        );
      },
      toggleHistoryModal: exceptionId => {
        dispatch(setHotelOverbookingExceptionsHistoryId({ exceptionId }));
      },
    };
  },
)(ExceptionTableRow);
