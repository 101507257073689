import React, { Component } from "react";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import styled from "styled-components";
import StyledPopover from "web/common/StyledPopover";
import SearchSelectContainer from "dux/searchField/searchSelectContainer";
import SearchFieldFlyout from "web/searchSalonSRC/flyouts/searchFieldFlyout";
import SearchInputWrapper from "./searchInputWrapper";
import { stripEmojis } from "core/utils/stringUtils/unicodeUtils";

/** ----------------------------------------------------------------------- **\
    STYLES
\** ----------------------------------------------------------------------- * */
const SectionWrapper = styled.section`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border: 1px solid #b6b6b6;
  ${props =>
    props.isFocused &&
    `
        border: 1px solid #2684ff;
        box-shadow: 0 0 0 1px #2684ff;
    `};
  width: 100%;
  .search-bar__control {
    border: none !important;
  }
  .search-bar__indicator-separator {
    width: 0 !important;
  }
  .search-bar__indicators {
    border-right: 1px solid #cccc;
  }
  .search-bar__control-is-focused {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }
`;

/** ----------------------------------------------------------------------- **\
    COMPONENT - [description of component purpose and responsibility]
    PROPS:
        @param {Object}     router
        @param {Function}   onSearch
        @param {Function}   validateSearchField
        @param {String}     searchFieldType
        @param {String}     searchFieldValue
        @param {Function}   setSearchErrorMessage
        @param {Function}   clearSearchErrorMessage
        @param {Function}   openSearchErrorFlyout
        @param {Function}   closeSearchErrorFlyout
        @param {Function}   redirectOnSearch
        @param {Function}   setSearchFieldType
        @param {String}     defaultSearchFieldType
        @param {String}     prevSearchComponentName
        @param {Function}   setPrevSearchComponentName
        @param {String}     searchComponentName
        @param {Function}   closeSearchErrorFlyout
        @param {Function}   clearSearchFieldActive
        @param {Function}   clearSearchFieldValue
        @param {Function}   clearSearchApplied
        @param {Function}   onFieldUpdate
        @param {Function}   setSearchFieldActive
\** ----------------------------------------------------------------------- * */
class SearchFieldComponent extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.inputRef = React.createRef();
  }

  /** ----------------------------------------------------------------------- **\
        LIFE CYCLE METHODS
    \** ----------------------------------------------------------------------- * */
  componentDidMount() {
    const {
      setSearchFieldType,
      searchFieldType,
      defaultSearchFieldType,
      prevSearchComponentName,
      setPrevSearchComponentName,
      searchComponentName,
    } = this.props;
    const shouldSetSearchFieldType =
      !searchFieldType || prevSearchComponentName !== searchComponentName;
    if (shouldSetSearchFieldType) {
      setSearchFieldType(defaultSearchFieldType);
    }
    setPrevSearchComponentName(searchComponentName);
  }

  componentWillUnmount() {
    const {
      closeSearchErrorFlyout,
      clearSearchFieldActive,
      clearSearchFieldValue,
      clearSearchApplied,
      router: { location },
    } = this.props;
    closeSearchErrorFlyout();
    clearSearchFieldActive();
    if (location.pathname === "/search" || location.pathname === "/associates") {
      clearSearchFieldValue();
      clearSearchApplied();
    }
  }

  /** ----------------------------------------------------------------------- **\
        HANDLER METHODS
    \** ----------------------------------------------------------------------- * */
  onChange(e) {
    const { onFieldUpdate, searchFieldType, isLiveSearch, clearSearchApplied } = this.props;
    const searchFieldValue = stripEmojis(e.target.value);

    onFieldUpdate({ searchFieldType, searchFieldValue });
    if (isLiveSearch) {
      if (e.target.value === "") {
        clearSearchApplied();
      } else {
        this.handleSearch(searchFieldValue);
      }
    }
  }

  handleKeyPress(e) {
    const { clearSearchApplied } = this.props;

    if (e.key === "Enter") {
      this.handleSearch(e);
    } else if (e.key === "Escape") {
      clearSearchApplied();
    }
  }

  handleFocus() {
    const { setSearchFieldActive } = this.props;
    setSearchFieldActive();
  }

  handleBlur() {
    const { clearSearchFieldActive } = this.props;
    clearSearchFieldActive();
  }

  handleSearch(e) {
    const {
      router: { navigate, location },
      onSearch,
      validateSearchField,
      searchFieldType,
      setSearchErrorMessage,
      clearSearchErrorMessage,
      openSearchErrorFlyout,
      closeSearchErrorFlyout,
      defaultSearchFieldType,
      redirectOnSearch,
    } = this.props;

    const searchFieldValue = stripEmojis(this.props.searchFieldValue || e?.target?.value);
    const { validationError, message } = validateSearchField({ searchFieldType, searchFieldValue });

    if (validationError) {
      setSearchErrorMessage(message);
      openSearchErrorFlyout();
    } else {
      clearSearchErrorMessage();
      closeSearchErrorFlyout();

      const fieldType = searchFieldType || defaultSearchFieldType;
      onSearch(fieldType, searchFieldValue);
      if (redirectOnSearch) {
        redirectOnSearch({ location, navigate });
      }
    }
  }

  /** ----------------------------------------------------------------------- **\
        SUB COMPONENT RENDER METHOD
    \** ----------------------------------------------------------------------- * */
  renderSearchFieldFlyout() {
    const { searchErrorMessage } = this.props;
    return <SearchFieldFlyout searchErrorMessage={searchErrorMessage} />;
  }

  /** ----------------------------------------------------------------------- **\
        COMPONENT RENDER METHOD
    \** ----------------------------------------------------------------------- * */
  render() {
    const {
      selectedSearchOption,
      defaultValue,
      searchOptions,
      searchFieldValue,
      searchFieldActive,
      searchErrorFlyoutOpen,
      closeSearchErrorFlyout,
      searchApplied,
      clearSearchApplied,
      styles,
    } = this.props;

    return (
      <StyledPopover
        isOpen={searchErrorFlyoutOpen}
        preferPlace="below"
        onOuterAction={closeSearchErrorFlyout}
        tipSize={7}
        target={this.inputRef}
        body={this.renderSearchFieldFlyout()}
      >
        <SectionWrapper className="SearchFieldComponent" isFocused={searchFieldActive}>
          <SearchSelectContainer
            className="SearchFieldComponent__searchSelectContainer"
            value={selectedSearchOption}
            defaultValue={defaultValue}
            options={searchOptions}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
          />
          <SearchInputWrapper
            id="input_dashboard_customer_search"
            className="SearchFieldComponent__searchInputWrapper"
            styles={styles}
            type="text"
            value={searchFieldValue}
            handleSearch={this.handleSearch}
            searchApplied={searchApplied}
            clearSearchApplied={clearSearchApplied}
            isFocused={searchFieldActive}
            onChange={this.onChange}
            onKeyPress={this.handleKeyPress}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
          />
        </SectionWrapper>
      </StyledPopover>
    );
  }
}

export default withRouteProps(SearchFieldComponent);
