import React from "react";
import { Rect, Group } from "react-konva";
import { color } from "../../common/styles/theme";
import ganttConstants from "../../../core/constants/ganttConstants";

class GanttAppointmentComponent extends React.Component {
  constructor(props) {
    super(props);

    this.getFill = this.getFill.bind(this);
    this.getStroke = this.getStroke.bind(this);
    this.isWarning = this.isWarning.bind(this);
  }

  isWarning() {
    const { appointment, speciesId, hasRatingAlert } = this.props;
    return appointment.isExpress || speciesId === 2 || hasRatingAlert;
  }

  getFill({ isSelected }) {
    const { isAvailable, isBooked, lockToAssociate } = this.props;
    if (isBooked) {
      return color.green300;
    }
    if (isAvailable) {
      return;
    }

    if (isSelected) {
      return color.blue500;
    }

    // isWarning shoudl take priority over lockAssociate
    if (this.isWarning()) {
      return color.red700;
    }

    if (lockToAssociate) {
      return color.yellow700;
    }

    return color.gray400;
  }

  getStroke({ isSelected }) {
    const { isAvailable, isHovered, isBooked, lockToAssociate } = this.props;

    if (isBooked) {
      return color.green300;
    }
    if (isAvailable) {
      if (isHovered) {
        return color.blue500;
      }
      return color.green300;
    }
    if (isSelected) {
      return color.blue500;
    }

    // isWarning shoudl take priority over lockAssociate
    if (this.isWarning()) {
      return color.red700;
    }

    if (lockToAssociate) {
      return color.yellow700;
    }

    return color.gray400;
  }

  render() {
    const {
      isAvailable,
      appointmentHeight,
      y,
      x,
      width,
      location,
      onSelectTimeSlot,
      onHoverTimeSlot,
      onClearHoverTimeSlot,
      onHoverAppointment,
      onLeaveAppointment,
      selectedPetService,
      onSelectAppointment,
      onClearAppointment,
      bookingSelectedPet,
      isSelected,
    } = this.props;

    // const inDashboard = location.pathname.endsWith("/dashboard");
    const inBooking =
      location.pathname.endsWith("/select-time-slot") ||
      location.pathname.includes("modify-appointment");

    return (
      <Group>
        <Rect
          x={x + ganttConstants.APPOINTMENT_X_GAP}
          y={y}
          height={
            appointmentHeight > ganttConstants.APPOINTMENT_Y_GAP
              ? appointmentHeight - ganttConstants.APPOINTMENT_Y_GAP
              : appointmentHeight
          }
          width={
            width > ganttConstants.APPOINTMENT_X_GAP * 2
              ? width - ganttConstants.APPOINTMENT_X_GAP * 2
              : width
          }
          fill={this.getFill({ isSelected })}
          stroke={this.getStroke({ isSelected })}
          lineWidth={3}
          cornerRadius={10}
          onClick={() => {
            inBooking && isAvailable && onSelectTimeSlot({ selectedPetService });
          }}
          onMouseEnter={e => {
            // When the user mouses over an appointment, we need to get the mouse x,y
            // position and pass that to the function that will dispatch the action
            // to show the quick view for appointments.
            onHoverAppointment(e.evt.clientX, e.evt.clientY);

            if (inBooking) {
              onSelectAppointment();
            } else if (!isAvailable) {
              onSelectAppointment();
            }

            inBooking && isAvailable && onHoverTimeSlot({ petId: bookingSelectedPet });
          }}
          onMouseLeave={() => {
            onClearAppointment();

            inBooking && isAvailable && onClearHoverTimeSlot({ petId: bookingSelectedPet });

            // When the user's mouse leaves the appointment we want to hide the appointment quickView
            onLeaveAppointment();
          }}
        />
      </Group>
    );
  }
}

export default GanttAppointmentComponent;
