import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Layout } from "@prism/psm-ui-components";
import { isEmpty } from "lodash/fp";
import { color } from "@/web/common/styles/theme";
import { systemName } from "@/web/setSystemType/constants/setSystemTypeConstants";
import { PROFILE_COMPONENT_TYPES } from "./associateProfileConstants";
import associateActionTypes from "@/core/actionTypes/associateActionTypes";
import { LOAD_TRAINER_BOOKED_APPOINTMENTS } from "./associateProfilePageActions";
import { createLoadingSelector } from "@/core/selectors/utils";
import {
  loadEmployeeGroups,
  loadAssociates,
  hideAssociateModal,
} from "@/core/actionCreators/associateActionCreator";
import { parseAssociateCopy } from "./associateProfileUtils";
import { getIsAssociateVirtualTrainer } from "./associateProfilePageSelectors";
import getIsTrainingWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/training/getIsTrainingWorkflowFeatureFlagHidden";
import {
  getTrainingAssociates,
  GET_TRAINING_ASSOCIATES,
} from "../trainingAssociates/trainingAssociatesActions";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import { getAssociates } from "@/core/selectors/entitiesSelector";
import SalonIcon from "@/web/common/icons/SalonIcon";
import TrainingIcon from "@/web/common/icons/TrainingIcon";
import LoadingWrapper from "@/web/common/LoadingWrapper";
import AssociateProfileHeader from "@/web/header/associateProfileHeaderContainer";
import HeaderImage from "@/assets/images/bg-header-image.png";
import IndexTab from "@/web/common/tabs/indexTabs/IndexTab";
import TakeAssociatePhotoModal from "./TakeAssociatePhotoModal";
import RemoveVirtualTrainerModal from "./RemoveVirtualTrainerModal";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";

const AssociateProfilePage = ({
  isLoading,
  associates,
  loadGroups,
  loadAssociates,
  getTrainingAssociates,
  associateProfileCopy,
  isVirtualTrainer,
  isTrainingFeatureFlagHidden,
}) => {
  const [activeTab, setActiveTab] = useState(systemName.SALON);
  const getActiveTabColor = isActive => (isActive ? color.blue500 : color.white0);

  useEffect(() => {
    loadGroups();
  }, []);

  useEffect(() => {
    if (isEmpty(associates)) {
      loadAssociates();
      if (!isTrainingFeatureFlagHidden) {
        getTrainingAssociates();
      }
    }
  }, [associates]);

  useEffect(() => {
    if (isVirtualTrainer) {
      setActiveTab(systemName.TRAINING);
    }
  }, [isVirtualTrainer]);

  return (
    <LoadingWrapper isLoading={isLoading} displayContainer="block">
      <AssociateProfileHeader backgroundImage={HeaderImage} title={associateProfileCopy} />
      <Layout.Cluster justify="flex-start" style={{ margin: "-40px 0 0 2rem" }}>
        <IndexTab
          label="Salon Profile"
          isActive={activeTab === systemName.SALON}
          renderIcon={isActive => <SalonIcon color={getActiveTabColor(isActive)} />}
          handleClick={() => setActiveTab(systemName.SALON)}
          style={{ cursor: isTrainingFeatureFlagHidden ? "auto" : "pointer" }}
        />
        {!isTrainingFeatureFlagHidden && (
          <IndexTab
            label="Training Profile"
            isActive={activeTab === systemName.TRAINING}
            renderIcon={isActive => <TrainingIcon color={getActiveTabColor(isActive)} />}
            handleClick={() => setActiveTab(systemName.TRAINING)}
          />
        )}
      </Layout.Cluster>
      {PROFILE_COMPONENT_TYPES[activeTab]}
      <TakeAssociatePhotoModal />
      <RemoveVirtualTrainerModal />
    </LoadingWrapper>
  );
};

export default withRouteProps(
  connect(
    (state, { router }) => {
      const isLoading = createLoadingSelector([
        associateActionTypes.LOAD_EMPLOYEE_GROUPS,
        associateActionTypes.LOAD_ASSOCIATES,
        associateActionTypes.UPDATE_ASSOCIATE,
        associateActionTypes.PATCH_ASSOCIATE,
        GET_TRAINING_ASSOCIATES,
        LOAD_TRAINER_BOOKED_APPOINTMENTS,
      ])(state);
      const { associateId } = router.params;
      return {
        isLoading,
        associates: getAssociates(state),
        storeNumber: getStoreNumber(state),
        associateProfileCopy: parseAssociateCopy({
          state,
          associateId,
          isLoading,
          text: "Profile",
        }),
        isTrainingFeatureFlagHidden: getIsTrainingWorkflowFeatureFlagHidden(state),
        isVirtualTrainer: getIsAssociateVirtualTrainer(state, { associateId }),
      };
    },
    dispatch => ({
      loadGroups: () => {
        dispatch(loadEmployeeGroups());
      },
      clearModal: () => dispatch(hideAssociateModal()),
      loadAssociates: () => dispatch(loadAssociates({ isLimited: false })),
      dispatchGetTrainingAssociates: storeNumber =>
        dispatch(getTrainingAssociates({ storeNumber, isLimited: false })),
    }),
    (stateProps, dispatchProps, mergeProps) => {
      const { storeNumber } = stateProps;
      const { dispatchGetTrainingAssociates } = dispatchProps;
      return {
        ...stateProps,
        ...dispatchProps,
        ...mergeProps,
        getTrainingAssociates: () => {
          dispatchGetTrainingAssociates(storeNumber);
        },
      };
    },
  )(AssociateProfilePage),
);
