import { createSelector } from "reselect";
import { getState } from "core/selectors/commonSelector";
import {
  getAssociate,
  getPetServiceItem,
  getStoreFromSalon,
  getServiceDetailsForCart,
} from "core/selectors/entitiesSelector";
import { getAppointmentByPet } from "core/selectors/cartSelectors";
import {
  getPageName,
  isCartDetailsEditable,
  isFromAnotherStore,
} from "core/selectors/checkInOutSelector";
import {
  getAppointmentDetailsType,
  getIsAppointmentDetailsTypeCheckedOut,
  getIsStatusConfirmed,
} from "dux/common/appointmentUtils";
import { getStoreAddress, getStoreNameAndNumber } from "dux/_selectors/storeSelectors";

export const getAppointmentMetaDataByPetIdFactory = SELECTOR => {
  return createSelector([SELECTOR, getState], (petId, state) => {
    const pageName = getPageName(state);

    // Appointment Data
    const appointment = getAppointmentByPet(state, { petId });
    const petServiceItemId = appointment?.petServiceItemId;
    const petServiceId = appointment?.petService;
    const completedPreCheckIn = appointment?.completedPreCheckIn;
    const lockToAssociate = appointment?.lockToAssociate;
    const timeSlot = appointment?.timeSlot;
    const timeSlotStartDateTime = timeSlot?.startDateTime;
    const associateId = timeSlot?.associateId;
    const associate = getAssociate(state, { associateId });
    const appointmentDetailsType = getAppointmentDetailsType(pageName);
    const isAppointmentDetailsTypeCheckedOut = getIsAppointmentDetailsTypeCheckedOut(
      appointmentDetailsType,
    );
    const addOns = appointment?.addons || [];

    // Pet service item data
    const petServiceItem = getPetServiceItem(state, { petServiceItemId }) || false;
    const status = petServiceItem?.status;
    const engagementId = petServiceItem?.engagement;
    const itineraryId = petServiceItem?.itineraryId;
    const storeNumber = petServiceItem?.storeNumber;
    const petServiceItemStartDateTime = petServiceItem?.startDateTime;
    const dynamicPrice = petServiceItem?.dynamicPrice;
    const isEditable = isCartDetailsEditable(state, { petServiceItemId });
    const currentStore = getStoreFromSalon(state, { storeNumber });
    const isAppointmentFromAnotherStore = isFromAnotherStore(state, { petServiceItemId });
    const isStatusConfirmed = getIsStatusConfirmed(status);

    // Service
    const service = getServiceDetailsForCart(state, {
      petServiceId,
      petServiceItemId,
    });

    // Store data
    const storeNameAndNumber = getStoreNameAndNumber(state, { storeNumber });
    const storeAddress = getStoreAddress(state, { storeNumber });

    // Specials
    const specials = dynamicPrice?.specials || [];

    // TODO: this object contains a lot of duplicate data and could be cleaned up
    // TODO: for example: petServiceItem's startDateTime is included on 5 different places of this object
    return {
      appointment,
      petServiceItemId,
      petServiceId,
      completedPreCheckIn,
      lockToAssociate,
      timeSlot,
      timeSlotStartDateTime,
      associateId,
      associate,
      appointmentDetailsType,
      isAppointmentDetailsTypeCheckedOut,
      addOns,
      petServiceItem,
      status,
      engagementId,
      itineraryId,
      storeNumber,
      petServiceItemStartDateTime,
      dynamicPrice,
      isEditable,
      currentStore,
      isStatusConfirmed,
      service,
      storeNameAndNumber,
      storeAddress,
      specials,
      isFromAnotherStore: isAppointmentFromAnotherStore,
      isStandalone: appointment?.isStandalone,
    };
  });
};
