import React from "react";
import styled from "styled-components";
import moment from "moment";
import isEqual from "lodash/isEqual";

import { Stage, Layer, Line } from "react-konva";
import { color } from "../common/styles/theme";

import { absenceTypes } from "../../core/constants/absencesContants";

import GanttAppointment from "./ganttAppointment/GanttAppointmentComponent";
import GanttAbsence from "./ganttAbsence/GanttAbsence";
import { isFromDashboard } from "../../core/utils/validationUtils/isFromValidation";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { LayoutGrid } from "@/layout/grid/Grid";
import ganttConstants from "@/core/constants/ganttConstants";

export const StyledGanttRow = styled.div`
  pointer-events: ${props => (props.disabled ? "none" : "auto")};

  &:hover {
    color: ${color.blue500};
  }
`;

const AppointmentsContainer = styled.div`
  overflow: hidden;
`;

class GanttRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      height: 0,
    };

    this.drawLines = this.drawLines.bind(this);
    this.drawMovingTimeline = this.drawMovingTimeline.bind(this);
    this.drawAppointments = this.drawAppointments.bind(this);
    this.drawAbsences = this.drawAbsences.bind(this);
    this.calculateX = this.calculateX.bind(this);
    this.calculateWidth = this.calculateWidth.bind(this);
    this.calculateAppointmentsPosition = this.calculateAppointmentsPosition.bind(this);

    this.xOffset = 3;
  }

  componentDidMount() {
    this.calculateAppointmentsPosition();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !isEqual(prevProps.appointments, this.props.appointments) ||
      !isEqual(prevProps.availableAppointments, this.props.availableAppointments)
    ) {
      this.calculateAppointmentsPosition();
    }
  }

  calculateAppointmentsPosition() {
    const { appointments, availableAppointments, height, appointmentHeight } = this.props;

    const allAppointments = [...appointments, ...availableAppointments];
    const rows = [];

    for (const appointment of allAppointments) {
      if (!rows.length) {
        rows.push([{ ...appointment, rowNumber: 0 }]);
        continue;
      }

      let rowNumber = null;

      rowLoop: for (let i = 0; i < rows.length; i++) {
        for (let rowAppointment of rows[i]) {
          if (
            moment(appointment.startDateTime).isBetween(
              rowAppointment.startDateTime,
              rowAppointment.endDateTime,
              null,
              "[)",
            ) ||
            moment(appointment.endDateTime).isBetween(
              rowAppointment.startDateTime,
              rowAppointment.endDateTime,
              null,
              "(]",
            ) ||
            moment(rowAppointment.startDateTime).isBetween(
              appointment.startDateTime,
              appointment.endDateTime,
              null,
              "[)",
            ) ||
            moment(rowAppointment.endDateTime).isBetween(
              appointment.startDateTime,
              appointment.endDateTime,
              null,
              "(]",
            )
          ) {
            continue rowLoop;
          }
        }
        rowNumber = i;
        rows[rowNumber].push({ ...appointment, rowNumber });
        break rowLoop;
      }

      if (rowNumber === null) {
        rows.push([{ ...appointment, rowNumber: rows.length }]);
      }
    }

    this.setState({
      rows,
      height: Math.max(height, rows.length * appointmentHeight),
    });
  }

  calculateX(startDateTime) {
    const { hours, currentWidth } = this.props;
    const startTime = moment(startDateTime);
    const itemHours = startTime.hours();
    const itemMinutes = startTime.minutes();
    return (
      (hours.findIndex(hour => hour.value === itemHours) * currentWidth) / hours.length +
      this.xOffset +
      ((itemMinutes / 60) * currentWidth) / hours.length
    );
  }

  calculateWidth(duration) {
    const { currentWidth, hours } = this.props;
    return ((Math.abs(duration) / 60) * currentWidth) / hours.length;
  }

  drawMovingTimeline() {
    const { height, timeOffset } = this.props;
    const x = this.calculateX(moment().utcOffset(timeOffset));

    return <Line points={[x, 0, x, height]} stroke={color.red700} strokeWidth={1} />;
  }

  drawLines() {
    const { hours, currentWidth } = this.props;
    const { height } = this.state;
    return hours.map((hour, index) => {
      const x = (index * currentWidth) / hours.length + this.xOffset;
      const lastX = ((index - 1) * currentWidth) / hours.length + this.xOffset;
      return (
        <React.Fragment key={index}>
          <Line
            points={[x, 0, x, height]}
            stroke={index % 4 === 0 ? color.gray800 : color.gray300}
            strokeWidth={1}
          />
          {index > 0 && (
            <Line
              points={[x - (x - lastX) / 2, 0, x - (x - lastX) / 2, height]}
              stroke={color.gray300}
              strokeWidth={1}
              dash={[5, 3]}
            />
          )}
        </React.Fragment>
      );
    });
  }

  drawAppointments() {
    const {
      groupMember,
      appointmentHeight,
      calculateX,
      getAppointmentStateProps,
      getAppointmentDispatchProps,
      router,
    } = this.props;
    const { rows, height } = this.state;

    const numberOfRows = rows.length;
    const appointmentsToDraw = rows.reduce((acc, value) => [...acc, ...value], []);

    const middleHeight = height / 2;
    const isEven = numberOfRows % 2 === 0;
    const middleRow = Math.ceil(numberOfRows / 2);

    return appointmentsToDraw.map((appointment, index) => {
      const y =
        middleHeight -
        (middleRow - appointment.rowNumber - 1) * appointmentHeight -
        (isEven ? appointmentHeight / 2 : 0);
      return (
        <React.Fragment key={index}>
          <GanttAppointment
            key={index}
            associateId={groupMember}
            appointment={appointment}
            appointmentHeight={appointmentHeight}
            x={calculateX(appointment.startDateTime)}
            width={this.calculateWidth(appointment.duration)}
            y={y}
            isAvailable={!appointment.petServiceItemId}
            // can't access the store or router within the canvas, so passing the props here instead of connect() & withRouter()
            location={router?.location}
            {...getAppointmentStateProps(appointment, groupMember)}
            {...getAppointmentDispatchProps(appointment, router)}
          />
        </React.Fragment>
      );
    });
  }

  drawAbsences() {
    const { absences } = this.props;
    return absences
      .filter(absence => absence.absenceReason === absenceTypes.BREAK)
      .map((absence, index) => (
        <GanttAbsence
          key={index}
          absence={absence}
          x={this.calculateX(absence.absenceStartDateTime)}
          width={this.calculateWidth(absence.absenceDuration)}
        />
      ));
  }

  handleGanttRowClick = () => {
    const {
      onRowClick,
      router: { location },
      isWeeklyView,
    } = this.props;

    onRowClick({
      fromDashboard: isFromDashboard(location.pathname),
      isWeeklyView,
    });
  };

  handleMouseEnter = () => {
    const { onSelectAssociate, groupMember, isWeeklyView } = this.props;
    !isWeeklyView && onSelectAssociate(groupMember);
  };

  handleMouseLeave = () => {
    const { onSelectAssociate, isWeeklyView } = this.props;

    !isWeeklyView && onSelectAssociate("");
  };

  render() {
    const {
      groupMember,
      GroupMemberComponent,
      innerRef,
      currentWidth,
      height,
      appointmentHeight,
      row,
      disabled,
    } = this.props;
    const { rows } = this.state;
    return (
      <StyledGanttRow
        row={row}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onClick={this.handleGanttRowClick}
        disabled={disabled}
      >
        <LayoutGrid space="scale-0" minimum="scale-0" style={ganttConstants.ROW_GRID_TEMPLATE}>
          <GroupMemberComponent groupMember={groupMember} />
          <AppointmentsContainer innerRef={innerRef}>
            <Stage width={currentWidth} height={Math.max(height, rows.length * appointmentHeight)}>
              <Layer>
                {this.drawLines()}
                {this.drawMovingTimeline()}
                {this.drawAbsences()}
                {this.drawAppointments()}
              </Layer>
            </Stage>
          </AppointmentsContainer>
        </LayoutGrid>
      </StyledGanttRow>
    );
  }
}

export default withRouteProps(GanttRow);
