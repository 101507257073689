import React, { useEffect, useState } from "react";
import { Button, Text } from "@prism/psm-ui-components";
import { LayoutBox } from "../../layout/box/Box";
import { LayoutCenter } from "../../layout/center/Center";
import { LayoutStack } from "../../layout/stack/Stack";

import { connect } from "react-redux";
import { color } from "web/common/styles/theme";
import { createLoadingSelector } from "core/selectors/utils";
import customersActionTypes from "core/actionTypes/customersActionTypes";
import { GET_HOTEL_ITINERARY } from "dux/hotelItinerary/hotelItineraryActions";
import { getCurrentPet } from "core/selectors/persistentSelectors";
import { getCurrentItinerary } from "core/selectors/checkInOutSelector";
import { cancelHotelPet } from "dux/hotelCancelAppt/hotelCancelApptActions";
import { itinerariesStatusList } from "dux/_constants";
import { getFirstHotelEngagementByPet } from "dux/hotelEngagements/hotelEngagementSelectors";
import { APPOINTMENT_STATUS } from "core/constants";
import CommonModal from "web/common/modals/commonModal";
import { getCancellationReasons } from "../cancellationreasons/cancellationReasonsActions";
import Checkbox from "web/common/Checkbox";
import { getCancelAppointmentReason } from "../../core/selectors/checkInOutSelector";
import { selectCancellationReasons } from "../cancellationreasons/cancellationReasonsSeletors";
import {
  clearCancelAppointmentReason,
  setCancelAppointmentReason,
} from "@/core/actionCreators/checkInOutActionCreator";
import CommonButton from "@/web/common/commonButton";
import { PATCH_HOTEL_ITINERARY_STATUS } from "@/dux/reservationCartDetailsConfirmation/reservationCartDetailsConfirmationActions";

export const HotelCancelApptButton = props => {
  const {
    componentId,
    label,
    isDisabled,
    loadCancellationReasons,
    cancellationReasons,
    setCancelAppointmentReason,
    cancelAppointmentReason,
    resetCancelApptReason,
    isHidden,
    onCancelPet,
  } = props;
  const [isModalShowing, setIsModalShowing] = useState(false);

  // Get Cancellation reasons
  useEffect(() => {
    if (cancellationReasons.length <= 0) {
      loadCancellationReasons();
    }
  }, [loadCancellationReasons]);

  const handleCancel = () => {
    setIsModalShowing(false);
    resetCancelApptReason();
  };

  const handleOnCancelSubmit = () => {
    setIsModalShowing(false);
    resetCancelApptReason();
    onCancelPet();
  };

  if (isHidden) return;

  return (
    <LayoutBox id={componentId}>
      <Button
        variant="link"
        disabled={isDisabled}
        onClick={() => setIsModalShowing(true)}
        style={{ color: color.red700 }}
      >
        {label}
      </Button>

      {isModalShowing && (
        <CommonModal id={`${componentId}__commonModal--appointmentCancel`} onClose={handleCancel}>
          <LayoutBox>
            <LayoutStack>
              <Text size="text-size-lg" align="center" bold>
                Appointment cancellation
              </Text>
              <Text size="text-size-md" align="center">
                Before we cancel the appointment, we want to know why
              </Text>
              <LayoutCenter>
                <LayoutStack>
                  {cancellationReasons?.map(reason => (
                    <Checkbox
                      label={reason.value}
                      labelPosition="right"
                      onChange={() => setCancelAppointmentReason(reason.value)}
                      checked={reason.value === cancelAppointmentReason?.reason}
                      boldSelection
                      key={reason.value}
                    />
                  ))}
                </LayoutStack>
              </LayoutCenter>
              <LayoutStack>
                <LayoutCenter>
                  <CommonButton
                    label="Cancel"
                    width="160px"
                    height="49px"
                    disabled={!cancelAppointmentReason}
                    onClick={handleOnCancelSubmit}
                  />
                </LayoutCenter>
                <LayoutCenter>
                  {!cancelAppointmentReason && <Text>Please supply a reason for cancellation</Text>}
                </LayoutCenter>
              </LayoutStack>
            </LayoutStack>
          </LayoutBox>
        </CommonModal>
      )}
    </LayoutBox>
  );
};

// HOTEL CANCEL CONTAINER ----------------------------------------------------------------------------------------
export const HotelCancelSingleAppointment = connect(
  state => {
    const petId = getCurrentPet(state);
    const hotelItineraryId = getCurrentItinerary(state);
    const engagement = getFirstHotelEngagementByPet(state, { petId });
    const isLoading = createLoadingSelector([
      customersActionTypes.LOAD_CUSTOMER,
      GET_HOTEL_ITINERARY,
      PATCH_HOTEL_ITINERARY_STATUS,
    ])(state);

    const isHidden =
      engagement?.status === APPOINTMENT_STATUS.CHECKED_IN ||
      engagement?.status === APPOINTMENT_STATUS.CHECKED_OUT;

    return {
      cancellationReasons: selectCancellationReasons(state),
      cancelAppointmentReason: getCancelAppointmentReason(state),
      componentId: "HotelCancelSingleAppointment",
      label: "Cancel Reservation",
      isDisabled:
        isLoading ||
        engagement?.status === APPOINTMENT_STATUS.CANCELED ||
        engagement?.status === APPOINTMENT_STATUS.PENDING,
      petId,
      hotelItineraryId,
      isHidden,
    };
  },

  dispatch => {
    return {
      cancelPet: ({ petId, hotelItineraryId, cancelAppointmentReason }) => {
        dispatch(
          cancelHotelPet({
            petId,
            hotelItineraryId,
            status: itinerariesStatusList.CANCELED,
            reason: cancelAppointmentReason.reason,
          }),
        );
      },
      getReasonsForCancel: () => {
        dispatch(getCancellationReasons());
      },
      setCancelAppointmentReason: reason => dispatch(setCancelAppointmentReason({ reason })),
      resetCancelApptReason: () => dispatch(clearCancelAppointmentReason()),
    };
  },
  (mapProps, dispatchProps) => {
    const {
      componentId,
      cancellationReasons,
      cancelAppointmentReason,
      label,
      isDisabled,
      petId,
      hotelItineraryId,
      isHidden,
    } = mapProps;
    const {
      cancelPet,
      getReasonsForCancel,
      setCancelAppointmentReason,
      resetCancelApptReason,
    } = dispatchProps;
    return {
      // control what props get passed to the view
      componentId,
      label,
      isDisabled,
      cancellationReasons,
      cancelAppointmentReason,
      isHidden,

      // actions to pass to view
      onCancelPet: () => cancelPet({ petId, hotelItineraryId, cancelAppointmentReason }),
      loadCancellationReasons: () => getReasonsForCancel(),
      setCancelAppointmentReason: reason => setCancelAppointmentReason({ reason }),
      resetCancelApptReason: () => resetCancelApptReason(),
    };
  },
)(HotelCancelApptButton);
