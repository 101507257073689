import React from "react";
import styled from "styled-components";

import Cell from "./cell";
import { mapWithKey } from "../../core/utils";
import { color, scheduleGridColumns } from "../common/styles/theme";

const TotalsRowComponent = ({ totals, backgroundColor, label, noBorderTop }) => (
  <Container noBorderTop={noBorderTop}>
    <TotalsCell>{label}</TotalsCell>

    {mapWithKey(
      (totalForDate, date) => (
        <Cell key={date} backgroundColor={backgroundColor}>
          {totalForDate && totalForDate.toFixed(2)}
        </Cell>
      ),
      totals,
    )}
  </Container>
);

const Container = styled.div`
  display: grid;
  grid-template-columns: ${scheduleGridColumns};
  grid-auto-rows: 100px;
  font-weight: bold;
  font-size: 1.125rem;
  border-top: ${({ noBorderTop }) => (noBorderTop ? "none" : `1px solid ${color.gray300}`)};
  border-bottom: 1px solid ${color.gray300};
`;

const TotalsCell = styled(Cell)`
  align-items: flex-start;
  border-left: 1px solid ${color.gray300};
`;

export default TotalsRowComponent;
