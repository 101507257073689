import React from "react";
import { connect } from "react-redux";
import { TextPassage } from "@petsmart-ui/sparky";
import BookingFoodsDeleteButtonContainer from "dux/bookingMedication/BookingFoodsDeleteButtonContainer";
import { CheckInOutUpdateFoodForm } from "dux/_components/checkInOutFeeding/CheckInOutFoodForm";
import PetProfileFoodsDeleteButtonContainer from "web/foodList/PetProfileFoodsDeleteButtonContainer";
import PetProfileFoodsRightArrowContainer from "web/foodList/PetProfileFoodsRightArrowContainer";
import PetProfileFoodFormContainer from "web/petProfileFoodForm/PetProfileFoodFormContainer";
import BookingFoodFormContainer from "dux/bookingFeeding/BookingFoodFormContainer";
import { FoodsDiscontinuedLabel } from "@/dux/discontinuedLabel/DiscontinuedLabel";
import { getFoodIsDiscontinuedById } from "web/food/foodsSelector";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutBox } from "@/layout/box/Box";
import { color } from "@/web/common/styles/theme";

const DefaultLabel = ({ foodIndex }) => <TextPassage size="lg">{`Food ${foodIndex}`}</TextPassage>;

export const FoodListItem = props => {
  const {
    componentId,
    isHidden,
    foodId,
    foodIndex,
    petId,
    locationType,
    setFeedingFormInvalid,
    invalidFeedingForms,
    diComp,
    hasBorder,
  } = props;

  if (isHidden) return null;

  const LabelComponent = diComp?.label || DefaultLabel;
  const FormComponent = diComp?.form || (() => <></>);

  return (
    <LayoutBox
      id={componentId}
      padding="scale-G1"
      style={{
        paddingRight: 0,
        paddingLeft: 0,
        ...(hasBorder && { borderBottom: `1px solid ${color.gray100}` }),
      }}
    >
      <LayoutStack>
        <LayoutBox padding="scale-0">
          <LayoutCluster style={{ justifyContent: "space-between" }}>
            <LayoutBox padding="scale-0">
              <LabelComponent
                foodId={foodId}
                foodIndex={foodIndex}
                petId={petId}
                locationType={locationType}
              />
            </LayoutBox>

            <LayoutBox padding="scale-0">
              <PetProfileFoodsRightArrowContainer
                foodId={foodId}
                petId={petId}
                locationType={locationType}
              />
            </LayoutBox>
          </LayoutCluster>
        </LayoutBox>

        <LayoutBox padding="scale-0">
          <FormComponent
            foodIndex={foodIndex}
            foodId={foodId}
            petId={petId}
            locationType={locationType}
            setFeedingFormInvalid={setFeedingFormInvalid}
            invalidFeedingForms={invalidFeedingForms}
          />
        </LayoutBox>
      </LayoutStack>
    </LayoutBox>
  );
};

export const CheckInOutFoodListItem = connect((state, { petId, foodId, foodIndex }) => {
  const isDiscontinued = getFoodIsDiscontinuedById(state, { petId, foodId });
  return {
    componentId: `CheckInOutFoodListItem-${foodIndex}`,
    diComp: {
      label: isDiscontinued && FoodsDiscontinuedLabel,
      form: CheckInOutUpdateFoodForm,
    },
  };
})(FoodListItem);

export const BookingFoodListItem = connect((state, { foodIndex }) => {
  return {
    componentId: `BookingFoodListItem-${foodIndex}`,
    diComp: {
      label: BookingFoodsDeleteButtonContainer,
      form: BookingFoodFormContainer,
    },
  };
})(FoodListItem);

export const PetProfileFoodListItem = connect((state, { petId, foodId, foodIndex }) => {
  const isDiscontinued = getFoodIsDiscontinuedById(state, { petId, foodId });
  return {
    componentId: `PetProfileFoodListItem-${foodIndex}`,
    diComp: {
      label: isDiscontinued ? FoodsDiscontinuedLabel : PetProfileFoodsDeleteButtonContainer,
      form: PetProfileFoodFormContainer,
    },
  };
})(FoodListItem);
