import React from "react";
import { connect } from "react-redux";
import { Button, Text } from "@prism/psm-ui-components";
import { history } from "@/dux/utils/browser/browserHistory";
import { fontSizes } from "web/common/styles/responsive/fonts";
import { color } from "web/common/styles/theme";
import { getPetById, getPetFullName } from "core/selectors/entitiesSelector";
import { setPetId } from "core/actionCreators/ui/web/generalActionCreators";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { createLoadingSelector } from "core/selectors/utils";
import customersActionTypes from "core/actionTypes/customersActionTypes";
import { GET_HOTEL_ITINERARY } from "dux/hotelItinerary/hotelItineraryActions";

export const PetNameCheckInOutLink = props => {
  const {
    petFullName,
    isLoading,
    loadingMessage,
    customerKey,
    petId,
    setPetId,
    isHidden,
    isPetActive,
    componentId,
  } = props;

  const handlePetNameClick = () => {
    setPetId(petId);
    history.push(`/pet-parent-profile/${customerKey}`);
  };

  if (isHidden) {
    return;
  }

  return (
    <Button
      id={componentId}
      style={{
        fontSize: fontSizes.big,
        padding: 0,
        color: isPetActive ? color.blue500 : color.gray600,
      }}
      variant="no-outline"
      onClick={!isLoading ? handlePetNameClick : undefined}
    >
      <Text
        color="text-color-blue"
        align="left"
        fontFamily="text-family-montserrat"
        letterSpacing="text-tracking-normal"
        size="text-size-lg"
      >
        {isLoading ? loadingMessage : petFullName}
      </Text>
    </Button>
  );
};

// HOTEL CHECKIN PET NAME LINK CONTAINER --------------------------------------------------------------------------
export const HotelPetNameCheckInOutLink = connect(
  (state, ownProps) => {
    const customerKey = getCurrentCustomerKey(state);
    const pet = getPetById(state, ownProps.petId);
    return {
      isLoading:
        createLoadingSelector([GET_HOTEL_ITINERARY])(state) ||
        createLoadingSelector([customersActionTypes.LOAD_CUSTOMER])(state),
      loadingMessage: "Loading...",
      isHidden: false,
      componentId: "HotelPetNameCheckInOutLink",
      isPetActive: pet?.isActive,
      petFullName: getPetFullName(state, { petId: ownProps.petId, customerKey }),
      customerKey,
    };
  },
  dispatch => ({
    setPetId: petId => dispatch(setPetId(petId)),
  }),
)(PetNameCheckInOutLink);
