import React, { useEffect } from "react";
import SetFrequencyFoodButtonContainer from "dux/bookingFeeding/setFoodFrequency/SetFrequencyFoodButtonContainer";
import AddNewBookingFoodButtonContainer from "dux/bookingFeeding/AddNewBookingFoodButtonContainer";
import { UpdateBookingFoodButtonContainer } from "dux/bookingFeeding/UpdateBookingFoodButtonContainer";
import FoodFrequencyErrorMessageContainer from "dux/frequency/FoodFrequencyErrorMessageContainer";
import FoodSaveToProfileCheckboxContainer from "dux/bookingFeeding/bookingSaveFoodToProfile/FoodSaveToProfileCheckboxContainer";
import FoodIsFromProfileCheckboxContainer from "dux/bookingFeeding/bookingIsFromProfile/FoodIsFromProfileCheckboxContainer";
import FoodTypeContainer from "web/foodType/FoodTypeContainer";
import FoodNameContainer from "web/foodName/FoodNameContainer";
import OtherFoodNameContainer from "web/otherFoodName/OtherFoodNameContainer";
import FoodTypeAmountContainer from "web/foodTypeAmount/FoodTypeAmountContainer";
import { FoodTimeOfDay } from "@/dux/timeOfDayCheckboxes/TimeOfDayCheckboxes";
import FoodInstructionsContainer from "web/foodInstructions/FoodInstructionsContainer";
import CancelNewFoodButtonContainer from "web/addFood/buttons/CancelNewFoodButtonContainer";
import { isNewFood } from "web/food/foodUtils";
import { Layout } from "@prism/psm-ui-components";
import { FoodFormLayout } from "../FoodFormLayout";

const BookingFoodFormComponent = ({
  componentId,
  isHidden,
  foodId,
  foodIndex,
  foodIds,
  petId,
  locationType,
  isEditingFood = false,
  preserveOrApplyFoodData = () => {},
}) => {
  useEffect(() => {
    if (!isNewFood(foodId)) preserveOrApplyFoodData();
  }, [isEditingFood]);

  const newFoodIndex = isNewFood(foodId) ? foodIds.length + 1 : foodIndex;
  return !isHidden ? (
    <FoodFormLayout componentId={componentId}>
      <FoodTypeContainer foodIndex={newFoodIndex} foodId={foodId} petId={petId} />
      <FoodNameContainer foodIndex={newFoodIndex} foodId={foodId} petId={petId} />
      <OtherFoodNameContainer foodId={foodId} petId={petId} />
      <FoodTypeAmountContainer foodId={foodId} foodIndex={newFoodIndex} petId={petId} />
      <FoodTimeOfDay foodId={foodId} foodIndex={newFoodIndex} petId={petId} />

      <Layout.Box>
        <FoodInstructionsContainer foodId={foodId} foodIndex={newFoodIndex} petId={petId} />
      </Layout.Box>

      <Layout.Box>
        <FoodSaveToProfileCheckboxContainer foodId={foodId} petId={petId} />
        <FoodIsFromProfileCheckboxContainer foodId={foodId} petId={petId} />
      </Layout.Box>

      <Layout.Cluster justify="space-between" style={{ padding: "15px 0px" }}>
        <SetFrequencyFoodButtonContainer foodId={foodId} petId={petId} />
        <Layout.Cluster style={{ marginLeft: "auto" }}>
          <UpdateBookingFoodButtonContainer
            foodId={foodId}
            petId={petId}
            locationType={locationType}
          />
          <AddNewBookingFoodButtonContainer
            foodId={foodId}
            petId={petId}
            locationType={locationType}
          />
          <CancelNewFoodButtonContainer foodId={foodId} petId={petId} locationType={locationType} />
        </Layout.Cluster>
      </Layout.Cluster>
      <FoodFrequencyErrorMessageContainer foodId={foodId} petId={petId} />
    </FoodFormLayout>
  ) : null;
};

export default BookingFoodFormComponent;
