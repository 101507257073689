import React from "react";
import { color } from "web/common/styles/theme";
import { itinerariesPaymentStatuses } from "core/constants/itinerariesConstants";

import { SalonPaidVoidTransaction } from "@/dux/_components/invoice/PaidVoidTransaction";
import { LayoutBox } from "@/layout/box/Box";
import { TextPassage } from "@petsmart-ui/sparky";

const PaidVoidTransaction = ({ componentId, isHidden, itineraryStatus }) => {
  if (isHidden) return null;

  const isVoided = itineraryStatus === itinerariesPaymentStatuses.VOIDED;

  if (isVoided) {
    return (
      <LayoutBox id={componentId} style={{ paddingLeft: 0 }}>
        <TextPassage size="lg" style={{ color: color.red700 }}>
          Transaction Voided
        </TextPassage>
      </LayoutBox>
    );
  }

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <SalonPaidVoidTransaction />
    </LayoutBox>
  );
};

export default PaidVoidTransaction;
