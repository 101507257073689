import styled from "styled-components";
import { color } from "../../../web/common/styles/theme";

const LinkText = styled.span`
  text-decoration: none;
  color: ${color.blue500};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export default LinkText;
