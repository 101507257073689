import React, { Component } from "react";
import styled from "styled-components";
import { get, isEmpty, groupBy, map, pick } from "lodash/fp";
import moment from "moment";

import {
  formatMoment,
  formatCalendarDateMoment,
} from "../../../core/utils/dateUtils/formatDateTime";

import { color } from "../../../web/common/styles/theme";
import AvailableAppointment from "./availableAppointment/AvailableAppointmentContainer";
import Gantt from "../../gantt/GanttContainer";
import { DATE_TIME_FORMAT } from "../../../core/constants";
import LoadingWrapper from "../../../web/common/LoadingWrapper";
import TimeRangePicker from "../../common/TimeRange/TimeRangePicker";
import getDifferenceInMinutes from "../../../core/utils/dateUtils/getDifferenceInMinutes";
import DateSelectionWrapper from "../../common/dateSelection/DateSelectionWrapper";
import BookingCalendarContainer from "./BookingCalendarContainer";
import BookingAssociateDropDownContainer from "./BookingAssociateDropDownContainer";
import BookingFinalizeMessageContainer from "./BookingFinalizeMessageContainer";
import StandAloneFinalizeMessageContainer from "./StandAloneFinalizeMessageContainer";
import StandAloneCalendarContainer from "./StandAloneCalendarContainer";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { adjustSelectedTimeWithOffset } from "./BookingTimeSlotUtils";

const AvailableAppointmentsWrapper = styled.div`
  flex: 1;
  padding: 1em;
  overflow: hidden;
`;

const AvailableAssociatesWrapper = styled.div`
  flex: 1;
  padding: 1em;
`;

const DateTitle = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px dashed ${color.gray300};
  padding: 0.5rem;
`;

const StyledBookingTimeSlot = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const StandalonePreferences = styled.div`
  display: flex;
  flex-direction: column;
`;

const AppointmentTimeHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 5px;
`;

const PreferencesTitle = styled.h3`
  font-weight: bold;
  margin: 0px;
`;

const HeadingTitle = styled.div`
  font-weight: bold;
  margin-right: 5px;
`;

const AppointmentsAndSchedule = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`;

const ScheduleWrapper = styled.div`
  flex: 2;
  padding: 1em;
`;

const ColumnHeading = styled.h4`
  margin: 0 0 1em 0;
`;

const AssociateGroup = styled.div`
  font-weight: bold;
  padding: 30px 0;
`;

const AssociateRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  border-bottom: 1px solid #dddddd;

  &:hover {
    background-color: #eeeeee;
  }
`;

const AssociateName = styled.div`
  flex: 1;
`;

const AssociateBook = styled.div`
  cursor: pointer;
  color: ${color.blue500};
  pointer-events: ${props => (props.disabled ? "none" : "auto")};
  opacity: ${props => (props.disabled ? "0.5" : "1")};
`;

const AutoBookButton = styled.span`
  color: ${color.blue500};
  cursor: pointer;
  pointer-events: ${props => (props.disabled ? "none" : "auto")};
  opacity: ${props => (props.disabled ? "0.5" : "1")};
`;

const TimeSlotsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
`;

class BookingComponent extends Component {
  constructor(props) {
    super(props);

    const { timeOffset } = this.props;

    this.state = {
      standaloneStartTime: moment()
        .utcOffset(timeOffset)
        .format("HH:mm"),
      standaloneBookingTime: moment()
        .utcOffset(timeOffset)
        .seconds(0),
    };

    this.loadAvailableTimeSlots = this.loadAvailableTimeSlots.bind(this);
    this.loadWeeklyAvailableTimeSlots = this.loadWeeklyAvailableTimeSlots.bind(this);
    this.renderAvailableAppointments = this.renderAvailableAppointments.bind(this);
    this.getAvailableAppointmentsForGantt = this.getAvailableAppointmentsForGantt.bind(this);
    this.getNoAvailableWording = this.getNoAvailableWording.bind(this);
  }

  componentDidMount() {
    const {
      dashboardAppointment,
      setTimeSlotFromDashboard,
      selectedPet,
      appointment,
      onSelectAssociate,
      selectedAssociateWeekly,
      loadItinerariesHandler,
      timeOffset,
      selectTime,
      isStandalone,
    } = this.props;

    loadItinerariesHandler();
    selectTime(
      moment()
        .utcOffset(timeOffset)
        .format("HH:mm"),
    );
    if (!isStandalone) {
      if (isEmpty(dashboardAppointment)) {
        if (selectedAssociateWeekly) {
          this.loadWeeklyAvailableTimeSlots();
        } else {
          this.loadAvailableTimeSlots();
        }
      } else {
        const { startDateTime, associateId } = dashboardAppointment;

        onSelectAssociate(associateId);
        setTimeSlotFromDashboard({
          fromDate: startDateTime,
          petId: selectedPet,
          associateId,
          petServiceId: appointment.petService,
        });
      }
    }
  }

  componentWillUnmount() {
    const {
      onSelectAssociate,
      onSetItineraryId,
      isModify,
      onDeleteItinerary,
      selectedItinerary,
      isDeletingItinerary,
    } = this.props;
    if (isModify && !isDeletingItinerary) {
      onDeleteItinerary(selectedItinerary);
      onSetItineraryId(null);
    }

    onSelectAssociate(null);
  }

  componentDidUpdate(prevProps) {
    const {
      router: { navigate },
      customerKey,
      onSetItineraryId,
      isStandalone,
      dashboardAppointment,
    } = this.props;
    const petsWithAppointments = Object.keys(this.props.appointments);

    let didFetch = false;

    if (!this.props.appointment) {
      if (!petsWithAppointments.length) {
        navigate(`/${isStandalone ? "standalone" : "booking"}/${customerKey}/select-service`);
        onSetItineraryId(null);
      } else {
        this.props.onSelectPet(petsWithAppointments[0]);
      }
    } else {
      if (isStandalone || !isEmpty(dashboardAppointment)) {
        return;
      }

      if (prevProps.selectedDate !== this.props.selectedDate) {
        if (this.props.selectedAssociateWeekly) {
          if (
            !moment(this.props.selectedDate).isBetween(
              moment(prevProps.selectedDate).startOf("isoWeek"),
              moment(prevProps.selectedDate).endOf("isoWeek"),
              "[]",
            )
          ) {
            this.loadWeeklyAvailableTimeSlots();
            didFetch = true;
          }
        } else {
          this.loadAvailableTimeSlots();
          didFetch = true;
        }
      }

      if (
        prevProps.selectedPet !== this.props.selectedPet ||
        prevProps.selectedAssociateWeekly !== this.props.selectedAssociateWeekly ||
        prevProps.appointment.petService !== this.props.appointment.petService ||
        prevProps.appointment.petServiceItemId !== this.props.appointment.petServiceItemId ||
        get("startDateTime", prevProps.appointment.timeSlot) !==
          get("startDateTime", this.props.appointment.timeSlot) ||
        get("duration", prevProps.appointment.timeSlot) !==
          get("duration", this.props.appointment.timeSlot) ||
        prevProps.appointment.lockToAssociate !== this.props.appointment.lockToAssociate ||
        prevProps.appointment.maxCheckInCount !== this.props.appointment.maxCheckInCount ||
        prevProps.appointment.maxPerBlockCount !== this.props.appointment.maxPerBlockCount ||
        prevProps.appointment.additionalBookingType !== this.props.appointment.additionalBookingType
      ) {
        if (this.props.selectedAssociateWeekly) {
          if (!didFetch) {
            this.loadWeeklyAvailableTimeSlots();
          }
        } else if (!didFetch) {
          this.loadAvailableTimeSlots();
        }
      }
    }
  }

  loadAvailableTimeSlots({ associateId, newSelectedDate } = {}) {
    const { selectedDate, appointment, selectedItinerary, isModify } = this.props;

    if (!appointment) {
      return;
    }

    const date = newSelectedDate || selectedDate;
    const itineraryId = selectedItinerary;
    const engagementId = appointment.petServiceItemId;
    const fromDate = formatMoment(moment(date));

    if (itineraryId && engagementId) {
      this.props.loadAvailableTimeSlotsByEngagement({
        fromDate,
        itineraryId,
        engagementId,
        associateId,
      });
    } else if (!isModify) {
      this.props.loadAvailableTimeSlots({
        fromDate,
        petServiceId: appointment.petService,
        associateId,
      });
    }
  }

  loadWeeklyAvailableTimeSlots() {
    const { selectedDate, selectedItinerary, selectedAssociateWeekly, appointment } = this.props;
    if (!appointment) {
      return;
    }

    const itineraryId = selectedItinerary;
    const engagementId = appointment.petServiceItemId;
    if (itineraryId && engagementId) {
      this.props.loadWeeklyAvailableTimeSlotsByEngagement({
        selectedDate,
        selectedAssociateWeekly,
        itineraryId,
        engagementId,
      });
    } else {
      this.props.loadWeeklyAvailableTimeSlots({
        selectedDate,
        selectedAssociateWeekly,
        petServiceId: appointment.petService,
      });
    }
  }

  getNoAvailableWording(isWeekly) {
    const { selectedDate, isAvailableTimeSlotsLoading } = this.props;

    if (isAvailableTimeSlotsLoading) {
      return null;
    }

    if (moment(selectedDate).isBefore(moment(), "day")) {
      return (
        <React.Fragment>
          <p>You have selected a date in the past. </p>
          <p>Only manual booking is enabled.</p>
        </React.Fragment>
      );
    } else if (isWeekly) {
      return (
        <React.Fragment>
          <p>We are sorry but this associate does not have any available appointments this week.</p>
          <p>Please select another week.</p>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <p>There are no available appointments for the selected date.</p>
        <p>Please select another date.</p>
      </React.Fragment>
    );
  }

  getDateTitle = (values, dateKey) => {
    const {
      availableTimeSlotsWeekly,
      timeSlotGroups,
      onAutoBook,
      appointment,
      isAvailableTimeSlotsLoading,
    } = this.props;

    const timeSlots = !values.isWeekly ? timeSlotGroups : availableTimeSlotsWeekly;

    return (
      <DateTitle>
        {!values.isWeekly ? (
          <AppointmentsAndSchedule>
            <AssociateName>{dateKey}</AssociateName>
            <AutoBookButton
              id="button_auto-select"
              onClick={() =>
                onAutoBook({
                  timeSlot:
                    timeSlots[dateKey] &&
                    timeSlots[dateKey][Math.floor(Math.random() * timeSlots[dateKey].length)],
                  selectedPetService: appointment.petService,
                })
              }
              disabled={isAvailableTimeSlotsLoading}
            >
              Auto Select
            </AutoBookButton>
          </AppointmentsAndSchedule>
        ) : (
          moment(dateKey).format("dddd DD")
        )}
      </DateTitle>
    );
  };

  renderAvailableAppointments(values) {
    const {
      isAvailableTimeSlotsLoading,
      availableTimeSlotsWeekly,
      selectedPet,
      timeSlotGroups,
    } = this.props;
    const timeSlots = !values.isWeekly ? timeSlotGroups : availableTimeSlotsWeekly;

    return (
      <LoadingWrapper isLoading={isAvailableTimeSlotsLoading} style={{ maxWidth: "100%" }}>
        <TimeSlotsContainer>
          {!isEmpty(timeSlots)
            ? Object.keys(timeSlots).map((dateKey, groupIndex) => (
                <div key={dateKey}>
                  {this.getDateTitle(values, dateKey)}
                  {timeSlots[dateKey].map((timeSlot, timeIndex) => (
                    <AvailableAppointment
                      selectButtonID={`button_select_available_appointment_${groupIndex}_${timeIndex}`}
                      key={timeSlot && timeSlot.startDateTime + timeSlot.associateId}
                      timeSlot={timeSlot}
                      petId={selectedPet}
                    />
                  ))}
                </div>
              ))
            : this.getNoAvailableWording(values.isWeekly)}
        </TimeSlotsContainer>
      </LoadingWrapper>
    );
  }

  renderAvailableAssociates = selectedDate => {
    const {
      onBookAssociate,
      associates,
      appointment,
      isAvailableTimeSlotsLoading,
      standaloneService,
      availableTimeSlots,
      availableStandaloneAssociates,
      timeOffset,
    } = this.props;
    const { standaloneStartTime, standaloneBookingTime } = this.state;

    const startTime = adjustSelectedTimeWithOffset(selectedDate, standaloneStartTime, timeOffset);
    //const availableAssociates = new Set(availableTimeSlots.map(timeSlot => timeSlot.associateId));
    const availableAssociates = availableStandaloneAssociates;

    const byGroup = groupBy("associateGroup", pick(Array.from(availableAssociates), associates));
    const groups = Object.keys(byGroup).sort();
    const isDisabled = getDifferenceInMinutes(standaloneBookingTime, startTime);
    const isValid = startTime?.isValid();
    return (
      <LoadingWrapper isLoading={isAvailableTimeSlotsLoading}>
        <TimeSlotsContainer>
          {map(
            group => (
              <div key={group}>
                <AssociateGroup>{group}</AssociateGroup>
                {standaloneService &&
                  map(
                    associate => (
                      <AssociateRow key={associate.associateId}>
                        <AssociateName>{associate.associateName}</AssociateName>
                        <AssociateBook
                          disabled={isDisabled || !isValid}
                          onClick={() => {
                            const timeSlot = {
                              startDateTime: startTime.format(DATE_TIME_FORMAT),
                              duration: standaloneService.duration,
                              price: standaloneService.price,
                              currencyCode: standaloneService.currencyCode,
                              associateId: associate.associateId,
                            };
                            onBookAssociate({
                              timeSlot,
                              selectedPetService: appointment.petService,
                            });
                          }}
                        >
                          Select
                        </AssociateBook>
                      </AssociateRow>
                    ),
                    byGroup[group],
                  )}
              </div>
            ),
            groups,
          )}
        </TimeSlotsContainer>
      </LoadingWrapper>
    );
  };

  getAvailableAppointmentsForGantt() {
    const {
      isStandalone,
      availableTimeSlotsByAssociate,
      isAvailableTimeSlotsLoading,
      availableTimeSlotsWeekly,
      selectedAssociateWeekly,
    } = this.props;
    if (isStandalone || isAvailableTimeSlotsLoading) {
      return [];
    }

    if (selectedAssociateWeekly) {
      return availableTimeSlotsWeekly;
    }

    return availableTimeSlotsByAssociate;
  }

  showModalBeforeOpen = onConfirm => {
    const { showConfirmationModal } = this.props;

    showConfirmationModal({
      header: "Preferred associate change",
      content: (
        <div>
          The associate selected for this appointment is the preferred associate.
          <br />
          Are you sure you want to change the associate?
        </div>
      ),
      confirmText: "Continue",
      confirm: () => {
        onConfirm();
      },
    });
  };

  // TODO: This needs to be moved into its own component with its own container.
  standaloneHeader = () => {
    const {
      selectDate,
      selectTime,
      selectedDate,
      isAvailableTimeSlotsLoading,
      timeOffset,
    } = this.props;
    const { standaloneStartTime, standaloneBookingTime } = this.state;
    const currentSelectedTime = adjustSelectedTimeWithOffset(
      selectedDate,
      standaloneStartTime,
      timeOffset,
    );

    return (
      <StandalonePreferences>
        <DateSelectionWrapper>
          <StandAloneCalendarContainer
            selectDate={date => {
              const formattedDate = formatCalendarDateMoment(date);
              selectDate(date);
              this.loadAvailableTimeSlots({ newSelectedDate: formattedDate });
            }}
          />
          <AppointmentTimeHeader>
            <HeadingTitle>Appointment Start Time: </HeadingTitle>
            <TimeRangePicker
              backgroundColor="inherit"
              startTime={standaloneStartTime}
              hideEndTime
              isValid={!getDifferenceInMinutes(standaloneBookingTime, currentSelectedTime)}
              onChange={({ startTime }) => {
                selectTime(moment(startTime, "HH:mm").format("HH:mm:ss"));
                this.setState({
                  standaloneStartTime: startTime && moment(startTime, "HH:mm").format("HH:mm:ss"),
                });
              }}
              disabled={isAvailableTimeSlotsLoading}
            />
          </AppointmentTimeHeader>
          <StandAloneFinalizeMessageContainer />
        </DateSelectionWrapper>
      </StandalonePreferences>
    );
  };

  // TODO: This needs to be moved into its own component with its own container.
  bookingHeader = () => {
    const { appointment } = this.props;
    return (
      <DateSelectionWrapper>
        <BookingCalendarContainer />
        <BookingAssociateDropDownContainer
          showModalBeforeOpen={get("lockToAssociate", appointment) && this.showModalBeforeOpen}
        />
        <BookingFinalizeMessageContainer />
      </DateSelectionWrapper>
    );
  };

  standaloneAppointments = () => {
    const {
      associates,
      isAvailableTimeSlotsLoading,
      availableTimeSlotsWeekly,
      selectedAssociateWeekly,
      availableTimeSlots,
    } = this.props;

    return !selectedAssociateWeekly ? (
      <AvailableAppointmentsWrapper>
        {!isAvailableTimeSlotsLoading && (
          <ColumnHeading>
            {availableTimeSlots && !availableTimeSlots.length
              ? "No appointments available"
              : "Available Appointments"}
          </ColumnHeading>
        )}
        {this.renderAvailableAppointments({ isWeekly: false })}
      </AvailableAppointmentsWrapper>
    ) : (
      <AvailableAppointmentsWrapper>
        {!isAvailableTimeSlotsLoading && (
          <ColumnHeading>
            {isEmpty(availableTimeSlotsWeekly)
              ? `${associates[selectedAssociateWeekly].associateName} is fully booked`
              : "Available Appointments"}
          </ColumnHeading>
        )}
        {this.renderAvailableAppointments({ isWeekly: true })}
      </AvailableAppointmentsWrapper>
    );
  };

  render() {
    const {
      isLoading,
      associates,
      availableStandaloneAssociates,
      selectedAssociateWeekly,
      selectedDate,
      isStandalone,
    } = this.props;

    return (
      <LoadingWrapper isLoading={isLoading} fullScreenHeight fullScreenWidth>
        <StyledBookingTimeSlot>
          {isStandalone ? this.standaloneHeader() : this.bookingHeader()}
          <AppointmentsAndSchedule>
            {!isStandalone ? (
              this.standaloneAppointments()
            ) : (
              <AvailableAssociatesWrapper>
                <ColumnHeading>
                  {availableStandaloneAssociates.length === 0
                    ? "No associates available"
                    : "Available Associates"}
                </ColumnHeading>
                {this.renderAvailableAssociates(selectedDate)}
              </AvailableAssociatesWrapper>
            )}
            <ScheduleWrapper>
              <ColumnHeading>
                Schedule
                <Gantt
                  availableAppointments={this.getAvailableAppointmentsForGantt()}
                  isWeekly={!!selectedAssociateWeekly}
                  shouldLoadPets
                />
              </ColumnHeading>
            </ScheduleWrapper>
          </AppointmentsAndSchedule>
        </StyledBookingTimeSlot>
      </LoadingWrapper>
    );
  }
}

export default withRouteProps(BookingComponent);
