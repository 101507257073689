import React, { Component } from "react";
import styled, { css } from "styled-components";
import ValuesInPixels from "../styles/valuesInPixels";
import TextInputTheme from "../styles/inputs/textInputTheme";
import { color } from "../styles/theme";
import { fontSizes } from "web/common/styles/responsive/fonts";
import getComputedLineHeightAsNumber from "../../../core/utils/conversions/getComputedLineHeightAsNumber";
import { device } from "web/common/styles/responsive/devices";

export default class ResizableTextarea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initRender: 0,
      rows: 1,
      minRows: 1,
      maxRows: 10,
    };
  }

  componentDidUpdate() {
    // Since we update the state when we call resizeElement(), a set of checks
    // need to happen so we don't go into a loop. We want to make sure the
    // element scroll height is larger than the 1 line default and we also
    // want to make sure we have rendered at least once. However if we need to
    // re-render after both those checks, we ill not call the resizeElement()
    // function again.
    if (this.textAreaNode.scrollHeight > ValuesInPixels.base + 12 && this.state.initRender < 1) {
      this.setState({ initRender: 1 });
      this.resizeElement(this.textAreaNode);
    }
  }

  /**
   * Responsible for scaling or resizing the text area based on input
   * @memberof ResizableTextarea
   * @param {*} element TextArea HTML element
   */
  resizeElement = element => {
    const textareaLineHeight = getComputedLineHeightAsNumber(element);
    const { minRows, maxRows } = this.state;
    const previousRows = element.rows;

    element.rows = minRows; // reset number of rows in textarea

    const currentRows = element.scrollHeight / textareaLineHeight;

    if (currentRows === previousRows) {
      element.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      element.rows = maxRows;
      element.scrollTop = element.scrollHeight;
    }

    element.rows = currentRows;

    this.setState({
      rows: currentRows < maxRows ? currentRows : maxRows,
    });
  };

  handleChange = event => {
    this.resizeElement(event.target);
  };

  render() {
    return (
      <SectionWrapper id={this.props?.id}>
        {this.props.lineSymbol && <Symbol>{this.props.lineSymbol}</Symbol>}
        <StyledTextArea
          {...this.props}
          rows={this.state.rows}
          innerRef={input => (this.textAreaNode = input)}
          onInput={this.handleChange}
        />
      </SectionWrapper>
    );
  }
}

/* ------------- Styles ------------- */
const SectionWrapper = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledTextArea = styled.textarea`
  ${props =>
    css`
      ${TextInputTheme(props)};
    `};

  @media ${device.mobileS} {
    width: 100%;
  }
  @media ${device.prismDesktop} {
    width: ${props => props.width || "auto"};
  }

  /* can't place sudo selectors in object literal so could not be included in TextInputTheme and
  * must be placed here
  */
  &:focus {
    outline: none;
    border-bottom-color: ${props => (props.error ? color.red700 : color.blue500)};
    color: ${props => (props.error ? color.red700 : color.blue500)};
  }

  &::placeholder {
    font-size: ${fontSizes.small};
  }
`;

const Symbol = styled.div`
  display: flex;
  flex-direction: row;
`;
