import { createSelector } from "reselect";
import { getProps, getState } from "core/selectors/commonSelector";
import { getAppointmentByPet } from "core/selectors/cartSelectors";
import getIsBGMWorkflowFeatureFlagHidden from "web/enableDisableWorkflowFeatureFlag/selectors/bgm/getIsBGMWorkflowFeatureFlagHidden";
import {
  getBundleAddOnIdByPetServiceId,
  getBundleOfferTypeByPetServiceId,
  getIsActiveBundleAvailable,
  getIsFreeUnitRedemptionRestricted,
} from "dux/bgm/availableBundlesByPet/availableBundlesByPetSelectors";
import { getAddonsWithDynamicPrice } from "core/selectors/dynamicPricesSelector";
import { get, isNumber } from "lodash/fp";
import { FTCO } from "web/enableDisableWorkflowFeatureFlag/workflowFeatureTypes";
import { getPetServiceItem } from "core/selectors/entitiesSelector";
import { hasManagerPrivileges } from "core/selectors/persistentSelectors";

export default createSelector([getState, getProps], (state, { petId, addOnId }) => {
  // Appointment Info
  const appointment = getAppointmentByPet(state, { petId });
  const petServiceId = appointment?.petService;
  const timeSlot = appointment?.timeSlot;
  const petServiceItemId = get("petServiceItemId", appointment);
  const petServiceItem = getPetServiceItem(state, { petServiceItemId }) || false;
  const isManager = hasManagerPrivileges(state);
  const isPriceClickable = isManager && petServiceItem;

  // Feature flag info
  const isBGMWorkflowFeatureFlagHidden = getIsBGMWorkflowFeatureFlagHidden(state);

  // Bundle Info
  const isBundleAvailable = getIsActiveBundleAvailable(state, { petId, petServiceId });
  const offerType = getBundleOfferTypeByPetServiceId(state, { petId, petServiceId });
  const isPackageAddOnApplied = isBundleAvailable && offerType !== FTCO;
  const isFreeUnitRedemptionRestricted = getIsFreeUnitRedemptionRestricted(state, {
    petId,
    petServiceId,
  });

  // Add-on Info
  const selectedBundleAddOnId = getBundleAddOnIdByPetServiceId(state, { petId, petServiceId });
  const addOns = getAddonsWithDynamicPrice(state, { petId });
  const currentAddOn = addOns?.find(addOn => addOn?.addOnId === addOnId);
  const dynamicFinalPrice = get(["dynamicPrice", "finalPrice"], currentAddOn);
  const finalPrice = isNumber(dynamicFinalPrice) ? dynamicFinalPrice : currentAddOn.price || 0;
  const dynamicOriginalPrice = get(["dynamicPrice", "originalPrice"], currentAddOn);
  const originalPrice = isNumber(dynamicOriginalPrice) && dynamicOriginalPrice;
  const isBundledAddOn = addOnId === selectedBundleAddOnId;
  const isAddOnsPriceChange =
    !isBGMWorkflowFeatureFlagHidden &&
    isPackageAddOnApplied &&
    isBundledAddOn &&
    !isFreeUnitRedemptionRestricted;
  const isOriginalPriceShown = originalPrice && finalPrice !== originalPrice;

  return {
    originalPrice,
    finalPrice,
    isAddOnsPriceChange,
    isOriginalPriceShown,
    selectedBundleAddOnId,
    currentAddOn,
    isPriceClickable,
    timeSlot,
    isBundledAddOn,
    isBundleAvailable,
  };
});
