import { createSelector, createSelectorCreator, defaultMemoize } from "reselect";
import { isEqual, get, has } from "lodash/fp";
import {
  DEFAULT_SERVER_ERROR_MESSAGE,
  EMAIL_DUPLICATE_SERVER_ERROR,
  UPDATE_PHONE_DUPLICATE_PHONE_SERVER_ERROR,
  CREATE_PHONE_DUPLICATE_SERVER_ERROR,
  DUPLICATE_PHONE_USER_FRIENDLY_ERROR_MESSAGE,
  DUPLICATE_EMAIL_USER_FRIENDLY_ERROR_MESSAGE,
} from "../constants/customerProfileConstants";
import { authErrorMessages } from "../constants/authConstants";
import { isCurrentEnvironment } from "../utils/envUtils";
import { errorMessageConstants } from "dux/_constants/errorMessageConstants";
import { SAFARI } from "@/dux/_constants/browserConstants";

export const getError = state => state?.error;

export const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const createLoadingSelector = actionTypes => {
  const getIsLoading = state => state?.isLoading;
  return createSelector([getIsLoading], isLoading =>
    actionTypes?.some(action => isLoading?.[action]),
  );
};

export const createErrorSelector = actionTypes =>
  createSelector([getError], error => {
    const actionTypesWithErrors = actionTypes?.filter(actionType => error?.[actionType]);
    return actionTypesWithErrors?.length ? error[actionTypesWithErrors[0]] : "";
  });

/*
    As the API error response payload differs for each of the various services
    that we are calling, our getApiError function will handle processing the different
    types of error messages that can come from the server. This will provide the end
    user with as specific of an error message as possible.  If an unexpected payload
    comes back from the server, we provide an escape hatch that returns a generic error
    message.  If we need to handle additional response types, we can add checks to our
    getApiError function.
*/

export const getApiError = error =>
  error?.response?.data?.StoreNumber?.[0] ||
  get("response.data.errors[0].trace[0].message")(error) ||
  get("response.data.message")(error) ||
  get("response.data.Message")(error) ||
  get("response.data.responses[0].message")(error) ||
  get("response.data.Responses[0].Message")(error) ||
  get("response.data.errors[0].message")(error) ||
  get("response.data[0].error")(error) ||
  Object.values(error?.response?.data ?? {})[0]?.message ||
  error?.message;

export const getServerError = createSelector([getError], errors => {
  return Object.values(errors ?? {}).find(Boolean);
});

export const getServerErrorMessage = createSelector([getServerError], error => {
  // If internet connection is offline, we want to display an error message indicating that the network is offline.
  // This change was done as part of SVCSART-9684 to support QA automation testing.
  if (error?.message === errorMessageConstants.NETWORK_ERROR && !navigator.onLine) {
    return errorMessageConstants.NETWORK_ERROR_USER_FRIENDLY;
  }

  if (
    error?.message === errorMessageConstants.NETWORK_ERROR &&
    navigator.userAgent.includes(SAFARI)
  ) {
    return undefined;
  }

  if (!error || get(["response", "status"], error) === 406) {
    return undefined;
  }

  // We should intercept and not display error messages if Okta delete session call fails
  // This prevents us from getting in a "stuck" state on login page after hitting "Start Shift"
  if (isOktaError(error)) {
    return undefined;
  }

  // Per Infosec team requirement, we need to make sure that
  // we are not exposing stack traces to the user on 500 errors in production
  if (isInternalServerError(error) && isCurrentEnvironment("prod")) {
    return DEFAULT_SERVER_ERROR_MESSAGE;
  }

  let message = getApiError(error);

  if (shouldNotDisplayErrorModal(message)) {
    return undefined;
  }

  // Intercept full authorization required error from quick pin & pass more user-friendly message
  if (message === authErrorMessages.ELEVATED_PERMISSION_EXPIRED) {
    message = authErrorMessages.ELEVATED_PERMISSION_EXPIRED_USER_FRIENDLY;
  }

  // Intercept missing permission error from quick pin & pass more user-friendly message
  if (message === authErrorMessages.ELEVATED_PERMISSIONS_REJECTED) {
    message = authErrorMessages.ELEVATED_PERMISSIONS_REJECTED_USER_FRIENDLY;
  }

  if (message && message.includes(authErrorMessages.SF_ACCESS_TOKEN_NOT_FOUND)) {
    message = authErrorMessages.SF_ACCESS_TOKEN_NOT_FOUND_USER_FRIENDLY;
  }

  if (isDuplicateEmail(message)) {
    message = DUPLICATE_EMAIL_USER_FRIENDLY_ERROR_MESSAGE;
  }

  if (isDuplicatePhone(message)) {
    message = DUPLICATE_PHONE_USER_FRIENDLY_ERROR_MESSAGE;
  }

  if (message) {
    return `Message: ${message}`;
  }

  return DEFAULT_SERVER_ERROR_MESSAGE;
});

export function isDuplicateEmail(message) {
  return message && message.includes(EMAIL_DUPLICATE_SERVER_ERROR);
}

export function isDuplicatePhone(message) {
  const createPhoneError = message && message.includes(CREATE_PHONE_DUPLICATE_SERVER_ERROR);
  const updatePhoneError = message && message.includes(UPDATE_PHONE_DUPLICATE_PHONE_SERVER_ERROR);
  return createPhoneError || updatePhoneError;
}

// Dont' show error modal if session token expired or access token not in repo
export function shouldNotDisplayErrorModal(message) {
  return (
    message === authErrorMessages.NON_ELEVATED ||
    message === authErrorMessages.MISSING_TOKEN ||
    message === authErrorMessages.ACCESS_TOKEN_NOT_IN_REPO
  );
}

export function isInternalServerError(error) {
  const statusCode = get(["response", "status"], error);
  return statusCode === 500;
}

export function isOktaError(error) {
  return has(["errorSummary"], error);
}
