import React from "react";
import styled from "styled-components";

import ClickableText from "../../../common/ClickableText";
import CommonButton from "../../../common/commonButton";
import { color, modalPadding } from "../../../common/styles/theme";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";

class ManualBookingWarning extends React.Component {
  handleContinueClick = () => {
    const {
      onClose,
      router: { navigate },
    } = this.props;

    onClose();
    navigate("/search");
  };

  handleCancelClick = () => {
    const { clearNewAppointmentData, onClose } = this.props;

    clearNewAppointmentData();
    onClose();
  };

  render() {
    return (
      <Container>
        <Header>Warning: Manual Booking</Header>

        <Message>
          All appointments booked from the main gantt are manually booked and may override booking
          rules for associate's schedule.
        </Message>

        <ButtonsContainer>
          <ClickableText
            isVisible
            styleObj={{ color: color.blue500 }}
            onClick={this.handleCancelClick}
          >
            Cancel
          </ClickableText>
          <CommonButton label="Continue" onClick={this.handleContinueClick} />
        </ButtonsContainer>
      </Container>
    );
  }
}

const Container = styled.div`
  width: calc(100% - 30px);
  height: calc(100% - 75px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  padding: ${modalPadding};
  top: 0;
  left: 0;
`;

const Header = styled.div`
  font-weight: bold;
`;

const Message = styled.div`
  width: 60%;
  text-align: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export default withRouteProps(ManualBookingWarning);
