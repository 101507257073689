import React from "react";
import styled from "styled-components";
import BookingCartFoodContainer from "dux/bookingFeeding/BookingCartFoodContainer";
import PetCartCardCollapsibleArrowContainer from "dux/petCartCard/PetCartCardCollapsibleArrowContainer";
import HotelIconContainer from "dux/hotelPetCardIcon/HotelBookingCardIconContainer";
import { BookingCartReservationNotesContainer } from "web/bookingCartReservationNotes/BookingCartReservationNotesComponent";
import { HotelBookingInvoiceGeneratedTotalPrice } from "web/invoiceGeneratedTotalPrice/InvoiceGeneratedTotalPriceComponent";
import { color } from "web/common/styles/theme";
import { HotelBookingInvoiceGeneratedCheckbox } from "web/invoiceGeneratedTotalPrice/InvoiceGeneratedCheckBox";
import { HotelBookingCartAddonsSection } from "dux/hotelCartAddons/HotelCartAddonsList";
import {
  HotelBookingCartCheckInTime,
  HotelBookingCartCheckOutTime,
} from "dux/_components/petCard/BookingCartTimeComponent";
import { HotelBookingCartServiceTypePrice } from "dux/_components/petCard/ServiceTypePrice";
import { HotelBookingNeedsReviewCheckbox } from "@/dux/needsReviewCheckbox/NeedsReviewCheckbox";
import { HotelBookingApplySpecial } from "dux/applySpecial/ApplySpecialComponent";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { HotelBookingCartSpecialPrice } from "dux/_components/petCard/HotelSpecials";
import getUnknownPetImage from "../../../core/utils/assetUtils/unknownPetImage";
import CancelPetCartCardContainer from "../../petCartCard/buttons/CancelPetCartCardContainer";
import BookingCartMedicationContainer from "../../../web/bookingAppointmentMedication/BookingCartMedicationContainer";
import { LayoutBox } from "@/layout/box/Box";

const CardWrapper = styled.div`
  border: 1px solid ${color.gray300};
  margin-top: 2em;
  font-size: 16px;
`;

const PetImageName = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.5em;
`;

const PetImg = styled.img`
  width: 57px;
  height: 57px;
`;

const PetInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;
  width: 100%;
`;

const NameWithButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const PetName = styled.div`
  font-weight: bold;
`;

export default function PetCartCardComponent({
  isHidden,
  pet,
  petName,
  age,
  breedName,
  componentId,
  petId,
}) {
  return !isHidden ? (
    <CardWrapper id={componentId}>
      <PetImageName>
        <PetImg src={getUnknownPetImage(pet)} />
        <PetInfo>
          <NameWithButton>
            <PetName>{petName}</PetName>
            <PetCartCardCollapsibleArrowContainer petId={petId} />
          </NameWithButton>
          <NameWithButton>
            <div>
              <div>{`${age}, ${breedName}`}</div>
              <HotelIconContainer petId={petId} />
            </div>
            <CancelPetCartCardContainer petId={petId} />
          </NameWithButton>
        </PetInfo>
      </PetImageName>
      <HotelBookingCartCheckInTime />
      <HotelBookingCartCheckOutTime />
      <HotelBookingNeedsReviewCheckbox petId={petId} />
      <LayoutCluster style={{ padding: "0 1.5em 0.5em 0", justifyContent: "right" }}>
        <HotelBookingApplySpecial petId={petId} />
      </LayoutCluster>
      <HotelBookingCartServiceTypePrice petId={petId} />
      <LayoutBox padding="scale-0" style={{ padding: "0 1.5em 0" }}>
        <HotelBookingCartSpecialPrice petId={petId} />
      </LayoutBox>
      <BookingCartFoodContainer petId={petId} />
      <BookingCartMedicationContainer petId={petId} />
      <LayoutBox padding="scale-0" style={{ padding: "0 1rem" }}>
        <HotelBookingCartAddonsSection petId={petId} />
      </LayoutBox>
      <BookingCartReservationNotesContainer petId={petId} />
      <HotelBookingInvoiceGeneratedTotalPrice petId={petId}>
        <HotelBookingInvoiceGeneratedCheckbox />
      </HotelBookingInvoiceGeneratedTotalPrice>
    </CardWrapper>
  ) : null;
}
