import React, { Component } from "react";
import styled from "styled-components";
import { color, modalPadding } from "../../../styles/theme";
import ClickableText from "../../../ClickableText";
import CommonButton from "../../../commonButton";

class RebookErrorComponent extends Component {
  onConfirm = () => {
    const {
      appointment,
      clearCart,
      router: { navigate },
    } = this.props;
    const { addOns, pet, associate, petServiceId, customer } = appointment || {};

    clearCart();

    navigate(`/booking/${customer}/select-service`, {
      state: {
        cart: { addOns, pet, associate, petServiceId },
      },
    });
  };

  render() {
    const { errorMessages, onCancel } = this.props;
    return (
      <Container>
        <Header>Please note:</Header>

        <Message>
          {errorMessages && (
            <ul>
              {errorMessages.map((errorMessage, index) => (
                <li key={index}>{errorMessage}</li>
              ))}
            </ul>
          )}
        </Message>

        <ButtonsContainer>
          <ClickableText isVisible styleObj={{ color: color.blue500 }} onClick={onCancel}>
            Cancel
          </ClickableText>
          <CommonButton label="Continue" onClick={this.onConfirm} />
        </ButtonsContainer>
      </Container>
    );
  }
}

const Container = styled.div`
  width: calc(100% - 30px);
  height: calc(100% - 75px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  padding: ${modalPadding};
  top: 0;
  left: 0;
`;

const Header = styled.div`
  font-weight: bold;
`;

const Message = styled.div`
  width: 90%;
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export default RebookErrorComponent;
