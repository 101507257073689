import { fork, put, takeEvery, call, all, select } from "redux-saga/effects";

// Actions
import {
  hotelDashBoardActionTypes,
  loadHotelDailyDashboardFailure,
  loadHotelDailyDashboardRequest,
  loadHotelDailyDashboardSuccess,
} from "dux/dashboard/hotel/hotelDashboardActions";
import { getHotelDashboardAppointments } from "@/core/services/associateWebBff/hotelDashboardAppointments";
import { getHotelDashboardCapacity } from "@/core/services/associateWebBff/hotelDashboardCapacity";
import {
  loadHotelArrivalsRequest,
  clearHotelArrivals,
} from "dux/hotelArrivals/columnList/hotelArivalsActions";
import {
  loadHotelOvernightRequest,
  clearHotelOvernights,
} from "dux/hotelOvernight/columnList/hotelOvernightActions";
import {
  loadHotelDeparturesRequest,
  clearHotelDepartures,
} from "dux/hotelDepartures/columnList/hotelDeparturesActions";
import {
  loadHotelCheckedOutRequest,
  clearHotelCheckedOut,
} from "dux/hotelCheckedOut/columnList/hotelCheckedOutActions";
import { loadHotelRoomCapacityRequest } from "dux/hotelRoomCapacity/hotelRoomCapacityActions";
import { loadDdcCapacityRequest } from "dux/ddcRoomCapacity/ddcRoomCapacityActions";

// Selectors
import { getStoreTimeZone } from "@/core/selectors/entitiesSelector";

// Utils
import {
  formatDateWithTimeZoneInUTC,
  formatCalendarDateMoment,
  initMomentObj,
} from "@/core/utils/dateUtils/formatDateTime";

function* clearAppointments() {
  yield put(clearHotelArrivals());
  yield put(clearHotelOvernights());
  yield put(clearHotelDepartures());
  yield put(clearHotelCheckedOut());
}

function* fireDailyDashboardAPI({ storeId, date }) {
  try {
    yield put(loadHotelDailyDashboardRequest());
    yield clearAppointments();

    const formattedDate = formatCalendarDateMoment(date);
    const timeZone = yield select(getStoreTimeZone, { storeNumber: storeId });

    // BFF endpoint for fetching dashboard capacity
    const capacityResponse = yield call(getHotelDashboardCapacity, {
      storeNumber: storeId,
      requestedDate: formattedDate,
    });

    // BFF endpoint for fetching dashboard appointments
    const appointmentsResponse = yield call(getHotelDashboardAppointments, {
      storeNumber: storeId,
      fromDateTime: formatDateWithTimeZoneInUTC(formattedDate, timeZone),
      toDateTime: formatDateWithTimeZoneInUTC(
        initMomentObj(formattedDate, timeZone)
          .add(1, "day")
          .subtract(1, "minutes"),
        timeZone,
      ),
    });

    // get each category of appointment and break them out
    const { arrivals, overnight, departures, checkedOut } = appointmentsResponse.data.result;

    // get hotel and ddc capacity
    const hotelCapacity = capacityResponse.data.result.hotel;
    const ddcCapacity = capacityResponse.data.result.doggieDayCamp;

    // Dispatch actions for each category of appointments to be handled in their own saga.
    yield put(loadHotelArrivalsRequest({ arrivals }));
    yield put(loadHotelOvernightRequest({ overnight }));
    yield put(loadHotelDeparturesRequest({ departures }));
    yield put(loadHotelCheckedOutRequest({ checkedOut }));

    // Dispatch actions for each category of room capacity to be handled in their own saga.
    yield put(loadHotelRoomCapacityRequest({ hotelCapacity }));
    yield put(loadDdcCapacityRequest({ ddcCapacity }));
    yield put(loadHotelDailyDashboardSuccess());
  } catch (error) {
    yield put(loadHotelDailyDashboardFailure({ error }));
  }
}

export function* loadHotelDashboard({ storeId, selectedDate }) {
  try {
    yield fireDailyDashboardAPI({ storeId, date: selectedDate });
  } catch (error) {
    yield put(loadHotelDailyDashboardFailure({ error }));
  }
}

function* watchDailyDashboard() {
  yield takeEvery(hotelDashBoardActionTypes.LOAD_PETS_HOTEL_DAILY_DASHBOARD, loadHotelDashboard);
}

export default function* hotelDashboardSaga() {
  yield all([fork(watchDailyDashboard)]);
}
