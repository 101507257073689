import React from "react";
import { messagePrefixes, reasonCodes } from "dux/eligibility/constants/eligibilityConstants";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { getPrismCustomerHotelProfile } from "@/web/petParentProfile/selectors/getPrismCustomerProfileSelector";
import getPrismPetHotelProfile from "@/dux/petAlerts/selectors/PrismPetProfilesSelectors";
import { getCustomerFullName, getPet, getPetPhoto } from "core/selectors/entitiesSelector";
import { connect } from "react-redux";
import { getCurrentPet } from "core/selectors/persistentSelectors";
import {
  getHotelBookingPetAlertReasons,
  getHotelBookingPetParentAlertReasons,
  getHotelCheckInPetAlertReasons,
  getHotelCheckInPetParentAlertReasons,
} from "dux/hotelEligibility/hotelEligibilitySelectors";
import { isEmpty } from "lodash/fp";
import { Layout } from "@prism/psm-ui-components";
import { color } from "../common/styles/theme";
import InitialsCircle from "../common/InitialsCircle";
import Img from "../common/Img";
import NotificationImage from "../../assets/icons/notificaiton.svg";

/**
 * React view component for check in and booking customer and pet alerts
 * @memberOf Views.Customer
 * @function
 * @name HotelAlertComponent
 * @param {Object} props
 * @param {String} props.subjectName
 * @param {Boolean} props.isPet
 * @param {String} props.photo
 * @param {Array} props.reasons\
 * @returns {JSX.Element|null}
 * @example const container = connect()(HotelAlertComponent)
 */
const HotelAlertComponent = props => {
  const { subjectName, isPet, photo, reasons } = props;

  return (
    <Layout.Box
      isHidden={isEmpty(reasons)}
      padding="box-padding-5"
      style={{ borderBottom: `1px solid ${color.gray300}` }}
    >
      <Layout.Cluster space="cluster-space-5" justify="left">
        {isPet
          ? photo && <Img width={40} height={40} src={photo} />
          : subjectName && <InitialsCircle displayName={subjectName} margin="0 1.65rem 0 0" />}
        <Img src={NotificationImage} />
        <h4>{`${subjectName}'s Alerts`}</h4>
      </Layout.Cluster>

      <Layout.Spacer />

      {reasons &&
        reasons.map(reason => (
          <Layout.Cluster
            space="cluster-space-2"
            justify="left"
            key={reason.reasonCode}
            style={{ padding: "0.5em" }}
          >
            <b>{`${messagePrefixes[reason.reasonCode] || "Alert"}`}: </b>
            {reason.description}
          </Layout.Cluster>
        ))}
    </Layout.Box>
  );
};

/**
 * Redux Connect function for hotel booking alerts for current pet and pet parent
 * @summary Located in the hotel booking alerts
 * @memberOf Views.Customer
 * @function
 * @name HotelBookingAlert
 * @returns {JSX.Element|null}
 * @example <HotelBookingAlert />
 */
export const HotelBookingAlert = connect((state, ownProps) => {
  const customerKey = getCurrentCustomerKey(state);
  const currentPet = getCurrentPet(state);
  const { isPet, petId = currentPet } = ownProps;
  return {
    reasons: isPet
      ? getHotelBookingPetAlertReasons(state, { customerKey, petId })
      : getHotelBookingPetParentAlertReasons(state, { customerKey, petId }),
    isPet,
    subjectName: isPet
      ? getPet(state, { petId })?.petName
      : getCustomerFullName(state, { customerKey }),
    photo: getPetPhoto(state, { petId }),
  };
})(HotelAlertComponent);

/**
 * Redux Connect function for hotel check in alerts for current pet parent
 * @summary Located in the hotel check in alerts
 * @memberOf Views.Customer
 * @function
 * @name HotelCheckInPetParentAlert
 * @returns {JSX.Element|null}
 * @example <HotelCheckInPetParentAlert />
 */
export const HotelCheckInPetParentAlert = connect(state => {
  const customerKey = getCurrentCustomerKey(state);

  return {
    reasons: getHotelCheckInPetParentAlertReasons(state, { customerKey }),
    subjectName: getCustomerFullName(state, { customerKey }),
  };
})(HotelAlertComponent);

/**
 * Redux Connect function for hotel check in alerts for selected pet
 * @summary Located in the hotel check in alerts
 * @memberOf Views.Customer
 * @function
 * @name HotelCheckInSinglePetAlert
 * @returns {JSX.Element|null}
 * @example <HotelCheckInSinglePetAlert petId={petId}/>
 */
export const HotelCheckInSinglePetAlert = connect((state, { petId }) => {
  const customerKey = getCurrentCustomerKey(state);

  return {
    reasons: getHotelCheckInPetAlertReasons(state, { petId }),
    isPet: true,
    subjectName: getPet(state, { petId })?.petName,
    photo: getPetPhoto(state, { petId }),
  };
})(HotelAlertComponent);

/**
 * Redux Connect function for hotel check out alerts for current pet parent
 * @summary Located in the hotel check out alerts
 * @memberOf Views.Customer
 * @function
 * @name HotelCheckOutPetParentAlert
 * @returns {JSX.Element|null}
 * @example <HotelCheckOutPetParentAlert />
 */
export const HotelCheckOutPetParentAlert = connect(state => {
  const customerKey = getCurrentCustomerKey(state);
  const selectedPrismHotelPetParent = getPrismCustomerHotelProfile(state, { customerKey });
  const reasons = selectedPrismHotelPetParent?.checkOutAlert
    ? [
        {
          reasonCode: reasonCodes.CheckOutAlert,
          description: selectedPrismHotelPetParent?.checkOutAlert,
        },
      ]
    : [];

  return {
    reasons,
    subjectName: getCustomerFullName(state, { customerKey }),
  };
})(HotelAlertComponent);

/**
 * Redux Connect function for hotel check out alerts for selected pet
 * @summary Located in the hotel check out alerts
 * @memberOf Views.Customer
 * @function
 * @name HotelCheckOutSinglePetAlert
 * @returns {JSX.Element|null}
 * @example <HotelCheckOutSinglePetAlert petId={petId}/>
 */
export const HotelCheckOutSinglePetAlert = connect((state, { petId }) => {
  const selectedPrismHotelPet = getPrismPetHotelProfile(state, { petId });
  const reasons = selectedPrismHotelPet?.checkOutAlert
    ? [
        {
          reasonCode: reasonCodes.CheckOutAlert,
          description: selectedPrismHotelPet?.checkOutAlert,
        },
      ]
    : [];

  return {
    reasons,
    isPet: true,
    subjectName: getPet(state, { petId })?.petName,
    photo: getPetPhoto(state, { petId }),
  };
})(HotelAlertComponent);
