// @ts-check
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// Components
import { Button, Text, Layout } from "@prism/psm-ui-components";
import { HotelManageRoomDetailsReasons } from "@/dux/hotelManageRoomDetailsReasons/hotelManageRoomDetailsReasons";
import {
  HotelManageRoomDetailsStartDate,
  HotelManageRoomDetailsEndDate,
} from "@/dux/hotelManageRoomDetailsDatePickers/hotelManageRoomDetailsDatePickers";
import { HotelManageRoomDetailsPetCard } from "./HotelMangeRoomDetailsPetCardContainer";
import { HotelManageRoomDetailsDetailsInput } from "../hotelManageRoomDetailsDetails/hotelManageRoomDetailsDetails";
import HistoryImage from "assets/icons/history.svg";

// Actions
import {
  patchHotelRoomSuspensionRequest,
  postHotelRoomSuspensionRequest,
  setHotelRoomSuspensionError,
} from "./hotelManageRoomDetailsTableActions";
import { setHotelManageRoomSuspensionReason } from "@/dux/hotelManageRoomDetailsReasons/hotelManageRoomDetailsReasonsActions";
import { setHotelManageRoomDetailsSuspensionHistoryId } from "@/dux/hotelManageRoomDetailsSuspensionHistory/hotelManageRoomDetailsSuspensionHistoryActions";
import { setHotelManageRoomScheduleInactivation } from "@/dux/hotelManageRoomDetails/hotelManageRoomDetailsActions";
import { setHotelManageRoomSuspensionDetails } from "@/dux/hotelManageRoomDetailsDetails/hotelManageRoomDetailsDetailsActions";

// Selectors
import { getServiceSuspensionRoomReasons } from "@/dux/servicesManagementToggle/servicesManagementToggleSelectors";
import { selectHotelRoomManageSchuduleInactivation } from "@/dux/hotelManageRoomDetails/hotelManageRoomDetailsSelectors";
import { selectHotelRoomSuspensionDetailsValue } from "@/dux/hotelManageRoomDetailsDetails/hotelManageRoomDetailsDetailsSelectors";
import { selectHotelRoomSuspensionError } from "@/dux/hotelManageRoomDetailsTable/hotelManageRoomDetailsTableSelectors";
import { selectHotelRoomSuspensionReason } from "@/dux/hotelManageRoomDetailsReasons/hotelManageRoomDetailsReasonsSelectors";

// Utils
import { color } from "web/common/styles/theme";

// Constants
import { ROOM_SUSPENSION_STATUS } from "./hotelManageRoomDetailsTableConstants";

// Styles
const style = {
  padding: "10px",
};

/**
 * Room Details Table Row Component
 * @param {object} props - props object
 * @param {Function} props.clearSuspensionError - clear suspension error
 * @param {number} props.index - index to track rows
 * @param {boolean} props.isLoading - Loading state for save, modify, and cancel
 * @param {object} props.suspension - suspension details
 * @param {Object[]} props.suspension.alerts - suspension alerts
 * @param {string} props.suspension.startDate - suspension start date
 * @param {string} props.suspension.endDate - suspension end date
 * @param {string} props.suspension.status - suspension status
 * @param {string} props.suspension.details - suspension details description
 * @param {string} props.suspension.reasonId - suspension reasonId
 * @param {string} props.suspension.suspensionId - suspension suspensionId
 * @param {Object[]} props.roomSuspensionReasons - room suspension reasons
 * @param {string} props.roomSuspensionReasons[].reasonName - room suspension reasonName
 * @param {string} props.roomSuspensionReasons[].reasonId - room suspension reasonId
 * @param {Function} props.saveSuspension - Save inactivation for a room
 * @param {Function} props.setSuspensionDetails - save suspension details for a room
 * @param {Function} props.setSuspensionReason - dispatch fn to set reason
 * @param {string} props.roomSuspensionDetailsValue - details value from store
 * @param {string} props.roomSuspensionError - error value while saving or modifying record
 * @param {string} props.roomSuspensionReasonValue - reason value from store
 * @param {Function} props.setSuspensionId - dispatch fn to set suspensionId
 * @param {boolean} props.isEditMode - switch read only and edit modes
 * @param {Function} props.setScheduleInactivation - Add new inactivation
 * @returns {(JSX.Element|null)}
 */

const ManageRoomDetailsTableRow = ({
  clearSuspensionError,
  index,
  isLoading,
  suspension,
  roomSuspensionReasons,
  saveSuspension,
  setSuspensionDetails,
  setSuspensionReason,
  roomSuspensionDetailsValue,
  roomSuspensionError,
  roomSuspensionReasonValue,
  setSuspensionId,
  isEditMode = false,
  setScheduleInactivation,
}) => {
  const { alerts, startDate, endDate, status, details, reasonId, suspensionId } = suspension;

  const roomSuspensionReason = roomSuspensionReasons?.find(
    (/** @type {{ reasonId: string; }} */ reason) => reason.reasonId === reasonId,
  );
  const initialReasonName = roomSuspensionReason?.reasonName || "";

  // Local form state
  const [isEdit, setIsEdit] = useState(isEditMode);
  const [isSavedDisabled, setIsSaveDisabled] = useState(true);
  const [reasonName, setReasonName] = useState("");
  const [descriptionDetails, setDescriptionDetails] = useState("");

  useEffect(() => {
    // Set data to store if its scheduled event
    if (status === ROOM_SUSPENSION_STATUS.scheduled) {
      // dispatch actions
      setSuspensionDetails(details);
      setSuspensionReason(reasonName);
    }
  }, []);

  useEffect(() => {
    if (!isEditMode && (reasonName !== initialReasonName || descriptionDetails !== details)) {
      setIsSaveDisabled(false);
    } else if (isEditMode && reasonName !== initialReasonName && descriptionDetails !== details) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  }, [reasonName, descriptionDetails, isEditMode]);

  // Handle discard changes
  const handleDiscardChanges = () => {
    setIsEdit(false);
    setScheduleInactivation(false);
    setReasonName(roomSuspensionReasonValue);
    setDescriptionDetails(roomSuspensionDetailsValue);
  };

  // Handle save changes
  const handleSaveChanges = () => {
    const savePayload = {
      isCanceled: false,
      suspensionId,
      details: descriptionDetails,
      reasonName,
    };
    saveSuspension(savePayload);
    setIsEdit(false);
    clearSuspensionError();
  };

  // Handle cancel changes
  const handleCancelChanges = () => {
    const cancelPayload = {
      isCanceled: true,
      suspensionId,
    };
    saveSuspension(cancelPayload);
  };

  // Handle modify changes
  const handleModifyChanges = () => {
    setIsEdit(true);
    setReasonName(initialReasonName);
    setDescriptionDetails(details);
  };

  const canEditSuspension = !(
    status === ROOM_SUSPENSION_STATUS.completed || status === ROOM_SUSPENSION_STATUS.canceled
  );
  const isEvenRow = index % 2 !== 0;
  const showAlerts = alerts?.length > 0 && !isLoading;
  const showErrors = roomSuspensionError && status === ROOM_SUSPENSION_STATUS.scheduled;

  const renderAlerts = (/** @type {any[]} */ alerts) => {
    return alerts.map((alertItem, idx) => {
      return (
        <Layout.Box key={idx}>
          <Layout.Stack space="stack-space-4">
            <Layout.Box>
              <Text as="div" color="text-color-red">
                {alertItem.alertText}
              </Text>
            </Layout.Box>
            {alertItem?.occupants.length > 0
              ? alertItem.occupants?.map((/** @type {any} */ occupant, /** @type {any} */ id) => (
                  <Layout.Box
                    style={{ width: "25%", padding: "0 0 10px 0" }}
                    key={`${alertItem.alertType}-${id}`}
                  >
                    <HotelManageRoomDetailsPetCard {...occupant} />
                  </Layout.Box>
                ))
              : null}
          </Layout.Stack>
        </Layout.Box>
      );
    });
  };

  const roomSuspensionsAlerts = (
    <tr
      style={{
        backgroundColor: (isEvenRow && color.gray100) || color.white0,
        borderBottom: "1px solid gray",
        textAlign: "left",
      }}
    >
      {/* Show Alerts from Success Response */}
      {showAlerts && (
        <td
          // @ts-ignore
          colSpan="6"
        >
          {renderAlerts(alerts)}
        </td>
      )}
      {/* Show Errors from Error Response */}
      {showErrors && (
        <td
          // @ts-ignore
          colSpan="6"
        >
          <Text as="p" color="text-color-red">
            {roomSuspensionError}
          </Text>
        </td>
      )}
    </tr>
  );

  if (isEdit) {
    return (
      <>
        <tr
          style={{
            backgroundColor: (isEvenRow && color.gray100) || color.white0,
            textAlign: "left",
          }}
        >
          <td style={style}>
            <HotelManageRoomDetailsStartDate />
          </td>
          <td style={style}>
            <HotelManageRoomDetailsEndDate />
          </td>
          <td style={style}>{status || ROOM_SUSPENSION_STATUS.scheduled}</td>
          <td style={style}>
            <HotelManageRoomDetailsReasons
              initialValue={reasonName}
              handleChange={(/** @type {{value: React.SetStateAction<string> }} */ { value }) =>
                setReasonName(value)
              }
            />
          </td>
          <td style={style}>
            <HotelManageRoomDetailsDetailsInput
              initialDetails={descriptionDetails}
              handleChange={(/** @type {React.SetStateAction<string>} */ details) =>
                setDescriptionDetails(details)
              }
            />
          </td>
          <td style={style}>
            <Button
              variant="prism-primary"
              disabled={isSavedDisabled}
              onClick={handleSaveChanges}
              size="xs"
              style={{
                width: "100px",
                display: "inline",
                marginRight: "10px",
                height: "35px",
                padding: "5px",
              }}
            >
              <Text>Save</Text>
            </Button>
            <Button
              variant="no-outline"
              onClick={handleDiscardChanges}
              style={{ color: color.red700 }}
            >
              <Text>Discard Changes</Text>
            </Button>
          </td>
        </tr>
        {roomSuspensionsAlerts}
      </>
    );
  }

  const statusWithHistoryIcon = (
    <div style={{ display: "flex" }}>
      {status}
      {
        <Button
          variant="link"
          size="sm"
          style={{ marginLeft: "5px" }}
          onClick={() => setSuspensionId(suspensionId)}
        >
          <img
            alt="Room Suspensions History Icon"
            id={`historyIcon--${suspensionId}`}
            src={HistoryImage}
          />
        </Button>
      }
    </div>
  );

  return (
    <>
      <tr
        style={{
          backgroundColor: (isEvenRow && color.gray100) || color.white0,
          textAlign: "left",
        }}
      >
        <td style={style}>{startDate}</td>
        <td style={style}>{endDate}</td>
        <td style={style}>{statusWithHistoryIcon}</td>
        <td style={style}>{roomSuspensionReason?.reasonName || reasonId}</td>
        <td style={style}>{details}</td>
        <td style={style}>
          {canEditSuspension && (
            <>
              <Button
                variant="prism-primary"
                disabled={isEditMode}
                onClick={handleModifyChanges}
                size="xs"
                style={{
                  width: "100px",
                  display: "inline",
                  marginRight: "10px",
                  height: "35px",
                  padding: "5px",
                }}
              >
                <Text>Modify</Text>
              </Button>
              <Button
                variant="no-outline"
                disabled={isEditMode}
                onClick={handleCancelChanges}
                style={{ color: isEditMode ? color.gray400 : color.red700 }}
              >
                <Text>Cancel Suspension</Text>
              </Button>
            </>
          )}
        </td>
      </tr>
      {roomSuspensionsAlerts}
    </>
  );
};

// CONTAINER(s) ----------------------------------------------------------------------------------------
export const HotelManageRoomDetailsTableRow = connect(
  // @ts-ignore
  (state, { index, isLoading, suspension }) => {
    const isNewScheduleInactivation = selectHotelRoomManageSchuduleInactivation(state);
    const roomSuspensionDetailsValue = selectHotelRoomSuspensionDetailsValue(state);
    const roomSuspensionError = selectHotelRoomSuspensionError(state);
    const roomSuspensionReasonValue = selectHotelRoomSuspensionReason(state);
    const roomSuspensionReasons = getServiceSuspensionRoomReasons(state);

    return {
      index,
      isLoading,
      isNewScheduleInactivation,
      roomSuspensionDetailsValue,
      roomSuspensionError,
      roomSuspensionReasonValue,
      roomSuspensionReasons,
      suspension,
    };
  },

  dispatch => {
    return {
      clearSuspensionError: () => dispatch(setHotelRoomSuspensionError([])),
      patchInactivation: (
        /** @type {{ isCanceled:boolean, suspensionId:string, details:string, reasonName:string }}*/ {
          isCanceled,
          suspensionId,
          details,
          reasonName,
        },
      ) =>
        dispatch(
          patchHotelRoomSuspensionRequest({ isCanceled, suspensionId, details, reasonName }),
        ),
      postInactivation: (
        /** @type {{ isCanceled:boolean, suspensionId:string, details:string, reasonName:string }}*/ {
          isCanceled,
          suspensionId,
          details,
          reasonName,
        },
      ) =>
        dispatch(postHotelRoomSuspensionRequest({ isCanceled, suspensionId, details, reasonName })),
      setScheduleInactivation: (/** @type {boolean} */ inactivation) =>
        dispatch(setHotelManageRoomScheduleInactivation(inactivation)),
      setSuspensionId: (/** @type {string} */ suspensionId) =>
        dispatch(setHotelManageRoomDetailsSuspensionHistoryId({ suspensionId })),
      setSuspensionDetails: (/** @type {string} */ details) =>
        dispatch(setHotelManageRoomSuspensionDetails(details)),
      setSuspensionReason: (/** @type {string} */ reasonName) =>
        dispatch(setHotelManageRoomSuspensionReason(reasonName)),
    };
  },

  (mapProps, dispatchProps) => {
    const {
      index,
      isLoading,
      isNewScheduleInactivation,
      roomSuspensionDetailsValue,
      roomSuspensionError,
      roomSuspensionReasonValue,
      roomSuspensionReasons,
      suspension,
    } = mapProps;
    const {
      clearSuspensionError,
      patchInactivation,
      postInactivation,
      setScheduleInactivation,
      setSuspensionId,
      setSuspensionDetails,
      setSuspensionReason,
    } = dispatchProps;

    const saveSuspension = isNewScheduleInactivation ? postInactivation : patchInactivation;

    return {
      clearSuspensionError,
      index,
      isEditMode: isNewScheduleInactivation,
      isLoading,
      roomSuspensionReasons,
      roomSuspensionDetailsValue,
      roomSuspensionError,
      roomSuspensionReasonValue,
      saveSuspension,
      setScheduleInactivation,
      setSuspensionId,
      setSuspensionDetails,
      setSuspensionReason,
      suspension,
    };
  },
)(ManageRoomDetailsTableRow);
