import emojiRegex from "emoji-regex";

/**
 * strip/remove emojis from a string.
 * @param {String } value
 */
export const stripEmojis = value => {
  // eslint-disable-next-line no-control-regex
  const nonASCII = /[^\u0000-\u007F]+/g;
  const multipleSpaces = /\s+/g;

  return value
    ?.replace(nonASCII, "") // remove all non ASCII characters
    ?.replace(emojiRegex(), "") // remove any Unicode that represent emojis that the remove ASCII may have not gotten
    ?.replace(multipleSpaces, " ") // set 2 or more spaces to a single space
    ?.trimStart(); // remove any white space at the beginning of the string.
};
