import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getCurrentPet } from "core/selectors/persistentSelectors";
import { LayoutBox } from "@/layout/box/Box";
import {
  getFirstHotelEngagementByPet,
  getFirstHotelEngagementStatusByPet,
  selectItineraryRoomCount,
  selectPetsWithEngagementsByHostPetId,
  selectPotentialRoomSharingPets,
  selectRebookingPetRooms,
} from "@/dux/hotelEngagements/hotelEngagementSelectors";
import { checkIsPreCheckIn } from "@/dux/itineraryHistoryList/itineraryHistoryListSelectors";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { TextPassage } from "@petsmart-ui/sparky";
import { HotelCheckInOutEditIconContainer } from "@/dux/hotelCheckInOutDateTimeCart/hotelCheckInOutEditIcon";
import { getPetBreedIsAggressive } from "@/core/selectors/entitiesSelector";
import getIsUpdateRoomSharingWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/updateRoomSharing/getIsUpdateRoomSharingWorkflowFeatureFlagHidden";
import {
  clearHotelBooking,
  setHotelBookingFlowDateTime,
  setHotelBookingFlowPets,
} from "@/web/features/hotelBookingFlow/hotelBookingFlowActions";
import { HotelCheckInOutRoomSelection } from "@/dux/hotelBookingServiceSelection/HotelBookingRoomSelection";
import { HotelCheckInOutRoomsServiceSelection } from "@/dux/hotelBookingServiceSelection/HotelBookingServiceSelection";
import { getHotelItinerary } from "@/dux/hotelItinerary/hotelItinerarySelectors";
import moment from "moment";
import { FormWithSaveAndCancel } from "../formWithSaveAndCancel/FormWithSaveAndCancel";
import {
  selectHotelBookingOpenStep,
  selectHotelBookingRooms,
  selectPetsWithoutService,
} from "@/web/features/hotelBookingFlow/hotelBookingFlowSelectors";
import { isRoomConfigsEqual } from "@/dux/hotelBookingServiceSelection/_helpers_/hotelBookingServiceSelectionHelpers";
import { patchHotelPrimaryServiceChange } from "@/dux/hotelPrimaryServiceChange/hotelPrimaryServiceChangeActions";
import { isEmpty } from "lodash";
import { selectMergeRoomUpdatesForPatch } from "./checkInOutMergeRoomsSelectors";
import { selectIsHotelMergeRoomsOpen } from "@/dux/toggleAccordion/toggleAccordionSelectors";
import { toggleHotelMergeRooms } from "@/dux/toggleAccordion/toggleAccordionActions";

const CheckInOutRoomsComponent = ({
  isHidden,
  componentId,
  roomCount,
  petId,
  isEditing,
  setIsEditing = () => {},
  setPendingRoomData = () => {},
  clearPendingRooms = () => {},
  savePendingRooms = () => {},
  isAtServiceStep,
  disableSave,
}) => {
  useEffect(() => {
    if (isEditing) setIsEditing(false);
  }, [petId]);

  useEffect(() => {
    // Initialize the hotel booking state with the current pet and any pets that could be roomed with them
    if (isEditing) setPendingRoomData();
    else clearPendingRooms();
  }, [isEditing]);

  if (isHidden) return null;

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <LayoutCluster>
        <TextPassage size="lg">Rooms: {roomCount}</TextPassage>
        <HotelCheckInOutEditIconContainer onClick={() => setIsEditing(!isEditing)} />
      </LayoutCluster>

      {isEditing && (
        <LayoutBox>
          <FormWithSaveAndCancel
            submitForm={savePendingRooms}
            resetForm={() => setIsEditing(false)}
            touched={{ isAtServiceStep }}
            errors={{ disableSave }}
          >
            <HotelCheckInOutRoomSelection stepIndex={0} />
            <HotelCheckInOutRoomsServiceSelection stepIndex={1} />
          </FormWithSaveAndCancel>
        </LayoutBox>
      )}
    </LayoutBox>
  );
};

export const CheckInOutRooms = connect(
  state => {
    const isFeatureHidden = getIsUpdateRoomSharingWorkflowFeatureFlagHidden(state);
    if (isFeatureHidden) return { isHidden: true };

    const petId = getCurrentPet(state);
    const isAggressiveBreed = getPetBreedIsAggressive(state, { petId });
    const status = getFirstHotelEngagementStatusByPet(state, { petId });
    const isBookedOrConfirmed = checkIsPreCheckIn({ status });
    const firstEngagement = getFirstHotelEngagementByPet(state, { petId });

    // Check if pet is currently sharing room with any others
    const roomSharingPets = selectPetsWithEngagementsByHostPetId({
      hostPetId: firstEngagement?.hostPetId,
      petId,
    })(state);
    const isSharingRoom = !!roomSharingPets?.length;

    // Get all ids of pets that could be roomed with current pet
    const potentialRoomSharingPets = selectPotentialRoomSharingPets(state);
    const canShareRoom = !!potentialRoomSharingPets?.length;

    // Get itinerary for start and end dates to be used in Get Pet Services call
    const itinerary = getHotelItinerary(state);

    // Check if room configuration has been updated and whether pets have services
    const originalRooms = selectRebookingPetRooms(state);
    const pendingRooms = selectHotelBookingRooms(state);
    const petsWithoutService = selectPetsWithoutService(state);
    const roomsUpdated = !isRoomConfigsEqual(originalRooms, pendingRooms);
    const isAtServiceStep = selectHotelBookingOpenStep(state) > 0;

    return {
      componentId: "CheckInOutRooms",
      isHidden: !canShareRoom || !isBookedOrConfirmed || isSharingRoom || isAggressiveBreed,
      roomCount: selectItineraryRoomCount(state),
      petId,
      petIds: [petId, ...potentialRoomSharingPets],
      startDate: moment(itinerary?.startDateTime),
      endDate: moment(itinerary?.endDateTime),
      isAtServiceStep,
      disableSave: !roomsUpdated || petsWithoutService,
      roomsUpdated,
      updatedItineraryPets: selectMergeRoomUpdatesForPatch(state),
      isEditing: selectIsHotelMergeRoomsOpen(state),
    };
  },
  dispatch => {
    return {
      setPendingRoomData: ({ petIds, startDate, endDate }) => {
        dispatch(setHotelBookingFlowPets({ pets: { petList: petIds } }));
        dispatch(setHotelBookingFlowDateTime({ startDate, endDate }));
      },
      clearPendingRooms: () => dispatch(clearHotelBooking()),
      callPrimaryServicePatch: ({ data, onComplete }) =>
        dispatch(patchHotelPrimaryServiceChange({ data, onComplete })),
      setIsEditing: isEditing => dispatch(toggleHotelMergeRooms(isEditing)),
    };
  },
  (stateProps, dispatchProps) => {
    const {
      petIds,
      startDate,
      endDate,
      roomsUpdated,
      updatedItineraryPets,
      ...propsFromState
    } = stateProps;
    const {
      setPendingRoomData,
      clearPendingRooms,
      callPrimaryServicePatch,
      setIsEditing,
    } = dispatchProps;
    return {
      ...propsFromState,
      setIsEditing,
      setPendingRoomData: () => setPendingRoomData({ petIds, startDate, endDate }),
      clearPendingRooms,
      savePendingRooms: () => {
        if (!roomsUpdated || isEmpty(updatedItineraryPets)) return;

        callPrimaryServicePatch({
          data: { pets: updatedItineraryPets },
          onComplete: () => setIsEditing(false),
        });
      },
    };
  },
)(CheckInOutRoomsComponent);
