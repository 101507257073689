import React from "react";
import styled from "styled-components";
import { isEmpty } from "lodash/fp";
import FoodSectionContainer from "../newFood/FoodSectionContainer";
import NewPetProfileFoodFormContainer from "../newFood/NewPetProfileFoodFormContainer";
import { PetProfilePetFoodList } from "web/foodList/FoodList";
import { color } from "../common/styles/theme";
import LoadingWrapper from "../common/LoadingWrapper";

const Container = styled.div`
  border-bottom: ${props => (props.showBottomBorder ? `1px solid ${color.gray100}` : "none")};
`;

class PetProfileFoodComponent extends React.Component {
  componentDidMount() {
    const { petId, foods, setPetFoods, loadFoodOptions } = this.props;

    loadFoodOptions();

    if (petId && !isEmpty(foods)) {
      setPetFoods(foods);
    }
  }

  componentDidUpdate(prevProps) {
    const { petId, foods, setPetFoods } = this.props;

    if (petId !== prevProps.petId || (isEmpty(prevProps.foods) && !isEmpty(foods))) {
      setPetFoods(foods);
    }
  }

  render() {
    const { isHidden, showBottomBorder, petId, isLoading } = this.props;
    return !isHidden ? (
      <Container showBottomBorder={showBottomBorder}>
        <LoadingWrapper isLoading={isLoading}>
          <FoodSectionContainer petId={petId}>
            <NewPetProfileFoodFormContainer petId={petId} />
            <PetProfilePetFoodList petId={petId} />
          </FoodSectionContainer>
        </LoadingWrapper>
      </Container>
    ) : null;
  }
}

export default PetProfileFoodComponent;
