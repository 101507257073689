import React from "react";
import { connect } from "react-redux";
import InputWithBlur from "../common/InputWithBlur";
import { setFoodTypeAmount } from "./actions/foodTypeAmountActions";
import { getFoodTypeAmountById } from "./foodTypeAmountSelectors";
import { REQUIRED_FIELD_ERROR_MESSAGE } from "../../core/constants/validationErrors";
import { isValidString } from "@/core/utils/validationUtils/stringValidation";
import { getFoodIsDiscontinuedById } from "../food/foodsSelector";

export const FoodAmountField = ({ foodIndex, ...props }) => {
  return <InputWithBlur {...props} title={`Food ${foodIndex} amount`} name="foodAmount" />;
};

const mapStateToProps = (state, ownProps) => {
  const { foodId, foodIndex, petId } = ownProps;
  const currentValue = getFoodTypeAmountById(state, { foodId, petId });
  const isValid = isValidString(currentValue);
  return {
    foodId,
    petId,
    currentValue,
    componentId: `foodDetails-foodTypeAmount-${foodId}`,
    foodIndex,
    error: !isValid && REQUIRED_FIELD_ERROR_MESSAGE,
    maxLength: 50,
    disabled: getFoodIsDiscontinuedById(state, { petId, foodId }),
  };
};

const mapDispatchToProps = dispatch => ({
  onChangeFoodTypeAmount: ({ value, foodId, petId }) => {
    dispatch(setFoodTypeAmount({ foodTypeAmount: value, foodId, petId }));
  },
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  onChange: value => {
    const { foodId, petId } = propsFromState;
    propsFromDispatch.onChangeFoodTypeAmount({ value, foodId, petId });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(FoodAmountField);
