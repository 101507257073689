import styled from "styled-components";
import { font, color } from "web/common/styles/theme";
import { fontSizes } from "web/common/styles/responsive/fonts";

export const AFontRegular = styled.div`
  font-family: ${font.mainFamily};
  font-size: ${fontSizes.regular};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
`;

export const APriceListHeading = styled.div`
  font-family: ${font.secondaryFamily};
  font-size: ${fontSizes.regular};
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${color.gray800};
`;

export const APriceListCopy = styled.div`
  font-family: ${font.mainFamily};
  font-size: ${fontSizes.regular};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 25px;
  letter-spacing: normal;
  color: ${color.gray800};
`;
