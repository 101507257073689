import React from "react";
import SectionTitle from "./SectionTitle";
import { color } from "./styles/theme";
import VaccinationDropdown from "../petParentProfile/pets/petVaccinations/vaccinationDropdownContainer";
import AddButton from "./buttons/addButton";
import fadeIn from "./styles/Animation/fade/fadeIn";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutStack } from "@/layout/stack/Stack";
import sectionStyles from "./styles/section.module.css";

/* * ----------------------------------------------------------------------- * *\
  Component
\* * ----------------------------------------------------------------------- * */
const Section = ({
  title,
  padding,
  paddingTop,
  topLine,
  disabled,
  buttonDisabled,
  centered,
  button,
  buttonClickHandler,
  petId,
  customerKey,
  popover,
  children,
  buttontextcolor,
  isVisible = true,
  addButton,
  titleMarginBottom,
  componentId,
}) => {
  if (!isVisible) {
    return null;
  }
  return (
    <LayoutStack
      data-testid="UISection"
      id={componentId}
      className={`pplt-layout__stack ${disabled ? sectionStyles.disabled : ""}`}
      style={{
        padding: padding ? padding : "2.5rem 0",
        paddingTop: paddingTop ? paddingTop : "inherit",
        borderTop: topLine ? `1px solid ${color.gray100}` : "none",
        alignItems: centered ? "center" : "auto",
        flex: "1",
        animation: `${fadeIn()} 1s ease`,
        ...(disabled && { opacity: "0.5" }),
      }}
    >
      <LayoutCluster style={{ justifyContent: "space-between" }}>
        {title && <SectionTitle title={title} titleMarginBottom={titleMarginBottom} />}
        {/* Added this to resolve merge conflict. Will need to be fully resolved soon by removing AddButton logic. */}
        {addButton}
        {button && (
          <AddButton
            fontSize="regular"
            opacity="1"
            label={button}
            buttonClickHandler={buttonClickHandler}
            buttonDisabled={buttonDisabled}
            buttontextcolor={buttontextcolor}
          />
        )}

        {popover && <VaccinationDropdown petId={petId} customerKey={customerKey} />}
      </LayoutCluster>

      {children}
    </LayoutStack>
  );
};

export default Section;
