import React from "react";
import styled from "styled-components";
import { isEmpty, sortBy, filter, find, get } from "lodash/fp";

import { history } from "dux/utils/browser/browserHistory";
import { SubTotalAssociateCell } from "web/schedules/associateCell";
import Cell from "./cell";
import { color, scheduleGridColumns } from "../common/styles/theme";
import TotalsRow from "./totalsRowContainer";
import { forEachWithKey } from "../../core/utils/general/loopsAndMaps";

import { dayActivityTypes } from "../../core/constants/schedulesConstants";
import getMomentByDateAndTime from "../../core/utils/dateUtils/byDateAndTime";

export default class SubtotalsTable extends React.Component {
  renderAmountRow = () => {
    const { amountOfColumns, schedules, groupName } = this.props;

    const result = [];

    for (let i = 0; i < amountOfColumns; i++) {
      if (!i) {
        result.push(
          <AmountCell key={i}>{`${Object.keys(schedules).length} ${groupName}`}</AmountCell>,
        );
      } else {
        result.push(<Cell key={i} />);
      }
    }

    return result;
  };

  renderAbsences = ({ absence, noShift, date }) => {
    if (noShift) {
      return (
        <Absence>
          <b>{`- ${absence.type} -`}</b>
        </Absence>
      );
    }

    const startMoment = getMomentByDateAndTime(date, absence.startTime);
    const endMoment = getMomentByDateAndTime(date, absence.endTime);

    return <Absence>{`${startMoment.format("LT")} - ${endMoment.format("LT")}`}</Absence>;
  };

  renderWorkDayCells = ({ associateId, backgroundColor }) => {
    const { schedules, isScheduleLoading, salonHours } = this.props;
    const result = [];

    forEachWithKey((associateSchedule, date) => {
      const { salonHours } = this.props;

      associateSchedule.sort((s1, s2) => {
        const s1StartMoment = getMomentByDateAndTime(date, s1.startTime);
        const s2StartMoment = getMomentByDateAndTime(date, s2.startTime);
        return s1StartMoment.isBefore(s2StartMoment) ? -1 : 1;
      });

      const absences = filter(
        daySchedule => daySchedule && daySchedule.type !== dayActivityTypes.WORK,
        associateSchedule,
      );
      const noShift = absences.length === associateSchedule.length;
      const isStoreClosed = get([date, "IsClosed"], salonHours);

      if (isStoreClosed) {
        result.push(
          <Cell backgroundColor={backgroundColor} key={date}>
            <b>Store Closed</b>
          </Cell>,
        );
      } else {
        result.push(
          <Cell backgroundColor={backgroundColor} key={date}>
            {isScheduleLoading ? (
              <b>Loading..</b>
            ) : (
              isEmpty(associateSchedule) && <b>- No activities -</b>
            )}
            {associateSchedule &&
              associateSchedule.map(schedule => {
                if (schedule.type === dayActivityTypes.WORK) {
                  const startWorkMoment = getMomentByDateAndTime(date, schedule.startTime);
                  const endWorkMoment = getMomentByDateAndTime(date, schedule.endTime);
                  return <b>{`${startWorkMoment.format("LT")} - ${endWorkMoment.format("LT")}`}</b>;
                }
                return this.renderAbsences({ absence: schedule, noShift, date });
              })}
          </Cell>,
        );
      }
    }, schedules[associateId].activities);

    result.push(
      <Cell key={associateId} backgroundColor={backgroundColor}>
        {schedules[associateId].hoursPerWeek.toFixed(2)}
      </Cell>,
    );

    return result;
  };

  renderRow = ({ associateId, backgroundColor }) => (
    <React.Fragment key={associateId}>
      <AssociateWrapper
        onClick={() => {
          history.push(`/associate-scheduling/${associateId}`);
        }}
      >
        <SubTotalAssociateCell backgroundColor={backgroundColor} associateId={associateId} />
      </AssociateWrapper>

      {this.renderWorkDayCells({ associateId, backgroundColor })}
    </React.Fragment>
  );

  render() {
    const { schedules, associates, startWeekDate, groupName } = this.props;

    if (isEmpty(schedules)) {
      return null;
    }

    const sortedSchedules = sortBy(
      associateId => associates[associateId].associateName,
      Object.keys(schedules),
    );

    return (
      <Container>
        <AmountRow>{this.renderAmountRow()}</AmountRow>

        <Table>
          {sortedSchedules.map((associateId, index) => {
            const backgroundColor = index % 2 ? "white" : color.gray50;
            return this.renderRow({ associateId, backgroundColor });
          })}
        </Table>

        <TotalsRow
          startWeekDate={startWeekDate}
          label={`Subtotals for ${groupName}`}
          schedules={schedules}
        />
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Table = styled.div`
  display: grid;
  grid-template-columns: ${scheduleGridColumns};
  grid-auto-rows: 80px;
`;

const AmountRow = styled.div`
  display: grid;
  grid-template-columns: ${scheduleGridColumns};
  grid-auto-rows: 30px;
`;

const AmountCell = styled(Cell)`
  border-left: 1px solid ${color.gray300};
  justify-content: center;
  align-items: flex-start;
`;

const Absence = styled.div`
  color: ${color.gray600};
`;

const AssociateWrapper = styled.div`
  display: flex;
  flex: 1;
  cursor: pointer;
`;
