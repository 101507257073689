import React from "react";
import { Layout, Text, Button } from "@prism/psm-ui-components";
import EditIcon from "assets/icons/edit.svg";
import { color } from "@/web/common/styles/theme";

/**
 * A View component for a applied addons button.
 * @param {Object} obj - destructed params.
 * @param {String} obj.componentId
 * @param {Boolean} obj.isHidden
 * @param {String} obj.buttonLabel
 * @param {Callback} obj.onClick
 * @return {JSX.Element|null}
 */
export const AddonsListAppliedAddonButton = ({
  componentId,
  isHidden,
  buttonLabel,
  onClick,
  hasError,
}) => {
  if (isHidden) {
    return null;
  }

  return (
    <Layout.Box id={componentId}>
      <Button variant="link" onClick={onClick}>
        <Layout.Cluster space="cluster-space-8" childOverrides={{ flexWrap: "nowrap" }}>
          <img src={EditIcon} />
          <Text size="text-size-xl" style={hasError ? { color: color.red700 } : {}}>
            {buttonLabel}
          </Text>
        </Layout.Cluster>
      </Button>
    </Layout.Box>
  );
};
