import React from "react";
import { connect } from "react-redux";

// Components
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { TextPassage } from "@petsmart-ui/sparky";
import LoadingWrapper from "@/web/common/LoadingWrapper";
import {
  HotelInvoicePaidInStore,
  HotelInvoicePaidOnline,
  SalonInvoicePaidInStore,
  SalonInvoicePaidOnline,
} from "@/dux/invoicePaidRadio/InvoicePaidRadio";

// Actions
import { showCheckInOutModal } from "core/actionCreators/checkInOutActionCreator";
import { PUT_HOTEL_INVOICE_PAID } from "@/dux/hotelItineraryPayment/hotelItineraryPaymentActions";

// Selectors
import { hasManagerPrivileges } from "@/core/selectors/persistentSelectors";
import {
  getHotelItinerary,
  selectItineraryInvoiceIsPaid,
} from "@/dux/hotelItinerary/hotelItinerarySelectors";
import { createLoadingSelector } from "@/core/selectors/utils";
import { getCurrentItinerary } from "@/core/selectors/checkInOutSelector";
import { getItinerary } from "@/core/selectors/entitiesSelector";

// Utils
import { color } from "@/web/common/styles/theme";

// Constants
import { modalTypes } from "@/core/constants/checkInOutConstants";
import { itinerariesPaymentStatuses } from "@/core/constants/itinerariesConstants";
import itinerariesActionTypes from "@/core/actionTypes/itinerariesActionTypes";

/**
 * React view component for hotel paid invoice and void transaction
 * @memberOf Views.Hotel.Itinerary
 * @function
 * @name PaidVoidTransaction
 * @param {Object} props
 * @param {string} props.componentId
 * @param {string} props.invoicePaidLabel
 * @param {Boolean} props.isHidden
 * @param {Boolean} props.isVoidTransactionVisible
 * @param {Function} props.onVoidTransactionClick
 * @param {string} props.voidTransactionLabel
 * @returns {JSX.Element}
 * @example const container = connect()(PaidVoidTransaction)
 */
export const PaidVoidTransaction = props => {
  const {
    componentId,
    invoicePaidLabel,
    isHidden,
    isVoidTransactionVisible,
    onVoidTransactionClick,
    voidTransactionLabel,
    isLoading,
    diComp,
    style = {},
  } = props;

  if (isHidden) return false;

  const PaidInStore = diComp?.paidInStore ?? React.Fragment;
  const PaidOnline = diComp?.paidOnline ?? React.Fragment;

  return (
    <LayoutBox style={style} id={componentId}>
      <LoadingWrapper isLoading={isLoading}>
        <LayoutCluster flexWrap="flex-noWrap">
          <TextPassage size="lg">{invoicePaidLabel}</TextPassage>
          <PaidInStore />
          <PaidOnline />
          {isVoidTransactionVisible && (
            <TextPassage onClick={onVoidTransactionClick}>
              <span style={{ color: color.red700, cursor: "pointer" }}>{voidTransactionLabel}</span>
            </TextPassage>
          )}
        </LayoutCluster>
      </LoadingWrapper>
    </LayoutBox>
  );
};

// CONTAINER ----------------------------------------------------------------------------------------
export const CheckInOutPaidVoidTransaction = connect(
  state => {
    const isManager = hasManagerPrivileges(state);
    const isInvoicePaid = selectItineraryInvoiceIsPaid(state);
    const isVoidTransactionVisible = isManager && isInvoicePaid;
    const itineraryStatus = getHotelItinerary(state)?.status;
    const isTransactionVoided = itineraryStatus === itinerariesPaymentStatuses.VOIDED;
    const voidTransactionLabel = isTransactionVoided ? "Transaction Voided" : "Void Transaction";

    return {
      componentId: "CheckInOutPaidVoidTransaction",
      invoicePaidLabel: "Invoice was paid:",
      isHidden: false,
      isVoidTransactionVisible,
      isTransactionVoided,
      voidTransactionLabel,
      isLoading: createLoadingSelector([PUT_HOTEL_INVOICE_PAID])(state),
    };
  },
  dispatch => {
    return {
      onVoidTransactionClick: () => {
        dispatch(showCheckInOutModal({ modalType: modalTypes.HOTEL_VOID_TRANSACTION }));
      },
    };
  },
  (stateProps, dispatchProps) => {
    const {
      componentId,
      invoicePaidLabel,
      isHidden,
      isVoidTransactionVisible,
      isTransactionVoided,
      voidTransactionLabel,
      isLoading,
    } = stateProps;

    const { onVoidTransactionClick } = dispatchProps;

    return {
      // state props
      componentId,
      invoicePaidLabel,
      isHidden,
      isVoidTransactionVisible,
      voidTransactionLabel,
      isLoading,
      // dispatch props
      onVoidTransactionClick: () => !isTransactionVoided && onVoidTransactionClick(),

      style: { paddingLeft: "1em" },
      diComp: {
        paidInStore: HotelInvoicePaidInStore,
        paidOnline: HotelInvoicePaidOnline,
      },
    };
  },
)(PaidVoidTransaction);

export const SalonPaidVoidTransaction = connect(
  state => {
    const itineraryId = getCurrentItinerary(state);
    const itineraryStatus = getItinerary(state, { itineraryId })?.status;

    const isPaid = itineraryStatus === itinerariesPaymentStatuses.PAID;
    const isClosed = itineraryStatus === itinerariesPaymentStatuses.CLOSED;
    const isVoided = itineraryStatus === itinerariesPaymentStatuses.VOIDED;
    const isPartial = itineraryStatus === itinerariesPaymentStatuses.PARTIAL_PAID;
    const isInProgress = itineraryStatus === itinerariesPaymentStatuses.IN_PROGRESS;

    const isManager = hasManagerPrivileges(state);
    const isVoidTransactionVisible = isManager && (isInProgress || isPaid || isPartial || isClosed);

    return {
      componentId: "SalonPaidVoidTransaction",
      invoicePaidLabel: "Invoice was paid:",
      isVoidTransactionVisible,
      voidTransactionLabel: "Void Transaction",
      isVoided,
      isLoading: createLoadingSelector([itinerariesActionTypes.UPDATE_ITINERARY_PAYMENT_STATUS])(
        state,
      ),
    };
  },
  dispatch => {
    return {
      onVoidTransactionClick: () =>
        dispatch(showCheckInOutModal({ modalType: modalTypes.VOID_CONFIRMATION })),
    };
  },
  (stateProps, dispatchProps) => {
    const {
      componentId,
      invoicePaidLabel,
      isVoidTransactionVisible,
      voidTransactionLabel,
      isVoided,
      isLoading,
    } = stateProps;

    const { onVoidTransactionClick } = dispatchProps;

    return {
      // state props
      componentId,
      invoicePaidLabel,
      isVoidTransactionVisible,
      voidTransactionLabel,
      isLoading,
      // dispatch props
      onVoidTransactionClick: () => !isVoided && onVoidTransactionClick(),

      style: { paddingLeft: 0, paddingRight: 0 },
      diComp: {
        paidInStore: SalonInvoicePaidInStore,
        paidOnline: SalonInvoicePaidOnline,
      },
    };
  },
)(PaidVoidTransaction);
