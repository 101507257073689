import { connect } from "react-redux";
import { color } from "web/common/styles/theme";
import { isFTCOHidden } from "dux/eligiblePromotions/helpers/isHiddenHelpers";
import { WELCOME_PACKAGE } from "dux/bgm/packageOfferingConstants";
import TextComponent from "dux/_components/genericLayoutComponents/TextComponent";

const mapStateToProps = state => {
  return {
    isHidden: isFTCOHidden(state),
    text: WELCOME_PACKAGE,
    styleType: "div",
    styleObj: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      fontWeight: 800,
      borderBottom: `1px solid ${color.gray300}`,
      padding: "0.5em",
    },
  };
};

export default connect(mapStateToProps)(TextComponent);
