import { errorMessages } from "dux/hotelBookingServiceSelection/__constants__/hotelBookingRoomSelectionConstants";
import { isEqual } from "lodash";

/**
 * A helper function to build an array of select options for room count
 * @param { number } Object.min - minumum value to be included
 * @param { number } Object.max - maximum value to be included
 * @return { array } The array of options
 */
export const getHotelBookingRoomCountOptions = ({ min, max }) =>
  Array.from({ length: max - min + 1 }, (option, index) => index + min);

export const getHotelBookingRoomErrorMessage = ({ isAggressiveBreed, isCatRoomSharingWithDog }) => {
  if (isAggressiveBreed) return errorMessages.AGGRESSIVE_BREED;
  if (isCatRoomSharingWithDog) return errorMessages.DOG_CAT_SHARING;
};

/**
 * A helper function to turn the rooms object into a sorted array
 * @param {{ [roomId: number]: string[]}} rooms
 * @return {Array} The sorted array of options
 */
export const sortRooms = (rooms = {}) =>
  Object.values(rooms)
    ?.map(room => room?.sort())
    ?.sort();

/**
 * A helper function to determine if 2 rooms objects contain the same pet configuration
 * Note: doesn't not take roomId into account, equality is based on whether the same pets are/aren't sharing a room
 * @param {{ [roomId: number]: string[]}} originalRooms
 * @param {{ [roomId: number]: string[]}} pendingRooms
 * @return {Boolean} true if same pets are/aren't sharing rooms within both objs
 */
export const isRoomConfigsEqual = (originalRooms, pendingRooms) => {
  const sortedOriginal = sortRooms(originalRooms);
  const sortedPending = sortRooms(pendingRooms);

  return isEqual(sortedOriginal, sortedPending);
};
