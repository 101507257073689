import React from "react";
import styled from "styled-components";
import { color } from "../common/styles/theme";

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 25px;
  min-width: 80px;
  cursor: pointer;
  text-align: center;
  background-color: ${props =>
    props.isSelected ? (props.isLoading ? color.gray100 : color.red700) : color.white0};
  border: 1px solid ${color.white0};

  pointer-events: ${props => (props.isLoading ? "none" : "auto")};

  &:hover {
    border: 1px solid ${color.blue500};
  }
`;
const Day = styled.div`
  text-transform: ${props => (props.isUpperCase ? "uppercase" : "initial")};
  color: ${props =>
    props.isSelected ? color.white0 : props.isLoading ? color.gray100 : color.gray800};
`;

/**
 * A Common UI Component that will display a formatted date e.g SUN 23. If the date is selected this
 * component will display with a red box around the formatted date.
 *
 * @export DaySelection
 * @param {Object} obj - Destructuring parameters
 * @param {String} obj.formatedDate
 * @param {Boolean} obj.isSelected
 * @param {Boolean} obj.isUpperCase - Optional if you want all text to be uppercase
 * @param {Function} obj.clickHandler
 * @returns - JSX
 */
export default function DaySelection({
  formatedDate,
  isSelected,
  isLoading,
  clickHandler,
  isUpperCase,
  componentId,
}) {
  const day = formatedDate.replace(/\s/g, "");
  return (
    <SectionWrapper
      id={`${componentId}--${day}`}
      isLoading={isLoading}
      isSelected={isSelected}
      onClick={clickHandler}
    >
      <Day
        id={`${componentId}__day--${day}`}
        isLoading={isLoading}
        isSelected={isSelected}
        isUpperCase={isUpperCase}
      >
        {formatedDate}
      </Day>
    </SectionWrapper>
  );
}
