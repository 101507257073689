import React from "react";
import styled from "styled-components";
import SVGArrow from "@/web/common/SVGArrow";
import { color } from "@/web/common/styles/theme";

const Dropdown = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  cursor: pointer;
  ${({ isMenuOpen }) =>
    isMenuOpen &&
    `
  padding-bottom: 0.5em;
`}
`;

const DropdownText = styled.div`
  color: ${color.blue500};
`;

export default function DropdownPanelComponent({
  isHidden,
  componentID,
  onClick,
  text,
  isMenuOpen,
}) {
  return (
    !isHidden && (
      <Dropdown id={componentID} onClick={onClick} isMenuOpen={isMenuOpen}>
        <DropdownText>{text}</DropdownText>
        <SVGArrow color={color.blue500} direction={isMenuOpen ? "down" : "right"} />
      </Dropdown>
    )
  );
}
