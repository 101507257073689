/* -------------------------------------------------------------------------------------------------
  NOTE: a value of true for isHidden means the feature flag is not active/on - all features related
  to that feature flag should be off.
 ------------------------------------------------------------------------------------------------ */
import { isCurrentEnvironment } from "@/core/utils/envUtils";
import * as workflowFeatureTypes from "./workflowFeatureTypes";

const workflowFeatureFlags = {
  [workflowFeatureTypes.BGM]: {
    id: workflowFeatureTypes.BGM,
    isHidden: false,
    requirements: [],
  },
  [workflowFeatureTypes.HOTEL]: {
    id: workflowFeatureTypes.HOTEL,
    isHidden: false,
    requirements: [],
  },
  [workflowFeatureTypes.TRAINING]: {
    id: workflowFeatureTypes.TRAINING,
    isHidden: false,
    requirements: [],
  },
  [workflowFeatureTypes.FTCO]: {
    id: workflowFeatureTypes.FTCO,
    isHidden: false,
    requirements: [],
  },
  [workflowFeatureTypes.MANUAL_APPLY]: {
    id: workflowFeatureTypes.MANUAL_APPLY,
    isHidden: true,
    requirements: [],
  },
  [workflowFeatureTypes.ENHANCED_SERVICES]: {
    id: workflowFeatureTypes.ENHANCED_SERVICES,
    isHidden: false,
    requirements: [],
  },
  [workflowFeatureTypes.ENHANCED_SERVICES_BULK_PACKAGES]: {
    id: workflowFeatureTypes.ENHANCED_SERVICES_BULK_PACKAGES,
    isHidden: false,
    requirements: [],
  },
  [workflowFeatureTypes.CONTROLLED_OVERBOOK]: {
    id: workflowFeatureTypes.CONTROLLED_OVERBOOK,
    isHidden: false,
    requirements: [],
  },
  [workflowFeatureTypes.CAT_GROOMING]: {
    id: workflowFeatureTypes.CAT_GROOMING,
    isHidden: false,
    requirements: [],
  },
  [workflowFeatureTypes.STORE_EVENT_NOTIFICATIONS]: {
    id: workflowFeatureTypes.STORE_EVENT_NOTIFICATIONS,
    isHidden: false,
    requirements: [],
  },
  [workflowFeatureTypes.SEARCH_FIELD_MAIN_V2]: {
    id: workflowFeatureTypes.SEARCH_FIELD_MAIN_V2,
    isHidden: false,
    requirements: [],
  },
  [workflowFeatureTypes.STAND_ALONE_BULK_PACKAGES]: {
    id: workflowFeatureTypes.STAND_ALONE_BULK_PACKAGES,
    isHidden: isCurrentEnvironment("prod") || isCurrentEnvironment("stage"),
    requirements: [],
  },
  [workflowFeatureTypes.HOTEL_ADMIN]: {
    id: workflowFeatureTypes.HOTEL_ADMIN,
    isHidden: false,
    requirements: [],
  },
  [workflowFeatureTypes.REPORTS]: {
    id: workflowFeatureTypes.REPORTS,
    isHidden: false,
    requirements: [],
  },
  [workflowFeatureTypes.DISCONTINUED_FOODS]: {
    id: workflowFeatureTypes.DISCONTINUED_FOODS,
    isHidden: false,
    requirements: [],
  },
  [workflowFeatureTypes.DISCONTINUED_MEDS]: {
    id: workflowFeatureTypes.DISCONTINUED_MEDS,
    isHidden: false,
    requirements: [],
  },
  [workflowFeatureTypes.DDC_PACKAGES]: {
    id: workflowFeatureTypes.DDC_PACKAGES,
    isHidden: isCurrentEnvironment("prod") || isCurrentEnvironment("stage"),
    requirements: [],
  },
  [workflowFeatureTypes.SALON_CART_MIGRATION]: {
    id: workflowFeatureTypes.SALON_CART_MIGRATION,
    isHidden:
      isCurrentEnvironment("prod") || isCurrentEnvironment("stage") || isCurrentEnvironment("qa"),
    requirements: [],
  },
  [workflowFeatureTypes.UPDATE_ROOM_SHARING]: {
    id: workflowFeatureTypes.UPDATE_ROOM_SHARING,
    isHidden: false,
    requirements: [],
  },
};

export default workflowFeatureFlags;
