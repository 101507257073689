import searchSalonActionTypes from "../actionTypes/searchSalonActionTypes";

export const showSearchResults = ({ showSearchResults }) => ({
  type: searchSalonActionTypes.SHOW_SEARCH_RESULTS,
  showSearchResults
});

export const setSearchField = ({ searchFieldValue }) => ({
  type: searchSalonActionTypes.SET_SEARCH_FIELD,
  searchFieldValue
});

export const clearSearchFieldValue = () => ({
  type: searchSalonActionTypes.CLEAR_SEARCH_FIELD_VALUE
});

export const setSearchFieldType = ({ searchFieldType }) => ({
  type: searchSalonActionTypes.SET_SEARCH_FIELD_TYPE,
  searchFieldType
});

export const setSearchErrorMessage = message => ({
  type: searchSalonActionTypes.SET_SEARCH_ERROR_MESSAGE,
  message
});

export const clearSearchErrorMessage = () => ({
  type: searchSalonActionTypes.CLEAR_SEARCH_ERROR_MESSAGE
});

export const openSearchErrorFlyout = () => ({
  type: searchSalonActionTypes.OPEN_SEARCH_ERROR_FLYOUT
});

export const closeSearchErrorFlyout = () => ({
  type: searchSalonActionTypes.CLOSE_SEARCH_ERROR_FLYOUT
});

export const setSearchFieldActive = () => ({
  type: searchSalonActionTypes.SET_SEARCH_FIELD_ACTIVE
});

export const clearSearchFieldActive = () => ({
  type: searchSalonActionTypes.CLEAR_SEARCH_FIELD_ACTIVE
});

export const setSearchComponentName = ({ searchComponentName }) => ({
  type: searchSalonActionTypes.SET_SEARCH_COMPONENT_NAME,
  searchComponentName
});

export const setSearchApplied = () => ({
  type: searchSalonActionTypes.SET_SEARCH_APPLIED
});

export const clearSearchApplied = () => ({
  type: searchSalonActionTypes.CLEAR_SEARCH_APPLIED
});

export const setPrevSearchComponentName = ({ prevSearchComponentName }) => ({
  type: searchSalonActionTypes.SET_PREVIOUS_SEARCH_COMPONENT_NAME,
  prevSearchComponentName
});

export default {
  showSearchResults,
  setSearchField,
  clearSearchFieldValue,
  setSearchFieldType,
  setSearchErrorMessage,
  clearSearchErrorMessage,
  openSearchErrorFlyout,
  closeSearchErrorFlyout,
  setSearchFieldActive,
  clearSearchFieldActive,
  setSearchComponentName,
  setSearchApplied,
  clearSearchApplied,
  setPrevSearchComponentName
};
