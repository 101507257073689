import React from "react";
import styled, { css } from "styled-components";
import { fontSizes } from "web/common/styles/responsive/fonts";
import { color } from "./styles/theme";
import Img from "./Img";
import ActiveChecked from "../../assets/icons/active-selected-appointment.svg";
import InactiveChecked from "../../assets/icons/selected-appointment.svg";

const Section = styled.div`
  display: flex;
  opacity: ${props => (props.disabled ? "0.5" : "auto")};
  pointer-events: ${props => (props.disabled ? "none" : "auto")};
`;

const StyledTab = styled.div`
  cursor: pointer;
  font-family: Montserrat;
  font-size: ${fontSizes.big};
  font-weight: bold;
  border-bottom: ${({ isActive }) => (isActive ? `0.0625rem solid ${color.blue500}` : "none")};
  color: ${({ isActive }) => (isActive ? `${color.blue500}` : "inherit")};
  display: ${props => (props.show ? "auto" : "none")};
  align-self: center;
  opacity: ${props => (props.isPetActive ? `0.5` : "auto")};
`;

const IconContainer = css`
  width: 20px;
  height: 20px;
  padding-left: 2px;
  align-self: center;
`;

const Icon = styled(Img)`
  ${IconContainer};
`;

const Tab = ({
  componentId,
  selected,
  onAddPet,
  disabled,
  title,
  isActive,
  onClick,
  isPetActive,
  show = true,
}) => (
  <Section id={componentId} className="Tab" disabled={disabled}>
    <StyledTab
      id={`${componentId}-${title}`}
      className="StyledTab"
      onClick={onClick}
      isPetActive={isPetActive}
      isActive={isActive}
      show={show}
    >
      {title}
    </StyledTab>
    {/* eslint-disable-next-line no-nested-ternary */}
    {!onAddPet && isActive && selected ? (
      <Icon src={ActiveChecked} />
    ) : !onAddPet && selected ? (
      <Icon src={InactiveChecked} />
    ) : null}
  </Section>
);

export default Tab;
