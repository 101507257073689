import React from "react";
import styled from "styled-components";
import Img from "../Img";
import InitialsCircle from "../../common/InitialsCircle";
import { font, color } from "../styles/theme";

const AssociateRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  color: ${props => (props.isSelected ? color.blue400 : "auto")};
  font-family: ${font.mainFamily};
  &:hover {
    color: ${color.blue400};
  }
`;

const AssociateName = styled.div`
  font-weight: bold;
`;

const AssociateRole = styled.div`
  font-size: 12px;
  margin: 0 0.5em;
  &:after {
    content: ${props => (props.isSelected ? '"\\2713"' : '""')};
    display: inline-block;
    margin: 0 0 0 5px;
  }
`;

const AssociateDropdownRow = ({ name, role, onClick, isSelected }) => (
  <AssociateRow onClick={onClick} isSelected={isSelected}>
    <InitialsCircle displayName={name && name.trim()} margin="0.75em 1.25em 0.75em 1.25em" />
    <AssociateName>{name}</AssociateName>
    <AssociateRole isSelected={isSelected}>{role}</AssociateRole>
  </AssociateRow>
);

export default AssociateDropdownRow;
