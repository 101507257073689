// Utils
import { formatDateWithTimeZoneInUTC } from "@/core/utils/dateUtils/formatDateTime";
import { setTimeForDate } from "@/core/utils/dateUtils/byDateAndTime";
import moment from "moment";

/**
 * @function checkIsRoomSelectionDisabled
 * @param {boolean} isSRCAgent
 * @param {boolean} isReservationDisabled
 * @param {boolean} isEngagementStartDateSameOrBeforeToday
 * @param {boolean} allEngagementServiceIdsMatch
 * @param {boolean} isReservationDatesInPastAndNoRoom
 * @param {boolean} isCheckOutInPast
 * @returns boolean
 */
export const checkIsRoomSelectionDisabled = ({
  isSRCAgent,
  isReservationDisabled,
  isEngagementStartDateSameOrBeforeToday,
  allEngagementServiceIdsMatch,
  isReservationDatesInPastAndNoRoom,
  isCheckOutInPast,
}) => {
  return (
    isSRCAgent ||
    isReservationDisabled ||
    !isEngagementStartDateSameOrBeforeToday ||
    !allEngagementServiceIdsMatch ||
    (!isReservationDatesInPastAndNoRoom && isCheckOutInPast)
  );
};

/**
 * Helper to set the given dates time to 12am and format in UTC
 * @function
 * @name formatStartForAPI
 * @param {String} start - date
 * @param {Number} timeZone - time zone for current store
 * @returns {string}
 */
export const formatStartForAPI = (start, timeZone = "UTC") => {
  // Get rooms available for current date from 12am
  const midnight = moment("12:00 AM", "HH:mm a");
  return formatDateWithTimeZoneInUTC(setTimeForDate(start, midnight, timeZone), timeZone);
};
