import { compose, get, getOr, omit, uniqBy } from "lodash/fp";
import { createSelector } from "reselect";
import { v4 as uuidv4 } from "uuid";
import getMedicationState from "./_medicationState";
import { getPet } from "../../core/selectors/entitiesSelector";
import { NEW_MEDICATION_ID, medicationTypes } from "../newMedication/newMedicationConstants";
import { reduceFoodOrMedHistory } from "core/utils/petUtils/petFoodAndMedUtils";
import { getCheckInOutPetMeds } from "@/dux/_components/checkInOutMedications/CheckInOutCartMedsSelectors";
import { frequencyConstants } from "@/dux/frequency/FrequencyConstants";
import isEmpty from "lodash/isEmpty";
import {
  selectMedicationOptions,
  selectMedicationsList,
} from "@/dux/medications/medicationsSelectors";
import selectIsDiscontinuedMedsFeatureHidden from "../enableDisableWorkflowFeatureFlag/selectors/discontinuedMeds/getIsDiscontinuedMedsWorkflowFeatureFlagHidden";
import {
  getHotelEngagements,
  getHotelEngagementsPets,
} from "@/dux/hotelEngagements/hotelEngagementSelectors";
import { getProps } from "@/core/selectors/commonSelector";
import flattenArray from "@/core/utils/arrayUtils/flattenArray";
import { convertTimeOfDayToObj } from "@/core/utils/checkInOutUtils";
import { selectDefaultFrequency } from "@/dux/frequency/dateRangeForFrequencySelectors";
import { isRestrictedOrFollowUpMed } from "../medicationName/medicationNameHelpers";

/**
 * Helper to get med from list by external id
 * @function
 * @name getExternalMedById
 * @param {Object} med
 * @param {String} med.externalId
 * @param {Object[]} medList
 * @returns {Object} med data with corresponding MedicationId
 */
export const getExternalMedById = ({ externalId, medicationId } = {}, medList) => {
  const id = externalId ?? medicationId;
  return medList?.find(({ MedicationId }) => MedicationId === id);
};

export const getMedications = createSelector([getMedicationState], medicationState =>
  getOr({}, "medications", medicationState),
);

export const getMedicationsByPet = createSelector(
  [getMedications, (state, props) => props],
  (medications, { petId }) => {
    return get(petId, medications);
  },
);

export const getMedsByPetExcludingNewMedID = createSelector([getMedicationsByPet], meds => {
  return omit(NEW_MEDICATION_ID, meds);
});

export const getMedsExcludingNewMedId = createSelector([getMedications], meds =>
  Object.entries(meds)?.reduce(
    (nonNewMeds, [petId, petMeds]) => ({
      ...nonNewMeds,
      [petId]: omit(NEW_MEDICATION_ID, petMeds),
    }),
    {},
  ),
);

export const getMedicationById = createSelector(
  [getMedicationsByPet, (state, props) => props],
  (medications, { medicationId }) => {
    return get(medicationId, medications);
  },
);

export const getPetMedications = createSelector([getPet], pet => {
  return (pet && pet.medications) || [];
});

export const getPetMedicationById = createSelector(
  [getPetMedications, (state, props) => props],
  (medications, { medicationId }) => {
    return medications.find(
      med => med.petMedicationId && med.petMedicationId.toString() === medicationId,
    );
  },
);

export const getMedicationIds = createSelector([getMedicationsByPet], medications => {
  return medications
    ? Object.keys(medications)?.filter(medication => medication !== NEW_MEDICATION_ID)
    : [];
});

export const getPetMedicationIds = createSelector([getPetMedications], medications => {
  return medications.map(medication => medication.petMedicationId);
});

/**
 * Given Pet meds, Selects the med history.
 *
 *  @memberOf Selectors.medications
 *  @function
 *  @name selectHotelMedHistory
 * @param {function} getFoodState - The selector function that returns the med state.
 * @returns {Array} - The med history .
 */
export const selectHotelMedHistory = createSelector([getMedications], hotelMeds => {
  return reduceFoodOrMedHistory(hotelMeds);
});

/**
 * Selector to get all pet meds from itinerary and format them for booking
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectPetMedsForRebooking
 * @param {Object} state
 * @param {Object} props
 * @param {String} props.petId
 * @returns {Array} pet meds
 * @example selectPetMedsForRebooking(state, { petId })
 */
export const selectPetMedsForRebooking = createSelector([getCheckInOutPetMeds], (meds = []) => {
  return meds?.map(med => {
    const isManual = med?.frequency === frequencyConstants.MANUAL;
    return {
      petMedicationId: med?.recordId,
      externalId: med?.medicationId,
      name: med?.medicationName,
      amount: med?.amount,
      frequency: med?.frequency,
      timeOfDay: med?.timeOfDay,
      specialInstructions: med?.splInstruction,
      startDate: med?.startDatetime,
      endDate: med?.endDatetime,
      locationTypes: isManual ? [medicationTypes.BOOKING] : [],
      frequencyTouched: isManual,
    };
  });
});

/**
 * Selector to get pet profile meds filtered by IsActive attr from meds list
 * @memberOf Selectors.Entities
 * @function
 * @name selectActivePetProfileMeds
 * @param {Object} state
 * @param {Object} props
 * @param {String} props.petId
 * @returns {Array} pet meds from profile where IsActive is true
 * @example selectActivePetProfileMeds(state, { petId })
 */
export const selectActivePetProfileMeds = createSelector(
  [getPetMedications, selectMedicationsList],
  (meds, medList) => {
    return meds?.filter(med => getExternalMedById(med, medList)?.IsActive);
  },
);

/**
 * Selector to filter out discontinued meds from itinerary for rebooking
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectActivePetMedsForRebooking
 * @param {Object} state
 * @param {Object} props
 * @param {String} props.petId
 * @returns {Array} pet meds
 * @example selectActivePetMedsForRebooking(state, { petId })
 */
export const selectActivePetMedsForRebooking = createSelector(
  [selectPetMedsForRebooking, selectMedicationsList],
  (meds, medList) => {
    return meds?.filter(med => getExternalMedById(med, medList)?.IsActive);
  },
);

/**
 * Selector to get initial meds for a pet in the hotel booking flow
 * @function
 * @name selectPetMedsForBooking
 * @param {Object} state
 * @param {Object} props
 * @param {String} props.petId
 * @param {String} props.isRebooking - if true then use meds from itinerary else use pet profile meds
 * @returns {Array} pet meds formatted for booking flow
 * @example selectPetMedsForBooking(state, { petId, isRebooking })
 */
export const selectPetMedsForBooking = createSelector(
  [selectActivePetProfileMeds, selectActivePetMedsForRebooking, (state, props) => props],
  (petProfileMeds, itineraryMeds, { isRebooking } = {}) => {
    const meds = isRebooking ? itineraryMeds : petProfileMeds;

    return meds?.map(petMed => ({
      ...petMed,
      petProfileMedId: !isRebooking ? petMed?.petMedicationId : undefined,
      saveToProfile: false,
      groupingId: uuidv4(),
      frequency: petMed?.frequency ?? frequencyConstants.DAILY,
    }));
  },
);

/**
 * Selector to format pet profile meds for check in out med form
 * @function
 * @name selectPetProfileMedsForPostBooking
 * @param {Object} state
 * @param {Object} props
 * @param {String} props.petId
 * @returns {Boolean}
 * @example selectPetProfileMedsForPostBooking(state, { petId })
 */
export const selectPetProfileMedsForPostBooking = createSelector(
  [selectActivePetProfileMeds, selectMedicationOptions, selectDefaultFrequency],
  (meds, medOptions, frequency) => {
    return meds?.reduce((medObj, med) => {
      const timeOfDayObj = convertTimeOfDayToObj(med?.timeOfDay);
      const externalMed = medOptions[med?.externalId];
      const isOther = externalMed?.Description?.toLowerCase() === "other";

      // Format the med name to include follow up or restricted label if needed
      const { label } = isRestrictedOrFollowUpMed(externalMed);

      return {
        ...medObj,
        [med?.petMedicationId]: {
          petProfileMedId: med?.petMedicationId,
          externalId: med?.externalId,
          medDisplayName: label,
          medName: externalMed?.Description ?? med?.name,
          otherMedName: isOther ? med?.name : "",
          medAmount: med?.amount,
          medInstructions: med?.specialInstructions,
          frequency,
          ...timeOfDayObj,
        },
      };
    }, {});
  },
);

/**
 * Selector to check if any meds for a given pet are missing dates for manual frequency
 * @function
 * @name selectPetMedsIsMissingDates
 * @param {Object} state
 * @param {Object} props
 * @param {String} props.petId
 * @returns {Boolean}
 * @example selectPetMedsIsMissingDates(state, { petId })
 */
export const selectPetMedsIsMissingDates = createSelector(
  [getMedsByPetExcludingNewMedID],
  (meds = {}) => {
    return !!Object.values(meds)?.some(
      ({ frequency, concreteSchedule }) =>
        frequency === frequencyConstants.MANUAL && isEmpty(concreteSchedule),
    );
  },
);

/**
 * Selector to get med from options by external id
 * @function
 * @name getMedExternalDataById
 * @param {Object} state
 * @param {Object} props
 * @param {String} props.petId
 * @param {String} props.medicationId
 * @returns {Object}
 * @example getMedExternalDataById(state, { petId, medicationId })
 */
export const getMedExternalDataById = createSelector(
  [getMedicationById, selectMedicationsList],
  getExternalMedById,
);

/**
 * Selector to check if given med is discontinued (IsActive is false)
 * Note: if the discontinued med feature flag is off then, this will return false
 * @function
 * @name getMedIsDiscontinuedById
 * @param {Object} state
 * @param {Object} props
 * @param {String} props.petId
 * @param {String} props.medicationId
 * @returns {Boolean}
 * @example getMedIsDiscontinuedById(state, { petId, medicationId })
 */
export const getMedIsDiscontinuedById = createSelector(
  [getMedExternalDataById, selectIsDiscontinuedMedsFeatureHidden],
  (med, isDiscontinuedMedsFeatureOff) => {
    if (isDiscontinuedMedsFeatureOff) return false;

    return med?.IsActive === false;
  },
);

/**
 * Selector to get discontinued meds from itinerary by pet id
 * @function
 * @name selectItineraryDiscontinuedMedsByPet
 * @param {Object} state
 * @param {Object} props
 * @param {String} props.petId
 * @returns {Object[]}
 */
export const selectItineraryDiscontinuedMedsByPet = createSelector(
  [getCheckInOutPetMeds, selectMedicationsList],
  (meds, medList) =>
    meds?.filter(med => getExternalMedById(med, medList)?.IsActive === false) ?? [],
);

/**
 * Selector to get slice of state needed for getting all discontinued meds from an itinerary
 * @function
 * @name selectEngagementAndMedOptionsSlice
 * @param {Object} state
 * @returns {Object}
 */
export const selectEngagementAndMedOptionsSlice = createSelector(
  [getHotelEngagements, selectMedicationOptions],
  (engagements, medicationList) => ({ hotelEngagements: { engagements }, medicationList }),
);

/**
 * Helper to format meds for not available modal
 * @function
 * @name selectItineraryDiscontinuedMedsByPet
 * @param {Object[]} med
 * @returns {{ id: string|number, name: string }[]}
 */
export const formatMedForNotAvailableModal = meds =>
  meds?.map(({ medicationId, medicationName }) => ({ id: medicationId, name: medicationName })) ??
  [];

/**
 * Selector to get all discontinued meds from an itinerary for the given pets
 * If no petIds prop then defaults to all pets on itinerary
 * @function
 * @name selectEngagementAndMedOptionsSlice
 * @param {Object} state
 * @param {Object} props
 * @param {Array?} props.petIds
 * @returns {Object}
 */
export const selectItineraryDiscontinuedMeds = createSelector(
  [
    selectEngagementAndMedOptionsSlice,
    getHotelEngagementsPets,
    getProps,
    selectIsDiscontinuedMedsFeatureHidden,
  ],
  (stateSlice, itineraryPets, { petIds }, isDiscontinuedMedsFeatureOff) => {
    if (isDiscontinuedMedsFeatureOff) return [];

    const petsToCheck = petIds ?? itineraryPets;
    const petMeds =
      petsToCheck?.map(petId => selectItineraryDiscontinuedMedsByPet(stateSlice, { petId })) ?? [];

    return compose(uniqBy("id"), formatMedForNotAvailableModal, flattenArray)(petMeds);
  },
);
