/*
  This file contains all the common styled components
  to layout the check-in check-out single & multi modals.
============================================================ */

import { fontSizes } from "web/common/styles/responsive/fonts";
import { color, font } from "../../common/styles/theme";
import styled from "styled-components";

export const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ModalHeader = styled.h3`
  font-family: ${font.secondaryFamily};
  font-size: ${fontSizes.regular};
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: center;
  color: ${color.gray800};
`;

export const ModalCopy = styled.p`
  font-family: ${font.mainFamily};
  font-size: ${fontSizes.regular};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: ${color.gray800};
`;

export const PetImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 1.5rem;
  }
`;

export const PetImage = styled.img`
  width: 82px;
  height: 82px;
  border-radius: 3.6px;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;

  > *:not(:last-child) {
    margin-right: 1rem;
  }
`;

export const MockButton = styled.div`
  background-color: #1777f2;
  padding: 10px 25px;
  display: block;
  color: white;
  text-align: center;
  max-width: 150px;
  cursor: pointer;

  &:hover {
    background-color: grey;
  }
`;
