import React, { Component } from "react";
import styled from "styled-components";
import CommonButton from "../../../commonButton";
import { color } from "../../../styles/theme";
import CommonModal from "../../commonModal";
import { CONFIRMATION_MODAL_TYPE } from "../../../../../core/constants/modalConstants";

class ConfirmationModal extends Component {
  handleConfirmClick = () => {
    const { confirm, onClose, closeBeforeConfirm = false } = this.props;

    if (closeBeforeConfirm) onClose();
    confirm && confirm();
    if (!closeBeforeConfirm) onClose();
  };

  handleCancelClick = () => {
    const { cancel, onClose } = this.props;

    cancel && cancel();
    onClose();
  };

  render() {
    const { modalType, header, content, confirmText, cancelText } = this.props;

    return (
      <CommonModal onClose={this.handleCancelClick}>
        <Container>
          <Header>{header}</Header>

          <Content>{content}</Content>

          <Footer modalType={modalType}>
            {modalType === CONFIRMATION_MODAL_TYPE.CONFIRM && (
              <CommonButton
                inverted
                label={cancelText}
                color={color.blue500}
                onClick={this.handleCancelClick}
              />
            )}
            <CommonButton label={confirmText} onClick={this.handleConfirmClick} />
          </Footer>
        </Container>
      </CommonModal>
    );
  }
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;

export const Header = styled.div`
  font-weight: bold;
`;

export const Content = styled.div`
  text-align: center;
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: ${({ modalType }) =>
    modalType === CONFIRMATION_MODAL_TYPE.CONFIRM ? "space-between" : "center"};
  align-items: center;
  position: absolute;
  bottom: 0;
  padding: 16px;
`;

export default ConfirmationModal;
