import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { EditableSection, Button, ButtonGroup, SparkyTextWrapper } from "@petsmart-ui/sparky";
import { Layout } from "@prism/psm-ui-components";
import {
  selectHotelBookingCanNavigate,
  selectHotelBookingEndDate,
  selectHotelBookingFurthestStep,
  selectHotelBookingIsActive,
  selectHotelBookingPetList,
  selectPetsWithoutService,
} from "@/web/features/hotelBookingFlow/hotelBookingFlowSelectors";
import { HotelSinglePetBookingServiceOptions } from "dux/hotelBookingServiceSelection/HotelBookingServiceOptions";
import { HotelBookingRoomServiceSelection } from "dux/hotelBookingServiceSelection/HotelBookingRoomServiceSelection";
import {
  setHotelBookingCurrentServiceSelectionRoom,
  setHotelBookingFlowPetService,
  setHotelBookingOpenStep,
} from "@/web/features/hotelBookingFlow/hotelBookingFlowActions";
import { HotelBookingServiceSectionAlerts } from "dux/hotelBookingAlerts/HotelBookingSelectServiceAlerts";
import { postHotelEligibility } from "dux/hotelEligibility/hotelEligibilityActions";
import { selectPetServiceTypesFromAppointmentServicesForPets } from "web/pendingAppointment/selectors/pendingAppointmentSelectors";
import { eligibilityTypes } from "dux/eligibility/constants/eligibilityConstants";
import {
  LOAD_PET_SERVICES_AT_HOTEL_BOOKING,
  loadPetServicesAtHotelBooking,
} from "@/web/services/hotelServices/actions/petServicesAtHotel";
import { selectRebookingPetServices } from "../hotelEngagements/hotelEngagementSelectors";
import { createLoadingSelector } from "@/core/selectors/utils";
import { selectIsRebookingItinerary } from "../hotelItinerary/hotelItinerarySelectors";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { compose } from "redux";
import { selectPetServicesForMergingRooms } from "../_components/checkInOutRooms/checkInOutMergeRoomsSelectors";

const HotelBookingServiceSelectionComponent = ({
  componentID,
  canNavigate,
  isActive,
  onClick,
  diComp,
  disabledContinue,
  clearCurrentServiceSelectionRoom,
  stepIndex,
  loadHotelEligibility = () => {},
  furthestStep,
  descriptionText = "",
  getPetServices = () => {},
  hasNextButton = true,
  isHidden,
  isLoading,
  selectServicesIfReBooking,
}) => {
  // Know if we need to show alerts or not.
  const [continueClicked, setContinueClicked] = useState(false);

  useEffect(() => {
    // When navigating back to this section make sure the
    // service selection for a specific room is not open
    // and alerts are not showing
    if (isActive) {
      clearCurrentServiceSelectionRoom();
      setContinueClicked(false);
    }

    // Call pet services if the step is active and if we haven't continued past selecting services before.
    if (isActive && furthestStep <= stepIndex) getPetServices();
  }, [isActive]);

  useEffect(() => {
    // Once get pet services call is done loading, select services from itinerary if rebooking
    if (!isLoading && isActive) {
      selectServicesIfReBooking();
    }
  }, [isLoading, isActive]);

  const ServiceSelectionComponent = diComp(continueClicked);

  const description = () => {
    if (isActive || !canNavigate) return;
    return <SparkyTextWrapper truncateOn={1}>{descriptionText}</SparkyTextWrapper>;
  };

  if (isHidden) return null;

  return (
    <EditableSection
      buttonText={canNavigate && "Edit"}
      isActive={isActive}
      onClick={onClick}
      title="Select a Service"
      description={description()}
    >
      <Layout.Box id={componentID}>
        <ServiceSelectionComponent stepIndex={stepIndex} />
        {!continueClicked && hasNextButton && (
          <ButtonGroup align="right">
            <Button
              variant="primary"
              onClick={() => {
                loadHotelEligibility();
                setContinueClicked(true);
              }}
              text="Next"
              disabled={disabledContinue}
            />
          </ButtonGroup>
        )}
      </Layout.Box>
    </EditableSection>
  );
};

export const HotelBookingServiceSelection = compose(
  withRouteProps,
  connect(
    (state, { stepIndex, router }) => {
      const selectedPetIds = selectHotelBookingPetList(state);
      const isMultiPet = selectedPetIds?.length > 1;

      // Determine whether to show multi pet or single pet component
      const ServiceComponent = isMultiPet
        ? HotelBookingRoomServiceSelection
        : HotelSinglePetBookingServiceOptions;
      const itineraryId = router?.params?.itineraryId;

      return {
        componentID: "hotelBookingFlow-serviceSelection",
        canNavigate: selectHotelBookingCanNavigate(stepIndex)(state),
        isActive: selectHotelBookingIsActive(stepIndex)(state),
        disabledContinue: selectPetsWithoutService(state),
        petsAndServiceTypes: selectPetServiceTypesFromAppointmentServicesForPets({
          state,
          petList: selectedPetIds,
        }),
        diComp: continueClicked =>
          continueClicked ? HotelBookingServiceSectionAlerts : ServiceComponent,
        stepIndex,
        furthestStep: selectHotelBookingFurthestStep(state),
        checkOutDateTime: selectHotelBookingEndDate(state),
        isRebooking: selectIsRebookingItinerary(state, { itineraryId }),
        rebookingServices: selectRebookingPetServices(state),
        isLoading: createLoadingSelector([LOAD_PET_SERVICES_AT_HOTEL_BOOKING])(state),
      };
    },
    dispatch => {
      return {
        dispatchSetHotelBookingCurrentServiceSelectionRoom: roomId =>
          dispatch(setHotelBookingCurrentServiceSelectionRoom({ roomId })),
        dispatchGetHotelEligibility: ({ petsAndServiceTypes, checkOutDateTime }) => {
          dispatch(
            postHotelEligibility({
              eligibilityType: eligibilityTypes.BOOKING,
              payload: { petsAndServiceTypes, checkOutDateTime },
            }),
          );
        },
        setStep: step => dispatch(setHotelBookingOpenStep(step)),
        dispatchLoadPetServicesAtHotel: petId => dispatch(loadPetServicesAtHotelBooking(petId)),
        selectServiceForPet: ({ petServiceId, roomTypeBucketId, petId }) =>
          dispatch(setHotelBookingFlowPetService({ petServiceId, roomTypeBucketId, petId })),
      };
    },
    (mapProps, dispatchProps) => {
      const {
        componentID,
        canNavigate,
        isActive,
        diComp,
        disabledContinue,
        stepIndex,
        petsAndServiceTypes,
        furthestStep,
        checkOutDateTime,
        rebookingServices,
        isRebooking,
        isLoading,
      } = mapProps;

      const {
        dispatchSetHotelBookingCurrentServiceSelectionRoom,
        dispatchGetHotelEligibility,
        setStep,
        dispatchLoadPetServicesAtHotel,
        selectServiceForPet,
      } = dispatchProps;

      return {
        // control what props get passed to the view
        componentID,
        canNavigate,
        isActive,
        onClick: () => setStep(stepIndex),
        diComp,
        disabledContinue,
        stepIndex,
        descriptionText: petsAndServiceTypes.map(({ serviceType }) => serviceType).join(", "),
        furthestStep,
        clearCurrentServiceSelectionRoom: () =>
          dispatchSetHotelBookingCurrentServiceSelectionRoom(),
        loadHotelEligibility: () =>
          dispatchGetHotelEligibility({ petsAndServiceTypes, checkOutDateTime }),
        getPetServices: () => dispatchLoadPetServicesAtHotel(),
        isLoading,
        selectServicesIfReBooking: () => {
          if (!isRebooking) return;
          Object.entries(
            rebookingServices,
          ).map(([petId, { petServiceId, roomTypeBucketId } = {}]) =>
            selectServiceForPet({ petServiceId, roomTypeBucketId, petId }),
          );
        },
      };
    },
  ),
)(HotelBookingServiceSelectionComponent);

export const HotelCheckInOutRoomsServiceSelection = connect(
  (state, { stepIndex }) => {
    const isActive = selectHotelBookingIsActive(stepIndex)(state);
    return {
      componentID: "HotelCheckInOutRoomsServiceSelection",
      canNavigate: selectHotelBookingCanNavigate(stepIndex)(state),
      isActive,
      disabledContinue: selectPetsWithoutService(state),
      diComp: () => HotelBookingRoomServiceSelection,
      furthestStep: selectHotelBookingFurthestStep(state),
      hasNextButton: false,
      isHidden: !isActive,
      services: selectPetServicesForMergingRooms(state),
      isLoading: createLoadingSelector([LOAD_PET_SERVICES_AT_HOTEL_BOOKING])(state),
    };
  },
  (dispatch, { stepIndex }) => {
    return {
      clearCurrentServiceSelectionRoom: () =>
        dispatch(setHotelBookingCurrentServiceSelectionRoom({ roomId: undefined })),
      onClick: () => dispatch(setHotelBookingOpenStep(stepIndex)),
      getPetServices: () => dispatch(loadPetServicesAtHotelBooking()),
      selectServiceForPet: ({ petServiceId, roomTypeBucketId, petId }) =>
        dispatch(setHotelBookingFlowPetService({ petServiceId, roomTypeBucketId, petId })),
    };
  },
  (stateProps, dispatchProps, ownProps) => {
    const { services, ...restOfStateProps } = stateProps;
    const { selectServiceForPet, ...restOfDispatchProps } = dispatchProps;

    return {
      ...ownProps,
      ...restOfStateProps,
      ...restOfDispatchProps,
      selectServicesIfReBooking: () => {
        Object.entries(services).map(([petId, { petServiceId, roomTypeBucketId } = {}]) =>
          selectServiceForPet({ petServiceId, roomTypeBucketId, petId }),
        );
      },
    };
  },
)(HotelBookingServiceSelectionComponent);
