import { connect } from "react-redux";
import { history } from "@/dux/utils/browser/browserHistory";
import { color } from "@/web/common/styles/theme";
import { getBundleReservedUnits } from "../availableBundlesByPetSelectors";
import { displayRefundPackageErrorModal } from "@/dux/refundPackageErrorModal/refundPackageErrorModalActions";
import { displayRefundPackageConfirmationModal } from "@/dux/refundPackageConfirmationModal/refundPackageConfirmationModalActions";
import ClickableText from "@/web/common/ClickableText";
import { routePaths } from "@/core/constants/routePaths";
import { hasManagerPrivileges } from "@/core/selectors/persistentSelectors";

const mapStateToProps = (state, { petId, bundleNumber }) => ({
  petId,
  bundleNumber,
  disabled: !hasManagerPrivileges(state),
  isVisible: history.location.pathname.includes(routePaths.PET_PARENT_PROFILE),
  label: "Refund",
  styleObj: {
    color: color.blue500,
    padding: "0px",
  },
  reservedUnits: getBundleReservedUnits(state, { petId, bundleNumber }),
});

const mapDispatchToProps = dispatch => ({
  dispatchOnClick: ({ reservedUnits, petId, bundleNumber }) => {
    if (reservedUnits) {
      dispatch(
        displayRefundPackageErrorModal({
          isHidden: false,
          petId,
          bundleNumber,
        }),
      );
    } else {
      dispatch(
        displayRefundPackageConfirmationModal({
          isHidden: false,
          petId,
          bundleNumber,
        }),
      );
    }
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onClick: () =>
    propsFromDispatch.dispatchOnClick({
      petId: propsFromState.petId,
      bundleNumber: propsFromState.bundleNumber,
      reservedUnits: propsFromState.reservedUnits,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ClickableText);
