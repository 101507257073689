import React from "react";
import styled from "styled-components";
import { color, font } from "web/common/styles/theme";
import { fontSizes } from "web/common/styles/responsive/fonts";
import { APriceListHeading } from "../../../../web/common/styles/abstractStyledComponents";
import { ALPHA_NUMERIC_ONLY_REGEX } from "../../../../core/constants/regex";
import Checkbox from "../../../../web/common/Checkbox";

// ABSTRACT CLASSES FOR THIS COMPONENT (DO NOT USE DIRECTLY, EXTEND)
const ACopy = styled.div`
  font-family: ${font.mainFamily};
  font-size: ${fontSizes.regular};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 25px;
  letter-spacing: normal;
  color: ${color.gray800};
`;

// WRAPPER CLASSES
const SectionWrapper = styled.section`
  padding-bottom: 27px;
  border-bottom: ${props => (props.noBorder ? "none" : `1px solid${color.gray200}`)};
  margin-bottom: 23px;
`;

const PackageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 10px 0;
`;

const PackageNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

// HEADING CLASSES
const SectionHeading = APriceListHeading.extend`
  margin-bottom: 31px;
`;

// OTHER
const PackageName = ACopy.extend`
  margin-left: 15px;
  margin-right: 20px;
`;
const PackagePrice = ACopy.extend``;

/**
 * List A set of Options with Prices and a selection Checkbox - Generally used within the commonSplitPriceList Component.
 * @param {Array} priceList - List of Items for the Price list
 * @param {string} idName - Each price listed item has an ID key value pair, this is the name of the Key
 * @param {Array} selectedOptions - Current set (id Numbers) selected price items
 * @param {String} heading - Price List Heading
 * @param {String} searchFilter - Filter out items in the priceList
 * @param {Function} onSelectionChange - Event handler that requires the id of the price item as a param
 */
export default function CommonPriceList(props) {
  const {
    priceList,
    idProperty,
    nameProperty,
    heading,
    searchFilter,
    onSelectionChange,
    selectedOptions = [],
    disabledOptions = [],
  } = props;

  if (!priceList || !priceList.length) {
    return null;
  }

  const filterList = priceList.filter(
    item =>
      (item.name || item[nameProperty]).toLowerCase().indexOf(searchFilter.toLowerCase()) > -1,
  );

  return (
    <SectionWrapper {...props}>
      <SectionHeading>{heading}</SectionHeading>
      {filterList.map(item => {
        const name = item.name || item[nameProperty];
        return (
          <PackageWrapper key={item[idProperty]}>
            <PackageNameWrapper>
              <Checkbox
                name={name.replace(ALPHA_NUMERIC_ONLY_REGEX, " ")}
                value={item[idProperty]}
                onChange={() => onSelectionChange(item[idProperty])}
                checked={selectedOptions.includes(item[idProperty])}
                disabled={disabledOptions.includes(item[idProperty])}
              />
              <PackageName> {name.replace(ALPHA_NUMERIC_ONLY_REGEX, " ")} </PackageName>
            </PackageNameWrapper>
            <PackagePrice> ${item.price} </PackagePrice>
          </PackageWrapper>
        );
      })}
      {filterList.length === 0 && <div>No items match your search.</div>}
    </SectionWrapper>
  );
}
