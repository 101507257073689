import React from "react";
import moment from "moment";
import styled from "styled-components";
import { get, isEmpty } from "lodash/fp";
import isOverdue from "core/utils/dateUtils/isOverdue";
import getUnknownPetImage from "core/utils/assetUtils/unknownPetImage";
import calculateAge from "core/utils/dateUtils/calculateAge";
import getUSDateString from "core/utils/dateUtils/usDate";
import isLate from "core/utils/dateUtils/isLate";
import { APPOINTMENT_STATUS } from "core/constants";
import PreCheckedInIcon from "dux/digitalServiceCard/DigitalServiceCardPreCheckedIcon";
import Icons from "../icons/iconsContainer";
import GroomerName from "../groomerName/groomerNameContainer";
import { color, font } from "web/common/styles/theme";
import { fontSizes } from "web/common/styles/responsive/fonts";
import Img from "../../common/Img";
import PetCardCustomerPhoneContainer from "../petCard/customerPhone/PetCardCustomerPhoneContainer";
import StarImage from "../../../assets/icons/star.svg";

// Styles via styled-components
const SectionWrapper = styled.section`
  display: flex;
  flex: 1;
  max-width: 100%;
  overflow: hidden;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  flex: 1;
  justify-content: space-between;
  overflow: hidden;
`;

const PetName = styled.div`
  font-family: ${font.mainFamily};
  font-size: ${fontSizes.regular};
  color: ${color.black0};
`;

const Service = styled.div`
  font-family: ${font.mainFamily};
  font-size: ${fontSizes.medium};
  color: ${color.black0};
`;

const Status = styled.div`
  font-family: ${font.mainFamily};
  font-size: ${fontSizes.medium};
  color: ${props => props.overdue && color.red700};
  font-weight: bold;
  margin-left: auto;
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  color: ${props => props.overdue && color.red700};
`;

const TimeRangeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${props => props.overdue && color.red700};
`;

const TimeDetails = styled.div`
  font-family: ${font.mainFamily};
  font-size: ${fontSizes.medium};
  display: flex;
  flex-direction: row;
`;

const TimeRange = styled.div`
  font-family: ${font.mainFamily};
  font-size: ${fontSizes.medium};
  color: ${props => props.isColored && color.red700};
`;

const TimeText = styled.div`
  font-family: ${font.mainFamily};
  font-size: ${fontSizes.medium};
  padding-right: 3px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Icon = styled.img`
  height: 1em;
  padding-left: 3px;
`;

const PetImage = styled(Img)`
  width: 70px;
  height: 70px;
`;

const GroomerNameWrapper = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  text-align: right;
  max-width: 100%;
`;

const BoldPetName = styled.span`
  font-weight: bold;
  margin-right: 0.4rem;
`;

class PetInfo extends React.Component {
  handleRebookClick = e => {
    e.stopPropagation();
    const { appointment, rebookClick } = this.props;

    if (!appointment) {
      return;
    }

    rebookClick({ appointment });
  };

  getStatus = (overdue, appointment) =>
    overdue ? <Status overdue>Overdue</Status> : <Status>{appointment.status}</Status>;

  render() {
    const {
      petServiceItemId,
      pet,
      customer,
      appointment,
      breeds,
      showStatus,
      showDate,
      shouldShowCustomerPhone,
      componentId,
    } = this.props;

    const age = pet ? calculateAge(pet.birthDate) : "Unknown Age";
    const actualStartTime = get("exactArrivalTime", appointment);
    const actualEndTime = moment(actualStartTime).add(get("duration", appointment), "minutes");
    const late =
      appointment &&
      (appointment.status === APPOINTMENT_STATUS.BOOKED ||
        appointment.status === APPOINTMENT_STATUS.CONFIRMED) &&
      isLate(moment(appointment.startDateTime));

    const overdue =
      appointment &&
      appointment.status === APPOINTMENT_STATUS.CHECKED_IN &&
      isOverdue(moment(appointment.endDateTime));

    return (
      <SectionWrapper id={componentId}>
        <PetImage id={`${componentId}--petImage`} src={getUnknownPetImage(pet)} />
        <InfoWrapper id={`${componentId}--Info`}>
          <PetName id={`${componentId}--petNameNameSpecies`}>
            {!isEmpty(pet) && !isEmpty(customer) ? (
              <>
                <BoldPetName id={`${componentId}--petNameName`}>
                  {pet.petName} {customer.lastName}
                </BoldPetName>
                <span id={`${componentId}--petNameSpecies`}>
                  {`${age}, 
                    ${breeds[pet.breedId] ? breeds[pet.breedId].Description : "Unknown Breed"}`}
                </span>
              </>
            ) : (
              <BoldPetName id={`${componentId}--petNameName`}>Loading pet data..</BoldPetName>
            )}
          </PetName>

          <Row id={`${componentId}--service`}>
            <Service id={`${componentId}--serviceName`}>{appointment.petServiceName}</Service>
          </Row>

          <TimeRangeWrapper id={`${componentId}--timeRanges`}>
            {actualStartTime && (
              <TimeDetails id={`${componentId}--arrivalTimeDetails`}>
                <TimeText id={`${componentId}--arrivalTime`}>Arrival Time: </TimeText>
                <TimeRange id={`${componentId}--arrivalTimeRange`} isColored={overdue || late}>
                  {` ${moment(actualStartTime).format("LT")} - ${moment(actualEndTime).format(
                    "LT",
                  )}`}
                </TimeRange>
              </TimeDetails>
            )}
            <TimeDetails id={`${componentId}--bookedTimeDetails`}>
              <TimeText id={`${componentId}--bookedTime`}>Booked Time: </TimeText>
              <TimeRange id={`${componentId}--bookedTimeRange`} isColored={overdue || late}>
                {` ${moment(appointment.startDateTime).format("LT")} - ${moment(
                  appointment.endDateTime,
                ).format("LT")}`}
              </TimeRange>
            </TimeDetails>
          </TimeRangeWrapper>
          <PetCardCustomerPhoneContainer isHidden={!shouldShowCustomerPhone} />
          {showDate && (
            <DateWrapper id={`${componentId}--overdue`} overdue={overdue}>
              <span id={`${componentId}--startDate`}>
                {getUSDateString(appointment.startDateTime)}
              </span>
              {showStatus && this.getStatus(overdue, appointment)}
            </DateWrapper>
          )}
          <Row>
            <Icons pet={pet} appointment={appointment} petServiceItemId={petServiceItemId} />
            {!showDate && showStatus && this.getStatus(overdue, appointment)}
          </Row>
          <Row>
            <PreCheckedInIcon petServiceItemId={petServiceItemId} />
            <GroomerNameWrapper id={`${componentId}--Groomer`}>
              <GroomerName petServiceItemId={petServiceItemId} />
              {appointment.lockToAssociate ? (
                <Icon id={`${componentId}--IconStar`} src={StarImage} />
              ) : null}
            </GroomerNameWrapper>
          </Row>
        </InfoWrapper>
      </SectionWrapper>
    );
  }
}

export default PetInfo;
