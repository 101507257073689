/** @module isFromValidation */

import { routePaths, routePathPartials } from "core/constants/routePaths";

/**
 * Returns true if it ends with 'dashboard'
 *
 * @export isFromDashboard
 * @param {string} url - url to check
 * @returns
 */
export function isFromDashboard(url) {
  return url.endsWith("dashboard");
}

/**
 * Returns true if the string contains 'includes'
 *
 * @export isFromStandalone
 * @param {string} url - url to check
 * @returns
 */
export function isFromStandalone(url) {
  return url.includes("standalone");
}

/**
 * Returns true if the string contains 'modify-appointment' or booking
 *
 * @export isFromBooking
 * @param {string} url - url to check
 * @returns
 */
export function isFromBooking(url) {
  if (!url) {
    return false;
  }

  return url.split("/")[1] === "booking" || url.includes("modify-appointment");
}

/**
 * Returns true if the string contains 'pet-profile'
 *
 * @export isFromPetProfile
 * @param {string} url - url to check
 * @returns
 */
export function isFromPetProfile(url) {
  if (!url) {
    return false;
  }

  return url.includes("pet-parent-profile");
}

/**
 * Returns true if the string contains 'check-in' or 'check-out'
 *
 * @export isFromCheckInOut
 * @param {string} url - url to check
 * @returns
 */
export function isFromCheckInOut(url) {
  if (!url) {
    return false;
  }

  return url.includes("check-in") || url.includes("check-out");
}

/**
 * Returns true if the string contains 'check-in'
 *
 * @export isFromCheckIn
 * @param {string} url - url to check
 * @returns
 */
export function isFromCheckIn(url) {
  return url?.includes(routePaths.CHECK_IN);
}


/**
 * Returns true if the string contains 'check-out'
 *
 * @export isFromCheckOut
 * @param {string} url - url to check
 * @returns
 */
export function isFromCheckOut(url) {
  if (!url) {
    return false;
  }

  return url.includes(routePaths.CHECK_OUT);
}

/**
 * Returns if the current path contains 'check-in' or 'check-out'
 *
 * @export getCheckInOutRoutePath
 * @param {string} url - url to check
 * @returns string the route path constant
 */
export function getCheckInOutRoutePath(url) {
  if (!url) return "";
  if (url.includes(routePaths.CHECK_IN)) return routePaths.CHECK_IN;
  if (url.includes(routePaths.CHECK_OUT)) return routePaths.CHECK_OUT;
  return "";
}

/**
 * Returns true if url ends with 'select-time-slot'
 *
 * @export isFromSelectTimeSlot
 * @param {string} url - url to check
 * @returns
 */
export function isFromSelectTimeSlot(url) {
  return url.endsWith("select-time-slot");
}

/**
 * Returns true if url includes 'check-in', 'check-out', 'past-appointment', 'future-appointment'
 *
 * @export isFromAppointmentView
 * @param {string} url - url to check
 * @returns
 */
export function isFromAppointmentView(url) {
  return (
    url.includes(routePaths.CHECK_IN) ||
    url.includes(routePaths.CHECK_OUT) ||
    url.includes(routePaths.PAST_APPOINTMENT) ||
    url.includes(routePaths.FUTURE_APPOINTMENT)
  );
}

/**
 * Returns true if url includes 'past-appointment' or 'future-appointment'
 *
 * @export isFromPastOrFutureApptView
 * @param {string} url - url to check
 * @returns
 */
export function isFromPastOrFutureApptView(url) {
  return url?.includes(routePaths.PAST_APPOINTMENT) || url?.includes(routePaths.FUTURE_APPOINTMENT);
}

/**
 * Returns true if url ends with includes 'quick-quote'
 *
 * @export isFromQuickQuote
 * @param {string} url - url to check
 * @returns
 */
export function isFromQuickQuote(url) {
  return url.includes(routePaths.QUICK_QUOTE);
}

/**
 * Returns true if url ends with includes 'modify-appointment'
 *
 * @export isFromModifyAppointment
 * @param {string} url - url to check
 * @returns
 */
export function isFromModifyAppointment(url) {
  return url.includes(routePathPartials.MODIFY_APPOINTMENT);
}
