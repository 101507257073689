// React Redux
import React, { useEffect } from "react";
import { connect } from "react-redux";

// layout
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutStack } from "@/layout/stack/Stack";

// Sparky
import { Heading } from "@petsmart-ui/sparky";

// Selectors and actions
import { getHotelServiceSuspension } from "dux/scheduleSuspensionPage/scheduleSuspensionActions";
import {
  selectIsSuspensionScheduledInNext7Days,
  selectSuspensionsListEmptyCheck,
  selectIsDoggieDayCampServiceAvailable,
} from "dux/storeInfoDayCampAvailability/storeInfoDayCampAvailabilitySelectors";
import { getStoreNumber } from "core/selectors/persistentSelectors";

// Moment
import moment from "moment";

// color theme
import { color } from "web/common/styles/theme";

export const StoreInfoDayCampAvailability = props => {
  const { componentId, isHidden, suspensionScheduledInNext7Days, title, apiCalls } = props;

  useEffect(() => {
    apiCalls();
  }, []);

  if (isHidden) {
    return null;
  }

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <LayoutStack space="scale-S2">
        <Heading tagName="h2" size="body-lg-bold">
          {title}
        </Heading>

        {/*Calendar*/}
        <LayoutBox padding="scale-0">
          <LayoutCluster style={{ justifyContent: "space-between" }}>
            {suspensionScheduledInNext7Days.map((item, index) => {
              return (
                <LayoutBox
                  key={index}
                  padding="scale-0"
                  style={{ color: item.isSuspended ? color.red700 : color.green300 }}
                >
                  <LayoutStack>
                    {/*Abbreviated date*/}
                    <LayoutBox padding="scale-0">
                      <Heading tagName="h2" size="body-bold">
                        {item.dowAbvr}
                      </Heading>
                    </LayoutBox>

                    {/*Day of the week*/}
                    <LayoutBox padding="scale-0" style={{ textAlign: "center" }}>
                      <Heading tagName="h2" size="body-bold">
                        {item.dowNumber}
                      </Heading>
                    </LayoutBox>
                  </LayoutStack>
                </LayoutBox>
              );
            })}
          </LayoutCluster>
        </LayoutBox>
      </LayoutStack>
    </LayoutBox>
  );
};

// CONTAINER(s) ----------------------------------------------------------------------------------------
export const SRC_StoreInfoDayCampAvailability = connect(
  state => {
    const storeNumber = getStoreNumber(state);
    const suspensionScheduledInNext7Days = selectIsSuspensionScheduledInNext7Days(state, {
      storeNumber,
    });
    const isSuspensionsListEmpty = selectSuspensionsListEmptyCheck(state);
    const isDayCampAvailable = selectIsDoggieDayCampServiceAvailable(state, { storeNumber });

    return {
      componentId: "SRC_StoreInfoDayCampAvailability",
      title: "Day Camp Availability",
      isHidden: !isDayCampAvailable,
      suspensionScheduledInNext7Days, // populate view
      isSuspensionsListEmpty, // used as a check if api has been called previously
    };
  },

  dispatch => {
    return {
      dispatchAPICalls: () => {
        const fromDate = moment().format("YYYY-MM-DD");
        const toDate = moment()
          .add(7, "days")
          .format("YYYY-MM-DD");
        dispatch(
          getHotelServiceSuspension({ fromDate, toDate, page: 0, size: 7, includeHistory: false }),
        );
      },
    };
  },

  (mapProps, dispatchProps) => {
    const {
      componentId,
      suspensionScheduledInNext7Days,
      isHidden,
      isSuspensionsListEmpty,
      title,
    } = mapProps;
    const { dispatchAPICalls } = dispatchProps;
    return {
      // control what props get passed to the view
      componentId,
      suspensionScheduledInNext7Days,
      title,
      isHidden,

      // actions to pass to view
      apiCalls: () => {
        // check if there are no suspension in the rolling week and the api has not been called previously, make api call
        const shouldCallAPI =
          suspensionScheduledInNext7Days.every(item => item?.isSuspended === false) &&
          isSuspensionsListEmpty;

        if (shouldCallAPI) {
          dispatchAPICalls();
        }
      },
    };
  },
)(StoreInfoDayCampAvailability);
