import React from "react";
import { connect } from "react-redux";

// Selectors
import { getModalType } from "../../core/selectors/modals/petProfileModalSelectors";
import { getCurrentPet } from "@/core/selectors/persistentSelectors";

// Components
import { Hr } from "@petsmart-ui/sparky";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutSwitcher } from "@/layout/switcher/Switcher";
import { LayoutStack } from "@/layout/stack/Stack";
import { HotelCheckInOutPetParentDetails } from "dux/checkInOut/CheckInoutPetParentDetails";
import { HotelCheckInOutPetDetails } from "dux/checkInOut/CheckInoutPetDetails";
import PetParentAgentFormHotelWrapperContainer from "web/petParentProfile/petParent/petParentAgentFormWrapper/PetParentAgentFormHotelWrapperContainer";
import PetVetsTable from "web/petParentProfile/pets/petVets/petVetsTable/PetVetsTableContainer";
import { PetParentAlertLayoutCheckInWrapper } from "dux/checkInOut/layoutComponentWrappers/petParentDetails/petParentAlertsLayoutWrapperView";
import PetFavorabilityHotelContainer from "dux/petFavorability/PetFavorabilityHotelContainer";
import { DDCPetDetailsContainer } from "dux/_components/ddcPetDetails/DDCPetDetailsComponent";
import { HotelCustomerNotesV2, HotelPetNotesV2 } from "dux/notes/Notes";
import { PetVaccinations } from "@/dux/vaccinations/PetVaccinations";
import { PetParentContactSettings } from "@/dux/petParentContactSettings/PetParentContactSettings";
import PetModalsContainer from "@/web/petParentProfile/pets/modals/PetModalsContainer";
import { CheckInOutPetForm } from "@/web/petParentProfile/pets/petForm/PetFormContainer";
import { HotelAppointmentColumn } from "../appointmentColumn/AppointmentColumn";
import { HotelCheckInOutPetTabs } from "./CheckInOutPetTabs";

// Utils
import fadeIn from "web/common/styles/Animation/fade/fadeIn";

// Constants
import { color } from "web/common/styles/theme";
import * as modularScale from "@/layout/modularScale";

export const CheckOutLayoutComponent = props => {
  const { componentId, customerKey, currentPetId, modalType, setPetId } = props;

  return (
    <LayoutBox
      id={`${componentId}__content`}
      style={{ animation: `${fadeIn()} 2s ease` }}
      padding="scale-0"
    >
      <LayoutSwitcher threshold="scale-G9">
        {/* LEFT COLUMN -------------------------------------------------------------------------------------------*/}
        <LayoutBox
          padding="scale-G1"
          style={{
            flexGrow: modularScale.S_3,
            boxShadow: "0 0.625rem 2.125rem 0 rgb(13 18 27 / 12%)",
          }}
        >
          <LayoutStack>
            <HotelCheckInOutPetParentDetails />

            {/* DO NOT BOOK, VIP, PSA ------------------------------------------------------------*/}
            <PetParentAlertLayoutCheckInWrapper />

            {/* CUSTOMER CONTACT SETTINGS  -----------------------------------------------------------------*/}
            <Hr style={{ width: "100%", margin: "15px 0" }} />
            <PetParentContactSettings customerKey={customerKey} />
            <Hr style={{ width: "100%", margin: "15px 0" }} />

            {/* Replace with component */}
            <PetParentAgentFormHotelWrapperContainer />

            {/* CUSTOMER NOTES  -----------------------------------------------------------------*/}
            <HotelCustomerNotesV2 />
          </LayoutStack>
        </LayoutBox>

        <LayoutBox padding="scale-0">
          <LayoutStack space="scale-0">
            {/* PET LIST ------------------------------------------------------------------------------------------*/}
            <HotelCheckInOutPetTabs setPetId={setPetId} />

            <LayoutSwitcher threshold="scale-G8">
              {/* CENTER COLUMN ------------------------------------------------------------------------------------------*/}
              <LayoutBox
                id={`${componentId}__column02`}
                padding="scale-G1"
                style={{
                  borderRight: `1px solid ${color.gray300}`,
                }}
              >
                <LayoutStack space="scale-G1">
                  <HotelCheckInOutPetDetails customerKey={customerKey} petId={currentPetId} />

                  {/* PET FORM CONTAINER  -----------------------------------------------------------------*/}
                  <CheckInOutPetForm customerKey={customerKey} petId={currentPetId} />

                  {/* DDC PET DETAILS CONTAINER  -----------------------------------------------------------------*/}
                  <DDCPetDetailsContainer />

                  {/* PET FAVORABILITY  -----------------------------------------------------------------*/}
                  <PetFavorabilityHotelContainer disabled={false} topLine={false} />

                  {/* PET VACCINATIONS  -----------------------------------------------------------------*/}
                  <PetVaccinations />

                  {/* PET NOTES  -----------------------------------------------------------------*/}
                  <HotelPetNotesV2 />
                  {/* Replace with component */}

                  <PetVetsTable customerKey={customerKey} petId={currentPetId} />

                  {/* <PetModalsContainer */}
                  {modalType && <PetModalsContainer modalType={modalType} />}
                </LayoutStack>
              </LayoutBox>

              {/* RIGHT COLUMN -------------------------------------------------------------------------------------------*/}
              <HotelAppointmentColumn />
            </LayoutSwitcher>
          </LayoutStack>
        </LayoutBox>
      </LayoutSwitcher>
    </LayoutBox>
  );
};

// HOTEL CHECK OUT LAYOUT CONTAINER --------------------------------------------------------------------------------------------

export const HotelCheckOutLayout = connect(state => {
  const modalType = getModalType(state);
  const currentPetId = getCurrentPet(state);

  return {
    componentId: "HotelCheckOutLayout",
    modalType,
    currentPetId,
  };
})(CheckOutLayoutComponent);

// we plan to bring Salon over from legacy code to this format. plan Place Salon SalonCheckOutLayout container here.
