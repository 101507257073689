import { Layout, Text, QuantityModifier } from "@prism/psm-ui-components";
import React from "react";
import { color } from "web/common/styles/theme";
import { connect } from "react-redux";
import {
  selectEnhancedAddonChildNames,
  selectHotelAddOnById,
} from "dux/hotelAddons/hotelAddonsLIstSelectors";
import { selectIsOvernight } from "@/web/features/hotelBookingFlow/hotelBookingFlowSelectors";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { getCurrentPet } from "@/core/selectors/persistentSelectors";
import {
  selectAddonByPetAndAddonId,
  selectCurrentHotelEngagement,
} from "../hotelEngagements/hotelEngagementSelectors";
import { engagementTypes } from "@/web/setSystemType/constants/setSystemTypeConstants";
import { GenericList } from "../_components/genericList/GenericList";
import { formatMoney } from "../_utils/moneyUtil";
import { selectHotelCartPetProductById } from "../servicesCartHotel/servicesCartHotelSelectors";

export const HotelAddonQuantityModifierWithDetails = ({
  componentId,
  addon = {},
  name,
  quantity,
  setQuantity,
  diComp,
  disableQuantity,
}) => {
  const { price } = addon;
  const Quantity = diComp?.qty;

  return (
    <Layout.Grid
      style={{
        gridTemplateColumns: "1fr .5fr 1.5fr .5fr",
        gridRowGap: "2px",
      }}
      id={`${componentId}__quantityModifier-grid`}
    >
      <Text bold style={{ borderBottom: `1px solid ${color.gray800}` }}>
        Add-on Name
      </Text>
      <Text align="center" bold style={{ borderBottom: `1px solid ${color.gray800}` }}>
        Price
      </Text>
      <Text align="center" bold style={{ borderBottom: `1px solid ${color.gray800}` }}>
        Qty
      </Text>
      <Text align="center" bold style={{ borderBottom: `1px solid ${color.gray800}` }}>
        Total
      </Text>

      <LayoutCluster style={{ alignItems: "center" }}>
        <Text>{name}</Text>
      </LayoutCluster>
      <LayoutCluster style={{ alignItems: "center", justifyContent: "center" }}>
        <Text>{formatMoney(price)}</Text>
      </LayoutCluster>
      <Quantity
        quantity={quantity}
        updateQuantity={setQuantity}
        disableQuantity={disableQuantity}
      />
      <LayoutCluster style={{ alignItems: "center", justifyContent: "center" }}>
        <Text>{formatMoney(price * quantity)}</Text>
      </LayoutCluster>
    </Layout.Grid>
  );
};

const StaticQty = ({ quantity }) => (
  <LayoutCluster style={{ alignItems: "center", justifyContent: "center" }}>
    <Text>{quantity}</Text>
  </LayoutCluster>
);

const renderEnhancedAddonName = (name, childNames) => (
  <>
    {name}
    <GenericList style={{ margin: 0 }} list={childNames} />
  </>
);

export const HotelBookingAddonModifier = connect(
  (state, { addonId, quantity, setQuantity, petId }) => {
    const addon = selectHotelAddOnById({ addonId })(state);
    const isOvernight = selectIsOvernight(state);
    const childNames = selectEnhancedAddonChildNames(addonId)(state);

    const name = addon?.isEnhanced
      ? renderEnhancedAddonName(addon?.addOnName, childNames)
      : addon?.addOnName;

    // Addon from cart
    const existingAddon = selectHotelCartPetProductById({ petId, productId: addonId })(state);

    return {
      componentId: "hotelAddons_modalContent",
      addon,
      name,
      quantity,
      setQuantity,
      disableQuantity: !!existingAddon?.isAutoApplyAddOn,
      diComp: {
        qty: isOvernight ? StaticQty : QuantityModifier,
      },
    };
  },
)(HotelAddonQuantityModifierWithDetails);

export const HotelCheckInOutAddonModifier = connect((state, { addonId, quantity, setQuantity }) => {
  const petId = getCurrentPet(state);
  const addon = selectHotelAddOnById({ addonId })(state);
  const engagementType = selectCurrentHotelEngagement(state, { petId })?.engagementType;
  const isOvernight = engagementType === engagementTypes.OVERNIGHT_BOARDING;
  const childNames = selectEnhancedAddonChildNames(addonId)(state);

  const name = addon?.isEnhanced
    ? renderEnhancedAddonName(addon?.addOnName, childNames)
    : addon?.addOnName;

  // Addon from itinerary
  const existingAddon = selectAddonByPetAndAddonId(addonId)(state, { petId });

  return {
    componentId: "HotelCheckInOutAddonModifier",
    addon,
    name,
    quantity,
    setQuantity,
    disableQuantity: !!existingAddon?.isAutoApplyAddOn,
    diComp: {
      qty: isOvernight ? StaticQty : QuantityModifier,
    },
  };
})(HotelAddonQuantityModifierWithDetails);
