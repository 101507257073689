import React, { useEffect } from "react";
import { connect } from "react-redux";
import { LayoutBox } from "@/layout/box/Box";
import LoadingWrapper from "@/web/common/LoadingWrapper";
import { LayoutStack } from "@/layout/stack/Stack";
import { Heading, Hr } from "@petsmart-ui/sparky";
import priceAdjustmentActionTypes from "@/core/actionTypes/priceAdjustmentActionTypes";
import specialsActionTypes from "@/core/actionTypes/specialsActionTypes";
import petServiceItemsActionTypes from "@/core/actionTypes/petServiceItemsActionTypes";
import engagementsActionTypes from "@/core/actionTypes/engagementsActionTypes";
import itinerariesActionTypes from "@/core/actionTypes/itinerariesActionTypes";
import { createLoadingSelector } from "@/core/selectors/utils";
import { getCartDetails } from "@/core/selectors/cartSelectors";
import { getSelectedPet } from "@/core/selectors/bookingUISelectors";
import { getSystemBookingFlow } from "@/web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "@/web/setSystemType/constants/setSystemTypeConstants";
import { getServicesForPet } from "@/core/selectors/entitiesSelector";
import { isEmpty, filter, get } from "lodash/fp";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import CartFooterContainer from "@/web/cart/CartFooterContainer";
import {
  clearCart,
  initializeCart,
  initializeCartByItinerary,
} from "@/core/actionCreators/ui/web/cartActionCreators";
import getIsSalonCartMigrationWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/salonCartMigration/getIsSalonCartMigrationWorkflowFeatureFlagHidden";
import {
  isFromCheckOut,
  isFromPastOrFutureApptView,
} from "@/core/utils/validationUtils/isFromValidation";
import quickQuoteConstants from "@/core/constants/quickQuoteConstants";
import { compose } from "redux";
import { LayoutCluster } from "@/layout/culster/Cluster";
import FirstTimeCustomerIndicatorAppointmentViewContainer from "../bgm/ftco/FirstTimeCustomerIndicatorAppointmentViewContainer";
import EligiblePromotionsFTCOAppointmentViewContainer from "../eligiblePromotions/EligiblePromotionsFTCOAppointmentViewContainer";
import EligiblePromotionsBGMAppointmentViewContainer from "../eligiblePromotions/EligiblePromotionsBGMAppointmentViewContainer";
import BulkPackageOfferingsCartDetailsContainer from "../bgm/bulkPackageOfferings/cartDetails/BulkPackageOfferingsCartDetailsContainer";
import { StoreNameNumberAddress } from "../storeNameNumberAddress/StoreNameNumberAddress";
import {
  SalonPetAppointmentDetails,
  SalonQuickQuotePetAppointmentDetails,
  SalonBookingPetAppointmentDetails,
  HotelPetAppointmentDetails,
} from "./PetAppointmentDetails";
import { PetParentCartSummary } from "../petParentCartSummary/PetParentCartSummary";
import { getIsAnyBulkPackageOfferingSelected } from "../bgm/bulkPackageOfferings/bulkPackageOfferingsSelectors";
import PetCartCardsMappingContainer from "../petCartCard/PetCartCardsMappingContainer";
import { CheckInOutSalonInvoiceMsg } from "../checkInOutSalonInvoiceMsg/CheckInOutSalonInvoiceMsg";
import { HotelCheckInOutTotalPrice } from "../hotelCheckInOutTotalPrice/HotelCheckInOutTotalPrice";
import {
  CheckInAllPetSHotelButton,
  CheckInSinglePetHotelButton,
} from "../checkInPetsHotelButton/checkInPetsHotelButton";
import { DifferentStoreMessage } from "../checkInOut/DifferentStoreMessage";
import { HotelAppointmentInfo } from "../appointmentInfo/AppointmentInfoComponent";
import {
  CheckOutAllPetsHotelButton,
  CheckOutSinglePetHotelButton,
} from "../checkOutPetsHotelButton/checkOutPetsHotelButton";
import HotelBookingTotalPriceContainer from "../bookHotel/HotelBookingTotalPriceContainer";
import { HotelBookButton } from "../bookHotel/HotelBookButton";
import { DdcPackagesPriceAndPurchase } from "../ddcPackagesPriceAndPurchase/DdcPackagesPriceAndPurchase";

/**
 * React view component that enforces the layout for appointment "cart" columns
 * @function
 * @name AppointmentColumn
 * @param {Object} props
 * @param {string} props.componentId
 * @param {Boolean} props.isHidden
 * @param {Boolean} props.isLoading
 * @param {string?} props.appointmentDetailsHeading
 * @param {Function?} props.initializeCartState
 * @param {Function?} props.clearCartState
 * @param {Function?} props.petParentSummaryComp
 * @param {Function?} props.totalPriceComp
 * @param {Function?} props.invoiceMsgComp
 * @param {Function?} props.statusButtonsComp
 * @param {Function?} props.packageOfferingIndicatorsComp
 * @param {Function?} props.differentStoreMsgComp
 * @param {Function?} props.storeAddressComp
 * @param {Function?} props.appointmentHistoryComp
 * @param {Function?} props.petAppointmentDetailsComp
 * @returns {JSX.Element|null}
 */
export const AppointmentColumn = props => {
  const {
    componentId,
    isHidden,
    isLoading,
    appointmentDetailsHeading,
    initializeCartState = () => {},
    clearCartState = () => {},
  } = props;

  useEffect(() => {
    initializeCartState();

    return () => {
      clearCartState();
    };
  }, []);

  if (isHidden) return null;

  // Dependency injected components
  const PetParentSummary = props?.petParentSummaryComp || React.Fragment;
  const TotalPrice = props?.totalPriceComp || React.Fragment;
  const InvoiceMsg = props?.invoiceMsgComp || React.Fragment;
  const StatusButtons = props?.statusButtonsComp || React.Fragment;
  const PackageOfferingIndicators = props?.packageOfferingIndicatorsComp || React.Fragment;
  const DifferentStoreMsg = props?.differentStoreMsgComp || React.Fragment;
  const StoreAddress = props?.storeAddressComp || React.Fragment;
  const AppointmentHistory = props?.appointmentHistoryComp || React.Fragment;
  const PetAppointmentDetails = props?.petAppointmentDetailsComp || React.Fragment;

  const hasPetParentSummary = !!props?.petParentSummaryComp;
  const hasPricingOrButtons = !!props?.totalPriceComp || !!props?.statusButtonsComp;
  const hasPackageOfferingIndicators = !!props?.packageOfferingIndicatorsComp;

  return (
    <LoadingWrapper isLoading={isLoading} displayContainer="block">
      <LayoutBox id={componentId} padding="scale-G1">
        <LayoutStack>
          <PetParentSummary />
          {hasPetParentSummary && <Hr style={{ marginLeft: 0, marginRight: 0 }} />}

          {hasPricingOrButtons && (
            <>
              <LayoutBox padding="scale-0">
                <LayoutCluster style={{ justifyContent: "space-between" }}>
                  <TotalPrice />
                  <InvoiceMsg />
                  <StatusButtons />
                </LayoutCluster>
              </LayoutBox>
              <Hr style={{ marginLeft: 0, marginRight: 0 }} />
            </>
          )}

          <PackageOfferingIndicators />
          {hasPackageOfferingIndicators && <Hr style={{ marginLeft: 0, marginRight: 0 }} />}

          {appointmentDetailsHeading && (
            <Heading tagName="h3" size="headline">
              {appointmentDetailsHeading}
            </Heading>
          )}
          <DifferentStoreMsg />
          <StoreAddress />
          <AppointmentHistory />
          <PetAppointmentDetails />
        </LayoutStack>
      </LayoutBox>
    </LoadingWrapper>
  );
};

/**
 * Redux Connect function for props that are used in multiple salon appt column containers
 */
export const SalonAppointmentCommonProps = compose(
  withRouteProps,
  connect(
    (state, { router: { params, location } }) => {
      const isCartMigrationHidden = getIsSalonCartMigrationWorkflowFeatureFlagHidden(state);
      const isHidden = isCartMigrationHidden || getSystemBookingFlow(state) !== systemName.SALON;
      if (isHidden) return { isHidden };

      const servicesForPet = getServicesForPet(state);
      const petHasServices = !isEmpty(servicesForPet);
      const isPastOrFutureApptPage = isFromPastOrFutureApptView(location?.pathname);
      const appointments = getCartDetails(state);
      const selectedPet = getSelectedPet(state);

      return {
        petHasServices,
        isPastOrFutureApptPage,
        appointments,
        selectedPet,
        itineraryId: params?.itineraryId,
        isLoading: createLoadingSelector([
          priceAdjustmentActionTypes.UPDATE_PRICE_OF_PET_SERVICE_OR_ADDON,
          specialsActionTypes.POST_SPECIALS,
          specialsActionTypes.REMOVE_SPECIALS,
          petServiceItemsActionTypes.UPDATE_PET_SERVICE_ITEM,
          engagementsActionTypes.UPDATE_ENGAGEMENT_STATUS,
          itinerariesActionTypes.UPDATE_ITINERARY_STATUS,
          itinerariesActionTypes.UPDATE_ITINERARY_PAYMENT_STATUS,
          engagementsActionTypes.UNDO_LAST_ENGAGEMENT_STATUS_UPDATE,
        ])(state),
      };
    },
    (dispatch, { router }) => {
      const itineraryId = router?.params?.itineraryId;

      return {
        clearCartState: () => dispatch(clearCart()),
        initializeCartState: petServiceItemId => {
          if (itineraryId) {
            dispatch(initializeCartByItinerary({ itineraryId }));
          } else if (petServiceItemId) {
            dispatch(initializeCart({ petServiceItemId }));
          }
        },
      };
    },
  ),
);

/**
 * Helper to render salon booking pet details for multiple pets
 * @param {Array} petIds
 * @returns {JSX.Element}
 */
const renderSalonBookingPetDetails = petIds => {
  return (
    <LayoutBox padding="scale-0">
      <LayoutStack space="scale-G1">
        {petIds?.map(petId => (
          <SalonBookingPetAppointmentDetails key={petId} petId={petId} />
        ))}
      </LayoutStack>
    </LayoutBox>
  );
};

/**
 * Redux Connect function for the salon booking appointment column
 * @memberOf Views.Booking
 * @function
 * @name SalonBookingAppointmentColumn
 * @param {Object} props
 * @param {string} props.appointmentDetailsType
 * @param {Number|String} props.petId
 * @returns {JSX.Element|null}
 * @example <SalonBookingAppointmentColumn />
 */
export const SalonBookingAppointmentColumn = compose(
  SalonAppointmentCommonProps,
  connect((state, ownProps) => {
    // props from SalonAppointmentCommonProps
    const {
      isHidden,
      petHasServices,
      isPastOrFutureApptPage,
      appointments,
      selectedPet,
      itineraryId,
      isLoading,
      clearCartState,
      initializeCartState,
    } = ownProps;

    if (isHidden) return { isHidden };

    // Props from parent
    const { footerActions, appointmentDetailsType, petId } = ownProps;

    const petIds = Object.keys(appointments) || [];
    const selectedPetString = selectedPet && selectedPet.toString();
    const sortedPetIds = get(selectedPetString, appointments)
      ? [selectedPetString, ...filter(id => id !== selectedPetString, petIds)]
      : petIds;

    const isBulkPackageHidden = getIsAnyBulkPackageOfferingSelected(state) === false;
    const showPriceAndStatusBtns = petHasServices && !isPastOrFutureApptPage;

    return {
      componentId: "SalonBookingAppointmentColumn",
      isLoading,
      clearCartState,
      initializeCartState,
      petParentSummaryComp: PetParentCartSummary,
      statusButtonsComp:
        showPriceAndStatusBtns &&
        (() => (
          <LayoutBox padding="scale-0" style={{ flex: 1 }}>
            <CartFooterContainer
              footerActions={footerActions}
              multiplePets
              petId={petId}
              selectedPet={selectedPet}
              itineraryId={itineraryId}
            />
          </LayoutBox>
        )),
      packageOfferingIndicatorsComp: () => (
        <>
          {/*  PACKAGE OFFERING INDICATORS */}
          <FirstTimeCustomerIndicatorAppointmentViewContainer />
          <EligiblePromotionsFTCOAppointmentViewContainer />
          <EligiblePromotionsBGMAppointmentViewContainer />
        </>
      ),
      petAppointmentDetailsComp: isBulkPackageHidden
        ? () => renderSalonBookingPetDetails(sortedPetIds)
        : BulkPackageOfferingsCartDetailsContainer,

      storeAddressComp: !appointmentDetailsType && StoreNameNumberAddress,
    };
  }),
)(AppointmentColumn);

/**
 * Redux Connect function for the salon check in, check out, future appointment, & past appointment page appointment column
 * @function
 * @name SalonBookingAppointmentColumn
 * @param {Object} props
 * @param {string} props.appointmentDetailsType
 * @param {Number|String} props.petId
 * @returns {JSX.Element|null}
 * @example <SalonAppointmentColumn />
 */
export const SalonAppointmentColumn = compose(
  SalonAppointmentCommonProps,
  connect((state, ownProps) => {
    // props from SalonAppointmentCommonProps
    const {
      isHidden,
      petHasServices,
      isPastOrFutureApptPage,
      itineraryId,
      isLoading,
      clearCartState,
      initializeCartState,
    } = ownProps;

    if (isHidden) return { isHidden };

    // Props from parent
    const { footerActions, appointmentDetailsType, petId } = ownProps;

    const showPriceAndStatusBtns = petHasServices && !isPastOrFutureApptPage;

    return {
      componentId: "SalonAppointmentColumn",
      isLoading,
      clearCartState,
      initializeCartState,
      appointmentDetailsHeading: "Appointment Details",
      statusButtonsComp:
        showPriceAndStatusBtns &&
        (() => (
          <LayoutBox padding="scale-0" style={{ flex: 1 }}>
            <CartFooterContainer
              footerActions={footerActions}
              petId={petId}
              selectedPet={petId}
              itineraryId={itineraryId}
            />
          </LayoutBox>
        )),
      packageOfferingIndicatorsComp: () => (
        <>
          {/*  PACKAGE OFFERING INDICATORS */}
          <FirstTimeCustomerIndicatorAppointmentViewContainer />
          <EligiblePromotionsFTCOAppointmentViewContainer />
          <EligiblePromotionsBGMAppointmentViewContainer />
        </>
      ),
      petAppointmentDetailsComp: () => (
        <SalonPetAppointmentDetails petId={petId} appointmentDetailsType={appointmentDetailsType} />
      ),
      storeAddressComp: !appointmentDetailsType && StoreNameNumberAddress,
    };
  }),
)(AppointmentColumn);

/**
 * Redux Connect function for the salon quick quote appointment column
 * @memberOf Views.QuickQuote
 * @function
 * @name SalonQuickQuoteAppointmentColumn
 * @returns {JSX.Element|null}
 * @example <SalonQuickQuoteAppointmentColumn />
 */
export const SalonQuickQuoteAppointmentColumn = compose(
  SalonAppointmentCommonProps,
  connect((state, ownProps) => {
    // props from SalonAppointmentCommonProps
    const { isHidden, petHasServices, isLoading, clearCartState, initializeCartState } = ownProps;

    if (isHidden) return { isHidden };

    const multiplePets = true;
    const petId = quickQuoteConstants.QUOTE_PET;

    return {
      componentId: "SalonQuickQuoteAppointmentColumn",
      isLoading,
      clearCartState,
      initializeCartState,
      statusButtonsComp:
        petHasServices &&
        (() => (
          <LayoutBox padding="scale-0" style={{ flex: 1 }}>
            <CartFooterContainer multiplePets={multiplePets} petId={petId} selectedPet={petId} />
          </LayoutBox>
        )),
      petAppointmentDetailsComp: SalonQuickQuotePetAppointmentDetails,
      storeAddressComp: StoreNameNumberAddress,
    };
  }),
)(AppointmentColumn);

/**
 * Redux Connect function for the hotel booking appointment column
 * @memberOf Views.Booking
 * @function
 * @name HotelBookingAppointmentColumn
 * @param {Object} props
 * @returns {JSX.Element|null}
 * @example <HotelBookingAppointmentColumn />
 */
export const HotelBookingAppointmentColumn = connect(state => {
  return {
    componentId: "HotelBookingAppointmentColumn",
    petParentSummaryComp: PetParentCartSummary,
    totalPriceComp: HotelBookingTotalPriceContainer,
    statusButtonsComp: HotelBookButton,
    petAppointmentDetailsComp: PetCartCardsMappingContainer,
    storeAddressComp: StoreNameNumberAddress,
  };
})(AppointmentColumn);

/**
 * Helper to render hotel check in & check in all buttons
 * @returns {JSX.Element}
 */
const HotelCheckInButtons = () => (
  <LayoutCluster style={{ justifyContent: "space-between" }}>
    <CheckInSinglePetHotelButton />
    <CheckInAllPetSHotelButton />
  </LayoutCluster>
);

/**
 * Helper to render hotel check out & check out all buttons
 * @returns {JSX.Element}
 */
const HotelCheckOutButtons = () => (
  <LayoutCluster style={{ justifyContent: "space-between" }}>
    <CheckOutSinglePetHotelButton />
    <CheckOutAllPetsHotelButton />
  </LayoutCluster>
);

/**
 * Redux Connect function for the hotel appointment column for check in & check out pages
 * @memberOf Views.Booking
 * @function
 * @name HotelAppointmentColumn
 * @param {Object} props
 * @returns {JSX.Element|null}
 * @example <HotelAppointmentColumn />
 */
export const HotelAppointmentColumn = compose(
  withRouteProps,
  connect((state, { router }) => {
    const isFromCheckOutPage = isFromCheckOut(router?.location?.pathname);
    return {
      componentId: "HotelAppointmentColumn",
      appointmentDetailsHeading: "Appointment Details",
      totalPriceComp: HotelCheckInOutTotalPrice,
      invoiceMsgComp: CheckInOutSalonInvoiceMsg,
      statusButtonsComp: isFromCheckOutPage ? HotelCheckOutButtons : HotelCheckInButtons,
      differentStoreMsgComp: DifferentStoreMessage,
      appointmentHistoryComp: HotelAppointmentInfo,
      petAppointmentDetailsComp: HotelPetAppointmentDetails,
    };
  }),
)(AppointmentColumn);

/**
 * Redux Connect function for cart column on DDC packages page
 * @memberOf Views.Purchase
 * @summary path: /purchase/:customerKey/ddc-packages
 * @function
 * @name DDCPackagesCartColumn
 * @example <DDCPackagesCartColumn />
 */
export const DDCPackagesCartColumn = connect(state => {
  return {
    componentId: "DDCPackagesCartColumn",
    petParentSummaryComp: PetParentCartSummary,
    statusButtonsComp: () => (
      <LayoutBox padding="scale-0" style={{ flex: 1 }}>
        <DdcPackagesPriceAndPurchase />
      </LayoutBox>
    ),
    storeAddressComp: () => <StoreNameNumberAddress heading="Package summary" />,
  };
})(AppointmentColumn);
