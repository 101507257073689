import { formatCalendarDateMoment } from "../dateUtils/formatDateTime";

/**
 * Util to get all pet service data from an array of engagements
 * @memberOf Utils.Engagement
 * @function
 * @name getServicesAndRoomsByDate
 * @param {Object} args
 * @param {Object[]} args.engagements - array of engagements
 * @param {string} args.timeZone - current stores time zone
 * @returns {{ [date: string]: { petService: Object, room: Object, hostPetId: string }}}
 */
export const getServicesAndRoomsByDate = ({ engagements, timeZone = "UTC" } = {}) => {
  return engagements?.reduce((roomAndService, engagement) => {
    if (!engagement?.startDatetime) return roomAndService;

    const date = formatCalendarDateMoment(engagement?.startDatetime, timeZone);
    const { petService, metadata: { room } = {}, hostPetId } = engagement || {};

    return { ...roomAndService, [date]: { petService, room, hostPetId } };
  }, {});
};

/**
 * Helper to copy the last day service and room data without any pricing information, to be used as a default
 * @memberOf Utils.Engagement
 * @function
 * @name updatePetRoomsAndServices
 * @param {Object} servicesAndRoomsByDate
 * @returns {Object}
 */
export const getDefaultRoomAndService = servicesAndRoomsByDate => {
  const lastDay = Object.keys(servicesAndRoomsByDate)?.at(-1);
  const { petService, room, hostPetId } = servicesAndRoomsByDate[lastDay] || {};

  return {
    room,
    hostPetId,
    petService: {
      petServiceId: petService?.petServiceId,
      groupingId: petService?.groupingId,
      petServiceName: petService?.petServiceName,
      petServiceProductId: petService?.petServiceProductId,
    },
  };
};

/**
 * Util to add pet service and rooms data to engagements based on start date
 * Note: if an engagement startDatetime is not included in servicesAndRoomsByDate, then the default is to use the last day of stay
 * @memberOf Utils.Engagement
 * @function
 * @name updatePetRoomsAndServices
 * @param {Object} args
 * @param {Object} args.servicesAndRoomsByDate - Object containing pet service and room data for each original day of stay
 * @param {string} args.timeZone - current stores time zone
 * @returns {Object[]} Array of engagements with pet service and room data
 * @example updatePetRoomsAndServices({servicesAndRoomsByDate, timeZone})(engagements)
 */
export const updatePetRoomsAndServices = ({
  servicesAndRoomsByDate = {},
  timeZone = "UTC",
} = {}) => engagements => {
  const defaultServiceAndRoom = getDefaultRoomAndService(servicesAndRoomsByDate);

  return engagements?.map(engagement => {
    const date = formatCalendarDateMoment(engagement?.startDatetime, timeZone);
    const { petService, room, hostPetId } = servicesAndRoomsByDate[date] || defaultServiceAndRoom;

    return {
      ...engagement,
      petService,
      hostPetId,
      metadata: { ...engagement?.metadata, room },
    };
  });
};
