/** ----------------------------------------------------------------------- **\
    Exports a customized ReactSelect component - to be customized with custom components and styles
    See https://deploy-preview-2289--react-select.netlify.com/home for docs and usage.
\** ----------------------------------------------------------------------- * */

import React from "react";
// eslint-disable-next-line import/no-named-default
import { default as ReactSelect } from "react-select";
import { fontSizes } from "web/common/styles/responsive/fonts";
import { color } from "./styles/theme";
import NonEditable from "./NonEditable";
import StaticInput from "./StaticInput";

const getControlStyles = (base, { isActive, isForm, backgroundColor }) => {
  const styles = {
    ...base,
    border: "none",
    backgroundColor: backgroundColor || "white",
    fontSize: fontSizes.regular,
    color: color.gray800,
  };
  if (isForm) {
    return {
      ...styles,
      borderRadius: "0",
      isFocused: {
        outline: "none",
        border: "none",
        boxShadow: "none",
      },
      borderBottom: isActive ? `1px solid ${color.blue400}` : "1px solid #b5b5b5",
      boxShadow: isActive ? `0 1px 0 0 ${color.blue400}` : "inherit",
    };
  }
  return styles;
};

/** ----------------------------------------------------------------------- **\
    STYLES
    In the case of the search Selection, the buildCustomStyles don't seem to be
    applicable and are overwritten in the parent component and passed in through
    props e.g. styles={customSelectStyles}, If you need to update the styles for the
    search selection do it there.
\** ----------------------------------------------------------------------- * */
const buildCustomStyles = props => ({
  option: (styles, { data }) => {
    return {
      ...styles,
      color: data.isInactive || data.isDisabled ? `${color.gray600}` : `${color.gray800}`,
    };
  },
  control: base => getControlStyles(base, props),
  indicatorSeparator: () => ({
    display: "none",
  }),
  menuList: base => ({
    ...base,
    maxHeight: props.menuListMaxHeight ? `${props.menuListMaxHeight}px` : base.maxHeight,
  }),
  valueContainer: base => ({
    ...base,
    padding: 0,
  }),
  singleValue: base => ({
    ...base,
    color: props.error ? color.red700 : "inherit",
    top: "65%",
  }),
  placeholder: base => ({
    ...base,
    color: props.error ? color.red700 : "inherit",
  }),
  dropdownIndicator: base => ({
    ...base,
    ":hover":
      props.isForm && props.isActive ? { color: `${color.blue400}` } : { color: "hsl(0, 0%, 60%)" },
    color: props.isForm && props.isActive ? `${color.blue400}` : "inherit",
  }),
});

/** ----------------------------------------------------------------------- **\
    CUSTOM SELECT COMPONENT
\** ----------------------------------------------------------------------- * */
class Select extends React.Component {
  constructor(props) {
    super(props);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.getStaticInputProps = this.getStaticInputProps.bind(this);
    this.state = { isActive: false };
  }

  getStaticInputProps() {
    const { isStatic, inputValue } = this.props;
    if (isStatic) {
      return {
        components: { Input: StaticInput },
        menuIsOpen: false,
        autoSize: false,
        inputValue: inputValue || " ",
      };
    }
    return {};
  }

  handleBlur() {
    this.setState({ isActive: false });
  }

  handleFocus() {
    const { onDone } = this.props;
    this.setState({ isActive: true });
    // eslint-disable-next-line no-unused-expressions
    onDone && onDone();
  }

  render() {
    const { className, ...props } = this.props;
    const customStyles = buildCustomStyles({ ...props, ...this.state });

    return (
      <div className={className} onFocus={this.handleFocus} onBlur={this.handleBlur}>
        {props.nonEditable ? (
          <NonEditable>{props.value ? props.value?.label : "No value"}</NonEditable>
        ) : (
          <ReactSelect
            styles={customStyles}
            {...props}
            {...this.getStaticInputProps()}
            isDisabled={props.disabled}
          />
        )}
      </div>
    );
  }
}

export default Select;
