import { createSelector } from "reselect";
import { isEmpty, get, getOr } from "lodash/fp";
import formatPhoneNumberNANP from "../utils/stringManipulationUtils/formatPhoneNumber";
import {
  getEmailsByCustomer,
  getPhonesByCustomer,
  getPetsNamesAndIdsByCustomer,
  getCustomerFullName,
} from "./entitiesSelector";
import verifyPhoneNumber from "../utils/validationUtils/phoneNumberValidation";
import { getState } from "./commonSelector";
import { getUiWeb } from "./uiSelector";

export const selectSearchState = createSelector([getState], state => state?.search);

// All customers based on search criteria, A list of customers.
export const getCustomersSearchResults = createSelector(
  [selectSearchState],
  search => search?.customers,
);

// Store Info based on a search for store Info.
export const getStoreSearchResults = createSelector(
  [selectSearchState],
  search => search?.salonInfoWithServices,
);

// A Bool to determine if search results should be shown.
export const getShowResults = createSelector([getUiWeb], uiWeb => uiWeb?.search?.showSearchResults);

export const getSearchResultsToDisplay = createSelector(
  [selectSearchState],
  search => search?.searchResultsToDisplay,
);

export const getSearchFieldType = createSelector(
  [getUiWeb],
  uiWeb => uiWeb?.search?.searchFieldType,
);

export const getSearchFieldValue = createSelector([getUiWeb], uiWeb => uiWeb?.search?.searchField);

export const getSearchApplied = createSelector([getUiWeb], uiWeb => uiWeb?.search?.searchApplied);

export const getFormattedStoreSearchResults = createSelector(
  [getStoreSearchResults],
  storeSearchResults => {
    /* data from state will be a single store wrapped in an array, In this case we do not need the
    array, only the single store object. */
    const storeSearchResult = storeSearchResults?.[0];

    // Place all values into an array so they can be neatly displayed in a list.
    const storeInfo = [];

    if (!isEmpty(storeSearchResult)) {
      storeInfo.push(`${storeSearchResult.StoreNumber} ${storeSearchResult.Name}`);
      storeInfo.push(storeSearchResult.StreetLine1);
      storeInfo.push(formatPhoneNumberNANP(storeSearchResult.PhoneNumber));

      // The storeSearchResult.StoreServices object is an array of objects that provide
      // properties such as name or if booking is allowed. We need to extract the name
      // from each object and all names need to be placed into a single string.
      let servicesString = "";
      if (storeSearchResult.StoreServices) {
        const servicesObjects = Object.values(storeSearchResult.StoreServices);
        const listOfServices = servicesObjects.map(item => item.ServiceName);
        servicesString = listOfServices.join(", ");
      }
      storeInfo.push(servicesString);
    }

    return storeInfo;
  },
);

export const getSearchResults = createSelector(
  [getCustomersSearchResults, getStoreSearchResults, getSearchFieldType],
  (customerSearchResults, storeSearchResults, searchFieldType) => {
    if (searchFieldType === "store" && !isEmpty(storeSearchResults)) {
      // The storeSearchResults.StoreServices object is an array of objects that provide
      // properties such as name or if booking is allowed. We need to extract the name
      // from each object and all names need to be placed into a single string.
      let servicesString = "";
      if (storeSearchResults.StoreServices) {
        const servicesObjects = Object.values(storeSearchResults.StoreServices);
        const listOfServices = servicesObjects.map(item => item.ServiceName);
        servicesString = listOfServices.join(", ");
      }

      // Place all values into an array so they can be neatly displayed in a list.
      const storeInfo = [];
      storeInfo.push(`${storeSearchResults.StoreNumber} ${storeSearchResults.Name}`);
      storeInfo.push(storeSearchResults.StreetLine1);
      storeInfo.push(formatPhoneNumberNANP(storeSearchResults.PhoneNumber));
      storeInfo.push(servicesString);

      return storeInfo;
    }
    return customerSearchResults;
  },
);

export const filterBySearchTerm = ({ arr = [], key, searchField }) =>
  arr.filter(get("isActive")).find(obj => obj[key] === searchField || obj.isPrimary);

export const getSearchInfoList = createSelector(
  [
    getCustomerFullName,
    getEmailsByCustomer,
    getPhonesByCustomer,
    getPetsNamesAndIdsByCustomer,
    getSearchFieldValue,
  ],
  (customerFullName, emails, phones, pets, searchField) => {
    const phone = filterBySearchTerm({ arr: phones, key: "phoneNumber", searchField });
    const email = filterBySearchTerm({ arr: emails, key: "email", searchField });

    const normalizedPhoneNumber =
      phone && verifyPhoneNumber(phone.phoneNumber) ? formatPhoneNumberNANP(phone.phoneNumber) : "";
    const normalizedEmail = getOr("", "email", email);
    const petNames = pets.length > 0 ? `Pets: ${pets.map(get("petName")).join(", ")}` : "";

    return [customerFullName, normalizedPhoneNumber, normalizedEmail, petNames];
  },
);

export const getNumberOfSearchResults = createSelector(
  [getSearchResults],
  searchResults => searchResults?.length,
);
