import {
  formatCalendarDateMoment,
  formatDateWithTimeZoneInUTC,
  initMomentObj,
} from "../dateUtils/formatDateTime";
import { getDateRange } from "../dateUtils/dateRanges";
import { updatePetEngagementsFoodMeds } from "./updatePetEngagementsFoodMeds";
import { updatePetEngagementsAddons } from "./updatePetEngagementsAddons";
import { formatFoodOrMedFromEngagements } from "@/dux/hotelEngagements/hotelEngagementSelectors";
import { getDedupedAddonsFromPetEngagements } from "./getAddonsFromPetEngagements";
import { compose } from "lodash/fp";
import { sortHotelEngagements } from "./sortHotelEngagements";
import { setTimeForDate } from "../dateUtils/byDateAndTime";
import {
  getServicesAndRoomsByDate,
  updatePetRoomsAndServices,
} from "./updatePetEngagementRoomAndService";

/**
 * Helper to copy an engagement
 * @memberOf Utils.Engagement
 * @function
 * @name filterEngagementDataToCopy
 * @param {Object} engagement
 * @returns {Object} - copy of engagement with some attributes filtered out
 * @example filterEngagementDataToCopy(engagement)
 */
export const filterEngagementDataToCopy = ({ metadata, petService, ...engagement }) => ({
  engagementId: null,
  hostPetId: null,
  engagementType: engagement?.engagementType,
  status: engagement?.status,
  addOns: [],
  needsReview: engagement?.needsReview,
  metadata: {
    ...metadata,
    room: {},
    feedings: [],
    medications: [],
  },
  petService: {},
});

/**
 * Util to add/remove engagements for a given pets engagements based on new check in & out dates
 * @memberOf Utils.Engagement
 * @function
 * @name addOrRemovePetEngagements
 * @param {Object} args
 * @param {string} args.checkInTime - new check in date & time in stores timezone
 * @param {string} args.checkOutTime - new check out date & time in stores timezone
 * @param {string} args.timeZone - current stores time zone
 * @returns {engagements => Object[]} function to update given engagements with dates
 * @example addOrRemovePetEngagements({ checkInTime, checkOutTime, timeZone, timeOffset })(petEngagements)
 */
export const addOrRemovePetEngagements = ({
  checkInTime,
  checkOutTime,
  timeZone,
}) => petEngagements => {
  const firstEng = petEngagements[0];
  const lastEng = petEngagements?.at(-1);
  const originalCheckIn = initMomentObj(firstEng?.startDatetime, timeZone);
  const originalCheckOut = initMomentObj(lastEng?.endDatetime, timeZone);
  const midStayStartTime = initMomentObj(lastEng?.startDatetime, timeZone);
  const midStayEndTime = initMomentObj(firstEng?.endDatetime, timeZone);

  const start = checkInTime ? initMomentObj(checkInTime, timeZone) : originalCheckIn;
  const end = checkOutTime ? initMomentObj(checkOutTime, timeZone) : originalCheckOut;

  const engagementDates = getDateRange(
    formatCalendarDateMoment(start),
    formatCalendarDateMoment(end),
    "days",
    "YYYY-MM-DD",
  );

  // Last engagement will stay last in order to maintain pricing differences on last day
  const engagementsToCopy = petEngagements.slice(0, -1);

  // For each date in engagementDates make sure there is a corresponding engagement for current pet
  return engagementDates.map((date, index) => {
    const isFirst = index === 0;
    const isLast = index === engagementDates.length - 1;
    const newStart = setTimeForDate(date, isFirst ? start : midStayStartTime, timeZone);
    const newEnd = setTimeForDate(date, isLast ? end : midStayEndTime, timeZone);
    const startDatetime = formatDateWithTimeZoneInUTC(newStart, timeZone);
    const endDatetime = formatDateWithTimeZoneInUTC(newEnd, timeZone);

    // Update last engagement to new checkout date
    if (isLast) return { ...lastEng, startDatetime, endDatetime };

    // Get engagement to be updated or create new one
    const engToCopy = engagementsToCopy[index] ?? filterEngagementDataToCopy(lastEng);

    // Update engagement dates
    return { ...engToCopy, startDatetime, endDatetime };
  });
};

/**
 * Util to update an overnight itinerary with new check in & out dates
 * and move food/med/addons to correct engagements
 * Note: check in will be updated for all pets and check out will only be updated for currentPetId, also times will only be updated for currentPetId
 * @memberOf Utils.Engagement
 * @function
 * @name updateOvernightItineraryDates
 * @param {Object} args
 * @param {Object} args.itinerary
 * @param {string} args.checkInTime - new check in date & time in stores timezone
 * @param {string} args.checkOutTime - new check out date & time in stores timezone
 * @param {string} args.timeZone - current stores time zone
 * @param {string} args.currentPetId - id of pet to edit
 * @param {Object} args.foodExternalData - obj containing food options
 * @param {Object} args.medExternalData - obj containing med options
 * @returns {Object} - updated itinerary
 * @example updateOvernightItineraryDates({ itinerary, checkInTime, checkOutTime, timeZone, currentPetId })
 */
export const updateOvernightItineraryDates = ({
  itinerary,
  checkInTime,
  checkOutTime,
  timeZone = "UTC",
  currentPetId,
  foodExternalData = {},
  medExternalData = {},
}) => {
  const updatedPets = itinerary?.pets?.map(pet => {
    const isCurrentPet = pet?.petKey === currentPetId;
    const shouldUpdateAllPets = !!checkInTime;
    const shouldUpdatePet = isCurrentPet || shouldUpdateAllPets;

    // Short circuit if we shouldn't update this pet
    const petEngagements = sortHotelEngagements(pet?.engagements);
    if (!petEngagements || !shouldUpdatePet) return pet;

    // Copy foods/meds/addons/service/room from original engagements
    const foods = formatFoodOrMedFromEngagements({ petEngagements, isFood: true });
    const meds = formatFoodOrMedFromEngagements({ petEngagements, isFood: false });
    const addons = getDedupedAddonsFromPetEngagements(petEngagements);
    const servicesAndRoomsByDate = getServicesAndRoomsByDate({
      engagements: petEngagements,
      timeZone,
    });

    // Original check in for pets who's check in time should not be updated
    const originalCheckIn = initMomentObj(petEngagements[0]?.startDatetime, timeZone);

    // Add/remove engagements and move food/med/addons/service/room accordingly
    const engagements = compose(
      updatePetEngagementsAddons({ addons, timeZone }),
      updatePetEngagementsFoodMeds({ foods, meds, timeZone, foodExternalData, medExternalData }),
      updatePetRoomsAndServices({ servicesAndRoomsByDate, timeZone }),
      addOrRemovePetEngagements({
        checkInTime: isCurrentPet
          ? checkInTime
          : setTimeForDate(checkInTime, originalCheckIn, timeZone), // Update check in date for all pets
        checkOutTime: isCurrentPet ? checkOutTime : undefined, // only update check out for current pet
        timeZone,
      }),
    )(petEngagements);

    return { ...pet, engagements };
  });

  return { ...itinerary, pets: updatedPets };
};

/**
 * Util to update a same day itinerary with new check in & out dates/times
 * Note: dates will be updated for all pets and times will only be updated for currentPetId
 * @memberOf Utils.Engagement
 * @function
 * @name updateSameDayItineraryDateTimes
 * @param {Object} args
 * @param {Object} args.itinerary
 * @param {string?} args.checkIn - optional new check in date & time in stores timezone
 * @param {string?} args.checkOut - optional new check out date & time in stores timezone
 * @param {string} args.timeZone - current stores time zone
 * @param {string} args.currentPetId - id of pet to edit
 * @returns {Object} - updated itinerary
 */
export const updateSameDayItineraryDateTimes = ({
  itinerary,
  checkIn,
  checkOut,
  currentPetId,
  timeZone = "UTC",
}) => {
  return {
    ...itinerary,
    pets: itinerary?.pets?.map(pet => {
      const isCurrentPet = currentPetId === pet?.petKey;
      const engagement = pet?.engagements[0] ?? {};

      const originalCheckIn = initMomentObj(engagement?.startDatetime, timeZone);
      const originalCheckOut = initMomentObj(engagement?.endDatetime, timeZone);

      const date = checkIn ?? checkOut ?? originalCheckIn;
      const start = checkIn ? initMomentObj(checkIn, timeZone) : originalCheckIn;
      const end = checkOut ? initMomentObj(checkOut, timeZone) : originalCheckOut;

      // Update check in & out date for all pets but only update times for current pet
      const newStart = setTimeForDate(date, isCurrentPet ? start : originalCheckIn, timeZone);
      const newEnd = setTimeForDate(date, isCurrentPet ? end : originalCheckOut, timeZone);

      return {
        ...pet,
        engagements: [
          {
            ...engagement,
            startDatetime: formatDateWithTimeZoneInUTC(newStart, timeZone),
            endDatetime: formatDateWithTimeZoneInUTC(newEnd, timeZone),
          },
        ],
      };
    }),
  };
};
