import React from "react";
import { connect } from "react-redux";

import { columnHeaders } from "@/core/constants/dashboardContants";
import ListFilterButtons from "@/dux/_components/buttons/ListFilterButtons";
import {
  HotelAllFilterBtnCompNames,
  HotelAttentionNeededFilterBtnCompNames,
  HotelUnassignedPetFilterBtnCompNames,
} from "@/dux/_constants/dynamicComponentNames";

// Takes up same amount of space as SmallPillButton component
export const filterSpace = {
  [columnHeaders.OVERNIGHT]: () => <span style={{ width: "80px", height: "1.9rem" }} />,
};

const mapStateToProps = () => ({
  isHidden: false,
  componentID: "ListFilterButtons__HotelOverNight",
  compName: columnHeaders.OVERNIGHT,
  filters: [
    HotelAllFilterBtnCompNames,
    HotelAttentionNeededFilterBtnCompNames,
    HotelUnassignedPetFilterBtnCompNames,

    // Empty components to take up space because the overnight column has fewer filter btns than the others
    filterSpace,
    filterSpace,
    filterSpace,
  ],
});

export default connect(mapStateToProps)(ListFilterButtons);
