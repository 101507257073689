import React, { useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import find from "lodash/fp/find";
import { List } from "react-virtualized";

// Selectors
import {
  getWeeklyEditActivities,
  getSelectedAssociate,
  getStartWeekDate,
  getLunchBreakTimes,
} from "@/core/selectors/schedulesSelectors";
import { getSalons } from "@/core/selectors/entitiesSelector";

// Actions
import schedulesActionTypes from "@/core/actionTypes/schedulesActionTypes";
import {
  hideSchedulesModal,
  addPartialDayRelocation,
  loadLunchBreakTimes,
} from "@/core/actionCreators/schedulesActionCreators";
import { loadRelocatedSalonHours } from "@/core/actionCreators/salonHoursActionCreators";

// Utils
import { createLoadingSelector } from "@/core/selectors/utils";

// Components
import Button from "@/web/common/commonButton";
import Select from "@/web/common/Select";
import TimeRangePicker from "@/web/common/TimeRange/TimeRangePicker";
import LoadingWrapper from "@/web/common/LoadingWrapper";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutBox } from "@/layout/box/Box";

// Constants
import { weekDaysNames } from "@/core/constants";
import { color } from "@/web/common/styles/theme";
import { dayActivityTypes } from "@/core/constants/schedulesConstants";

const MenuList = props => {
  const rows = props.children;
  const rowRenderer = ({ key, index, style }) => (
    <LayoutBox key={key} padding="scale-0" style={{ ...style, overflow: "hidden" }}>
      {rows[index]}
    </LayoutBox>
  );

  return (
    <List
      style={{ width: "100%" }}
      width={300}
      height={300}
      rowHeight={30}
      rowCount={rows.length || 0}
      rowRenderer={rowRenderer}
    />
  );
};

export const PartialDayRelocationComponent = props => {
  const {
    componentId,
    salonsOptions,
    weekdaysOptions,
    dayActivities,
    selectedAssociate,
    isLoading,
    onStoreChange = () => {},
    onSave = () => {},
    closeModal = () => {},
  } = props;

  const [dayOfWeek, setDayOfWeek] = useState();
  const [startTime, setStartTime] = useState("00:00");
  const [endTime, setEndTime] = useState("00:00");
  const [storeId, setStoreId] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const validateValues = () => {
    // Check Day - Required
    if (!dayOfWeek) {
      setErrorMessage(`Please select a day`);
      return false;
    }

    // Check Day - is Relocation already exists for that day
    if (find({ dayOfWeek, type: dayActivityTypes.RELOCATION }, dayActivities)) {
      setErrorMessage(
        `This action exceeds the maximum number of partial relocations for this associate on this day. (${dayOfWeek})`,
      );
      return false;
    }

    // Check Working Hours
    if (startTime >= endTime) {
      setErrorMessage("End Time must be greater than Start Time");
      return false;
    }

    // Check StoreId
    if (!storeId) {
      setErrorMessage("Please select Store #");
      return false;
    }

    setErrorMessage(null);
    return true;
  };

  const handleSave = () => {
    if (validateValues()) {
      const partialDay = {
        dayOfWeek,
        startTime,
        endTime,
        storeId,
      };

      onSave(partialDay);
    }
  };

  const handleStoreChange = e => {
    const newStoreId = e.value;
    if (!newStoreId) return;

    setStoreId(newStoreId);
    setErrorMessage(null);
    onStoreChange(newStoreId);
  };

  // Derived state
  const selectedDay = find({ value: dayOfWeek }, weekdaysOptions);
  const selectedStoreId = find({ value: storeId }, salonsOptions);

  // Shared styles
  const fieldStyles = { color: color.blue500, marginTop: "10px", marginRight: "5px" };
  const selectWrapperStyles = { width: "200px", borderBottom: "1px solid #dddddd" };

  return (
    <LayoutBox id={componentId} padding="scale-0" style={{ padding: "0 50px", width: "1000px" }}>
      <LoadingWrapper display="block" displayContainer="block" isLoading={isLoading}>
        <LayoutBox
          padding="scale-0"
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Partial Day Relocation Details
        </LayoutBox>
        <LayoutBox padding="scale-0" style={{ textAlign: "center" }}>
          Please specify day, work hours and store ID# for partly relocation for{" "}
          {selectedAssociate.associateName}
        </LayoutBox>

        <LayoutCluster style={{ justifyContent: "space-between" }}>
          <LayoutBox padding="scale-0">
            <LayoutBox padding="scale-0" style={fieldStyles}>
              Day
            </LayoutBox>
            <LayoutBox padding="scale-0" style={selectWrapperStyles}>
              <Select
                components={{ MenuList }}
                options={weekdaysOptions}
                value={selectedDay}
                onChange={e => {
                  setDayOfWeek(e.value);
                  setErrorMessage(null);
                }}
              />
            </LayoutBox>
          </LayoutBox>

          <LayoutBox padding="scale-0">
            <LayoutBox padding="scale-0" style={fieldStyles}>
              Work Hours
            </LayoutBox>
            <LayoutBox padding="scale-0" style={{ marginTop: 10 }}>
              <TimeRangePicker
                backgroundColor="inherit"
                startTime={startTime}
                endTime={endTime}
                onChange={newTimes => {
                  if (newTimes?.startTime)
                    setStartTime(moment(newTimes?.startTime, "HH:mm").format("HH:mm:ss"));
                  if (newTimes?.endTime)
                    setEndTime(moment(newTimes?.endTime, "HH:mm").format("HH:mm:ss"));
                  setErrorMessage(null);
                }}
              />
            </LayoutBox>
          </LayoutBox>

          <LayoutBox padding="scale-0">
            <LayoutBox padding="scale-0" style={fieldStyles}>
              Store #
            </LayoutBox>
            <LayoutBox padding="scale-0" style={selectWrapperStyles}>
              <Select
                components={{ MenuList }}
                options={salonsOptions}
                value={selectedStoreId}
                onChange={handleStoreChange}
              />
            </LayoutBox>
          </LayoutBox>
        </LayoutCluster>

        <LayoutCluster
          space="scale-0"
          style={{
            justifyContent: "space-between",
            marginTop: "30px",
            borderTop: `1px solid ${color.gray200}`,
            minHeight: "79px",
          }}
        >
          <LayoutBox padding="scale-0" style={{ color: "red" }}>
            {errorMessage}
          </LayoutBox>
          <LayoutCluster style={{ justifyContent: "space-between", width: "200px" }}>
            <LayoutBox
              padding="scale-0"
              style={{ cursor: "pointer", color: color.blue500, fontSize: "16px" }}
              onClick={closeModal}
            >
              Cancel
            </LayoutBox>
            <Button label="Save" onClick={() => handleSave()} />
          </LayoutCluster>
        </LayoutCluster>
      </LoadingWrapper>
    </LayoutBox>
  );
};

export const PartialDayRelocation = connect(
  state => {
    const salons = getSalons(state);
    return {
      componentId: "PartialDayRelocation",
      startWeekDate: getStartWeekDate(state),
      selectedAssociate: getSelectedAssociate(state),
      salonsOptions: Object.values(salons).map(salon => ({
        value: salon.StoreNumber,
        label: `${salon.Name} (#${salon.StoreNumber})`,
      })),
      weekdaysOptions: weekDaysNames.map(day => ({ value: day, label: day })),
      dayActivities: getWeeklyEditActivities(state),
      lunchBreakTimes: getLunchBreakTimes(state),
      isLoading: createLoadingSelector([schedulesActionTypes.LOAD_LUNCH_BREAK_TIMES])(state),
    };
  },
  dispatch => ({
    closeModal: () => dispatch(hideSchedulesModal()),
    dispatchAddPartialDayRelocation: partialDay => dispatch(addPartialDayRelocation(partialDay)),
    dispatchLoadLunchBreakTimes: storeNumber => dispatch(loadLunchBreakTimes(storeNumber)),
    dispatchLoadRelocatedSalonHours: ({ storeNumber, beginDate, endDate }) =>
      dispatch(loadRelocatedSalonHours({ storeNumber, beginDate, endDate })),
  }),
  (stateProps, dispatchProps) => {
    const {
      componentId,
      startWeekDate,
      selectedAssociate,
      salonsOptions,
      weekdaysOptions,
      dayActivities,
      lunchBreakTimes,
      isLoading,
    } = stateProps;

    const {
      closeModal,
      dispatchAddPartialDayRelocation,
      dispatchLoadLunchBreakTimes,
      dispatchLoadRelocatedSalonHours,
    } = dispatchProps;

    return {
      componentId,
      salonsOptions,
      weekdaysOptions,
      dayActivities,
      selectedAssociate,
      isLoading,
      onStoreChange: storeNumber => {
        if (!lunchBreakTimes[storeNumber]) {
          dispatchLoadLunchBreakTimes(storeNumber);
        }

        const beginDate = moment(startWeekDate).startOf("isoWeek");
        const endDate = moment(startWeekDate).endOf("isoWeek");
        dispatchLoadRelocatedSalonHours({ storeNumber, beginDate, endDate });
      },
      onSave: partialDay => {
        dispatchAddPartialDayRelocation(partialDay);
        closeModal();
      },
      closeModal,
    };
  },
)(PartialDayRelocationComponent);
