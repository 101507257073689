import { createSelector } from "reselect";
import moment from "moment";
import { serviceTypeIds } from "core/constants/serviceTypesConstants";
import {
  formatCalendarDateMoment,
  formatDayHourMinutes,
  formatHourMinuteTime,
} from "core/utils/dateUtils/formatDateTime";
import getHotelHoursState from "./_hotelState";
import { getStoreTimeZone } from "@/core/selectors/entitiesSelector";
import { getProps } from "@/core/selectors/commonSelector";
import { dateTimeErrors } from "@/dux/hotelBookingDateTimeSelection/hotelBookingDateTimeConstants";

export const selectHotelHours = createSelector(
  [getHotelHoursState],
  hotelHours => hotelHours?.[serviceTypeIds.HOTEL] ?? {},
);

export const selectDDCHours = createSelector(
  [getHotelHoursState],
  hotelHours => hotelHours?.[serviceTypeIds.DDC] ?? {},
);

export const selectIsSameDay = createSelector(
  [getStoreTimeZone, getProps],
  (timeZone, { date, startDateTime, endDateTime }) => {
    const start = startDateTime ? formatCalendarDateMoment(startDateTime, timeZone) : date;
    const end = endDateTime ? formatCalendarDateMoment(endDateTime, timeZone) : date;

    return moment(start).isSame(moment(end), "day");
  },
);

export const selectHoursByLengthOfStay = createSelector(
  [selectHotelHours, selectDDCHours, selectIsSameDay],
  (hotelHours, ddcHours, isSameDay) => {
    return isSameDay ? ddcHours : hotelHours;
  },
);

export const getHotelHoursByDate = createSelector(
  [selectHoursByLengthOfStay, getProps],
  (hours, { date }) => {
    return hours?.[date];
  },
);

export const getHotelHoursOpenTimeByDate = createSelector([getHotelHoursByDate], hours => {
  return hours?.OpenTime;
});

export const getHotelHoursCloseTimeByDate = createSelector([getHotelHoursByDate], hours => {
  return hours?.CloseTime;
});

export const getHotelIsClosedByDate = createSelector([getHotelHoursByDate], hours => {
  return hours?.IsClosed;
});

/**
 *  Selector to get error message if store is closed, or if given check out date and time are out of bounds for the store hours
 *  @memberOf Selectors.HotelHours
 *  @function
 *  @name getHotelCheckOutHourOutsideRange
 *  @param {Object} state - redux state
 *  @param {string | moment} props.startDateTime - reservation startDateTime
 *  @param {string} props.time - check out time
 *  @param {string} props.date - check out date
 *  @returns {string | JSX}
 *  @example getHotelCheckOutHourOutsideRange(state, { startDateTime, time, date })
 */
export const getHotelCheckOutHourOutsideRange = createSelector(
  [
    getHotelHoursOpenTimeByDate,
    getHotelHoursCloseTimeByDate,
    getHotelIsClosedByDate,
    getStoreTimeZone,
    selectIsSameDay,
    getProps,
  ],
  (openTime, closeTime, isClosed, timeZone, isSameDay, { startDateTime, time }) => {
    if (isClosed) {
      return isSameDay ? dateTimeErrors.SAME_DAY_CLOSED : dateTimeErrors.CLOSED;
    }

    const start = isSameDay ? formatHourMinuteTime(startDateTime, timeZone) : openTime;
    const formattedOpenTime = formatDayHourMinutes(start);
    const formattedCloseTime = formatDayHourMinutes(closeTime);

    return (
      (closeTime < time || start > time) &&
      `Time must be between ${formattedOpenTime}-${formattedCloseTime}`
    );
  },
);

/**
 *  Selector to check if given check in date and time are out of bounds for the store hours
 *  @memberOf Selectors.HotelHours
 *  @function
 *  @name getHotelCheckInHourOutsideRange
 *  @param {Object} state - redux state
 *  @param {string | moment} props.endDateTime - reservation endDateTime
 *  @param {string} props.time - check in time
 *  @param {string} props.date - check in date
 *  @returns {boolean}
 *  @example getHotelCheckInHourOutsideRange(state, { endDateTime, time, date })
 */
export const getHotelCheckInHourOutsideRange = createSelector(
  [
    getHotelHoursOpenTimeByDate,
    getHotelHoursCloseTimeByDate,
    getStoreTimeZone,
    selectIsSameDay,
    getProps,
  ],
  (openTime, closeTime, timeZone, isSameDay, { endDateTime, time }) => {
    const end = isSameDay ? formatHourMinuteTime(endDateTime, timeZone) : closeTime;

    return time < openTime || time > end;
  },
);

/**
 *  Selector to format open and close time or to return hotel is closed msg
 *  @memberOf Selectors.HotelHours
 *  @function
 *  @name getFormattedTimeRange
 *  @param {Object} state - redux state
 *  @param {string | moment} props.endDateTime - reservation endDateTime
 *  @param {string} props.date - check in date
 *  @returns {object} { isClosed, formattedOpenTime, formattedCloseTime }
 *  @example getFormattedTimeRange(state, { endDateTime, date })
 */
export const getFormattedTimeRange = createSelector(
  [
    getHotelHoursOpenTimeByDate,
    getHotelHoursCloseTimeByDate,
    getHotelIsClosedByDate,
    getStoreTimeZone,
    selectIsSameDay,
    getProps,
  ],
  (openTime, closeTime, isClosed, timeZone, isSameDay, { endDateTime }) => {
    if (isClosed) {
      return isSameDay ? dateTimeErrors.SAME_DAY_CLOSED : dateTimeErrors.CLOSED;
    }

    const endTime = isSameDay ? formatHourMinuteTime(endDateTime, timeZone) : closeTime;
    const formattedCloseTime = formatDayHourMinutes(endTime);
    const formattedOpenTime = formatDayHourMinutes(openTime);

    return { isClosed, formattedOpenTime, formattedCloseTime };
  },
);
