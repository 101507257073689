import { connect } from "react-redux";
import { getOtherMedicationNameById } from "./otherMedicationNameSelectors";
import InputWithBlur from "../common/InputWithBlur";
import { getMedicationIsShowingDetails } from "../medicationList/medicationListSelectors";
import { REQUIRED_FIELD_ERROR_MESSAGE } from "../../core/constants/validationErrors";
import { OTHER_MEDICATION_NAME } from "../newMedication/newMedicationConstants";
import {
  getMedicationNameExternalIdById,
  getMedicationNameByExternalId,
} from "../medicationName/medicationNameSelectors";
import { setMedicationName } from "../medicationName/actions/setMedicationNameActions";
import { isValidString } from "@/core/utils/validationUtils/stringValidation";
import { getMedIsDiscontinuedById } from "../medication/medicationsSelector";

const mapStateToProps = (state, ownProps) => {
  const { medicationId, petId } = ownProps;
  const currentValue = getOtherMedicationNameById(state, { medicationId, petId });
  const isValid = isValidString(currentValue);
  const isDiscontinued = getMedIsDiscontinuedById(state, { petId, medicationId });
  const disabled =
    isDiscontinued ||
    getMedicationNameByExternalId(state, { medicationId, petId }) !== OTHER_MEDICATION_NAME;
  const hasError = !disabled && !isValid;
  return {
    medicationId,
    currentValue,
    petId,
    value: currentValue,
    isHidden: !getMedicationIsShowingDetails(state, { medicationId, petId }),
    display: "grid",
    title: "Other medication name",
    componentId: "medicationDetails-OTHER_MEDICATION_NAME",
    medicationExternalId: getMedicationNameExternalIdById(state, { medicationId, petId }),
    disabled,
    error: hasError && REQUIRED_FIELD_ERROR_MESSAGE,
  };
};

const mapDispatchToProps = dispatch => ({
  onChangeMedicationType: ({ value, medicationExternalId, medicationId, petId }) => {
    dispatch(
      setMedicationName({ name: value, externalId: medicationExternalId, medicationId, petId }),
    );
  },
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  onChange: value => {
    const { medicationId, medicationExternalId, petId } = propsFromState;
    propsFromDispatch.onChangeMedicationType({ value, medicationExternalId, medicationId, petId });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(InputWithBlur);
