import React from "react";
import { connect } from "react-redux";
import { color } from "@/web/common/styles/theme";
import { getStoreCountry } from "@/core/selectors/entitiesSelector";
import { Layout, Text } from "@prism/psm-ui-components";
import { TrainingClassNotesContainer } from "dux/trainingClassNotes/TrainingClassNotes";
import { TimeZoneCustomerVPTContainer } from "dux/timeZoneCustomer/TimeZoneCustomer";
import { getStoreNumber } from "core/selectors/persistentSelectors";
import { TrainingClassTimesContainer } from "dux/trainingClassAvailability/TrainingClassAvailabilityList";
import ScheduleTrainingButtonWithModal from "@/dux/_components/scheduleTraining/ScheduleTrainingButtonWithModal";
import ToggleClassByTrainerContainer from "@/dux/filteringButtons/ToggleClassByTrainerContainer";
import PetProfileDetails from "@/web/petProfileDetails/PetProfileDetailsTrainingContainer";
import BookingPetTabsTrainingContainer from "@/dux/bookingPetTabs/BookingPetTabsTrainingContainer";
import TrainingCalendarContainer from "@/dux/selectedDate/TrainingCalendarContainer";
import WeeklyNavigationBarTrainingContainer from "@/dux/selectedDate/WeeklyNavigationBarTrainingContainer";
import TrainingClassTypeListContainer from "@/dux/scheduleTrainingPage/TrainingClassTypeListContainer";
import CancelTrainingAppointmentModal from "@/dux/_components/cancelTrainingAppointmentModal/CancelTrainingAppointmentModal";

// Feature Components --------------------------------------------------------------------------------------------------
const StoreLocationContainer = connect(state => ({
  children: `Virtual - ${getStoreCountry(state, { storeNumber: getStoreNumber(state) })}`,
}))(Text);

// Layout Components ---------------------------------------------------------------------------------------------------
function UpperContent({ children, ...props }) {
  return (
    <Layout.Box
      padding="box-padding-10"
      borderColor="box-border-color-gray-200"
      borderWidth
      {...props}
    >
      {children}
    </Layout.Box>
  );
}

function LowerContent({ children, ...props }) {
  return (
    <Layout.Box {...props}>
      <Layout.Switcher
        threshold="switcher-threshold-6"
        growNthChild={1}
        growValue="switcher-grow-value-8"
      >
        {children}
      </Layout.Switcher>
    </Layout.Box>
  );
}

function ColumnA({ children, ...props }) {
  return <Layout.Box {...props}>{children}</Layout.Box>;
}

function ColumnB({ children, ...props }) {
  return (
    <Layout.Box padding="box-padding-7" {...props}>
      {children}
    </Layout.Box>
  );
}

function ColumnC({ children, ...props }) {
  return (
    <Layout.Box padding="box-padding-7" {...props}>
      <Layout.Stack space="stack-space-5" style={{ border: "none" }}>
        {children}
      </Layout.Stack>
    </Layout.Box>
  );
}

export default function ScheduleTrainingColumns({ componentID }) {
  return (
    <Layout.Box id={componentID}>
      <Layout.Stack>
        <UpperContent>
          <BookingPetTabsTrainingContainer />
        </UpperContent>
        <LowerContent>
          <ColumnA>
            <PetProfileDetails />
          </ColumnA>
          <ColumnB>
            <Layout.Stack space="stack-space-7" style={{ border: "none" }}>
              <Layout.Box>
                <TrainingClassTypeListContainer />
              </Layout.Box>
              <Layout.Box>
                <TrainingCalendarContainer />
              </Layout.Box>
              <Layout.Box>
                <WeeklyNavigationBarTrainingContainer />
              </Layout.Box>
              <Layout.Box>
                <ToggleClassByTrainerContainer />
              </Layout.Box>
              <Layout.Box>
                <TrainingClassTimesContainer />
              </Layout.Box>
            </Layout.Stack>
          </ColumnB>

          <ColumnC>
            <TimeZoneCustomerVPTContainer />

            <Layout.Box
              padding="box-padding-4"
              style={{
                borderTop: `1px solid ${color.gray200}`,
                borderBottom: `1px solid ${color.gray200}`,
              }}
            >
              <Layout.Cluster justify="space-between" space="cluster-space-5">
                <Layout.Stack>
                  <Text>Total Price</Text>
                  <Text>$0.00</Text>
                  <Text>Prepaid Package</Text>
                </Layout.Stack>
                <ScheduleTrainingButtonWithModal />
              </Layout.Cluster>
            </Layout.Box>
            <Layout.Box style={{ "--setStackSpace": "1.5rem" }}>
              <Layout.Stack space="stack-space-4">
                <Text size="text-size-xl" bold>
                  Appointment Summary
                </Text>
                <StoreLocationContainer />
              </Layout.Stack>
            </Layout.Box>
            <TrainingClassNotesContainer />
            <CancelTrainingAppointmentModal />
          </ColumnC>
        </LowerContent>
      </Layout.Stack>
    </Layout.Box>
  );
}
