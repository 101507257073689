import moment from "moment";
import momentTz from "moment-timezone";

/** @module formatDateTime */

/**
 * Returns a moment object using moment timezone if a timezone is passed else using moment
 * @exports initMomentObj
 * @param {string|moment} date
 * @param {string?} timeZone
 * @returns {moment} a moment object
 */
export const initMomentObj = (date, timeZone) =>
  timeZone ? momentTz.tz(date, timeZone) : moment(date);

/**
 * Format a moment object to YYYY-MM-DDTHH:mm:ss
 *
 * @exports formatMoment
 * @param {object} moment - Input date as a moment object
 * @returns {string} - a sting that represents the date in the following format 'YYYY-MM-DDTHH:mm:ss'
 */
export function formatMoment(momentObj) {
  return momentObj.format("YYYY-MM-DDTHH:mm:ss");
}

/**
 * Format a moment object to YYYY-MM-DD
 *
 * @exports formatCalendarDateMoment
 * @param {object} moment - Input date as a moment object
 * @param {String} timeZone - optional param to specify time zone
 * @returns {string} - a sting that represents the date in the following format 'YYYY-MM-DD'
 */
export function formatCalendarDateMoment(date, timeZone) {
  return initMomentObj(date, timeZone).format("YYYY-MM-DD");
}

/**
 * Format a date to HH:mm
 * @exports formatHourMinuteTime
 * @param {string|moment} date
 * @param {string?} timeZone
 * @returns {string}
 */
export function formatHourMinuteTime(date, timeZone) {
  return initMomentObj(date, timeZone).format("HH:mm");
}

/**
 * Format a moment object to YYYY-MM-DD
 *
 * @exports formatHourTimeMoment
 * @param {object} moment - Input date as a moment object
 * @returns {string} - a sting that represents the time in the following format 'HH'
 */
export function formatHourTimeMoment(date) {
  return moment(date).format("HH:mm:ss");
}

/**
 * Gets today's date and formats it to an ISO date format, i.e. '01/01/2018'
 *
 * @exports getTodayISODate
 * @returns {String}
 */
export function getTodayISODate() {
  return moment().format("YYYY-MM-DD");
}

/**
 * formats dates to MM/DD/YYYY with slashes  , i.e. '01/01/2018'
 * @param {string | moment } - date to format
 * @exports getTodayISODate
 * @returns {String}
 */
export function getMoDayYearDate(dateToFormat) {
  return moment(dateToFormat).format("l");
}

/**
 * Responsible for formating the date with Day Name, Month, Day, four digit year, e.g. Wednesday, April 17, 2019
 *
 * @exports formatDayNameMonthDayYear
 * @returns {String}
 */
export function formatDayNameMonthDayYear(selectedDate) {
  return moment(selectedDate).format("dddd, MMMM DD, YYYY");
}

/**
 * Responsible for formating the date with Day Name, Month, Day, e.g. Wednesday, April 17
 *
 * @exports formatDayNameMonthDay
 * @returns {String}
 */
export function formatDayNameMonthDay(selectedDate) {
  return moment(selectedDate).format("dddd, MMMM DD");
}

/**
 * Use When you need to keep the UTC date and not have moment format to local(browser)
 * @param {string | moment } - date to format
 * @exports formatUTCDate
 * @returns {String}
 */
export function formatUTCDate(date) {
  const formatDate = moment(date)
    .utc()
    .format("MM/DD/YYYY");
  return formatDate;
}

/**
 * Responsible for formatting the date with time with AM/PM, e.g. 11:30 AM
 *
 * @exports formatDayHourMinutes
 * @returns {String}
 */
export function formatDayHourMinutes(selectedDate) {
  return moment(selectedDate, "HH:mm:ss").format("h:mm A");
}

/**
 * Coverts a date with a given time zone into a UTC string
 * @name formatDateWithTimeZoneInUTC
 * @param {string | moment} date - date to format
 * @param {string} timeZone
 * @returns {string}
 * @example formatDateWithTimeZoneInUTC(date, timeZone)
 */
export function formatDateWithTimeZoneInUTC(datetime, timeZone) {
  return initMomentObj(datetime, timeZone)
    .utc()
    .format();
}

/**
 * Formats the date with Month Day, e.g. April 17
 * @exports formatMonthDay
 * @returns {String}
 */
export function formatMonthDay(date) {
  return moment(date).format("MMMM D");
}
