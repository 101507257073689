import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { compose } from "redux";
import every from "lodash/fp/every";
import isEmpty from "lodash/fp/isEmpty";

// Components
import {
  HotelBookingPetTabs,
  SalonBookingPetTabs,
} from "@/dux/bookingPetTabs/BookingPetTabsContainer";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutStack } from "@/layout/stack/Stack";
import BookingService from "@/web/booking/bookingService/BookingServiceContainer";
import BookingTimeSlot from "@/web/booking/bookingTImeSlot/BookingTimeSlotContainer";
import BookingModals from "@/web/booking/modals/bookingModalsContainer";
import CartContainer from "@/web/cart/CartContainer";
import LoadingWrapper from "@/web/common/LoadingWrapper";
import { BookingHeader } from "@/web/header/BookingHeaderContainer";
import {
  HotelBookingAppointmentColumn,
  SalonBookingAppointmentColumn,
} from "@/dux/appointmentColumn/AppointmentColumn";

// Actions
import { loadAssociates } from "@/core/actionCreators/associateActionCreator";
import bookingActionCreators, { selectPet } from "@/core/actionCreators/bookingActionCreators";
import customerActionCreator from "@/core/actionCreators/customer/customerActionCreator";
import customersActionCreators from "@/core/actionCreators/customersActionCreators";
import uiActionCreators from "@/core/actionCreators/ui/uiActionCreators";
import {
  selectPetService,
  setAddons,
  setLockToAssociate,
} from "@/core/actionCreators/ui/web/cartActionCreators";
import { selectAssociateWeekly } from "@/core/actionCreators/ui/web/commonActionCreators";
import { clearNewAppointmentData } from "@/core/actionCreators/ui/web/dashboardActionCreators";
import { setPetId } from "@/core/actionCreators/ui/web/generalActionCreators";
import bookingActionTypes from "@/core/actionTypes/bookingActionTypes";
import customersActionTypes from "@/core/actionTypes/customersActionTypes";
import itinerariesActionTypes from "@/core/actionTypes/itinerariesActionTypes";
import petVaccinationsActionTypes from "@/core/actionTypes/petVaccinationsActionTypes";
import {
  POST_BULK_PACKAGE_OFFERINGS,
  purchaseBulkPackageOfferings,
} from "@/dux/bgm/bulkPackageOfferings/bulkPackageOfferingsActions";
import { hotelBookingActionTypes } from "@/dux/bookHotel/hotelBookingActions";
import { DELETE_CART, PUT_CART } from "@/dux/servicesCart/servicesCartActions";
import { setSystemBookingFlowType } from "@/web/setSystemType/actions/setSystemTypeActions";
import { getBookingScreenEligibility } from "dux/eligibility/actions/eligibilityByScreenActions";
import { eligibilityPetActionTypes } from "dux/eligibility/actions/eligibilityPetActions";
import { setEnhancedServicesAddon } from "dux/enhancedServicesList/EnhancedServicesListActions";
import { loadHotelPriceAdjustmentReasons } from "dux/hotelPriceAdjustmentReasons/hotelPriceAdjustmentActions";

// Selectors
import {
  getAreAllServicesScheduledOnTheSameDate,
  getCanFinalizeBooking,
  getSelectedPet,
} from "@/core/selectors/bookingUISelectors";
import {
  getCanNotBookPetsInCart,
  getCartDetails,
  getCurrentPetServiceId,
  getSelectedAddOns,
} from "@/core/selectors/cartSelectors";
import {
  getAddonIdsByService,
  getAssociates,
  getCustomer,
  getPetsNamesAndIdsAndActiveByCustomer,
} from "@/core/selectors/entitiesSelector";
import { getBookingModalType } from "@/core/selectors/modals/bookingModalSelectors";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { getIsSRCAgent } from "@/core/selectors/persistentSelectors";
import {
  getIsAnyBulkPackageOfferingSelected,
  getSelectedProductBundleConfigId,
} from "@/dux/bgm/bulkPackageOfferings/bulkPackageOfferingsSelectors";
import { getHotelBookingAlertReasons } from "@/web/hotelAlerts/hotelBookingAlertsSelectors";
import {
  getSystemBookingFlow,
  getSystemType,
} from "@/web/setSystemType/selectors/setSystemTypeSelectors";
import { getIsStandaloneNotBookable } from "dux/_selectors/standaloneSelectors";
import { getCustomerCanBook } from "dux/eligibility/selectors/customerCanBookSelectors";
import { getAllPetsCanNotBook } from "dux/eligibility/selectors/eligibilityPetsCanNotBookSelectors";
import { getPageMountReasons } from "dux/eligibility/selectors/getReasonsSelectors";
import { selectEnhancedServicesCart } from "dux/enhancedServicesList/EnhancedServicesListSelectors";
import getIsSalonCartMigrationWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/salonCartMigration/getIsSalonCartMigrationWorkflowFeatureFlagHidden";

// Helpers & Utils
import { createLoadingSelector } from "@/core/selectors/utils";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import {
  isFromModifyAppointment,
  isFromSelectTimeSlot,
} from "@/core/utils/validationUtils/isFromValidation";
import {
  deriveActiveAddons,
  deriveActiveEnhancedService,
  hasAPrimaryStoreNumber,
} from "@/web/booking/salonBookingHelpers";
import isPetAndCustomerEligibilityReasonsEmpty from "dux/eligibility/helperUtils/isPetAndCustomerEligibilityReasonsEmpty";

// Assets
import HeaderImage from "@/assets/images/bg-header-image.png";

// Constants
import { modalTypes as bookingModalTypes } from "@/core/constants/bookingConstants";
import { modalTypes as commonModalTypes } from "@/core/constants/modalConstants";
import { systemName } from "@/web/setSystemType/constants/setSystemTypeConstants";
import { GET_HOTEL_ITINERARY } from "../hotelItinerary/hotelItineraryActions";

/**
 * A view component for the salon and hotel booking pages
 * @memberof Views.Booking
 * @function
 * @param {Object} props
 * @param {string} props.componentId
 * @param {Boolean} props.isLoading
 * @param {Object[]} props.pets
 * @param {Array} props.appointmentPets
 * @param {string} props.customerKey
 * @param {string} props.selectedPet
 * @param {Function} props.onSelectPet
 * @param {string} props.modalType
 * @param {Boolean} props.isHidden
 * @param {Boolean} props.isStandalone
 * @param {Boolean} props.isModify
 * @param {Function} props.onPageLoad
 * @param {Function} props.showRedirectionModal
 * @param {Boolean} props.doNotBook
 * @param {Array} props.addOnsIdListByService
 * @param {Boolean} props.hasPetOrCustomerAlertReasons
 * @param {Function} props.handleAddOns
 * @param {Function} props.onPageUnMount
 * @param {Function} props.showAlertsModal
 * @param {string} props.systemBookingFlow
 * @param {Function} props.buildCartFooterActions
 * @param {Object} props.diComp
 * @returns {JSX.Element|null}
 */
export const BookingComponent = props => {
  const {
    componentId,
    isLoading,
    pets,
    appointmentPets,
    customerKey,
    selectedPet,
    onSelectPet,
    modalType,
    isHidden,
    isStandalone = false,
    isModify = false,
    onPageLoad = () => {},
    showRedirectionModal,
    doNotBook,
    addOnsIdListByService,
    hasPetOrCustomerAlertReasons,
    handleAddOns = () => {},
    onPageUnMount = () => {},
    showAlertsModal = () => {},
    systemBookingFlow,
    buildCartFooterActions = () => {},
    diComp,
  } = props;

  // Obj containing petIds of pets who's alert modal has been shown -> { [petId]: true }
  const [showedAlertsModalByPet, setShowedAlertsModalByPet] = useState({});

  // flag for if CTA has been clicked or not
  const [actionClicked, setActionClicked] = useState(false);

  // On mount
  useEffect(() => {
    if (!isHidden) {
      onPageLoad();
    }
  }, [isHidden]);

  //
  useEffect(() => {
    /* * ----------------------------------------------------------------------- * *\
      For Salon, we need to wait until the Eligibility (Do not Book, or any Pet Alerts)
      call is returned before we attempt to display any modals.
      We do one more check if a modal for that particular pet has fired, if
      we have seen a modal for this pet already, we do not want to display modals
      again on a re-render, if we have not, and both prior conditions are true,
      we finally want to show a modal.
    \* * ----------------------------------------------------------------------- * */
    if (!isHidden && hasPetOrCustomerAlertReasons && !showedAlertsModalByPet[selectedPet]) {
      displayModals();
    }
  }, [isHidden, selectedPet, hasPetOrCustomerAlertReasons]);

  useEffect(() => {
    // if there is no pet ID, but pets has data, update redux state with the 1st pet
    if (!isHidden && !selectedPet && pets?.length) {
      onSelectPet(Number(pets[0].petId));
    }
  }, [isHidden, selectedPet, pets]);

  useEffect(() => {
    // When should redirect modal show/ Hotel or Salon or both
    if (!isHidden && doNotBook) {
      showRedirectionModal();
    }
  }, [isHidden, doNotBook]);

  useEffect(() => {
    if (!isHidden && addOnsIdListByService?.length) {
      handleAddOns();
    }
  }, [isHidden, addOnsIdListByService]);

  // Un Mount
  useEffect(() => {
    return () => {
      onPageUnMount();
    };
  }, []);

  /**
   * Responsible for determining what type of modal shows for Salon Booking
   */
  function displaySalonModal() {
    // Show alerts
    showAlertsModal();

    // set flag that alerts have been shown for the current pet
    setShowedAlertsModalByPet(prev => ({ ...prev, [selectedPet]: true }));
  }

  /**
   * Responsible for determining if a hotel, or salon modal should show.
   */
  function displayModals() {
    const isSalon = systemBookingFlow === systemName.SALON;

    /* * ----------------------------------------------------------------------- * *\
      criteria for Salon modals
      * In Salon
      * if there are any Booking Alert reasons (Pet or customer) - covered in the inti CDM call.
      * If  a modal for that pet has not already shown. - covered in the inti CDM call.
    \* * ----------------------------------------------------------------------- * */
    if (isSalon) {
      displaySalonModal();
    }
  }

  const PetTabs = diComp.petTabs ?? (() => <></>);
  const CartColumn = diComp.cartColumn ?? (() => <></>);

  if (isHidden) return null;
  return (
    <LayoutStack id={componentId} space="scale-0">
      <BookingHeader backgroundImage={HeaderImage} />
      <LoadingWrapper isLoading={isLoading}>
        <LayoutCluster space="scale-0" style={{ alignItems: "unset" }} styleRoot={{ flex: "1" }}>
          <LayoutStack space="scale-0" style={{ flex: "2.5" }}>
            <PetTabs selectedPet={selectedPet} onSelectPet={onSelectPet} />

            {!isModify && (
              <Routes>
                {/* Full path for hotel rebooking: "/booking/:customerKey/select-service/:itineraryId" */}
                {systemBookingFlow === systemName.HOTEL && (
                  <Route
                    path="/select-service/:itineraryId"
                    element={<BookingService onClearActionClicked={() => setActionClicked(null)} />}
                    exact
                  />
                )}
                {/* Full paths: "/booking/:customerKey/select-service" & "/standalone/:customerKey/select-service" */}
                <Route
                  path="/select-service"
                  element={
                    <BookingService
                      onClearActionClicked={() => setActionClicked(null)}
                      isStandalone={isStandalone}
                    />
                  }
                  exact
                />
                {/* Full paths: "/booking/:customerKey/select-time-slot" & "/standalone/:customerKey/select-time-slot" */}
                <Route
                  path="/select-time-slot"
                  element={
                    <BookingTimeSlot
                      customerKey={customerKey}
                      pets={pets}
                      isStandalone={isStandalone}
                    />
                  }
                  exact
                />
              </Routes>
            )}
            {isModify && (
              <Routes>
                {/* Full path: "/modify-appointment/:customerKey/:itineraryId" */}
                <Route
                  index
                  element={<BookingTimeSlot customerKey={customerKey} pets={pets} />}
                  exact
                />
              </Routes>
            )}
          </LayoutStack>

          <LayoutBox
            padding="scale-0"
            style={{ flex: "1", boxShadow: "0 0.625rem 2.125rem 0 rgba(13, 18, 27, 0.12)" }}
          >
            <CartColumn
              customerKey={customerKey}
              footerActions={buildCartFooterActions(setActionClicked)}
              multiplePets
            />
          </LayoutBox>
        </LayoutCluster>
      </LoadingWrapper>

      {modalType && (
        <BookingModals
          modalType={modalType}
          selectedPets={actionClicked ? appointmentPets : [selectedPet]}
        />
      )}
    </LayoutStack>
  );
};

/**
 * Redux Connect function for props common to salon and hotel booking
 * @memberOf Views.Booking
 * @function
 * @returns wrapper function for a component
 */
const SharedBookingProps = compose(
  withRouteProps,
  connect(
    (state, { router }) => {
      const customerKeyParam = router?.params?.customerKey;
      const customerKey = getCurrentCustomerKey(state);
      const customer = getCustomer(state, { customerKey });
      const customerMatchesParam = customerKeyParam === customer?.customerKey?.toString();
      return {
        customerKey: customerKeyParam === customerKey ? customerKey : null,
        customer: customerMatchesParam ? customer : null,
        customerIsInState: customerMatchesParam && !!customer,
        pets: getPetsNamesAndIdsAndActiveByCustomer(state, { customerKey }),
        systemBookingFlow: getSystemBookingFlow(state),
        systemType: getSystemType(state),
        selectedPet: getSelectedPet(state),
        modalType: getBookingModalType(state),
      };
    },
    dispatch => {
      return {
        setCurrentCustomerKey: currentCustomerKey =>
          dispatch(customerActionCreator.setCurrentCustomerKey({ currentCustomerKey })),
        loadCustomer: customerKey =>
          dispatch(customersActionCreators.loadCustomer({ customerKey })),
        onSelectPet: petId => {
          dispatch(setPetId(petId));
          dispatch(bookingActionCreators.selectPet(petId));
        },
        clearUiStore: () => dispatch(uiActionCreators.clearUiStore()),
        showRedirectionModal: () =>
          dispatch(
            bookingActionCreators.showBookingModal(bookingModalTypes.BOOKING_REDIRECTION_MODAL),
          ),
        hideBookingModals: () => dispatch(bookingActionCreators.hideBookingModal()),
      };
    },
    (stateProps, dispatchProps, ownProps) => {
      const { customerKey, customer } = stateProps;
      const { setCurrentCustomerKey, loadCustomer } = dispatchProps;
      const { router } = ownProps;

      return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        makeSureCustomerIsInState: () => {
          const currentCustomerKey = router?.params?.customerKey;

          // If there is no customer key in state we get the key from the url and add it
          if (!customerKey) {
            setCurrentCustomerKey(currentCustomerKey);
          }

          // If there is no customer in state e.g. browser reload situation, we set customer in state.
          if (!customer) {
            loadCustomer(currentCustomerKey);
          }
        },
      };
    },
  ),
);

/**
 * Redux Connect function for salon booking
 * @see {@link Views.Booking.BookingComponent}
 * @summary Located on the salon booking, standalone services, and modify appointment pages
 * @memberOf Views.Booking
 * @function
 * @param {Object} props
 * @param {Boolean} isStandalone
 * @returns {JSX.Element|null}
 * @example <SalonBooking isStandalone />
 */
export const SalonBooking = compose(
  SharedBookingProps,
  connect(
    (state, ownProps) => {
      const {
        router: { location },
      } = ownProps;
      // Props from SharedBookingProps
      const { pets, selectedPet, systemBookingFlow } = ownProps;

      const eligibilityAlerts = getPageMountReasons(state);
      const petServiceId = getCurrentPetServiceId(state, { petId: selectedPet });
      const appointments = getCartDetails(state);
      const isModify = isFromModifyAppointment(location?.pathname);
      const customerCanBook = getCustomerCanBook(state);
      const allPetsCanNotBook = getAllPetsCanNotBook(state);
      const isCartMigrationHidden = getIsSalonCartMigrationWorkflowFeatureFlagHidden(state);

      return {
        componentId: "SalonBooking",
        currentAddons: getSelectedAddOns(state, { petId: selectedPet }),
        addOnsIdListByService: getAddonIdsByService(state, { petServiceId }),
        currentEnhancedService: selectEnhancedServicesCart(state),
        associates: getAssociates(state),
        appointments,
        appointmentPets: pets.map(pet => pet.petId).filter(petId => appointments[petId]),
        isModify,
        isLoading: createLoadingSelector([
          customersActionTypes.LOAD_CUSTOMER,
          petVaccinationsActionTypes.UPDATE_PET_VACCINATIONS,
          POST_BULK_PACKAGE_OFFERINGS,
        ])(state),
        isFinalizingItinerary: createLoadingSelector([itinerariesActionTypes.FINALIZE_ITINERARY])(
          state,
        ),
        hasPetOrCustomerAlertReasons: !isPetAndCustomerEligibilityReasonsEmpty(eligibilityAlerts),
        doNotBook: customerCanBook === false || allPetsCanNotBook,
        canFinalizeBooking: getCanFinalizeBooking(state),
        areAllServicesScheduledOnTheSameDate: getAreAllServicesScheduledOnTheSameDate(state),
        isAvailabilityLoading: createLoadingSelector([
          bookingActionTypes.LOAD_AVAILABLE_TIME_SLOTS,
          bookingActionTypes.LOAD_AVAILABLE_TIME_SLOTS_BY_ENGAGEMENT,
        ])(state),
        isEligibilityLoading: createLoadingSelector([
          eligibilityPetActionTypes.GET_PET_ELIGIBILITY,
        ])(state),
        canNotBookPets: getCanNotBookPetsInCart(state),
        isHidden: systemBookingFlow !== systemName.SALON,
        isAnyBulkPackageOfferingSelected: getIsAnyBulkPackageOfferingSelected(state),

        // We want to make sure a product bundle is selected before we enable the "Purchase" button
        isBundleReadyForCheckout: !!getSelectedProductBundleConfigId, // TODO: investigate why this selector isn't being called
        isBundleBeingPurchased: createLoadingSelector([POST_BULK_PACKAGE_OFFERINGS])(state),
        isStandaloneNotBookable: getIsStandaloneNotBookable(state, { location }),

        diComp: {
          petTabs: SalonBookingPetTabs,
          cartColumn: isCartMigrationHidden ? CartContainer : SalonBookingAppointmentColumn,
        },
      };
    },
    (dispatch, ownProps) => {
      const {
        router: {
          params: { itineraryId },
        },
      } = ownProps;

      return {
        dispatchPurchaseBulkPackageOfferings: () => dispatch(purchaseBulkPackageOfferings()),
        handleOnCreateAppointments: ({ customerKey }) => {
          dispatch(bookingActionCreators.finalizeAppointment({ customerKey }));
        },
        dispatchSetAddOns: ({ addons, petId }) => dispatch(setAddons({ addons, petId })),
        dispatchSetEnhancedServices: ({ addOn, petId }) =>
          dispatch(setEnhancedServicesAddon(addOn, petId)),
        setLockToAssociate: ({ petId, lockToAssociate }) =>
          dispatch(setLockToAssociate({ petId, lockToAssociate })),
        selectPet: pet => dispatch(selectPet(pet)),
        selectAssociate: associateId => dispatch(selectAssociateWeekly({ associateId })),
        selectPetService: ({ petId, petServiceId }) =>
          dispatch(selectPetService({ petId, petServiceId })),
        clearNewAppointmentData: () => dispatch(clearNewAppointmentData()),
        callEligibility: ({ customerKey }) => {
          dispatch(getBookingScreenEligibility({ customerKey, itineraryId }));
        },
        loadAssociates: () => dispatch(loadAssociates({ isLimited: true })),
        showAlertsModal: () => {
          dispatch(bookingActionCreators.showBookingModal(commonModalTypes.ALERTS));
        },
        showEligibilityWarningsModal: () =>
          dispatch(bookingActionCreators.showBookingModal(commonModalTypes.ELIGIBILITY_WARNINGS)),
        onFinalizeModifyAppointment: () =>
          dispatch(bookingActionCreators.showBookingModal(bookingModalTypes.APPOINTMENT_MODIFIED)),
      };
    },
    (stateProps, dispatchProps, ownProps) => {
      const {
        componentId,
        currentAddons,
        addOnsIdListByService,
        currentEnhancedService,
        associates,
        appointments,
        appointmentPets,
        isModify,
        isLoading,
        isFinalizingItinerary,
        hasPetOrCustomerAlertReasons,
        doNotBook,
        canFinalizeBooking,
        areAllServicesScheduledOnTheSameDate,
        isAvailabilityLoading,
        isEligibilityLoading,
        canNotBookPets,
        isHidden,
        isAnyBulkPackageOfferingSelected,
        isBundleReadyForCheckout,
        isStandaloneNotBookable,
        isBundleBeingPurchased,
        diComp,
      } = stateProps;
      const {
        dispatchPurchaseBulkPackageOfferings,
        handleOnCreateAppointments,
        dispatchSetAddOns,
        dispatchSetEnhancedServices,
        setLockToAssociate,
        selectPet,
        selectAssociate,
        selectPetService,
        clearNewAppointmentData,
        callEligibility,
        loadAssociates,
        showAlertsModal,
        showEligibilityWarningsModal,
        onFinalizeModifyAppointment,
      } = dispatchProps;
      const { router, isStandalone } = ownProps;
      const { location } = router;

      // Props from SharedBookingProps
      const {
        customerKey,
        pets,
        systemBookingFlow,
        selectedPet,
        modalType,
        onSelectPet,
        clearUiStore,
        showRedirectionModal,
        makeSureCustomerIsInState,
        customerIsInState,
        hideBookingModals,
      } = ownProps;

      const setAddOns = addons => {
        dispatchSetAddOns({
          addons: deriveActiveAddons(addons, addOnsIdListByService),
          petId: selectedPet,
        });
      };

      const setEnhancedServices = enhancedServices => {
        dispatchSetEnhancedServices({
          addOn: deriveActiveEnhancedService(enhancedServices, addOnsIdListByService),
          petId: selectedPet,
        });
      };

      // Grab cart obj from router state if rebooking
      const handleCartItems = () => {
        const cart = router?.location?.state?.cart;

        if (cart) {
          const { addOns, enhancedServices, pet, associate, petServiceId, lockToAssociate } = cart;

          clearNewAppointmentData();
          setLockToAssociate({ petId: pet, lockToAssociate });
          selectPet(pet);
          selectAssociate(associate);
          selectPetService({ petId: pet, petServiceId });
          setAddOns(addOns);
          setEnhancedServices(enhancedServices);
        }
      };

      return {
        componentId,
        isLoading,
        pets,
        appointmentPets,
        customerKey,
        selectedPet,
        onSelectPet,
        modalType,
        isHidden,
        isStandalone,
        isModify,
        showRedirectionModal,
        doNotBook: customerIsInState && doNotBook,
        addOnsIdListByService,
        hasPetOrCustomerAlertReasons,
        showAlertsModal,
        systemBookingFlow,
        diComp,
        onPageLoad: () => {
          makeSureCustomerIsInState();
          callEligibility({ customerKey });
          handleCartItems();

          if (isEmpty(associates) || !hasAPrimaryStoreNumber(associates)) {
            loadAssociates();
          }
        },
        onPageUnMount: () => {
          clearUiStore();
          hideBookingModals();
        },

        // Responsible for setting selected addon/enhanced service if the addon/ES is currently 'active' in SF
        handleAddOns: () => {
          setAddOns(currentAddons);
          setEnhancedServices(currentEnhancedService);
        },

        buildCartFooterActions: setActionClicked => {
          const bookingDisabled = !appointments || !Object.keys(appointments).length;

          // TODO: Investigate this, as it seems like the wrong logic for determine if Book button is disabled
          // in the standalone flow.
          const standaloneDisable =
            isStandalone &&
            !every(appointment => (appointment.addons || []).length > 0, appointments);

          if (isFromSelectTimeSlot(location.pathname) && !isHidden) {
            return [
              {
                action: () => handleOnCreateAppointments({ customerKey }),
                label: "Book",
                disabled:
                  !canFinalizeBooking.value ||
                  isFinalizingItinerary ||
                  isAvailabilityLoading ||
                  isStandaloneNotBookable ||
                  !areAllServicesScheduledOnTheSameDate,
              },
            ];
          }

          if (isFromModifyAppointment(location.pathname)) {
            return [
              {
                action: onFinalizeModifyAppointment,
                label: "Finalize",
                disabled: !canFinalizeBooking.value || !areAllServicesScheduledOnTheSameDate,
              },
            ];
          }

          // If a bulk package offering is selected, we are now in the purchase
          // bulk package offering flow and want to change the label of the
          // call to action to be "Purchase" rather than "Schedule"
          if (isAnyBulkPackageOfferingSelected) {
            // When purchasing the bundle we disable the button again to avoid duplicate requests
            if (isBundleBeingPurchased) {
              return [{ label: "Purchase", disabled: true, action: () => {} }];
            }

            return isBundleReadyForCheckout
              ? [
                  {
                    action: () => {
                      setActionClicked(true);
                      dispatchPurchaseBulkPackageOfferings();
                    },
                    label: "Purchase",
                    disabled: false,
                  },
                ]
              : [{ action: () => {}, label: "Purchase", disabled: true }];
          }

          return [
            {
              action: () => {
                setActionClicked(true);
                showEligibilityWarningsModal();
              },
              label: "Schedule",
              disabled:
                bookingDisabled ||
                standaloneDisable ||
                isEligibilityLoading ||
                !isEmpty(canNotBookPets),
            },
          ];
        },
      };
    },
  ),
)(BookingComponent);

/**
 * Redux Connect function for hotel booking
 * @see {@link Views.Booking.BookingComponent}
 * @summary Located on the hotel booking page
 * @memberOf Views.Booking
 * @function
 * @returns {JSX.Element|null}
 * @example <HotelBooking />
 */
export const HotelBooking = compose(
  SharedBookingProps,
  connect(
    (state, ownProps) => {
      // Props from SharedBookingProps
      const { customerIsInState, customerKey, systemBookingFlow } = ownProps;
      const isSRCAgent = getIsSRCAgent(state);

      const doNotBook =
        isSRCAgent &&
        customerIsInState &&
        !isEmpty(getHotelBookingAlertReasons(state, { customerKey }));

      return {
        componentId: "HotelBooking",
        isLoading: createLoadingSelector([
          PUT_CART,
          DELETE_CART,
          hotelBookingActionTypes.PATCH_HOTEL_BOOKING_STATUS_FOR_CART,
          GET_HOTEL_ITINERARY,
        ])(state),
        isHidden: systemBookingFlow !== systemName.HOTEL,
        diComp: {
          petTabs: HotelBookingPetTabs,
          cartColumn: HotelBookingAppointmentColumn,
        },
        doNotBook,

        // Pets Hotel does not check for Reasons(eligibility check) to display a modal like salon, Hotel Only checks
        // for DoNotBook, however the Booking Component uses the hasPetOrCustomerAlertReasons as an initial check
        // before firing off to show a modal, so it is hard coded as true here so it always passes that initial
        // check in the Component did Update method.
        hasPetOrCustomerAlertReasons: true,
      };
    },
    dispatch => {
      return {
        loadHotelPriceAdjustmentReasons: () => dispatch(loadHotelPriceAdjustmentReasons({})),
        handleOnCreateAppointments: ({ systemType }) => {
          // Since we reached the end of the booking flow we need to set the booking
          // flow back to system type that the user logged in as
          dispatch(setSystemBookingFlowType({ systemBookingFlow: systemType }));
        },
      };
    },
    (stateProps, dispatchProps, ownProps) => {
      const {
        componentId,
        isLoading,
        doNotBook,
        isHidden,
        hasPetOrCustomerAlertReasons,
        diComp,
      } = stateProps;
      const { handleOnCreateAppointments, loadHotelPriceAdjustmentReasons } = dispatchProps;

      // Props from SharedBookingProps
      const {
        customerKey,
        pets,
        systemBookingFlow,
        systemType,
        selectedPet,
        modalType,
        onSelectPet,
        clearUiStore,
        showRedirectionModal,
        makeSureCustomerIsInState,
        hideBookingModals,
      } = ownProps;

      return {
        componentId,
        isLoading,
        pets,
        appointmentPets: pets,
        customerKey,
        selectedPet,
        onSelectPet,
        modalType,
        isHidden,
        showRedirectionModal,
        doNotBook,
        hasPetOrCustomerAlertReasons,
        systemBookingFlow,
        diComp,
        onCreateAppointments: () => {
          handleOnCreateAppointments({ systemType });
        },
        onPageLoad: () => {
          makeSureCustomerIsInState();
          loadHotelPriceAdjustmentReasons();
        },
        onPageUnMount: () => {
          clearUiStore();
          hideBookingModals();
        },
      };
    },
  ),
)(BookingComponent);
