import React from "react";
import { get } from "lodash/fp";

import styled from "styled-components";
import { color } from "../../../../common/styles/theme";
import { fontSizes } from "web/common/styles/responsive/fonts";
import ClickableText from "../../../../common/ClickableText";
import CommonButton from "../../../../common/commonButton";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";

class CancelBookingAppointment extends React.Component {
  handleCancel = () => {
    const { cancelAppointment, petIdToRemoveFromCart, router, hideModal } = this.props;
    const customerKey = get("params.customerKey", router);

    if (!petIdToRemoveFromCart || !customerKey) {
      return;
    }

    cancelAppointment({ petId: petIdToRemoveFromCart, customerKey });
    hideModal();
  };

  render() {
    const { hideModal, petName } = this.props;

    if (!petName) {
      return null;
    }

    return (
      <Container>
        <Header>Removing from Cart</Header>

        <Message>{`Are you sure you want to cancel the appointment for ${petName}? This action can’t be undone.`}</Message>

        <ButtonsContainer>
          <ClickableText isVisible styleObj={{ color: color.blue500 }} onClick={hideModal}>
            Go back
          </ClickableText>
          <CommonButton label="Remove from Cart" onClick={this.handleCancel} />
        </ButtonsContainer>
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const Header = styled.div`
  font-size: ${fontSizes.regular};
  font-weight: bold;
  text-align: center;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Message = styled.div`
  margin: 30px auto;
  text-align: center;
  max-width: 250px;
`;

export default withRouteProps(CancelBookingAppointment);
