/* * ----------------------------------------------------------------------- * *\
  This is a Controlled component e.g. Data is passed in as props and the parent
  controls the data and thus the local state.

  Example of Use:
    You can clear the radio list by passing in a {Boolean} prop of [clear]
    To Populate the list, pass in a an {Array} of values via prop [radioList].
    to handle a change, pass in the {function} prop [onHandleChange]

    You can control if this component exists in the DOM tree via a {Boolean}
    prop [isVisible] and is a required prop. If no isVisible prop is passed,
    the component will not be listed in the DOM tree. To pass a default prop, you should
    do this via a individual use case container, however you can also do it in the parent just
    by simply declaring it e.g. <ControlledRadioList isVisible .... />
\* * ----------------------------------------------------------------------- * */

import React, { Component } from "react";
import styled from "styled-components";
import fadeIn from "../styles/Animation/fade/fadeIn.ts";
import { color } from "../styles/theme";

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  border: 1px solid ${color.gray200};
  margin: 10px;
  padding: 10px;
  animation: ${fadeIn()} 0.8s ease;
`;

const RadioListWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const RadioLabel = styled.div`
  margin-left: 10px;
`;

export default class RadioList extends Component {
  constructor(props) {
    super(props);

    // Components state
    this.state = {
      checkedItem: "",
    };
  }

  static getDerivedStateFromProps(props) {
    if (props.clear) {
      return { checkedItem: "" };
    }

    return { checkedItem: props.radioValue };
  }

  onRadioChange = e => {
    this.props.onHandleChange(e.currentTarget.value);
  };

  render() {
    const { isVisible, radioList } = this.props;
    if (isVisible) {
      return (
        <SectionWrapper>
          {radioList.map((value, index) => (
            <RadioListWrapper>
              <input
                key={index + value}
                type="radio"
                name={value}
                value={value}
                checked={this.state.checkedItem === value}
                onChange={this.onRadioChange}
              />
              <RadioLabel>{value}</RadioLabel>
            </RadioListWrapper>
          ))}
        </SectionWrapper>
      );
    }

    return null;
  }
}
