import React from "react";
import { Navigate } from "react-router-dom";
import FirstTimeCustomerIndicatorPetParentProfileContainer from "dux/bgm/ftco/FirstTimeCustomerIndicatorPetParentProfileContainer";
import EligiblePromotionsFTCOPetParentProfileContainer from "dux/eligiblePromotions/EligiblePromotionsFTCOPetParentProfileContainer";
import EligiblePromotionsBGMPetParentProfileContainer from "dux/eligiblePromotions/EligiblePromotionsBGMPetParentProfileContainer";
import { DdcPackagesPurchaseButton } from "@/dux/ddcPackages/DdcPackagesPurchaseButton";
import PetParentProfileHeader from "../../header/PetParentProfileHeaderContainer";
import PetParentProfileSalonColumnContainer from "../../petParentProfile/PetParentProfileSalonColumnContainer";
import AppointmentsColumnSalonContainer from "../../petParentProfile/AppointmentsColumnSalonContainer";
import HeaderImage from "../../../assets/images/bg-header-image.png";
import LoadingWrapper from "../LoadingWrapper";
import PetParentProfileModals from "../../petParentProfile/petParentProfile/modals/petParentProfileModalsContainer";
import BookStandalonePPPContainer from "../../booking/bookStandAlone/BookStandalone_PPP_cont";
import PetParentProfileSalonContentContainer from "../../petParentProfile/PetParentProfileSalonContentContainer";
import PetParentProfileHotelContentContainer from "../../petParentProfile/PetParentProfileHotelContentContainer";
import SalonIndexTabContainer from "../../setSystemType/SalonIndexTabContainer";
import HotelIndexTabContainer from "../../setSystemType/HotelIndexTabContainer";
import PetParentProfileIndexTabsContentContainer from "../../petParentProfile/PetParentProfileIndexTabsAndContentContainer";
import PetParentProfileTabsContainer from "../../petParentProfile/PetParentProfileTabsContainer";
import PetParentProfileNonIndexTabContentContainer from "../../petParentProfile/PetParentProfileNonIndexTabContentContainer";
import PetParentProfileHotelColumnContainer from "../../petParentProfile/PetParentProfileHotelColumnContainer";
import PetsProfileDetailsColumnSalonContainer from "../../petParentProfile/PetsProfileDetailsColumnSalonContainer";
import PetsProfileDetailsColumnHotelContainer from "../../petParentProfile/PetsProfileDetailsColumnHotelContainer";
import { HotelItineraryHistoryColumn } from "@/dux/itineraryHistoryList/itineraryHistoryList";
import SalonIcon from "../icons/SalonIcon";
import PetsHotelIcon from "../icons/PetsHotelIcon";
import { color } from "../styles/theme";
import TrainingIndexTabContainer from "web/setSystemType/TrainingIndexTabContainer";
import PetParentProfileTrainingContentContainer from "web/petParentProfile/PetParentProfileTrainingContentContainer";
import PetParentProfileTrainingColumnContainer from "web/petParentProfile/PetParentProfileTrainingColumnContainer";
import PetsProfileDetailsColumnTrainingContainer from "web/petParentProfile/PetsProfileDetailsColumnTrainingContainer";
import AppointmentsColumnTrainingContainer from "web/petParentProfile/AppointmentsColumnTrainingContainer";
import TrainingIcon from "@/web/common/icons/TrainingIcon";
import { CancelTrainingAppointmentFlashMessage } from "@/dux/flashMessage/CancelTrainingAppointmentFlashMessage";
import { BookAppointmentButtonsWrapper } from "@/dux/bookAppointment/BookAppointmentButtonsWrapper";
import { LayoutSwitcher } from "@/layout/switcher/Switcher";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";

class PetParentProfileComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    const { customerKey, currentCustomerKey, setCurrentCustomerKey, loadAPIs } = this.props;

    if (customerKey) {
      loadAPIs();
    }

    if (!currentCustomerKey || currentCustomerKey !== customerKey) {
      setCurrentCustomerKey(customerKey);
    }
  }

  componentDidUpdate(prevProps) {
    const { customerKey, loadAPIs, isCustomerActive, callEligibility } = this.props;
    const previousCustomerKey = prevProps.customerKey;
    const previousIsCustomerActive = prevProps.isCustomerActive;

    if (previousCustomerKey !== customerKey) {
      loadAPIs();
    } else if (
      previousIsCustomerActive !== undefined &&
      !previousIsCustomerActive &&
      isCustomerActive
    ) {
      callEligibility();
    }
  }

  handleError() {
    const { loadCustomerError, clearErrors } = this.props;
    alert(
      loadCustomerError.Responses
        ? loadCustomerError.Responses[0].Message
        : "Error when loading customer",
    );
    clearErrors();
    return <Navigate replace to="/dashboard" />;
  }

  render() {
    const { componentID, customerKey, loadCustomerError, isLoading, modalType } = this.props;

    return loadCustomerError && loadCustomerError.customerKey === customerKey ? (
      this.handleError()
    ) : (
      <LoadingWrapper isLoading={isLoading} fullScreenHeight fullScreenWidth>
        <LayoutStack id={componentID} space="scale-0" style={{ width: "100%", height: "100%" }}>
          <PetParentProfileHeader
            backgroundImage={HeaderImage}
            gridArea="pet-parent-header"
            customerKey={customerKey}
          >
            <BookStandalonePPPContainer />
            <DdcPackagesPurchaseButton />
            <BookAppointmentButtonsWrapper />
          </PetParentProfileHeader>

          {/** * ----------------------------------------------------------------------- * *\
            Pet Parent Content Enclosed in within the Index Tabs Via Ticket SVCSART-728
          \* * ----------------------------------------------------------------------- * */}
          <LayoutBox padding="scale-0">
            <PetParentProfileIndexTabsContentContainer>
              <PetParentProfileTabsContainer>
                <SalonIndexTabContainer
                  renderIcon={isActive => (
                    <SalonIcon
                      componentID="petParentProfile-salonIcon"
                      color={isActive ? color.blue500 : color.white0}
                    />
                  )}
                />
                <HotelIndexTabContainer
                  renderIcon={isActive => (
                    <PetsHotelIcon
                      componentID="petParentProfile-petsHotelIcon"
                      color={isActive ? color.blue500 : color.white0}
                    />
                  )}
                />
                <TrainingIndexTabContainer
                  renderIcon={isActive => (
                    <TrainingIcon
                      componentID="petParentProfile-trainingIcon"
                      color={isActive ? color.blue500 : color.white0}
                    />
                  )}
                />
              </PetParentProfileTabsContainer>

              {/** * ----------------------------------------------------------------------- * *\
                Salon Content
              \* * ----------------------------------------------------------------------- * */}
              <PetParentProfileSalonContentContainer>
                {/* PACKAGE OFFERINGS INDICATOR */}
                <FirstTimeCustomerIndicatorPetParentProfileContainer />
                <EligiblePromotionsFTCOPetParentProfileContainer />
                <EligiblePromotionsBGMPetParentProfileContainer />

                <LayoutSwitcher space="scale-S10">
                  <PetParentProfileSalonColumnContainer />
                  <PetsProfileDetailsColumnSalonContainer customerKey={customerKey} />
                  <AppointmentsColumnSalonContainer />
                </LayoutSwitcher>
              </PetParentProfileSalonContentContainer>

              {/** * ----------------------------------------------------------------------- * *\
                Hotel Content
              \* * ----------------------------------------------------------------------- * */}
              <PetParentProfileHotelContentContainer>
                {/* PACKAGE OFFERINGS INDICATOR */}
                <FirstTimeCustomerIndicatorPetParentProfileContainer />
                <EligiblePromotionsFTCOPetParentProfileContainer />
                <EligiblePromotionsBGMPetParentProfileContainer />

                <LayoutSwitcher space="scale-S10">
                  <PetParentProfileHotelColumnContainer />
                  <PetsProfileDetailsColumnHotelContainer customerKey={customerKey} />
                  <HotelItineraryHistoryColumn customerKey={customerKey} />
                </LayoutSwitcher>
              </PetParentProfileHotelContentContainer>

              {/** * ----------------------------------------------------------------------- * *\
               Training Content
              \* * ----------------------------------------------------------------------- * */}
              <PetParentProfileTrainingContentContainer>
                <LayoutSwitcher space="scale-S10">
                  <PetParentProfileTrainingColumnContainer />
                  <PetsProfileDetailsColumnTrainingContainer customerKey={customerKey} />
                  <AppointmentsColumnTrainingContainer />
                </LayoutSwitcher>
              </PetParentProfileTrainingContentContainer>
            </PetParentProfileIndexTabsContentContainer>
          </LayoutBox>
          {/** * ----------------------------------------------------------------------- * *\
            End Pet Parent Content Enclosed in within the Index Tabs Via Ticket SVCSART-728
          \* * ----------------------------------------------------------------------- * */}

          {/** * ----------------------------------------------------------------------- * *\
            Pet Parent Content Before Content was enclosed in Tabs in Ticket SVCSART-728

            NOTE: As of Ticket SVCSART-728, once live this block of code along with the
            PetParentProfileNonIndexTabContentContainer.js file can be removed.
          \* * ----------------------------------------------------------------------- * */}
          <PetParentProfileNonIndexTabContentContainer>
            {/* PACKAGE OFFERINGS INDICATOR */}
            <FirstTimeCustomerIndicatorPetParentProfileContainer />
            <EligiblePromotionsFTCOPetParentProfileContainer />
            <EligiblePromotionsBGMPetParentProfileContainer />

            <LayoutSwitcher space="scale-S10">
              <PetParentProfileSalonColumnContainer />
              <PetsProfileDetailsColumnSalonContainer customerKey={customerKey} />
              <AppointmentsColumnSalonContainer />
            </LayoutSwitcher>
          </PetParentProfileNonIndexTabContentContainer>

          {modalType && <PetParentProfileModals modalType={modalType} />}
          <CancelTrainingAppointmentFlashMessage />
        </LayoutStack>
      </LoadingWrapper>
    );
  }
}

export default PetParentProfileComponent;
