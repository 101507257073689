/**
 * Util to get a specific engagement from an array of engagements
 * @memberOf Utils.Engagement
 * @function
 * @name getEngagementFromArrayById
 * @param {Object[]} engagements
 * @param {String|Number} engagementId
 * @returns {Object} - engagement
 * @example getEngagementFromArrayById(engagements, engagementId)
 */
export const getEngagementFromArrayById = (engagements, engagementId) =>
  engagements?.find(eng => eng?.engagementId?.toString() === engagementId?.toString()) ?? {};

/**
 * Util to get a given pets engagements from an itinerary
 * @memberOf Utils.Engagement
 * @function
 * @name getPetEngagementsFromItinerary
 * @param {String|Number} petId
 * @param {Object} itinerary
 * @returns {Object[]} - array of engagements
 * @example getPetEngagementsFromItinerary(petId, itinerary)
 */
export const getPetEngagementsFromItinerary = (petId, itinerary) =>
  itinerary?.pets?.find(({ petKey }) => petKey?.toString() === petId?.toString())?.engagements ??
  [];

/**
 * Util to count the number of unique hostPetId's from a given list of engagements
 * @memberOf Utils.Engagement
 * @function
 * @name countEngagementHosts
 * @param {Object[]} - array of engagements
 * @returns {number}
 */
export const countEngagementHosts = (engagements = []) =>
  engagements?.reduce(
    (hostPets, { hostPetId }) =>
      hostPets?.includes(hostPetId) ? hostPets : [...hostPets, hostPetId],
    [],
  )?.length;
