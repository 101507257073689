import { put, takeEvery, call, all, select } from "redux-saga/effects";
import {
  getHotelItineraryRequest,
  PUT_HOTEL_ITINERARY,
  PUT_HOTEL_ITINERARY_FAILURE,
} from "./hotelItineraryActions";
import {
  selectMinHotelItinGetWaitTimeStoreSetting,
  selectMinHotelStayToWarnStoreSetting,
} from "../storeSettings/storeSettingsSelectors";
import getParamFromPath from "@/core/utils/matchUtils/getParmFromPath";
import { paramName } from "@/core/constants/urlParamConstants";
import { onGetHotelItinerary } from "./hotelItinerarySaga";
import { getHotelEngagementsArray } from "../hotelEngagements/hotelEngagementSelectors";
import { countEngagementsOnItinerary } from "@/core/utils/hotelItineraryUtils/countEngagementsOnItinerary";
import { showConfirmationModal } from "@/core/actionCreators/confirmationModalActionCreators";
import { CONFIRMATION_MODAL_TYPE } from "@/core/constants/modalConstants";
import {
  DELETE_CHECK_IN_FEEDING,
  DELETE_CHECK_IN_FEEDING_FAILURE,
} from "../_components/checkInOutFeeding/checkInFoodsActions";
import {
  DELETE_CHECK_IN_MED,
  DELETE_CHECK_IN_MED_FAILURE,
} from "../_components/checkInOutMedications/checkInMedsActions";
import {
  PATCH_HOTEL_MANUAL_SPECIALS,
  PATCH_HOTEL_MANUAL_SPECIALS_FAILURE,
  REMOVE_HOTEL_MANUAL_SPECIALS,
  REMOVE_HOTEL_MANUAL_SPECIALS_FAILURE,
} from "../applySpecial/applyManaulSpecialsHotelActions";
import {
  PATCH_HOTEL_ITINERARY_ADDONS,
  PATCH_HOTEL_ITINERARY_ADDONS_FAILURE,
} from "../hotelItineraryAddonPatch/hotelItineraryAddonPatchActions";
import {
  PUT_HOTEL_ITINERARY_ADDONS,
  PUT_HOTEL_ITINERARY_ADDONS_FAILURE,
} from "../hotelItineraryAddonPut/hotelItineraryAddonPutActions";
import {
  PATCH_HOTEL_ITINERARY_PET_NOTES,
  PATCH_HOTEL_ITINERARY_PET_NOTES_FAILURE,
} from "../hotelItineraryPetNotes/hotelItineraryPetNotesActions";
import {
  PATCH_HOTEL_PRIMARY_SERVICE_CHANGE,
  PATCH_HOTEL_PRIMARY_SERVICE_CHANGE_FAILURE,
} from "../hotelPrimaryServiceChange/hotelPrimaryServiceChangeActions";
import {
  PATCH_HOTEL_ITINERARY_CHECK_IN_STATUS,
  PATCH_HOTEL_ITINERARY_PRICE_OVERRIDE,
  PATCH_HOTEL_ITINERARY_PRICE_OVERRIDE_FAILURE,
  PATCH_HOTEL_ITINERARY_ROOM,
  PATCH_HOTEL_ITINERARY_ROOM_FAILURE,
  PATCH_HOTEL_ITINERARY_STATUS,
  PATCH_HOTEL_ITINERARY_STATUS_FAILURE,
  PATCH_RESERVATION_CART_DETAILS,
  PATCH_RESERVATION_CART_DETAILS_FAILURE,
  SAVE_RESERVATION_CART_DETAILS,
  SAVE_RESERVATION_CART_DETAILS_FAILURE,
} from "../reservationCartDetailsConfirmation/reservationCartDetailsConfirmationActions";
import { clearError } from "@/core/actionCreators/errorActionCreators";
import {
  PUT_HOTEL_INVOICE_PAID,
  PUT_HOTEL_INVOICE_PAID_FAILURE,
  PUT_HOTEL_INVOICE_VOID_TRANSACTION,
  PUT_HOTEL_INVOICE_VOID_TRANSACTION_FAILURE,
} from "../hotelItineraryPayment/hotelItineraryPaymentActions";
import {
  PATCH_HOTEL_ITINERARY_CHECK_OUT_STATUS,
  PATCH_HOTEL_ITINERARY_CHECK_OUT_STATUS_FAILURE,
} from "../checkOut/checkOutActions";

export function* checkIfItineraryCallMightTakeLong(hotelItinerary) {
  // Not all itinerary update actions have the same payload, the PUT uses
  // hotelItinerary but if it's undefined then use engagements from state
  const engagements = yield select(getHotelEngagementsArray);
  const engagementsCount = engagements?.length;
  const lengthOfStay = hotelItinerary
    ? countEngagementsOnItinerary(hotelItinerary)
    : engagementsCount;
  const lengthOfStayToWarn = yield select(selectMinHotelStayToWarnStoreSetting);
  return lengthOfStay >= lengthOfStayToWarn;
}

export function* warnUserIfItineraryCallCouldBeLong({ hotelItinerary }) {
  const shouldWarn = yield call(checkIfItineraryCallMightTakeLong, hotelItinerary);
  if (!shouldWarn) return;

  // Show informational modal that call may take a while
  yield put(
    showConfirmationModal({
      modalType: CONFIRMATION_MODAL_TYPE.ALERT,
      content: "This reservation update may take longer than usual to complete.",
    }),
  );
}

/**
 * Helper to handle calls made for an itinerary with a long stay
 * Sometimes SF takes so long that the .NET layer times out and returns a 502 error,
 * this function will wait some amount of time then make a get itinerary call
 * to ensure that we have the latest data
 * @param {Object} obj
 * @param {Object} obj.error - caught error from a try catch block
 * @param {string} obj.type - the action type for the saga loading state
 */
export function* handleItineraryError({ type, error }) {
  const userWasWarned = yield call(checkIfItineraryCallMightTakeLong, error?.hotelItinerary);
  const isAxiosErrWithoutResponse = error?.name === "AxiosError" && !error?.response;
  const is502ErrOrEmpty = error?.response?.status === 502 || isAxiosErrWithoutResponse;
  // if error isn't an axios error with no response or 502 status then don't do an itinerary get
  if (!userWasWarned || !is502ErrOrEmpty) return;

  // Clear the generic error modal
  if (type) {
    const actionType = type?.replace(/_FAILURE$/, "");
    yield put(clearError(actionType));
  }

  // Start loading spinner for get itinerary call
  yield put(getHotelItineraryRequest());

  // Wait before making an itinerary GET to give SF enough time to make the updates
  const waitTimeBeforeGet = yield select(selectMinHotelItinGetWaitTimeStoreSetting);
  yield call(time => new Promise(fn => setTimeout(fn, time)), waitTimeBeforeGet * 1000);

  const customerKey = getParamFromPath(paramName.CUSTOMER_KEY);
  const itineraryId = getParamFromPath(paramName.ITINERARY_ID);
  yield call(onGetHotelItinerary, { payload: { customerKey, itineraryId } });

  yield put(
    showConfirmationModal({
      modalType: CONFIRMATION_MODAL_TYPE.ALERT,
      content: "The reservation update has been completed. Please verify the updates.",
    }),
  );
}

export function* watchHotelItineraryUpdates() {
  yield takeEvery(
    [
      PUT_HOTEL_ITINERARY,
      SAVE_RESERVATION_CART_DETAILS,
      PATCH_RESERVATION_CART_DETAILS,
      PATCH_HOTEL_ITINERARY_ROOM,
      PATCH_HOTEL_ITINERARY_STATUS,
      PATCH_HOTEL_ITINERARY_CHECK_IN_STATUS,
      PATCH_HOTEL_MANUAL_SPECIALS,
      REMOVE_HOTEL_MANUAL_SPECIALS,
      PATCH_HOTEL_ITINERARY_ADDONS,
      PUT_HOTEL_ITINERARY_ADDONS,
      DELETE_CHECK_IN_FEEDING,
      DELETE_CHECK_IN_MED,
      PATCH_HOTEL_ITINERARY_PET_NOTES,
      PATCH_HOTEL_PRIMARY_SERVICE_CHANGE,
      PATCH_HOTEL_ITINERARY_PRICE_OVERRIDE,
      PUT_HOTEL_INVOICE_PAID,
      PUT_HOTEL_INVOICE_VOID_TRANSACTION,
      PATCH_HOTEL_ITINERARY_CHECK_OUT_STATUS,
    ],
    warnUserIfItineraryCallCouldBeLong,
  );
}

export function* watchHotelItineraryErrors() {
  yield takeEvery(
    [
      PUT_HOTEL_ITINERARY_FAILURE,
      SAVE_RESERVATION_CART_DETAILS_FAILURE,
      PATCH_RESERVATION_CART_DETAILS_FAILURE,
      PATCH_HOTEL_ITINERARY_ROOM_FAILURE,
      PATCH_HOTEL_ITINERARY_STATUS_FAILURE,
      PATCH_HOTEL_MANUAL_SPECIALS_FAILURE,
      REMOVE_HOTEL_MANUAL_SPECIALS_FAILURE,
      PATCH_HOTEL_ITINERARY_ADDONS_FAILURE,
      PUT_HOTEL_ITINERARY_ADDONS_FAILURE,
      DELETE_CHECK_IN_FEEDING_FAILURE,
      DELETE_CHECK_IN_MED_FAILURE,
      PATCH_HOTEL_ITINERARY_PET_NOTES_FAILURE,
      PATCH_HOTEL_PRIMARY_SERVICE_CHANGE_FAILURE,
      PATCH_HOTEL_ITINERARY_PRICE_OVERRIDE_FAILURE,
      PUT_HOTEL_INVOICE_PAID_FAILURE,
      PUT_HOTEL_INVOICE_VOID_TRANSACTION_FAILURE,
      PATCH_HOTEL_ITINERARY_CHECK_OUT_STATUS_FAILURE,
    ],
    handleItineraryError,
  );
}

export default function* hotelItineraryLongStaySaga() {
  yield all([watchHotelItineraryUpdates(), watchHotelItineraryErrors()]);
}
