import React from "react";
import styled from "styled-components";
import { first, last } from "lodash/fp";
import { device } from "web/common/styles/responsive/devices";

import { color as themeColors } from "web/common/styles/theme";
import { fontSizes } from "web/common/styles/responsive/fonts";

const Circle = styled.div`
  width: ${props => `${props.radius}px`};
  height: ${props => `${props.radius}px`};
  font-size: ${props => props.fontSize};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin: ${props => props.margin};
  color: ${props => props.color};
  border-radius: 30px;
  background-color: ${props => props.backgroundColor};

  @media ${device.mobileS} {
    display: none;
  }
  @media ${device.laptop} {
    display: fex;
  }

  // There were concerns around responsive affecting the UI layout a week before the BGM deployment, All media queries
  // that use the device object will have a media query to target Prism desktop to support BGM release.
  // This media query can be removed after 11/9/20
  @media ${device.prismDesktop} {
    display: flex;
  }
`;

const InitialsCircle = props => {
  const {
    displayName = "",
    radius = 30,
    fontSize = fontSizes.regular,
    color = themeColors.white0,
    backgroundColor = themeColors.black0,
    margin = "auto",
  } = props;

  const splitName = displayName.toUpperCase().split(" ");
  let displayText = first(splitName).charAt(0);

  if (splitName.length > 1) {
    displayText += last(splitName).charAt(0);
  }

  return (
    <Circle
      radius={radius}
      fontSize={fontSize}
      color={color}
      backgroundColor={backgroundColor}
      margin={margin}
    >
      {displayText}
    </Circle>
  );
};

export default InitialsCircle;
