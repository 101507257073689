import React, { Component } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import StyledPopover from "../../../common/StyledPopover";
import { color } from "web/common/styles/theme";
import { fontSizes } from "web/common/styles/responsive/fonts";
import VaccinationAddButtonContainer from "./buttons/VaccinationAddButtonContainer";

const DropdownContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const ComponentWrapper = styled.section``;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const Icon = styled.img`
  display: flex;
  height: 1.25em;
  width: 1.25em;
  stroke: #fff;
  margin-right: 5px;
  opacity: ${props => (props.opacity ? props.opacity : "1")};
`;

const Text = styled.div`
  font-size: ${fontSizes.regular};
`;

const TableWrapper = styled.div`
  display: flex;
  ${props =>
    props.width &&
    `
        width: ${props.width}px;
    `} flex-direction: column;
  max-height: 200px;
  font-family: Lato;
  font-size: 13px;
  overflow: auto;
`;

const GridDiv = styled.div`
  font-size: ${fontSizes.regular};
  cursor: pointer;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;

  &:hover {
    background-color: #deebfe;
  }

  &:active {
    background-color: ${color.blue500};
    color: white;
  }
`;

class VaccinationDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      containerWidth: 0,
    };
  }

  componentDidMount() {
    this.setState({
      containerWidth: ReactDOM.findDOMNode(this.refs.container).getBoundingClientRect().width,
    });
  }

  handleSelect = vaccination => {
    const { newVaccination, hideDropdown } = this.props;
    hideDropdown();

    if (!vaccination) {
      return;
    }

    newVaccination({ vaccination });
  };

  /** ----------------------------------------------------------------------- **\
        Rendered Components
    \** ----------------------------------------------------------------------- * */
  renderDropDownContent = props => (
    <DropdownContainer className="VaccinationDropdown__dropdownContainer">
      <TableWrapper width={this.state.containerWidth}>
        {props.vaccinations.map(vaccination => (
          <GridDiv
            key={vaccination.value}
            onClick={() => {
              this.handleSelect(vaccination.value);
            }}
          >
            {vaccination?.label}
          </GridDiv>
        ))}
      </TableWrapper>
    </DropdownContainer>
  );

  render() {
    const { isShowingDropdown, hideDropdown } = this.props;
    return (
      <ComponentWrapper ref="container" className="VaccinationDropdown">
        <StyledPopover
          body={this.renderDropDownContent(this.props)}
          isOpen={isShowingDropdown}
          place="below"
          tipSize={0.1}
          onOuterAction={() => hideDropdown()}
        >
          <VaccinationAddButtonContainer />
        </StyledPopover>
      </ComponentWrapper>
    );
  }
}

export default VaccinationDropdown;
