import { connect } from "react-redux";
import getTrainingWorkflowFeatureFlag from "web/enableDisableWorkflowFeatureFlag/selectors/training/getTrainingWorkflowFeatureFlag";
import { loginWithOkta } from "@/core/actionCreators/authActionCreators";
import { getRedirectToForgotPin } from "@/core/selectors/persistentSelectors";
import { systemName } from "./constants/setSystemTypeConstants";
import CommonButton from "@/web/common/commonButton";
import { determineRedirectRoute } from "./LoginSystemUtils";

const mapStateToProps = state => {
  const redirectToForgotPin = getRedirectToForgotPin(state);
  const featureFlag = getTrainingWorkflowFeatureFlag(state);
  return {
    redirectToForgotPin,
    componentID: "commonButton-loginTrainingButton",
    width: "15ch",
    label: `Login ${systemName.TRAINING}`,
    isHidden: featureFlag.isHidden,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchLogin: ({ storeNumber, redirectToForgotPin }) => {
    const redirectRoute = determineRedirectRoute(storeNumber, redirectToForgotPin);
    dispatch(
      loginWithOkta({
        route: redirectRoute,
        storeNumber,
        systemType: systemName.TRAINING,
      }),
    );
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onClick: () => {
    propsFromDispatch.dispatchLogin({
      storeNumber: ownProps.storeNumber,
      redirectToForgotPin: propsFromState.redirectToForgotPin,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CommonButton);
