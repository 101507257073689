import { connect } from "react-redux";
import {
  getFoodSaveToProfileAmount,
  getFoodSaveToProfileById,
  getIsFoodOnPetById,
} from "dux/bookingFeeding/bookingSaveFoodToProfile/foodSaveToProfileSelectors";
import { fontSizes } from "web/common/styles/responsive/fonts";
import { FOOD_MAX_COUNT } from "web/newFood/newFoodConstants";
import { setFoodSaveToProfile } from "dux/bookingFeeding/bookingSaveFoodToProfile/foodSaveToProfileActions";
import Checkbox from "web/common/Checkbox";

const mapStateToProps = (state, ownProps) => {
  const { foodId, petId } = ownProps;
  const checked = getFoodSaveToProfileById(state, { foodId, petId });
  return {
    foodId,
    petId,
    checked,
    componentId: `foodDetails-saveToProfile-${foodId}`,
    label: "Save to profile",
    labelPosition: "right",
    paddingTop: "10px",
    fontSize: fontSizes.regular,
    disabled: getIsFoodOnPetById(state, { foodId, petId })
      ? false
      : getFoodSaveToProfileAmount(state, { petId, foodId }) >= FOOD_MAX_COUNT && !checked,
  };
};

const mapDispatchToProps = dispatch => ({
  setSaveToProfile: (saveToProfile, foodId, petId) =>
    dispatch(setFoodSaveToProfile({ saveToProfile, foodId, petId })),
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  onChange: () => {
    const { foodId, checked, petId } = propsFromState;
    propsFromDispatch.setSaveToProfile(!checked, foodId, petId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Checkbox);
