import React from "react";
import styled from "styled-components";
import { isEmpty, get } from "lodash/fp";
import moment from "moment";
import { color } from "../common/styles/theme";
import { mapWithKey } from "../../core/utils";
import HeaderImage from "../../assets/images/bg-header-image.png";
import { formatCalendarDateMoment } from "../../core/utils/dateUtils/formatDateTime";
import SchedulesHeader from "../header/schedulesHeaderContainer";
import LoadingWrapper from "../common/LoadingWrapper";
import CommonButton from "../common/commonButton";
import DatesRange from "./datesRangeContainer";
import ColumnTitles from "./ColumnTitlesContainer";
import TotalsRow from "./totalsRowContainer";
import SubtotalsTable from "./subtotalsTableContainer";
import PrintSchedulesComponent from "./PrintSchedulesTable/PrintSchedulesContainer";

export default class SchedulesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.amountOfColumns = 9;
  }

  componentDidMount() {
    const { loadAssociates } = this.props;
    loadAssociates({ isLimited: false });
    this.loadWeekData();
    this.loadSalonHours();
  }

  componentDidUpdate(prevProps) {
    const { startWeekDate } = this.props;
    if (startWeekDate !== prevProps.startWeekDate) {
      this.loadWeekData();
      this.loadSalonHours();
    }
  }

  componentWillUnmount() {
    const { setSchedulesSearchValue } = this.props;
    setSchedulesSearchValue("");
  }

  loadWeekData = () => {
    const {
      isAllAssociatesWeekScheduleFetched,
      loadAssociatesSchedule,
      startWeekDate,
    } = this.props;

    if (!isAllAssociatesWeekScheduleFetched) {
      loadAssociatesSchedule({ fromDate: startWeekDate });
    }
  };

  loadSalonHours = () => {
    const { loadSalonHours, startWeekDate, storeHours } = this.props;

    if (!get(startWeekDate, storeHours)) {
      loadSalonHours({
        beginDate: startWeekDate,
        endDate: formatCalendarDateMoment(moment(startWeekDate).add(7, "days")),
      });
    }
  };

  filterScheduleByAssociates = ({ associates }) => {
    const { schedules } = this.props;
    const result = {};

    associates.forEach((associateId) => {
      result[associateId] = schedules[Number(associateId)];
    });

    return result;
  };

  handlePrint = () => {
    window.print();
  };

  render() {
    const { isLoading, schedules, filteredAssociatesByGroup } = this.props;

    return (
      <React.Fragment>
        <SchedulesContainer>
          <SchedulesHeader backgroundImage={HeaderImage}>
            <CommonButton label="Print" onClick={this.handlePrint} />
          </SchedulesHeader>
          <LoadingWrapper isLoading={isLoading} fullScreenHeight fullScreenWidth>
            <Container>
              <ScheduleHeader>
                <Row>
                  <Title>Schedule</Title>
                  <DatesRangeContainer>
                    <DatesRange />
                  </DatesRangeContainer>
                </Row>
                <ColumnTitles />
              </ScheduleHeader>
              {!isEmpty(schedules)
                ? mapWithKey((associates, groupName) => {
                    const filteredSchedules = this.filterScheduleByAssociates({
                      associates,
                    });

                    return (
                      <SubtotalsTable
                        key={groupName}
                        groupName={groupName}
                        amountOfColumns={this.amountOfColumns}
                        schedules={filteredSchedules}
                      />
                    );
                  }, filteredAssociatesByGroup)
                : !isLoading && <Container>No associates found.</Container>}

              {!isEmpty(schedules) && (
                <TotalsRow
                  schedules={schedules}
                  backgroundColor={color.gray50}
                  label="Totals"
                  noBorderTop
                />
              )}
            </Container>
          </LoadingWrapper>
        </SchedulesContainer>
        <PrintSchedulesComponent boldIndex={this.props.boldIndex} />
      </React.Fragment>
    );
  }
}

const Row = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 30px;
`;

const SchedulesContainer = styled.div`
  flex-direction: column;
  flex: 1;
  @media print {
    display: none;
  }
`;

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  padding: 20px;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 40px;
`;

const DatesRangeContainer = styled.div`
  display: flex;
  justify-self: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
`;

const ScheduleHeader = styled.div`
  position: sticky;
  top: 0;
  background-color: white;
  padding-top: 10px;
`;
