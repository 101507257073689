import React from "react";
import styled from "styled-components";
import { color } from "../../../web/common/styles/theme";

const RootComponent = styled.div`
  cursor: pointer;
  padding: 1em 0;
  ${props =>
    props.filterApplied === props.option &&
    `
        color: ${color.blue500}
        &:after {
            content: '\\2713';
            display: inline-block;
            margin: 0 0 0 5px;
        }
    `};
`;

export default function RootComponentContainer(props) {
  const { isHidden, componentID, option, selectedOption, selectionHandler } = props;

  if (!isHidden) {
    return (
      <RootComponent
        id={componentID}
        filterApplied={selectedOption}
        option={option}
        onClick={() => selectionHandler(option)}
      >
        {option}
      </RootComponent>
    );
  }
}
