import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import { get } from "lodash/fp";
import styled from "styled-components";
import isPetAndCustomerEligibilityReasonsEmpty from "dux/eligibility/helperUtils/isPetAndCustomerEligibilityReasonsEmpty";
import AppointmentRebookPastApptContainer from "dux/appointmentRebook/AppointmentRebookPastApptContainer";
import QuickViewRebookButtonEnterLeaveWrapperContainer from "dux/quickViews/QuickViewRebookButtonEnterLeaveWrapperContainer";
import { color } from "../common/styles/theme";

import CheckInOutHeader from "../header/CheckInOutHeaderContainer";
import PetParentDetails from "./petParentDetails/petParentDetailsContainer";
import PetDetails from "./petDetails/petDetailsContainer";
// import CheckInOutModals from "./modals/checkInOutModalsContainer";
import HeaderImage from "../../assets/images/bg-header-image.png";
import Cart from "../cart/CartContainer";
import { APPOINTMENT_DETAILS_TYPES, APPOINTMENT_STATUS } from "../../core/constants";
import getPageFromURLPath from "../../core/utils/matchUtils/pageFromURLPath";
import CommonButton from "../common/commonButton";
import LoadingWrapper from "../common/LoadingWrapper";

import { pageNames, statusChangeActions } from "../../core/constants/checkInOutConstants";
import ConfirmButtonsAggregate from "../confirmation/ConfirmButtonsAggregate";
import PrintInvoiceButton from "../../dux/printInvoice/printInvoiceButtonContainer";
import CancelAppointmentModal_CICO_Cont from "../cancelAppointment/CancelAppointmentModal_CICO_Cont";
import CheckInOutPetTabsContainer from "@/dux/checkInOutPetTabs/CheckInOutPetTabsContainer";
import { SalonCheckInOutModals } from "web/petCheckInOut/modals/checkInOutModals";

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const TabsWrapper = styled.div`
  padding-top: 2.875rem;
  padding-right: 2.1875rem;
  padding-left: 2.1875rem;
  border-bottom: 1px solid ${color.gray300};
`;

const PetAndAppointment = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const PetAndAppointmentColumns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
`;

const PrintButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

class SharedCheckInOutWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showedAlertsModal: false,
      actionClicked: null,
    };

    this.showAlertsModal = this.showAlertsModal.bind(this);
    this.handleError = this.handleError.bind(this);
    this.getActionForModal = this.getActionForModal.bind(this);
  }

  getStatusIncluded(values) {
    return [
      APPOINTMENT_STATUS.CHECKED_OUT,
      APPOINTMENT_STATUS.CANCELED,
      APPOINTMENT_STATUS.CONFIRMED,
      APPOINTMENT_STATUS.BOOKED,
    ].includes(get(["petServiceItem", "status"], values));
  }

  getIsDisabledMultiStatusCheckOut() {
    const { petServiceItems } = this.props;
    return petServiceItems.some(petServiceItem => this.getStatusIncluded({ petServiceItem }));
  }

  getCheckOutAction = confirmActionType => {
    const { showConfirmationModal } = this.props;

    showConfirmationModal({
      header: "Check-Out Reminder",
      content: (
        <div>
          Have all services/add-ons been updated on the appointment?
          <br />
          Completing this step will send the invoice to POS and
          <br />
          no further changes can be made to the appointment.
        </div>
      ),
      confirmText: "Proceed Anyway",
      confirm: () => this.createCartFooterAction(confirmActionType),
      cancelText: "Cancel",
    });
  };

  buildCartFooterActions() {
    const {
      pageName,
      isMultiplePets,
      currentPetServiceItem,
      isFromAnotherStore,
      isOnePetServiceItemCancelled,
      doServiceItemsMatch,
      isSRCAgent,
    } = this.props;
    const hide = !isMultiplePets;
    switch (pageName) {
      case pageNames.CHECK_OUT:
        return [
          {
            action: () => this.getCheckOutAction(statusChangeActions.CHECK_OUT_MULTI),
            label: `${pageName}-multi`,
            inverted: true,
            hide,
            disabled:
              this.getIsDisabledMultiStatusCheckOut() ||
              isFromAnotherStore ||
              isOnePetServiceItemCancelled ||
              !doServiceItemsMatch,
          },
          {
            action: () => this.getCheckOutAction(statusChangeActions.CHECK_OUT_SINGLE),
            label: pageName,
            disabled:
              this.getStatusIncluded({ petServiceItem: currentPetServiceItem }) ||
              isFromAnotherStore ||
              isSRCAgent,
          },
        ];

      default:
        return [];
    }
  }

  // TODO: Break this and functions it calls into smaller components for different functionalities.
  createCartFooterAction(actionClicked) {
    const { eligibilityWarnings, showEligibilityWarningsModal } = this.props;

    this.setState({ actionClicked });
    if (eligibilityWarnings) {
      showEligibilityWarningsModal();
    } else {
      this.getActionForModal(actionClicked)();
    }
  }

  componentDidMount() {
    const {
      router,
      setPage,
      petId,
      selectedPet,
      customerKey,
      setCurrentCustomerKey,
      isBGMOrFTCOEnabled,
      getAvailableBundlesForAllPetsByCustomer,
    } = this.props;

    const { itineraryId, storeNumber } = router.params;

    const pageName = getPageFromURLPath(router.location.pathname);
    setPage({ pageName });

    if (!customerKey && setCurrentCustomerKey) {
      setCurrentCustomerKey(router.params.customerKey);
    }

    if (customerKey && itineraryId) {
      this.props.loadItinerary({ customerKey, itineraryId });
      this.props.setCustomerID(customerKey);
      this.props.setItinerary(Number(itineraryId));
    }

    if (!petId && selectedPet) {
      this.props.selectPet(selectedPet.petId);
    }

    const canFetchAvailableBundles = isBGMOrFTCOEnabled && petId && customerKey;

    if (canFetchAvailableBundles) {
      getAvailableBundlesForAllPetsByCustomer({ petId });
    }

    if (itineraryId) {
      this.props.setItinerary(itineraryId);
    }

    if (this.props.petServiceItems.length && !this.props.currentPetServiceItemId) {
      this.props.setPetServiceItem(this.props.petServiceItems[0].petServiceItemId);

      if (storeNumber !== this.props.petServiceItems[0].storeNumber) {
        this.props.loadSalon(this.props.petServiceItems[0].storeNumber);
      }
    }

    if (this.props.currentPetServiceItem) {
      this.props.loadSalon(this.props.currentPetServiceItem.storeNumber);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      eligibilityAlerts,
      storeNumber,
      petId,
      isBGMOrFTCOEnabled,
      getAvailableBundlesForAllPetsByCustomer,
      customerKey,
    } = this.props;
    const { showedAlertsModal } = this.state;

    if (
      this.props.selectedPet &&
      (!prevProps.selectedPet || this.props.selectedPet.petId !== prevProps.selectedPet.petId)
    ) {
      const { petId } = this.props.selectedPet;

      this.props.setPetId(petId);
      this.props.selectPet(petId);
    }

    const canFetchAvailableBundles =
      (isBGMOrFTCOEnabled && prevProps.customerKey !== customerKey) ||
      (isBGMOrFTCOEnabled && !prevProps.petId && petId);

    if (canFetchAvailableBundles) {
      getAvailableBundlesForAllPetsByCustomer({ petId });
    }

    if (this.props.petServiceItems.length && !this.props.currentPetServiceItemId) {
      this.props.setPetServiceItem(this.props.petServiceItems[0].petServiceItemId);

      if (storeNumber !== this.props.petServiceItems[0].storeNumber) {
        this.props.loadSalon(this.props.petServiceItems[0].storeNumber);
      }
    }

    if (!isPetAndCustomerEligibilityReasonsEmpty(eligibilityAlerts) && !showedAlertsModal) {
      this.showAlertsModal();
    }
  }

  componentWillUnmount() {
    this.props.setPetServiceItem(null);
  }

  getActionForModal(actionClicked) {
    const { updateCheckInOutMultiStatus, updateCheckInOutSingleStatus, pageName } = this.props;
    switch (actionClicked) {
      case statusChangeActions.CHECK_OUT_MULTI:
        return () =>
          updateCheckInOutMultiStatus({
            reason: "",
            status: APPOINTMENT_STATUS.CHECKED_OUT,
            pageName: `${pageName}-multi`,
          });
      case statusChangeActions.CHECK_OUT_SINGLE:
        return () =>
          updateCheckInOutSingleStatus({
            reason: "",
            status: APPOINTMENT_STATUS.CHECKED_OUT,
            pageName,
          });
      default:
        return () => {};
    }
  }

  showAlertsModal() {
    const { showAlertsModal } = this.props;
    showAlertsModal();
    this.setState({
      showedAlertsModal: true,
    });
  }

  handleError() {
    alert(
      "There does not seem to be an Itinerary or Customer. You will be taken back to the dashboard.",
    );
    return <Navigate replace to="/dashboard" />;
  }

  handleSelectPet = petServiceItemId => {
    const { setPetServiceItem } = this.props;

    setPetServiceItem(petServiceItemId);
  };

  getAppointmentDetailsType = () => {
    const { pageName } = this.props;

    switch (pageName) {
      case pageNames.CHECK_IN:
        return APPOINTMENT_DETAILS_TYPES.CHECK_IN;

      case pageNames.CHECK_OUT:
        return APPOINTMENT_DETAILS_TYPES.CHECK_OUT;

      case pageNames.FUTURE_APPOINTMENT:
        return APPOINTMENT_DETAILS_TYPES.FUTURE_APPOINTMENT;

      case pageNames.PAST_APPOINTMENT:
        return APPOINTMENT_DETAILS_TYPES.PAST_APPOINTMENT;

      default:
    }
  };

  render() {
    const {
      petNames,
      hasError,
      isPetsLoading,
      ModalType,
      selectedPet,
      petServiceItems,
      currentPetServiceItemId,
      router,
      printServiceCard,
      printServiceCards,
      isMultiplePets,
      pageName,
      currentPetServiceItem,
      itineraryId,
      isPageLoading,
      isFromAnotherStore,
      diComp,
    } = this.props;
    const { customerKey } = router.params;

    if (hasError) {
      return this.handleError();
    }
    if (!selectedPet) {
      return null;
    }

    const CartColumn = diComp?.cartColumn || React.Fragment;

    return (
      <LoadingWrapper isLoading={isPageLoading}>
        <SectionWrapper>
          <CheckInOutHeader
            petId={selectedPet.petId}
            customerKey={customerKey}
            backgroundImage={HeaderImage}
          >
            <PrintInvoiceButton />
            {pageName === pageNames.CHECK_OUT && (
              <PrintButtons>
                <CommonButton
                  disabled={isFromAnotherStore}
                  label={`Print service card${isMultiplePets ? " for selected pet" : ""}`}
                  onClick={() => printServiceCard()}
                />
                {isMultiplePets && (
                  <CommonButton
                    disabled={isFromAnotherStore}
                    label="Print service cards for all pets"
                    onClick={() => printServiceCards()}
                  />
                )}
              </PrintButtons>
            )}
            {pageName === pageNames.PAST_APPOINTMENT && (
              <QuickViewRebookButtonEnterLeaveWrapperContainer>
                <AppointmentRebookPastApptContainer />
              </QuickViewRebookButtonEnterLeaveWrapperContainer>
            )}
            <ConfirmButtonsAggregate />
          </CheckInOutHeader>
          <PageContent>
            <PetParentDetails customerKey={customerKey} />
            <LoadingWrapper isLoading={isPetsLoading}>
              <PetAndAppointment>
                {isMultiplePets && (
                  <TabsWrapper>
                    <CheckInOutPetTabsContainer
                      itineraryId={itineraryId}
                      selectedPet={currentPetServiceItemId}
                      customerKey={customerKey}
                      petNames={petNames}
                      onSelectPet={this.handleSelectPet}
                    />
                  </TabsWrapper>
                )}
                <PetAndAppointmentColumns>
                  <PetDetails petId={selectedPet.petId} customerKey={customerKey} />
                  <CartColumn
                    customerKey={customerKey}
                    petId={selectedPet.petId}
                    storeId={currentPetServiceItem.storeNumber}
                    footerActions={this.buildCartFooterActions()}
                    appointmentDetailsType={this.getAppointmentDetailsType()}
                  />
                </PetAndAppointmentColumns>
              </PetAndAppointment>
            </LoadingWrapper>
          </PageContent>

          {ModalType ? (
            <SalonCheckInOutModals
              itineraryId={itineraryId}
              petId={selectedPet.petId}
              customerKey={customerKey}
              onProceed={() => this.getActionForModal(this.state.actionClicked)()}
              contentType={ModalType}
              selectedPets={
                (this.state.actionClicked === statusChangeActions.CHECK_IN_SINGLE ||
                  this.state.actionClicked === statusChangeActions.CHECK_OUT_SINGLE) && [
                  selectedPet.petId,
                ]
              }
            />
          ) : null}

          <CancelAppointmentModal_CICO_Cont />
        </SectionWrapper>
      </LoadingWrapper>
    );
  }
}

export default SharedCheckInOutWrapper;
