import React from "react";
import styled from "styled-components";
import { color } from "../../../web/common/styles/theme";

function config(type, props) {
  if (type === "bundle") {
    return `
      color: ${color.blue500};
    `;
  }

  if (type === "price-change") {
    return `
      text-decoration: line-through;
      padding-left: 0.5em;
    `;
  }

  if (type === "add-on") {
    const { isPriceClickable } = props;
    return `
      cursor: ${isPriceClickable ? "pointer" : "normal"};
      color: ${color.blue500};
      pointer-events: ${isPriceClickable ? "auto" : "none"};
    `;
  }

  return "";
}

const SectionWrapper = styled.span`
  display: flex;
  flex-direction: row;
  ${({ type, ...props }) => config(type, props)};
`;

export default function PriceComponent({
  isHidden,
  componentID,
  price,
  type = "bundle",
  ...props
}) {
  if (!isHidden) {
    return (
      <SectionWrapper id={componentID} type={type} {...props}>
        ${price}
      </SectionWrapper>
    );
  }

  return null;
}
