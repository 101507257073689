import { connect } from "react-redux";
import BookingFoodFormComponent from "dux/_components/feeding/booking/BookingFoodFormComponent";
import { getFoodIsShowingDetailsForType } from "web/foodList/foodListSelectors";
import { NEW_FOOD_ID } from "web/newFood/newFoodConstants";
import { getFoodIds } from "web/food/foodsSelector";

const mapStateToProps = (state, ownProps) => {
  const { petId, locationType } = ownProps;
  const foodId = NEW_FOOD_ID;
  const isShowingDetails = getFoodIsShowingDetailsForType(state, {
    foodId,
    petId,
    locationType,
  });
  return {
    componentId: `bookingFoodForm-${foodId}`,
    foodId,
    petId,
    foodIds: getFoodIds(state, { petId }),
    isHidden: !isShowingDetails,
  };
};

export default connect(mapStateToProps)(BookingFoodFormComponent);
