import React from "react";
import { connect } from "react-redux";

// Selectors
import { getPets } from "@/core/selectors/entitiesSelector";
import { getHotelEngagementsPets } from "@/dux/hotelEngagements/hotelEngagementSelectors";
import { getCurrentPet } from "@/core/selectors/persistentSelectors";

// Components
import { Tabs } from "@petsmart-ui/sparky";
import { LayoutBox } from "@/layout/box/Box";

export const CheckOutPetTabsComponent = props => {
  const { allPets, componentId, isHidden, currentPetId, hotelEngagementsPets, setPetId } = props;

  const petIndex = hotelEngagementsPets?.indexOf(currentPetId);
  const activeTab = petIndex === -1 ? 0 : petIndex;

  const handleActiveTabChange = index => {
    const petId = hotelEngagementsPets[index];
    setPetId(petId);
  };

  if (isHidden) return null;
  return (
    <LayoutBox padding="scale-0" id={componentId}>
      <Tabs
        key={hotelEngagementsPets?.map(petId => allPets[petId]?.petName)?.join(",")}
        activeIndex={activeTab}
        onChange={handleActiveTabChange}
      >
        {hotelEngagementsPets?.map(petId => {
          const petName = allPets[petId]?.petName;
          return <Tabs.Panel title={petName} key={petId} />;
        })}
      </Tabs>
    </LayoutBox>
  );
};

export const HotelCheckInOutPetTabs = connect(state => {
  return {
    componentId: "HotelCheckInOutPetTabs",
    hotelEngagementsPets: getHotelEngagementsPets(state),
    currentPetId: getCurrentPet(state),
    allPets: getPets(state),
  };
})(CheckOutPetTabsComponent);
