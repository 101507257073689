import React, { useState } from "react";
import { minimizeWhitespace } from "@/core/utils/stringUtils/stringModificationUtils";
import { TextField } from "@petsmart-ui/sparky";

const InputWithBlur = props => {
  const {
    componentId,
    title,
    isHidden,
    error,
    disabled,
    maxLength,
    currentValue,
    onChange,
    name,
  } = props;

  const [touched, setTouched] = useState(false);
  const showError = touched && error;

  if (isHidden) return null;

  return (
    <TextField
      id={componentId}
      label={title}
      onBlur={() => setTouched(true)}
      value={currentValue}
      onChange={e => onChange(minimizeWhitespace(e.target.value))}
      fieldNote={showError}
      isError={!!showError}
      disabled={disabled}
      maxLength={maxLength}
      name={name}
    />
  );
};

export default InputWithBlur;
