import React, { useRef, useState } from "react";
import moment from "moment";
import TimeRangePicker from "./TimeRangePicker";

import { ClickOutside } from "@/core/utils/clickOutsideUtils/clickOutside";
import EditIcon from "@/assets/icons/edit.svg";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { Button, TextPassage } from "@petsmart-ui/sparky";

const TimeRangePickerToggle = props => {
  const { disabled, petServiceItem, onSave, startTime, endTime } = props;

  const [inEditMode, setInEditMode] = useState(false);
  const [appointmentStartTime, setAppointmentStartTime] = useState(null);
  const [appointmentEndTime, setAppointmentEndTime] = useState(null);

  const isValid =
    appointmentStartTime &&
    appointmentEndTime &&
    appointmentStartTime.isBefore(appointmentEndTime, "minutes");
  const startDateTime = petServiceItem?.startDateTime || appointmentStartTime;
  const endDateTime = petServiceItem?.endDateTime || appointmentEndTime;

  const initialStartTime = startTime ? moment(startTime, "HH:mm") : null;
  const initialEndTime = endTime ? moment(endTime, "HH:mm") : null;

  const cancel = () => {
    setInEditMode(false);
    setAppointmentStartTime(initialStartTime);
    setAppointmentEndTime(initialEndTime);
  };

  const save = () => {
    if (!isValid) return;

    if (onSave) {
      onSave({
        startTime: appointmentStartTime,
        endTime: appointmentEndTime,
      });
    }
    setInEditMode(false);
  };

  const handleClickOutside = () => {
    if (inEditMode) {
      save();
    }
  };

  const clickOutsideRef = useRef(null);
  ClickOutside(clickOutsideRef, handleClickOutside);

  return (
    <div ref={clickOutsideRef}>
      <LayoutCluster
        style={disabled ? { pointerEvents: "none", opacity: "0.5" } : {}}
        data-testid="time-range-picker-toggle"
        flexWrap="flex-noWrap"
      >
        {inEditMode ? (
          <>
            <TimeRangePicker
              startTime={appointmentStartTime || initialStartTime}
              endTime={appointmentEndTime || initialEndTime}
              onChange={({ startTime: newStart, endTime: newEnd }) => {
                setAppointmentStartTime(newStart ? moment(newStart, "HH:mm") : null);
                setAppointmentEndTime(newEnd ? moment(newEnd, "HH:mm") : null);
              }}
            />
            <Button
              variant="bare"
              onClick={save}
              text="Save"
              disabled={!isValid}
              style={!isValid ? { opacity: "0.5" } : {}}
            />
            <Button variant="bare" onClick={cancel} text="Cancel" />
          </>
        ) : (
          <TextPassage size="lg">
            {startDateTime && endDateTime
              ? `${moment(startDateTime).format("hh:mm A")} - ${moment(endDateTime).format(
                  "hh:mm A",
                )}`
              : "No times entered"}
          </TextPassage>
        )}

        {!inEditMode && (
          <Button
            variant="bare"
            onClick={() => !disabled && !inEditMode && setInEditMode(true)}
            text={<img src={EditIcon} alt="Edit" />}
          />
        )}
      </LayoutCluster>
    </div>
  );
};

export default TimeRangePickerToggle;
