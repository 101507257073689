import React, { useEffect, useState } from "react";
import { Box, LoadingIndicator, Tabs, TextPassage } from "@petsmart-ui/sparky";
import { Text } from "@prism/psm-ui-components";
import { connect } from "react-redux";
import {
  selectHotelBookingEndDate,
  selectHotelBookingFurthestStep,
  selectHotelBookingIsActive,
  selectHotelBookingPetList,
  selectHotelBookingStartDate,
  selectPetSelectedService,
  selectPetRoomTypeBucketId,
  selectRoomHostIdByPetId,
  selectGuestPetsByHostPetId,
  selectHotelBookingRooms,
} from "web/features/hotelBookingFlow/hotelBookingFlowSelectors";
import { createLoadingSelector } from "core/selectors/utils";
import { LOAD_PET_SERVICES_AT_HOTEL_BOOKING } from "web/services/hotelServices/actions/petServicesAtHotel";
import { getPendingAppointmentServicesByPet } from "web/pendingAppointment/selectors/pendingAppointmentSelectors";
import { formatMoney } from "dux/_utils/moneyUtil";
import {
  setHotelBookingFlowPetRoomNumber,
  setHotelBookingFlowPetService,
  deleteHotelBookingFlowPetService,
  setHotelBookingFlowRoom,
  setHotelBookingPendingChanges,
  removeHotelBookingFlowRoom,
} from "web/features/hotelBookingFlow/hotelBookingFlowActions";
import moment from "moment";
import groupBy from "core/utils/arrayUtils/groupBy";
import { hotelBookingFlowSparkyBoxStyle } from "web/features/hotelBookingFlow/hotelBookingFlowUtils";
import { showBookingModal } from "@/core/actionCreators/bookingActionCreators";
import { hotelBookingTypes } from "@/web/hotelAlerts/hotelBookingConstants";
import { LayoutStack } from "@/layout/stack/Stack";
import { getCardForPet } from "../petCartCard/selectors/petCartCardSelectors";
import { getIsSRCAgent } from "core/selectors/persistentSelectors";
import { compose } from "redux";
import { selectAllPetServicesMatch } from "../hotelEngagements/hotelEngagementSelectors";
import { color } from "@/web/common/styles/theme";
import { errorMessages } from "./__constants__/hotelBookingRoomSelectionConstants";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutGrid } from "@/layout/grid/Grid";
import { LayoutBox } from "@/layout/box/Box";
import { SERVICE_NOT_AVAIL } from "../primaryServiceSelection/PrimaryServiceDropdown";

const HotelBookingServiceOptions = ({
  onServiceSelect,
  onIsActive = () => {},
  componentId,
  isLoading,
  isOvernight,
  isActive,
  initialSelectedService = null,
  petServiceGroups = {},
  shouldRestartToEdit = false,
  savePendingAndShowModal = () => {},
  isSRCAgent,
  hostRoomTypeBucketId,
  resetGuestServices,
  error,
}) => {
  const [selectedService, setSelectedService] = useState(initialSelectedService);

  useEffect(() => {
    // If initialSelectedService was updated and selectedService no longer matches then update it
    if (initialSelectedService !== selectedService) setSelectedService(initialSelectedService);
  }, [initialSelectedService]);

  useEffect(() => {
    onIsActive();
  }, [isActive]);

  if (isLoading) {
    return (
      <LayoutCluster style={{ justifyContent: "center" }}>
        <LoadingIndicator iconSize="md" />
      </LayoutCluster>
    );
  }

  const onServiceClick = ({ petServiceId, isAvailable, roomTypeBucketId }) => {
    if (isSRCAgent && !isAvailable) return;

    const shouldResetGuests = hostRoomTypeBucketId && hostRoomTypeBucketId !== roomTypeBucketId;

    if (shouldRestartToEdit) {
      savePendingAndShowModal(petServiceId, roomTypeBucketId, shouldResetGuests);
      return;
    }

    if (shouldResetGuests) {
      resetGuestServices();
    }

    setSelectedService(petServiceId);
    onServiceSelect(petServiceId, roomTypeBucketId);
  };

  const services = groupServices => {
    return (
      <LayoutStack id={componentId} style={{ justifyContent: "center", padding: "5%" }}>
        {groupServices?.map(service => (
          <Box
            key={service.petServiceId}
            onClick={() => onServiceClick(service)}
            style={hotelBookingFlowSparkyBoxStyle({
              disabled: isSRCAgent && !service.isAvailable,
              isSelected: selectedService === service.petServiceId,
            })}
          >
            <LayoutGrid space="scale-0" style={{ gridTemplateColumns: ".5fr 1.5fr .5fr" }}>
              <img src={service?.imageUrl} alt="" style={{ width: "100%", height: "auto" }} />
              <LayoutStack>
                <Text bold align="center" style={{ alignSelf: "center" }}>
                  {service.associateDisplayName}
                </Text>
                {service?.isRoomTypeRecommended && (
                  <Text color="text-color-green" align="center" bold>
                    Recommended
                  </Text>
                )}
              </LayoutStack>
              <LayoutStack>
                <Text align="right">{formatMoney(service.price)}</Text>
                {isOvernight && <Text align="right">/per night</Text>}
              </LayoutStack>
            </LayoutGrid>
            {!service.isAvailable && (
              <Box style={{ border: "none" }} styleModifier="flush">
                <Text color="text-color-red" style={{ fontSize: ".9em" }}>
                  {SERVICE_NOT_AVAIL}
                </Text>
              </Box>
            )}
          </Box>
        ))}
      </LayoutStack>
    );
  };

  return (
    <LayoutBox padding="scale-0">
      {isOvernight ? (
        <Tabs key={Object.keys(petServiceGroups).join("-")}>
          {Object.keys(petServiceGroups)?.map(group => {
            return (
              <Tabs.Panel key={group} title={group}>
                {services(petServiceGroups[group])}
              </Tabs.Panel>
            );
          })}
        </Tabs>
      ) : (
        services(petServiceGroups)
      )}

      {error && <TextPassage style={{ color: color.red700 }}>{error}</TextPassage>}
    </LayoutBox>
  );
};

/**
 * Helper used to create pending changes for guest pets to clear them if host room type changes
 * @param {Array} guestPetIds
 * @returns obj with pet ids as keys and values with nul service and room type
 */
export const getPendingGuests = guestPetIds => {
  return guestPetIds?.reduce(
    (guests, petId) => ({ ...guests, [petId]: { petService: null, roomTypeBucketId: null } }),
    {},
  );
};

const RebookingServicesContainer = connect(
  (state, { petId }) => {
    const currentPet = petId ?? selectHotelBookingPetList(state)[0];
    const allEngagementServiceIdsMatch = selectAllPetServicesMatch(state, { petId: currentPet });

    return {
      // If current pet has different services per engagement then show message
      error: !allEngagementServiceIdsMatch && errorMessages.REBOOK_SERVICE,
    };
  },
  dispatch => ({
    selectServiceForPet: ({ petServiceId, roomTypeBucketId, petId }) =>
      dispatch(setHotelBookingFlowPetService({ petServiceId, roomTypeBucketId, petId })),
  }),
  (stateProps, dispatchProps, ownProps) => {
    const { error } = stateProps;
    const { selectServiceForPet } = dispatchProps;

    return {
      ...ownProps,
      error,
      selectServiceForPet,
    };
  },
);

export const HotelSinglePetBookingServiceOptions = compose(
  RebookingServicesContainer,
  connect(
    (state, { stepIndex }) => {
      const pets = selectHotelBookingPetList(state);
      const petId = pets[0]; // Only 1 pet in a single pet booking, so grab first array element
      const startDate = selectHotelBookingStartDate(state);
      const endDate = selectHotelBookingEndDate(state);

      const petServices = getPendingAppointmentServicesByPet({ petId })(state);
      const isOvernight = !moment(startDate).isSame(moment(endDate), "day");
      const isSRCAgent = getIsSRCAgent(state);

      return {
        componentId: "hotelBookingFlow-serviceOptions",
        isLoading: createLoadingSelector([LOAD_PET_SERVICES_AT_HOTEL_BOOKING])(state),
        furthestStep: selectHotelBookingFurthestStep(state),
        stepIndex,
        isActive: selectHotelBookingIsActive(stepIndex)(state),
        petId,
        isOvernight,
        initialSelectedService: selectPetSelectedService(petId)(state),
        petServiceGroups: isOvernight ? groupBy(petServices, "groupName") : petServices,
        // If this pet is already in the cart then editing the service will need to create a new itinerary
        shouldRestartToEdit: !!getCardForPet(state, { petId }),
        isSRCAgent,
        roomsToRemove: Object.keys(selectHotelBookingRooms(state)),
      };
    },
    (dispatch, { stepIndex }) => {
      return {
        dispatchSetHotelBookingFlowRoom: ({ petId, roomsToRemove }) => {
          // Cleanup any existing room selections and add one room with current pet
          roomsToRemove?.map(roomId => dispatch(removeHotelBookingFlowRoom({ roomId })));
          dispatch(setHotelBookingFlowRoom({ roomId: 1, pets: [petId] }));
        },
        dispatchSetHotelBookingFlowPetRoomNumber: ({ roomId, petId }) =>
          dispatch(setHotelBookingFlowPetRoomNumber({ roomId, petId })),
        savePendingAndShowModal: ({ selectedService, roomTypeBucketId, petId }) => {
          dispatch(
            setHotelBookingPendingChanges({
              [petId]: { petService: selectedService, roomTypeBucketId },
              openStep: stepIndex,
              furthestStep: stepIndex,
            }),
          );
          dispatch(showBookingModal(hotelBookingTypes.HOTEL_BOOKING_RESTART_MODAL));
        },
      };
    },
    (mapProps, dispatchProps, ownProps) => {
      const {
        isOvernight,
        componentId,
        isActive,
        isLoading,
        petId,
        furthestStep,
        stepIndex,
        petServiceGroups,
        shouldRestartToEdit,
        initialSelectedService,
        isSRCAgent,
        roomsToRemove,
      } = mapProps;
      const {
        dispatchSetHotelBookingFlowRoom,
        dispatchSetHotelBookingFlowPetRoomNumber,
        savePendingAndShowModal,
      } = dispatchProps;
      const { error, selectServiceForPet } = ownProps;

      return {
        // control what props get passed to the view
        onIsActive: () => {
          // If the step is active and if we haven't continued past selecting services before.
          if (isActive && furthestStep <= stepIndex) {
            dispatchSetHotelBookingFlowRoom({ petId, roomsToRemove });
            dispatchSetHotelBookingFlowPetRoomNumber({ roomId: 1, petId });
          }
        },
        onServiceSelect: (petServiceId, roomTypeBucketId) => {
          selectServiceForPet({ petServiceId, roomTypeBucketId, petId });
        },
        componentId,
        isActive,
        isLoading,
        isOvernight,
        petServiceGroups,
        initialSelectedService,
        isSRCAgent,
        shouldRestartToEdit,
        savePendingAndShowModal: (selectedService, roomTypeBucketId) => {
          savePendingAndShowModal({ selectedService, roomTypeBucketId, petId });
        },
        error,
      };
    },
  ),
)(HotelBookingServiceOptions);

export const HotelMultiPetBookingServiceOptions = compose(
  RebookingServicesContainer,
  connect(
    (state, { petId, stepIndex }) => {
      const startDate = selectHotelBookingStartDate(state);
      const endDate = selectHotelBookingEndDate(state);

      const petServices = getPendingAppointmentServicesByPet({ petId })(state);
      const hostPetId = selectRoomHostIdByPetId(state, { petId });
      const hostRoomTypeBucketId = selectPetRoomTypeBucketId(hostPetId)(state);

      const isHostPet = hostPetId === petId;
      const servicesMatchingRoomTypeBucket = petServices.filter(
        ({ roomTypeBucketId }) => roomTypeBucketId === hostRoomTypeBucketId,
      );
      const petServicesMatchingHostRoomTypeBucket = isHostPet
        ? petServices
        : servicesMatchingRoomTypeBucket;

      const guestPetIds = selectGuestPetsByHostPetId(state, { hostPetId });
      const isOvernight = !moment(startDate).isSame(moment(endDate), "day");
      const isSRCAgent = getIsSRCAgent(state);

      return {
        componentId: "hotelBookingFlow-serviceOptions",
        isLoading: createLoadingSelector([LOAD_PET_SERVICES_AT_HOTEL_BOOKING])(state),
        petId,
        isOvernight: !moment(startDate).isSame(moment(endDate), "day"),
        initialSelectedService: selectPetSelectedService(petId)(state),
        petServiceGroups: isOvernight
          ? groupBy(petServicesMatchingHostRoomTypeBucket, "groupName")
          : petServicesMatchingHostRoomTypeBucket,
        // If this pet is already in the cart then editing the service will need to create a new itinerary
        shouldRestartToEdit: !!getCardForPet(state, { petId }),
        isSRCAgent,
        hostRoomTypeBucketId,
        guestPetIds,
        isActive: selectHotelBookingIsActive(stepIndex)(state),
      };
    },
    (dispatch, { stepIndex }) => {
      return {
        savePendingAndShowModal: ({ selectedService, roomTypeBucketId, petId, pendingGuests }) => {
          dispatch(
            setHotelBookingPendingChanges({
              [petId]: { petService: selectedService, roomTypeBucketId },
              ...pendingGuests,
              openStep: stepIndex,
              furthestStep: stepIndex,
            }),
          );
          dispatch(showBookingModal(hotelBookingTypes.HOTEL_BOOKING_RESTART_MODAL));
        },
        resetGuestServices: guestPetIds => {
          guestPetIds.forEach(petId => {
            dispatch(deleteHotelBookingFlowPetService({ petId }));
          });
        },
      };
    },
    (mapProps, dispatchProps, ownProps) => {
      const {
        isOvernight,
        componentId,
        isLoading,
        petId,
        initialSelectedService,
        petServiceGroups,
        shouldRestartToEdit,
        isSRCAgent,
        hostRoomTypeBucketId,
        guestPetIds,
        isActive,
      } = mapProps;
      const { savePendingAndShowModal, resetGuestServices } = dispatchProps;
      const { error, selectServiceForPet } = ownProps;

      return {
        // control what props get passed to the view
        onServiceSelect: (petServiceId, roomTypeBucketId) => {
          selectServiceForPet({ petServiceId, roomTypeBucketId, petId });
        },
        componentId,
        isLoading,
        isOvernight,
        initialSelectedService,
        petServiceGroups,
        shouldRestartToEdit,
        isSRCAgent,
        hostRoomTypeBucketId,
        isActive,
        savePendingAndShowModal: (selectedService, roomTypeBucketId, shouldResetGuests) => {
          const pendingGuests = shouldResetGuests ? getPendingGuests(guestPetIds) : {};
          savePendingAndShowModal({ selectedService, roomTypeBucketId, petId, pendingGuests });
        },
        resetGuestServices: () => resetGuestServices(guestPetIds),
        error,
      };
    },
  ),
)(HotelBookingServiceOptions);
