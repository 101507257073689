import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

// Components
import { LayoutBox } from "@/layout/box/Box.tsx";
import { LayoutStack } from "@/layout/stack/Stack.tsx";
import GridTable from "@/web/common/GridTable/GridTable";
import { TextPassage } from "@petsmart-ui/sparky";
import {
  buildSearchFieldProducts,
  SearchFactorySalon,
} from "@/dux/searchFieldMain/searchFactory/SearchFactory.ts";

// Actions
import {
  setTabFilter as setTabFilterAction,
  loadAssociates as fetchAssociates,
} from "@/core/actionCreators/associateActionCreator";
import { getTrainingAssociates as fetchTrainingAssociates } from "@/dux/trainingAssociates/trainingAssociatesActions";

// Selectors
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import {
  getSearchApplied,
  getSearchFieldValue,
  getSearchFieldType,
} from "@/core/selectors/searchSalonSRCSelector";
import {
  getAssociatesBySearchOrFilter,
  getAssociatesByGroup,
  getTabFilterApplied,
} from "@/core/selectors/associatesSelectors";
import getIsTrainingWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/training/getIsTrainingWorkflowFeatureFlagHidden";
import getIsSearchFieldMainV2WorkflowFeatureFlagHidden from "web/enableDisableWorkflowFeatureFlag/selectors/searchFieldMainV2/getIsSearchFieldMainV2WorkflowFeatureFlagHidden";

// Utils
import InitialsCircle from "@/web/common/InitialsCircle";
import { capitalize, keys } from "lodash/fp";
import { getAssociatePreferredName } from "@/core/utils/associateUtils";
import { mapWithKey } from "@/core/utils";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";

// Constants
import { font, color } from "@/web/common/styles/theme";
import { associateGroupConstants } from "@/core/constants/associatesConstants";

import headerImage from "@/assets/images/bg-header-image.png";
import AssociatesSearchHeaderContainer from "../../header/AssociatesSearchHeaderContainer";

/**
 *  React view component that allows associate to search other associates
 *
 *  @summary Located on the "/associates" path in App.js.
 *  @memberOf Views.Associate
 *  @function
 *  @name AssociatesSearchComponent
 *  @param { Object } props - props passed into the view component
 *  @param { Array } props.associates
 *  @param { Array } props.associatesByGroup
 *  @param { String } props.componentId
 *  @param { Function } props.getTrainingAssociates
 *  @param { Boolean } props.isTrainingFeatureFlagHidden
 *  @param { Function } props.loadAssociates
 *  @param { Object } props.router
 *  @param { JSX.Element } props.searchField
 *  @param { Function } props.setTabFilter
 *  @param { String } props.tabFilterApplied
 *  @returns {JSX.Element}
 *  @example
 *
 *  <AssociatesSearchComponent />
 */
const AssociatesSearchComponent = props => {
  const {
    associates,
    associatesByGroup,
    componentId,
    getTrainingAssociates,
    isTrainingFeatureFlagHidden,
    loadAssociates,
    router,
    searchField,
    setTabFilter,
    tabFilterApplied,
  } = props;

  const numberOfAssociatesFound = keys(associates).length;
  const isAllSelected = tabFilterApplied === associateGroupConstants.ALL;
  const SearchField = searchField;

  // Grid Config
  const config = {
    gridOptions: {
      rowClick: row => router.navigate(`/associate/${row.associateId}`),
      rowKeyField: "associateId",
      fixedHeader: true,
    },
    columnDefs: [
      {
        title: "",
        field: "associateImage",
        width: "80px",
        cellRenderer: row => <InitialsCircle displayName={row.associateName} margin={"0 1em"} />,
      },
      {
        title: "Preferred Name",
        field: "preferredName",
        cellRenderer: row => (
          <div>
            {getAssociatePreferredName(row)}
            <br />
            {row.lastName}
          </div>
        ),
      },
      {
        title: "Associate #",
        field: "oktaId",
      },
      {
        title: "Job Group",
        field: "associateGroup",
      },
      {
        title: "Bookable in salon",
        field: "isBookable",
        valueGetter: row => (row.isBookable ? "Yes" : "No"),
      },
    ],
  };

  // Component Mount
  useEffect(() => {
    setTabFilter(associateGroupConstants.ALL);
    loadAssociates({ isLimited: false });

    if (!isTrainingFeatureFlagHidden) {
      getTrainingAssociates();
    }
  }, []);

  return (
    <LayoutStack id={componentId} space="scale-0">
      <AssociatesSearchHeaderContainer
        id={`${componentId}__header`}
        backgroundImage={headerImage}
      />
      <LayoutBox id={`${componentId}__searchHeading`} style={{ margin: "2em 3em" }}>
        <TextPassage id={`${componentId}__subHeading`} style={{ margin: "0 0 2em 0" }}>
          <h3>Associate Profiles</h3>
        </TextPassage>
        <LayoutBox id={`${componentId}__searchComponentWrapper`}>
          <SearchField />
        </LayoutBox>
        <LayoutBox id={`${componentId}__searchResultsTabGroup`} style={{ display: "flex" }}>
          <LayoutBox
            id={`${componentId}__searchResultsTab--all`}
            onClick={() => setTabFilter(associateGroupConstants.ALL)}
            style={{
              fontFamily: font.secondaryFamily,
              margin: "2em 2em 0 0",
              padding: "0 0 0.5em 0",
              cursor: "pointer",
              color: isAllSelected && color.blue500,
              borderBottom: isAllSelected && `1px solid ${color.blue500}`,
            }}
          >
            {`${capitalize(associateGroupConstants.ALL)}`}
          </LayoutBox>
          {mapWithKey(
            (group, groupName) => (
              <LayoutBox
                id={`${componentId}__searchResultsTab--${groupName}`}
                onClick={() => setTabFilter(groupName)}
                key={groupName}
                style={{
                  fontFamily: font.secondaryFamily,
                  margin: "2em 2em 0 0",
                  padding: "0 0 0.5em 0",
                  cursor: "pointer",
                  color: tabFilterApplied === groupName && color.blue500,
                  borderBottom: tabFilterApplied === groupName && `1px solid ${color.blue500}`,
                }}
              >
                {`${group.length} ${capitalize(groupName)}${group.length > 1 ? "s" : ""}`}
              </LayoutBox>
            ),
            associatesByGroup,
          )}
        </LayoutBox>
      </LayoutBox>
      {numberOfAssociatesFound === 0 ? (
        <LayoutBox style={{ marginLeft: "3em" }}>{`${numberOfAssociatesFound} Results`}</LayoutBox>
      ) : (
        <GridTable config={config} rowsData={associates} />
      )}
    </LayoutStack>
  );
};

const mapStateToProps = state => {
  const associatesByGroup = getAssociatesByGroup(state);
  const isFeatureFlagHidden = getIsSearchFieldMainV2WorkflowFeatureFlagHidden(state);
  const search = buildSearchFieldProducts(new SearchFactorySalon());

  return {
    componentId: "AssociateSearch",
    isTrainingFeatureFlagHidden: getIsTrainingWorkflowFeatureFlagHidden(state),
    storeNumber: getStoreNumber(state),
    associates: getAssociatesBySearchOrFilter(state),
    associatesByGroup,
    searchApplied: getSearchApplied(state),
    searchValue: getSearchFieldValue(state),
    searchFieldType: getSearchFieldType(state),
    tabFilterApplied: getTabFilterApplied(state),
    // Factory will return the new or old associate search based on feature flag status
    searchField: search.associateSearch.getSearchField(isFeatureFlagHidden),
  };
};

const mapDispatchToProps = dispatch => ({
  setTabFilter: tabFilter => dispatch(setTabFilterAction({ tabFilter })),
  loadAssociates: ({ isLimited }) => dispatch(fetchAssociates({ isLimited })),
  dispatchGetTrainingAssociates: storeNumber => dispatch(fetchTrainingAssociates({ storeNumber })),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { storeNumber } = stateProps;
  const { dispatchGetTrainingAssociates } = dispatchProps;
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    getTrainingAssociates: () => {
      dispatchGetTrainingAssociates(storeNumber);
    },
  };
};

export default compose(
  withRouteProps,
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
)(AssociatesSearchComponent);
