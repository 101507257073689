import React from "react";
import styled from "styled-components";
import { get } from "lodash/fp";
import {
  GroupMemberTitle,
  GroupMemberDetails,
} from "../ganttCommonComponents/groupMemberComponents";

import InitialsCircle from "../../common/InitialsCircle";
import { color } from "../../common/styles/theme";
import { preferredNameConstants } from "@/core/constants/associatesConstants";

const StyledGanttAssociateInfoComponent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  min-height: 80px;
  color: ${props => (props.isSelectedAssociate ? color.blue500 : "inherit")};
  cursor: pointer;
  max-width: 100%;
  overflow: hidden;
`;

const AssociateInfo = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const GanttAssociateInfoComponent = props => {
  const { associate, shiftTime, isSelectedAssociate } = props;

  return (
    <StyledGanttAssociateInfoComponent isSelectedAssociate={isSelectedAssociate}>
      <InitialsCircle displayName={associate.associateName} margin="0 1rem" />
      <AssociateInfo>
        <GroupMemberTitle limit={preferredNameConstants.limit}>
          {associate.associateName}
        </GroupMemberTitle>
        {shiftTime && get("shiftStartDateTime", shiftTime) && get("shiftEndDateTime", shiftTime) && (
          <GroupMemberDetails>
            {shiftTime.shiftStartDateTime.format("LT")} - {shiftTime.shiftEndDateTime.format("LT")}
          </GroupMemberDetails>
        )}
        {associate.associateGroup && (
          <GroupMemberDetails>{associate.associateGroup}</GroupMemberDetails>
        )}
      </AssociateInfo>
    </StyledGanttAssociateInfoComponent>
  );
};

export default GanttAssociateInfoComponent;
