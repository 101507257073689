import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { color, modalPadding } from "../styles/theme";

const ModalOverlay = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 1;
`;

const RelContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  min-width: 470px;
  min-height: 260px;
  padding: ${modalPadding};
  background-color: ${color.white0};
  box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.5);
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export default class CommonModal extends React.Component {
  render() {
    const { componentId, children } = this.props;

    return ReactDOM.createPortal(
      <ModalOverlay>
        <RelContainer>
          <ModalContent id={componentId}>{children}</ModalContent>
        </RelContainer>
      </ModalOverlay>,
      document.body,
    );
  }
}
