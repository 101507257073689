import { connect } from "react-redux";
import { getActivePetsForTabsList } from "@/core/selectors/entitiesSelector";
import PetsTabs from "@/web/petParentProfile/pets/petsTabs/PetsTabs";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { color } from "@/web/common/styles/theme";
import { createLoadingSelector } from "@/core/selectors/utils";
import bookingActionTypes from "@/core/actionTypes/bookingActionTypes";
import { getCartDetails } from "@/core/selectors/cartSelectors";
import {
  isFromModifyAppointment,
  isFromSelectTimeSlot,
} from "@/core/utils/validationUtils/isFromValidation";
import { history } from "../utils/browser/browserHistory";

const tabsStyle = {
  padding: "2em 1em 0 1em",
  borderBottom: `1px solid ${color.gray300}`,
  marginBottom: "0.625rem",
};

/**
 * Redux Connect function for salon booking pet tabs
 * @summary Located on the hotel booking page
 * @memberOf Views.Booking
 * @function
 * @param {Object} props
 * @param {String} props.selectedPet - id od selected pet
 * @param {Function} props.onSelectPet - function to set selected pet
 * @returns {JSX.Element|null}
 * @example <SalonBookingPetTabs selectedPet={selectedPet} onSelectPet={onSelectPet} />
 */
export const SalonBookingPetTabs = connect(state => {
  const customerKey = getCurrentCustomerKey(state);
  const pets = getActivePetsForTabsList(state, { customerKey });
  const appointments = getCartDetails(state);
  const path = history?.location?.pathname;
  const isModify = isFromModifyAppointment(path);
  const petsForTabs =
    isFromSelectTimeSlot(path) || isModify ? pets.filter(pet => appointments[pet.petId]) : pets;

  return {
    componentId: "SalonBookingPetTabs",
    isHidden: petsForTabs.length <= 1,
    tabsData: petsForTabs,
    style: tabsStyle,
    withPadding: true,
    appointments,
    isAvailabilityLoading: createLoadingSelector([
      bookingActionTypes.LOAD_AVAILABLE_TIME_SLOTS,
      bookingActionTypes.LOAD_AVAILABLE_TIME_SLOTS_BY_ENGAGEMENT,
    ])(state),
  };
})(PetsTabs);

/**
 * Redux Connect function for hotel booking pet tabs
 * @summary Located on the hotel booking page
 * @memberOf Views.Booking
 * @function
 * @param {Object} props
 * @param {String} props.selectedPet - id od selected pet
 * @param {Function} props.onSelectPet - function to set selected pet
 * @returns {JSX.Element|null}
 * @example <HotelBookingPetTabs selectedPet={selectedPet} onSelectPet={onSelectPet} />
 */
export const HotelBookingPetTabs = connect(state => {
  const customerKey = getCurrentCustomerKey(state);
  const pets = getActivePetsForTabsList(state, { customerKey });

  return {
    componentId: "HotelBookingPetTabs",
    isHidden: pets.length <= 1,
    tabsData: pets,
    style: tabsStyle,
    withPadding: true,
    appointments: {},
  };
})(PetsTabs);
