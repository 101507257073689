/** ----------------------------------------------------------------------- **\
    HOW TO USE:
    In the parent component you should have a reference to items that have been
    selected. For example an array of selected options:

        this.state = {
            selectedOptions: [],
        };


    In the onChange prop of the <Checkbox />, create a  function that will invoke
    your change handler, and pass the value into the handler, e.g your items id

        <Checkbox onChange={() => this.onSelectionChange(item.id) ... } />


    To show checked state, see if the selectedOptions includes the value of
    this item, e.g. item.id
        <Checkbox ... checked={selectedOptions.includes(item.id)} />

\** ----------------------------------------------------------------------- * */

import React from "react";
import styled from "styled-components";
import { device } from "web/common/styles/responsive/devices";
import Img from "./Img";
import checkboxIcon from "../../assets/icons/checkbox.svg";
import uncheckedIcon from "../../assets/icons/unchecked.svg";
import { color } from "./styles/theme";

const checkBoxHeightWidthSmall = 1.375;
const checkBoxHeightWidthMed = 1.5;
const checkBoxHeightWidthLarge = 2;

const InvisibleCheckbox = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  margin: 0;
  border: none;
  max-width: 1px;
  &:focus {
    outline: none;
  }
`;

const StyledSpan = styled.span`
  display: flex;
  flex-direction: row;
  position: relative;
  // width: 1.375rem;
  // height: 1.375rem;
  border: ${props =>
    props.isFocused ? `0.0625rem solid ${color.blue500}` : "0.0625rem solid transparent"};
  border-radius: 0.1875rem;
  margin-right: 0.5em;

  @media ${device.mobileS} {
    width: ${checkBoxHeightWidthLarge}rem;
    height: ${checkBoxHeightWidthLarge}rem;
  }

  @media ${device.tablet} {
    width: ${checkBoxHeightWidthMed}rem;
    height: ${checkBoxHeightWidthMed}rem;
  }

  // TODO: Un-comment when the device.prismDesktop media query below this one is removed after hte BGM deployment
  // @media ${device.prismDesktop} {
  //   width: ${checkBoxHeightWidthSmall}rem;
  //   height: ${checkBoxHeightWidthSmall}rem;
  // }
  
  // There were concerns around responsive affecting the UI layout a week before the BGM deployment, All media queries
  // that use the device object will have a media query to target Prism desktop to support BGM release.
  // This media query can be removed after 11/9/20
  @media ${device.prismDesktop} {
    width: 1.375rem;
    height: 1.375rem;
  }
`;

const StyledImg = styled(Img)`
  background-color: white;
  border-radius: 0.1875rem;

  @media ${device.mobileS} {
    width: ${checkBoxHeightWidthLarge}rem;
    height: ${checkBoxHeightWidthLarge}rem;
  }

  @media ${device.tablet} {
    width: ${checkBoxHeightWidthMed}rem;
    height: ${checkBoxHeightWidthMed}rem;
  }

  // TODO: Un-comment when the device.prismDesktop media query below this one is removed after hte BGM deployment
  // @media ${device.prismDesktop} {
  //   width: ${checkBoxHeightWidthSmall}rem;
  //   height: ${checkBoxHeightWidthSmall}rem;
  // }
  
  / There were concerns around responsive affecting the UI layout a week before the BGM deployment, All media queries
  // that use the device object will have a media query to target Prism desktop to support BGM release.
  // This media query can be removed after 11/9/20
  @media ${device.prismDesktop} {
    width: 1.375rem;
    height: 1.375rem;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: ${({ labelPosition }) => (labelPosition === "right" ? "row" : "row-reverse")};
  justify-content: ${({ labelPosition }) =>
    labelPosition === "right" ? "flex-start" : "flex-end"};
  align-items: center;
  padding-top: ${({ paddingTop }) => paddingTop};
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

const Label = styled.div`
  margin: ${({ labelPosition }) => (labelPosition === "right" ? "0" : "0 5px 0 0")};
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  color: ${({ disabled }) => (disabled ? "rgba(0, 0, 0, 0.5)" : "inherit")};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}` : "inherit")};

  @media ${device.prismDesktop} {
    font-size: ${({ fontSize }) => (fontSize ? `${fontSize}` : "inherit")};
  }
`;

class Checkbox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFocused: false,
    };

    this.checkboxRef = React.createRef();
  }

  focus = e => {
    const { onFocus } = this.props;
    if (onFocus) {
      onFocus(e);
    }

    this.setState({
      isFocused: true,
    });
  };

  blur = e => {
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur(e);
    }

    this.setState({
      isFocused: false,
    });
  };

  triggerCheckboxChange = () => {
    const { disabled, onChange } = this.props;
    if (!disabled) {
      if (this.checkboxRef.current) {
        this.checkboxRef.current.focus();
      }

      if (onChange) {
        onChange();
      }
    }
  };

  render() {
    const {
      checked,
      name,
      value,
      label,
      labelPosition = "left",
      boldSelection = false,
      fontSize,
      componentId,
      paddingTop,
      isHidden,
      ...props
    } = this.props;
    if (!isHidden) {
      const { isFocused } = this.state;
      return (
        <Container
          data-testid="checkBoxComponent"
          labelPosition={labelPosition}
          paddingTop={paddingTop}
          disabled={props.disabled}
          onClick={this.triggerCheckboxChange}
        >
          <StyledSpan labelPosition={labelPosition} isFocused={isFocused}>
            <InvisibleCheckbox
              innerRef={this.checkboxRef}
              onChange={() => {}}
              onFocus={this.focus}
              onBlur={this.blur}
              name={name}
              id={name || componentId}
              checked={checked}
              value={value}
              data-testid="checkbox"
              {...props}
            />
            {checked ? (
              <StyledImg src={checkboxIcon} alt="checked" />
            ) : (
              <StyledImg src={uncheckedIcon} alt="unchecked" />
            )}
          </StyledSpan>

          {label && (
            <Label
              fontSize={fontSize}
              disabled={props.disabled}
              bold={boldSelection && checked}
              labelPosition={labelPosition}
            >
              {label}
            </Label>
          )}
        </Container>
      );
    }

    return null;
  }
}

export default Checkbox;
