import React from "react";
import styled from "styled-components";
import { isEmpty } from "lodash/fp";
import MedicationSectionContainer from "../newMedication/MedicationSectionContainer";
import NewPetProfileMedicationFormContainer from "../newMedication/NewPetProfileMedicationFormContainer";
import { PetProfilePetMedList } from "../medicationList/MedList";
import { color } from "../common/styles/theme";
import LoadingWrapper from "../common/LoadingWrapper";

const Container = styled.div`
  border-bottom: ${props => (props.showBottomBorder ? `1px solid ${color.gray100}` : "none")};
`;

class PetProfileMedicationComponent extends React.Component {
  componentDidMount() {
    const { petId, medications, setPetMedications, loadMedicationsList } = this.props;

    if (petId && !isEmpty(medications)) {
      setPetMedications({ medications });
    }

    if (typeof loadMedicationsList === "function") loadMedicationsList();
  }

  componentDidUpdate(prevProps) {
    const { petId, medications, setPetMedications } = this.props;

    if (petId !== prevProps.petId || (isEmpty(prevProps.medications) && !isEmpty(medications))) {
      setPetMedications({ medications, prevPet: prevProps.petId });
    }
  }

  render() {
    const { isHidden, showBottomBorder, petId, isLoading } = this.props;
    return !isHidden ? (
      <Container showBottomBorder={showBottomBorder}>
        <LoadingWrapper isLoading={isLoading}>
          <MedicationSectionContainer petId={petId}>
            <NewPetProfileMedicationFormContainer petId={petId} />
            <PetProfilePetMedList petId={petId} />
          </MedicationSectionContainer>
        </LoadingWrapper>
      </Container>
    ) : null;
  }
}

export default PetProfileMedicationComponent;
