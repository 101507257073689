import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getFilteredScheduledSuspensionData } from "dux/scheduleSuspensionTable/scheduleSuspensionTableSelector";
import { Layout, Button, Text } from "@prism/psm-ui-components";
import { color } from "web/common/styles/theme";
import HistoryImage from "assets/icons/history.svg";
import upIcon from "assets/icons/selectUp.svg";
import downIcon from "assets/icons/selectDown.svg";
import {
  ACTIONS_TITLE,
  DATE_TITLE,
  REASON_NAME_KEY,
  REASON_TITLE,
  START_DATE_KEY,
  STATUS_KEY,
  STATUS_TITLE,
  SUSPENDED_SERVICE_TITLE,
  SUSPENSION_TYPE_KEY,
} from "dux/scheduleSuspensionTable/scheduleSuspensionConstants";
import { generateRandomString } from "core/utils/authHelper";
import { sortByPropertyAsc, sortByPropertyDesc } from "core/utils/arrayUtils/sortByProperty";
import { setSelectDate } from "dux/selectedDate/selectedDateActions";
import { history } from "dux/utils/browser/browserHistory";
import { routePaths } from "core/constants/routePaths";
import moment from "moment";
import { toggleScheduleSuspensionHistoryModal } from "dux/scheduleSuspensionHistoryModal/scheduleSuspensionHistoryModalActions";
import { toggleScheduleSuspensionCancelModal } from "dux/scheduleSuspensionCancelModal/scheduleSuspensionCancelModalActions";
import { setSelectedSuspensionType } from "dux/hotelSelectedSuspensionType/selectedSuspensionTypeActions";
import { CTAD_ID } from "../servicesManagementToggle/serviceManagmentToggleConstants";

export const ScheduleSuspensionTable = props => {
  const {
    componentId,
    isHidden,
    scheduledSuspensionTableData,
    modifySuspension,
    toggleCancelSuspensionModal,
    toggleHistoryModal,
  } = props;

  // List of Column header titles.
  const headerTitles = [
    DATE_TITLE,
    SUSPENDED_SERVICE_TITLE,
    REASON_TITLE,
    STATUS_TITLE,
    ACTIONS_TITLE,
  ];

  // set initial local state value from redux
  const [sortedTableData, setSortedTableData] = useState(scheduledSuspensionTableData);
  useEffect(() => {
    setSortedTableData(scheduledSuspensionTableData);
  }, [scheduledSuspensionTableData]);

  // In order to sort on an array of objects, we need to be have a property/key to sort on, We will
  // use the Column Header Title to know what column we are sorting and match that to the correct
  // key returned from SF and just return the matched string.
  const getSortValueByTitle = ({ title }) => {
    if (title === DATE_TITLE) return START_DATE_KEY;
    if (title === SUSPENDED_SERVICE_TITLE) return SUSPENSION_TYPE_KEY;
    if (title === REASON_TITLE) return REASON_NAME_KEY;
    if (title === STATUS_TITLE) return STATUS_KEY;
  };

  // Handler to sort any column by Asc order
  const handleSortASC = ({ title }) => {
    const key = getSortValueByTitle({ title });
    const ascTableData = sortByPropertyAsc(sortedTableData, key);

    setSortedTableData([...ascTableData]);
  };

  // Handler to sort any column by Desc order
  const handleSortDESC = ({ title }) => {
    const key = getSortValueByTitle({ title });
    const descTableData = sortByPropertyDesc(sortedTableData, key);

    // since decsTableData === sortedTableData, we must "clone" the array so React knows to re-render
    setSortedTableData([...descTableData]);
  };

  const paddingValue = "2.5rem";

  if (isHidden) {
    return null;
  }

  return (
    <Layout.Box id={componentId}>
      {/* TABLE */}
      <table id={`${componentId}__table`} style={{ width: "100%", borderCollapse: "collapse" }}>
        {/* HEADINGS */}
        <thead>
          <tr style={{ paddingBottom: paddingValue }}>
            {headerTitles?.map((title, index) => {
              const isLastTitle = index + 1 === headerTitles.length;
              const setPadding =
                (index === 0 && `0 0 0 ${paddingValue}`) ||
                (isLastTitle && `0 ${paddingValue}  0 0`) ||
                "none";

              return (
                // padding is set based on whether the heading is is the 1st or last title in the row
                <th
                  id={`${componentId}__headerRow--${index}`}
                  key={generateRandomString(12)}
                  style={{ textAlign: "left", padding: setPadding, paddingBottom: "1rem" }}
                >
                  {/* HEADINGS, UP AND DOWN ARROWS*/}
                  <div style={{ paddingLeft: isLastTitle && "55%" }}>
                    <Layout.Cluster justify="left" space="cluster-space-2">
                      <Text size="text-size-lg">{title}</Text>

                      {/* UP AND DOWN SORT ARROWS*/}
                      {!isLastTitle && (
                        <Layout.Stack>
                          <Button variant="link" onClick={() => handleSortASC({ title })}>
                            <img alt="up icon" src={upIcon} />
                          </Button>
                          <Button variant="link" onClick={() => handleSortDESC({ title })}>
                            <img alt="down icon" src={downIcon} />
                          </Button>
                        </Layout.Stack>
                      )}
                    </Layout.Cluster>
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>

        {/* ROWS*/}
        <tbody>
          {sortedTableData?.map((rowData, rowIndex) => {
            const {
              suspensionId,
              startDate,
              suspensionType,
              reasonName,
              status,
              suspensionTypeId,
            } = rowData;
            const colValues = Object.values({ startDate, suspensionType, reasonName, status });
            const showModifyAndCancel = status === "Scheduled" && suspensionTypeId !== CTAD_ID;

            // to determine odd row(s) so we can color/shade the odd row(s).
            const isOdd = !(rowIndex % 2 == 0);

            return (
              <tr
                id={`${componentId}__tableRow--${rowIndex}`}
                key={generateRandomString(12)}
                style={{ backgroundColor: isOdd && color.gray100, border: "none" }}
              >
                {// ROW CELLS
                colValues?.map((item, celIndex) => {
                  return (
                    // set the padding on the 1t item of each row
                    <td
                      id={`${componentId}__tableCell--${rowIndex}-${celIndex}`}
                      key={generateRandomString(12)}
                      style={{
                        padding: celIndex === 0 && `0 0 0 ${paddingValue}`,
                        paddingTop: ".5rem",
                        paddingBottom: ".5rem",
                      }}
                    >
                      <Layout.Cluster justify="left" space="cluster-space-4">
                        {/*<Text size="text-size-lg" align="left">{item}</Text>*/}

                        {/*If it is the second column, add a history icon*/}
                        {celIndex === 1 && (
                          <Button
                            variant="link"
                            size="sm"
                            onClick={() => toggleHistoryModal({ suspensionId })}
                          >
                            <img
                              alt="history icon"
                              id={`${componentId}__historyIcon--${rowIndex}`}
                              src={HistoryImage}
                            />
                          </Button>
                        )}

                        <Text
                          id={`${componentId}__cellItem--${rowIndex}-${celIndex}`}
                          size="text-size-lg"
                          align="left"
                        >
                          {item}
                        </Text>
                      </Layout.Cluster>
                    </td>
                  );
                })}

                {/* MODIFY AND CANCEL CTAs*/}
                <td id={`${componentId}__Actions--${rowIndex}`} style={{ paddingRight: "2.5rem" }}>
                  {showModifyAndCancel && (
                    <Layout.Cluster justify="right" space="cluster-space-8">
                      <Button
                        id={`${componentId}__modifyCta--${rowIndex}`}
                        variant="link"
                        size="xl"
                        onClick={() => modifySuspension({ suspensionId, startDate })}
                      >
                        modify
                      </Button>
                      {// cancel button will show if the scheduled start date is after today
                      moment(startDate).isAfter(moment()) && (
                        <Button
                          id={`${componentId}__cancelCta--${rowIndex}`}
                          variant="link"
                          style={{ color: "red" }}
                          size="xl"
                          onClick={() => toggleCancelSuspensionModal({ suspensionId, startDate })}
                        >
                          cancel
                        </Button>
                      )}
                    </Layout.Cluster>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Layout.Box>
  );
};

// CONTAINER NAME CONTAINER ----------------------------------------------------------------------------------------
export const HotelScheduleSuspensionTable = connect(
  state => {
    const scheduledSuspensionTableData = getFilteredScheduledSuspensionData(state);

    return {
      componentId: "HotelScheduleSuspensionTable",
      scheduledSuspensionTableData,
    };
  },

  dispatch => {
    return {
      modifySuspension: ({ suspensionId, startDate }) => {
        dispatch(setSelectDate(startDate));
        dispatch(setSelectedSuspensionType({ suspensionType: {}, suspensionId }));
        history.push(`${routePaths.SERVICE_MANAGEMENT}?suspensionId=${suspensionId}`);
      },
      toggleCancelSuspensionModal: ({ suspensionId, startDate }) => {
        dispatch(toggleScheduleSuspensionCancelModal({ suspensionId, startDate }));
      },
      toggleHistoryModal: ({ suspensionId }) => {
        dispatch(toggleScheduleSuspensionHistoryModal({ suspensionId }));
      },
    };
  },
)(ScheduleSuspensionTable);
