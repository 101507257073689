import React from "react";
import { Layout } from "@prism/psm-ui-components";
import { connect } from "react-redux";
import HotelCapacityHeaderContainer from "dux/hotelRoomCapacity/HotelCapacityHeaderContainer";
import HotelCapacityTableContainer from "dux/hotelRoomCapacity/HotelCapacityTableContainer";
import DoggyDayCampCapacityHeaderContainer from "dux/ddcRoomCapacity/DoggyDayCampCapacityHeaderContainer";
import DoggyDayCampCapacityTableContainer from "dux/ddcRoomCapacity/DoggyDayCampCapacityTableContainer";

export const RoomCapacity = props => {
  const { isHidden, componentID, diComp, instance, cursor } = props;

  if (!isHidden) {
    const Header = diComp.header[instance];
    const Table = diComp.table[instance];

    return (
      <Layout.Box
        id={componentID}
        padding="box-padding-4"
        borderWidth
        borderColor="box-border-color-gray-100"
        style={{ cursor: cursor }}
      >
        <Header />
        <Table />
      </Layout.Box>
    );
  }

  return null;
};

// HOTEL CONTAINER ----------------------------------------------------------------------------------------
export const HotelRoomCapacity = connect(
  state => {
    return {
      isHidden: false,
      cursor: "pointer",
      instance: "hotel",
      componentID: "HotelRoomCapacity",
      diComp: {
        header: {
          hotel: HotelCapacityHeaderContainer,
        },
        table: {
          hotel: HotelCapacityTableContainer,
        },
      },
    };
  },
  dispatch => {
    return {};
  },
  (mapProps, dispatchProps) => {
    const { componentID, isHidden, cursor, diComp, instance } = mapProps;
    return {
      // control what props get passed to the view
      componentID,
      isHidden,
      cursor,
      diComp,
      instance,
    };
  },
)(RoomCapacity);

// DOGGY DAY CAMP CONTAINER ----------------------------------------------------------------------------------------
export const DoggyDayCampRoomCapacity = connect(state => {
  // const someProp = someSellector(state);

  return {
    isHidden: false,
    instance: "DDC",
    componentID: "DoggyDayCampRoomCapacity",
    diComp: {
      header: {
        DDC: DoggyDayCampCapacityHeaderContainer,
      },
      table: {
        DDC: DoggyDayCampCapacityTableContainer,
      },
    },
  };
})(RoomCapacity);
