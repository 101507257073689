import React from "react";
import { connect } from "react-redux";
import { Button } from "@petsmart-ui/sparky";
import { getFoodCount } from "../../foodCount/foodCountSelector";
import { hideAddNewFood, clearNewFood } from "../../newFood/actions/newFoodActions";
import { NEW_FOOD_ID } from "../../newFood/newFoodConstants";
import { LayoutBox } from "@/layout/box/Box";

const CancelButton = props => {
  const { componentId, isHidden, onClick, disabled, label } = props;
  if (isHidden) return null;
  return (
    <LayoutBox>
      <Button
        id={componentId}
        variant="link"
        size="lg"
        onClick={onClick}
        disabled={disabled}
        text={label}
      />
    </LayoutBox>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { foodId, petId } = ownProps;
  const foodCount = getFoodCount(state, { petId });
  return {
    componentId: `newFood__cancelButton`,
    foodId,
    petId,
    foodCount,
    isHidden: foodId !== NEW_FOOD_ID,
    label: "Cancel",
  };
};

const mapDispatchToProps = dispatch => ({
  cancelNewFood: (foodCount, petId) => {
    dispatch(hideAddNewFood({ petId }));
    dispatch(clearNewFood({ petId }));
  },
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  onClick: () => {
    const { foodCount, petId } = propsFromState;
    propsFromDispatch.cancelNewFood(foodCount, petId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CancelButton);
