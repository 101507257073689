import React from "react";
import { connect } from "react-redux";
import { columnHeaders } from "@/core/constants/dashboardContants";
import {
  hotelDashboardColListCompNames,
  hotelDashboardHeaderCompNames,
} from "@/dux/_constants/dynamicComponentNames";
import { LayoutGrid } from "@/layout/grid/Grid";
import { color } from "@/web/common/styles/theme";
import { LayoutStack } from "@/layout/stack/Stack";
import { getCheckedOutColumnVisibility } from "../hotelCheckedOut/columnHeader/columnSwitch/hotelCheckedOutColumnToggleSelector";

const { ARRIVALS, DEPARTURES, OVERNIGHT, CHECKED_OUT } = columnHeaders;

export const HotelDashboardAppointmentColumnsComponent = props => {
  const { componentId, isHidden, compNames, headerComps, apptListComps } = props;

  if (isHidden) return null;
  return (
    <LayoutGrid id={componentId} space="scale-0" minimum="scale-G6">
      {compNames?.map(compName => {
        const Header = headerComps[compName] ?? "div";
        const AppointmentList = apptListComps[compName] ?? "div";

        return (
          <LayoutStack
            key={compName}
            space="scale-0"
            style={{ border: `1px solid ${color.gray100}` }}
          >
            <Header />
            <AppointmentList />
          </LayoutStack>
        );
      })}
    </LayoutGrid>
  );
};

export const HotelDashboardAppointmentColumns = connect(state => {
  const thirdColumn = getCheckedOutColumnVisibility(state) ? DEPARTURES : CHECKED_OUT;
  return {
    componentId: "HotelDashboardAppointmentColumns",
    compNames: [ARRIVALS, OVERNIGHT, thirdColumn],
    headerComps: hotelDashboardHeaderCompNames,
    apptListComps: hotelDashboardColListCompNames,
  };
})(HotelDashboardAppointmentColumnsComponent);
