import { createSelector } from "reselect";
import { get } from "lodash/fp";
import { getPetServiceItem } from "../entitiesSelector";

export const getAddOnsForPetServiceItem = createSelector([getPetServiceItem], petServiceItem =>
  get("addOns", petServiceItem),
);

export const getEnhancedServicesForPetServiceItem = createSelector(
  [getPetServiceItem],
  petServiceItem => get("enhancedServices", petServiceItem),
);

export const getAddOnsList = createSelector([getAddOnsForPetServiceItem], (addOns = []) =>
  addOns.map(get("addOnName")),
);

export const getEnhancedServiceName = createSelector(
  [getEnhancedServicesForPetServiceItem],
  enhancedService => get("addOnName", enhancedService),
);
