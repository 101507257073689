import React from "react";
import styled from "styled-components";
import moment from "moment";
import { color } from "web/common/styles/theme";
import { fontSizes } from "web/common/styles/responsive/fonts";
import { TextWithEllipsis } from "@/dux/_components/textWithEllipsis/TextWithEllipsis";
import { preferredNameConstants } from "@/core/constants/associatesConstants";

const AvailableAppointment = styled.div`
  display: flex;
  flex-direction: column;
  border: ${props => `1px solid ${props.isHovered ? color.blue500 : "transparent"} `};
  border-bottom: ${props => `1px solid ${props.isHovered ? color.blue500 : color.gray300}`};
  padding: 0.5rem;
  max-width: 100%;
`;

const DetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: ${fontSizes.regular};
  margin: 0.625rem 0;
`;

const SelectRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 0.625rem 0;
`;

const BookButton = styled.span`
  color: ${color.blue500};
  cursor: pointer;
  pointer-events: ${props => (props.disabled ? "none" : "auto")};
  opacity: ${props => (props.disabled ? "0.5" : "1")};
  font-size: ${fontSizes.regular};
`;

const AvailableAppointmentComponent = props => {
  const {
    timeSlot: { price, duration },
    associate,
    isLoading,
    selectedPetService,
    onSelectTimeSlot,
    onHoverTimeSlot,
    onClearHoverTimeSlot,
    isHovered,
    selectButtonID,
  } = props;
  const startDateTime = moment(props.timeSlot.startDateTime);
  const endDateTime = startDateTime.clone().add(duration, "minutes");
  return (
    <AvailableAppointment
      isHovered={isHovered}
      onMouseEnter={onHoverTimeSlot}
      onMouseLeave={onClearHoverTimeSlot}
    >
      <DetailsRow>
        <span>
          {startDateTime.format("LT")} - {endDateTime.format("LT")}
        </span>
        <span>$ {price && price.toFixed(2)}</span>
      </DetailsRow>
      <DetailsRow>
        <TextWithEllipsis limit={preferredNameConstants.limit}>
          {associate?.associateName}
        </TextWithEllipsis>
      </DetailsRow>
      <SelectRow>
        <span>{associate && associate.associateGroup}</span>
        <BookButton
          id={selectButtonID}
          onClick={() => onSelectTimeSlot({ selectedPetService })}
          disabled={isLoading}
        >
          Select
        </BookButton>
      </SelectRow>
    </AvailableAppointment>
  );
};

export default AvailableAppointmentComponent;
