import { connect } from "react-redux";
import ClickableText from "../common/ClickableText";
import { color, font } from "../common/styles/theme";
import { clearUiStore } from "../../core/actionCreators/ui/uiActionCreators";
import { routePaths } from "../../core/constants/routePaths";
import { history } from "@/dux/utils/browser/browserHistory";
import { getSystemType } from "../setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "../setSystemType/constants/setSystemTypeConstants";
import { setSystemBookingFlowType } from "../setSystemType/actions/setSystemTypeActions";

const mapStateToProps = (state, ownProps) => {
  const systemType = getSystemType(state);
  return {
    isVisible: systemType === systemName.SALON,
    children: "Dashboard",
    styleObj: {
      fontSize: "16px",
      color: color.white0,
      textDecorationLine: "underline",
      fontFamily: font.mainFamily,
    },

    systemType,
  };
};

const mapDispatchToProps = dispatch => ({
  onClick: () => {
    dispatch(clearUiStore());
    dispatch(setSystemBookingFlowType({ systemBookingFlow: systemName.SALON }));
    history.push(routePaths.DASHBOARD);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ClickableText);
