import { connect } from "react-redux";
import { color } from "web/common/styles/theme";
import { isFTCOHidden } from "dux/eligiblePromotions/helpers/isHiddenHelpers";
import { getFirstTimeCustomerInfo } from "./ftcoSelectors";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { FIRST_TIME_CUSTOMER_MESSAGE } from "./ftcoConstants";
import IconWithLabel from "@/dux/_components/icon/IconWithLabel";
import PreCheckedInIcon from "../../../assets/icons/pre-checked-in-appointment.svg";

const mapStateToProps = state => {
  const customerKey = getCurrentCustomerKey(state);
  const { firstName } = getFirstTimeCustomerInfo(state, { customerKey });
  const isHidden = !customerKey || isFTCOHidden(state);
  return {
    isHidden,
    firstName,
    componentId: "ftco-indicator",
    src: PreCheckedInIcon,
    label: `${firstName} ${FIRST_TIME_CUSTOMER_MESSAGE}`,
    styleObj: {
      padding: "1em",
      justifyContent: "center",
      borderBottom: `1px solid ${color.gray300}`,
    },
  };
};

export default connect(mapStateToProps)(IconWithLabel);
