import React from "react";
import { connect } from "react-redux";
import { Button } from "@petsmart-ui/sparky";
import { LayoutBox } from "@/layout/box/Box";
import { color } from "@/web/common/styles/theme";
import { createLoadingSelector } from "@/core/selectors/utils";
import { showConfirmationModal } from "@/core/actionCreators/confirmationModalActionCreators";
import { SAVE_RESERVATION_CART_DETAILS } from "@/dux/reservationCartDetailsConfirmation/reservationCartDetailsConfirmationActions";
import { deleteCheckInFeeding } from "@/dux/_components/checkInOutFeeding/checkInFoodsActions";
import { deleteCheckInMed } from "@/dux/_components/checkInOutMedications/checkInMedsActions";
import { isFromCheckInOut } from "core/utils/validationUtils/isFromValidation";
import { history } from "@/dux/utils/browser/browserHistory";
import { get } from "lodash/fp";
import {
  selectEnhancedAddonChildrenFromCart,
  selectHotelCartPetProductById,
} from "../servicesCartHotel/servicesCartHotelSelectors";
import { removeHotelCartProducts } from "../servicesCartHotel/servicesCartHotelActions";
import {
  putCart,
  PUT_CART,
  deleteCartSpecial,
  DELETE_CART_SPECIAL,
} from "../servicesCart/servicesCartActions";
import { ADDON_REMOVE_TEXT } from "./hotelAddonsRemoveConstants";
import {
  REMOVE_HOTEL_MANUAL_SPECIALS,
  removeHotelSpecialsManual,
} from "../applySpecial/applyManaulSpecialsHotelActions";
import { selectIfFoodCanBeRemoved } from "../_components/checkInOutFeeding/CheckInOutCartFoodSelectors";
import { getCartItemId } from "../servicesCart/servicesCartUtils";
import {
  getPetHotelEngagements,
  selectAddonByPetAndAddonId,
  selectAppliedAddonsFromEngagementsByPet,
} from "../hotelEngagements/hotelEngagementSelectors";
import isEmpty from "lodash/isEmpty";
import {
  PUT_HOTEL_ITINERARY_ADDONS,
  putHotelItineraryAddons,
} from "../hotelItineraryAddonPut/hotelItineraryAddonPutActions";
import { buildEngagementAddonUpdatesForPut } from "@/core/utils/hotelEngagementUtils/buildEngagementsWithUpdatedAddons";
import { selectIsReservationDisabled } from "../hotelItinerary/hotelItinerarySelectors";
import { selectCurrentStoreTimeZone } from "@/core/selectors/persistentSelectors";

export const HotelRemoveButtonComponent = ({
  componentId,
  buttonLabel,
  isHidden,
  disabled,
  onClick = () => {},
}) => {
  if (isHidden) return null;

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <Button
        id={`${componentId}__removeButton`}
        text={buttonLabel || ADDON_REMOVE_TEXT.REMOVE}
        variant="link"
        disabled={!!disabled}
        onClick={onClick}
        iconName="trash"
        style={{ color: !disabled && color.red700 }}
      />
    </LayoutBox>
  );
};

/**
 * Helper to check if addon remove button should be disabled
 * @param {Object} addon
 * @param {Boolean} isLoading
 * @returns {Boolean}
 */
export const removeAddonBtnDisabled = ({ addon, isLoading }) =>
  isEmpty(addon) || !!addon?.isAutoApplyAddOn || !!isLoading;

// HOTEL BOOKING ADDON CONTAINER ----------------------------------------------------------------------------------------
export const HotelBookingAddonsRemoveButton = connect(
  (state, { petId, addonId }) => {
    const addon = selectHotelCartPetProductById({ petId, productId: addonId })(state);
    const isLoading = createLoadingSelector([PUT_CART])(state);
    const childAddons = selectEnhancedAddonChildrenFromCart({ petId, productId: addonId })(state);

    return {
      componentId: "HotelBookingAddonsRemoveButton",
      disabled: removeAddonBtnDisabled({ addon, isLoading }),
      childAddons,
    };
  },
  (dispatch, { addonId, petId }) => {
    const cartItemId = getCartItemId({ petId, item: { productId: addonId } });
    return {
      removeAddons: (childAddonIds = []) =>
        dispatch(
          showConfirmationModal({
            header: ADDON_REMOVE_TEXT.HEADER,
            content: ADDON_REMOVE_TEXT.CONTENT,
            confirmText: ADDON_REMOVE_TEXT.CONFIRM,
            confirm: () => {
              // If there are child addons we need to remove them too
              dispatch(removeHotelCartProducts([cartItemId, ...childAddonIds]));
              dispatch(putCart());
            },
            cancelText: ADDON_REMOVE_TEXT.CANCEL,
          }),
        ),
    };
  },
  (stateProps, dispatchProps, { petId }) => {
    const { componentId, disabled, childAddons } = stateProps;
    const { removeAddons } = dispatchProps;
    const childAddonIds = Object.values(childAddons)?.map(child =>
      getCartItemId({ petId, item: child }),
    );

    return { componentId, disabled, onClick: () => removeAddons(childAddonIds) };
  },
)(HotelRemoveButtonComponent);

// HOTEL Check In/Out - Food details remove button CONTAINER ----------------------------------------------------------------------------------------
export const HotelCheckInOutFoodRemoveButton = connect(
  (state, { petId, foodId, isDisabed, isHidden }) => {
    const isLoading = createLoadingSelector([SAVE_RESERVATION_CART_DETAILS])(state);
    const canRemoveFood = selectIfFoodCanBeRemoved(state, { petId });
    return {
      componentId: `HotelCheckInOutFoodRemoveButton-${foodId}`,
      buttonLabel: "Remove food",
      disabled: isDisabed || isLoading || !canRemoveFood,
      isHidden: isHidden || !isFromCheckInOut(get(["location", "pathname"], history)),
    };
  },
  (dispatch, { petId, foodId, foodIndex, onClick, onConfirm }) => {
    return {
      onClick: () => {
        if (onClick) onClick();
        dispatch(
          showConfirmationModal({
            header: `Remove food ${foodIndex} from booking`,
            content: "Are you sure want to remove this food from booking?",
            confirmText: `Remove food ${foodIndex}`,
            confirm: () => {
              dispatch(deleteCheckInFeeding({ petId, foodId }));
              if (onConfirm) onConfirm();
            },
            closeBeforeConfirm: true,
            cancelText: "Go back",
          }),
        );
      },
    };
  },
)(HotelRemoveButtonComponent);

// HOTEL Check In/Out - Medication details remove button CONTAINER ----------------------------------------------------------------------------------------
export const HotelCheckInOutMedRemoveButton = connect(
  (state, { isDisabed, isHidden }) => {
    const isLoading = createLoadingSelector([SAVE_RESERVATION_CART_DETAILS])(state);
    return {
      componentId: "HotelCheckInOutMedRemoveButton",
      buttonLabel: "Remove medication",
      disabled: isDisabed || isLoading,
      isHidden: isHidden || !isFromCheckInOut(get(["location", "pathname"], history)),
    };
  },
  (dispatch, { petId, medId, medIndex, onClick, onConfirm }) => {
    return {
      onClick: () => {
        if (onClick) onClick();
        dispatch(
          showConfirmationModal({
            header: `Remove medication ${medIndex} from booking`,
            content: "Are you sure want to remove this medication from booking?",
            confirmText: `Remove medication ${medIndex}`,
            confirm: () => {
              dispatch(deleteCheckInMed({ petId, medId }));
              if (onConfirm) onConfirm();
            },
            closeBeforeConfirm: true,
            cancelText: "Go back",
          }),
        );
      },
    };
  },
)(HotelRemoveButtonComponent);

// HOTEL Booking - Manual special remove button CONTAINER ----------------------------------------------------------------------------------------
export const HotelBookingManualSpecialRemoveButton = connect(
  (state, { isDisabled, isHidden, special }) => {
    const isLoading = createLoadingSelector([DELETE_CART_SPECIAL])(state);
    return {
      componentId: "HotelBookingManualSpecialRemoveButton",
      buttonLabel: "Remove Special",
      disabled: isDisabled || isLoading,
      isHidden: isHidden || !special?.isManual,
    };
  },
  (dispatch, { petId, special }) => {
    return {
      onClick: () =>
        dispatch(
          showConfirmationModal({
            header: "Are you sure?",
            content: `Are you sure you want to remove this special?`,
            confirmText: "Yes",
            confirm: () => dispatch(deleteCartSpecial({ specialCode: special?.code, petId })),
          }),
        ),
    };
  },
)(HotelRemoveButtonComponent);

// HOTEL Check In Out - Manual special remove button CONTAINER ----------------------------------------------------------------------------------------
export const HotelCheckInOutManualSpecialRemoveButton = connect(
  (state, { special }) => {
    const isLoading = createLoadingSelector([REMOVE_HOTEL_MANUAL_SPECIALS])(state);
    const isDisabled = selectIsReservationDisabled(state);
    return {
      componentId: "HotelCheckInOutManualSpecialRemoveButton",
      buttonLabel: "Remove Special",
      disabled: isDisabled || isLoading,
      isHidden: !special?.isManual,
    };
  },
  (dispatch, { petId, special }) => {
    return {
      onClick: () =>
        dispatch(
          showConfirmationModal({
            header: "Are you sure?",
            content: `Are you sure you want to remove this special?`,
            confirmText: "Yes, Remove Special",
            confirm: () =>
              dispatch(removeHotelSpecialsManual({ specialCode: special?.code, petId })),
            closeBeforeConfirm: true,
          }),
        ),
    };
  },
)(HotelRemoveButtonComponent);

// HOTEL CHECK IN/OUT ADDON CONTAINER ----------------------------------------------------------------------------------------
export const HotelCheckInOutAddonRemoveButton = connect(
  (state, { petId, addonId }) => {
    const addon = selectAddonByPetAndAddonId(addonId)(state, { petId });
    const isLoading = createLoadingSelector([PUT_HOTEL_ITINERARY_ADDONS])(state);

    return {
      componentId: "HotelCheckInOutAddonRemoveButton",
      disabled: removeAddonBtnDisabled({ addon, isLoading }),

      // merge props
      petEngagements: getPetHotelEngagements(state, { petId }),
      petAddons: selectAppliedAddonsFromEngagementsByPet(state, { petId }),
      timeZone: selectCurrentStoreTimeZone(state),
    };
  },
  (dispatch, { petId }) => {
    return {
      removeAddons: updatedEngagements =>
        dispatch(
          showConfirmationModal({
            header: ADDON_REMOVE_TEXT.HEADER,
            content: ADDON_REMOVE_TEXT.CONTENT,
            cancelText: ADDON_REMOVE_TEXT.CANCEL,
            confirmText: ADDON_REMOVE_TEXT.CONFIRM,
            confirm: () => {
              dispatch(putHotelItineraryAddons({ petId, updatedEngagements }));
            },
            closeBeforeConfirm: true,
          }),
        ),
    };
  },
  (stateProps, dispatchProps, { addonId }) => {
    const { componentId, disabled, petEngagements, petAddons, timeZone } = stateProps;
    const { removeAddons } = dispatchProps;

    return {
      componentId,
      disabled,
      onClick: () => {
        // Remove addons with PUT call
        const updatedEngagements = buildEngagementAddonUpdatesForPut({
          petEngagements,
          petAddons,
          addonToEditId: addonId,
          timeZone,
          // sending empty dates array to remove this addon from all engagements
          dates: [],
        });
        removeAddons(updatedEngagements);
      },
    };
  },
)(HotelRemoveButtonComponent);
