import React, { useEffect } from "react";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import isEmpty from "lodash/fp/isEmpty";
import { color } from "web/common/styles/theme";
import { Button, Form, Text } from "@prism/psm-ui-components";
import { getCurrentPet } from "core/selectors/persistentSelectors";
import { formatDayNameMonthDayYear } from "core/utils/dateUtils/formatDateTime";
import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";

// temp hard coded import
import {
  clearTrainingEngagementNote,
  setTrainingEngagementNote,
  toggleCancelTrainingPendingAppointmentModal,
} from "dux/scheduleTrainingPage/scheduleTrainingPageActions";
import ScheduleTrainingAppointmentDetails from "@/dux/scheduleTrainingAppointment/ScheduleTrainingAppointmentDetails";
import {
  getTrainingAppointment,
  getTrainingEngagementNote,
} from "@/dux/scheduleTrainingPage/scheduleTrainingPageSelectors";
import getUnknownPetImage from "@/core/utils/assetUtils/unknownPetImage";
import { getPet, getPetFullName } from "@/core/selectors/entitiesSelector";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { displayCharacterCount } from "@/core/utils/displayUtils/characterCountDisplayUtil";
import { trainingClassNotesConstants } from "./trainingClassNotesConstants";

// React View Component ------------------------------------------------------------------------------------------------
export const TrainingClassNotes = ({
  isHidden,
  componentId,
  petImage,
  petName,
  selectedDate,
  handleClick,
  handleChange,
  isDisabled,
  cancelButtonColor,
  cancelButtonStyles,
  characterCountText,
  handleClearNotes = () => {},
}) => {
  // Unmount
  useEffect(() => {
    return () => {
      handleClearNotes();
    };
  }, []);

  if (isHidden) return null;

  return (
    <LayoutBox
      className="psm-debug-layout--None"
      id={componentId}
      style={{ border: `1px solid ${color.blue500}` }}
      padding="scale-0"
    >
      <LayoutStack space="scale-0">
        {/*Class Notes top half ---------------------------------------------------------------*/}
        <LayoutBox style={{ borderBottom: `1px solid ${color.gray300}` }} padding="scale-G1">
          <LayoutStack space="scale-G1">
            {/*Image, Name and Cancel button --------------------------------------------------*/}
            <LayoutCluster style={{ justifyContent: "space-between" }}>
              <LayoutBox padding="scale-0">
                {/*Image and Name -------------------------------------------------------------*/}
                <LayoutCluster space="scale-G1">
                  <img alt="petImage" src={petImage} style={{ width: "60px", height: "60px" }} />
                  <Text bold>{petName}</Text>
                </LayoutCluster>
              </LayoutBox>

              {/*Cancel button ----------------------------------------------------------------*/}
              <Button
                variant="no-outline"
                onClick={handleClick}
                disabled={isDisabled}
                style={cancelButtonStyles}
              >
                <Text color={cancelButtonColor}>Cancel</Text>
              </Button>
            </LayoutCluster>

            {/*Class and Icon -----------------------------------------------------------------*/}
            <LayoutBox padding="scale-0">
              <LayoutCluster style={{ justifyContent: "space-between" }}>
                <Text>Virtual Dog Training - Private Class</Text>
              </LayoutCluster>
            </LayoutBox>

            {/*Selected Date and Children -----------------------------------------------------*/}
            <LayoutBox padding="scale-0">
              <LayoutStack>
                <Text>{selectedDate}</Text>
                <ScheduleTrainingAppointmentDetails />
              </LayoutStack>
            </LayoutBox>
          </LayoutStack>
        </LayoutBox>

        {/*Class Notes bottom half ------------------------------------------------------------*/}
        <LayoutBox padding="scale-G1">
          <LayoutStack>
            <Text size="text-size-xl" bold>
              Class Notes
            </Text>
            <Form.Field.TextArea
              style={{ border: "1px solid black", fontSize: "1.15rem" }}
              width="100%"
              rows={4}
              name="textArea"
              maxLength={trainingClassNotesConstants.limit}
              onInput={value => handleChange(value)}
            />
            <LayoutStack space="scale-S3">
              <LayoutCluster style={{ justifyContent: "flex-end" }}>
                <Text>{characterCountText}</Text>
              </LayoutCluster>
            </LayoutStack>
          </LayoutStack>
        </LayoutBox>
      </LayoutStack>
    </LayoutBox>
  );
};

// Container for Class Specific notes ----------------------------------------------------------------------------------
export const TrainingClassNotesContainer = connect(
  state => {
    const petId = getCurrentPet(state);
    const customerKey = getCurrentCustomerKey(state);
    const pet = getPet(state, { petId });
    const petName = getPetFullName(state, { petId, customerKey });
    const notes = getTrainingEngagementNote(state);
    const isDisabled = isEmpty(getTrainingAppointment(state));
    return {
      componentId: "TrainingClassNotesContainer",
      isDisabled,
      petImage: getUnknownPetImage(pet),
      petName: petName,
      selectedDate: formatDayNameMonthDayYear(getSelectedDate(state)),
      cancelButtonStyles: { padding: 0, "pointer-events": isDisabled ? "none" : "inherit " },
      cancelButtonColor: isDisabled ? "text-color-grey" : "text-color-red",
      characterCountText: displayCharacterCount(trainingClassNotesConstants.limit, notes),
    };
  },
  dispatch => {
    return {
      handleClick: () => {
        dispatch(toggleCancelTrainingPendingAppointmentModal());
      },
      handleChange: value => {
        // The use of the debounce function is not optimal, however we are using it to avoid a possible race condition that will
        // result in the case of a click outside (may not update state before a training class is scheduled on the click of the
        // training class button) or a performance issue by updating state on each key stroke.
        const debounce_fun = debounce(function() {
          dispatch(setTrainingEngagementNote(value));
        }, 1000);

        debounce_fun();
      },
      handleClearNotes() {
        dispatch(clearTrainingEngagementNote());
      },
    };
  },
)(TrainingClassNotes);
