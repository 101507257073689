/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const toggleAccordionActionTypes = {
  SHOW_STANDALONE_LIST: "SHOW_STANDALONE_LIST",
  SHOW_BULK_PACKAGE_OFFERINGS: "SHOW_BULK_PACKAGE_OFFERINGS",
  TOGGLE_HOTEL_UPDATE_SERVICE: "TOGGLE_HOTEL_UPDATE_SERVICE",
  TOGGLE_HOTEL_MERGE_ROOMS: "TOGGLE_HOTEL_MERGE_ROOMS",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const toggleStandaloneAccordion = showStandaloneList => ({
  type: toggleAccordionActionTypes.SHOW_STANDALONE_LIST,
  showStandaloneList,
});

export const toggleBulkPackageOfferingsAccordion = showBulkPackageOfferings => ({
  type: toggleAccordionActionTypes.SHOW_BULK_PACKAGE_OFFERINGS,
  showBulkPackageOfferings,
});

export const toggleHotelUpdateService = showHotelUpdateServiceOpen => ({
  type: toggleAccordionActionTypes.TOGGLE_HOTEL_UPDATE_SERVICE,
  showHotelUpdateServiceOpen,
});

export const toggleHotelMergeRooms = showHotelMergeRooms => ({
  type: toggleAccordionActionTypes.TOGGLE_HOTEL_MERGE_ROOMS,
  showHotelMergeRooms,
});
