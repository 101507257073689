import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// Components
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutStack } from "@/layout/stack/Stack";
import { TextPassage, Checkbox, Label, Button, Hr } from "@petsmart-ui/sparky";
import AppHeader from "@/web/header/AppHeader";
import LoadingWrapper from "@/web/common/LoadingWrapper";
import { ReportSelectionContainer } from "@/dux/reportSelection/ReportSelection";
import { ReportDateSelectionContainer } from "@/dux/reportDateSelection/ReportDateSelection";
import { ReportTable } from "@/dux/reportTable/reportTable";

// Actions
import {
  GET_AVAILABLE_REPORTS,
  setSelectedReportType,
} from "@/dux/reportSelection/reportSelectionActions";
import { setSelectedReportDate } from "@/dux/reportDateSelection/reportDateSelectionActions";
import {
  GET_SELECTED_REPORT,
  getSelectedReport,
  setSelectedFilters,
} from "@/dux/reports/reportsActions";

// Selectors
import { createLoadingSelector } from "@/core/selectors/utils";
import { getSystemBookingFlow } from "@/web/setSystemType/selectors/setSystemTypeSelectors";
import {
  selectAvailableReportsList,
  selectSelectedReportType,
} from "@/dux/reportSelection/reportSelectionSelectors";
import { selectSelectedReportDate } from "@/dux/reportDateSelection/reportDateSelectionSelectors";
import { selectSelectedFilters } from "./reportsSelectors";

// Feature Flag
import getReportsWorkflowFeatureFlag from "@/web/enableDisableWorkflowFeatureFlag/selectors/reports/getReportsWorkflowFeatureFlag";

// Utils
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { formatCalendarDateMoment } from "@/core/utils/dateUtils/formatDateTime";
import moment from "moment";
import { color } from "web/common/styles/theme";
import { printWithZoomByElementWidth } from "@/core/utils/printingUtils/printingUtils";

// Assets
import HeaderImage from "@/assets/images/hotel-int-header-image.png";

// Constants
import { systemName } from "@/web/setSystemType/constants/setSystemTypeConstants";
import { routePaths } from "@/core/constants/routePaths";
import { PrintFoodAndMedLabels } from "../foodAndMedLabels/FoodAndMedLabels";

// Icons
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";

// CSS
import "./reports.css";

/**
 *  React view component for reports page
 *  @memberOf Views.Associate
 *  @function
 *  @name Reports
 *  @param {object} props
 *  @param {string} props.componentId
 *  @param {string} props.clearAllFilters
 *  @param {Function} props.clearSelectedReportAndDate
 *  @param {JSX.Element} props.headerComp
 *  @param {string} props.isHidden
 *  @param {string} props.isLoading
 *  @param {Function} props.getSelectedReport
 *  @param {Function} props.navigateTo
 *  @param {JSX.Element} props.reportSelectionComp
 *  @param {JSX.Element} props.reportDateSelectionComp
 *  @param {JSX.Element} props.reportTableComp
 *  @param {string} props.selectedDate
 *  @param {Array} props.selectedFilters
 *  @param {string} props.selectedReportType
 *  @param {string} props.title
 *  @returns {JSX.Element}
 *
 *  @example const component = connect()(Reports);
 */
export const Reports = props => {
  const {
    componentId,
    clearAllFilters = () => {},
    clearSelectedReportAndDate,
    headerComp: Header,
    isHidden,
    isLoading,
    getSelectedReport,
    navigateTo,
    reportSelectionComp: ReportSelection,
    reportDateSelectionComp: ReportDateSelection,
    reportTableComp: ReportTable,
    selectedDate,
    selectedFilters,
    selectedReportType,
    title,
    printPage,
  } = props;

  const [labelsVisible, setLabelsVisible] = useState(false);
  const [labelCheckboxVisible, setLabelCheckboxVisible] = useState(false);

  useEffect(() => {
    if (isHidden && typeof navigateTo === "function") navigateTo();
    return () => clearSelectedReportAndDate();
  }, [isHidden]);

  useEffect(() => {
    if (selectedDate && selectedReportType) {
      getSelectedReport(selectedReportType, selectedDate);
    }
  }, [selectedDate, selectedReportType]);

  /* 
    Use Effect to handle visibility of label checkbox
  */
  useEffect(() => {
    if (selectedReportType.includes("Feed") || selectedReportType.includes("Med")) {
      setLabelCheckboxVisible(true);
      return;
    }

    setLabelCheckboxVisible(false);
    setLabelsVisible(false);
  }, [selectedReportType]);

  if (isHidden) {
    return null;
  }

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <LayoutStack>
        {/* Header */}
        <LayoutBox className="prism-reports--header" padding="scale-0">
          <Header />
        </LayoutBox>

        {/* Sub header */}
        <LayoutBox className="prism-reports--sub-header">
          <LayoutCluster style={{ justifyContent: "space-between" }}>
            {/* LEFT SIDE */}
            <LayoutBox style={{ minWidth: "360px" }}>
              <LayoutCluster>
                <AssessmentOutlinedIcon fontSize="large" />
                <TextPassage>
                  <h4>{selectedReportType || title}</h4>
                </TextPassage>
              </LayoutCluster>
            </LayoutBox>

            {/* RIGHT SIDE */}
            <LayoutBox>
              <LayoutCluster>
                {selectedReportType && (
                  <LayoutCluster>
                    {/* CheckBox and Label */}
                    <LayoutBox>
                      <LayoutCluster>
                        <Checkbox
                          disabled={!labelCheckboxVisible}
                          checked={labelsVisible}
                          onChange={() => setLabelsVisible(!labelsVisible)}
                        />
                        <Label
                          text="Show Labels"
                          bold={false}
                          style={{ color: !labelCheckboxVisible ? color.gray300 : "initial" }}
                        />
                      </LayoutCluster>
                    </LayoutBox>

                    {/* Clear Filters */}
                    <LayoutCluster style={{ justifyContent: "flex-end" }}>
                      <LayoutBox>
                        <Button
                          disabled={selectedFilters.length === 0}
                          onClick={() => clearAllFilters()}
                          text="Clear All Filters"
                        />
                      </LayoutBox>
                    </LayoutCluster>

                    {/* Print Button */}
                    <LayoutBox>
                      <Button
                        variant="primary"
                        onClick={() => printPage(!labelsVisible)}
                        text="Print"
                      />
                    </LayoutBox>
                  </LayoutCluster>
                )}

                {/* Report and Date dropdowns  */}
                <LayoutBox>
                  <LayoutCluster style={{ justifyContent: "space-between" }}>
                    <ReportSelection />
                    <ReportDateSelection />
                  </LayoutCluster>
                </LayoutBox>
              </LayoutCluster>
            </LayoutBox>
          </LayoutCluster>
          <Hr style={{ margin: 0 }} />
        </LayoutBox>

        {/* 
          Table:
            * Table will hide if Labels are visible
        */}
        {!labelsVisible ? (
          <LayoutBox
            id={`${componentId}__tableContent`}
            padding="scale-0"
            className="prism-reports--tableContent"
          >
            {/* 
              Title or selectedReportType is visible only when printing
            */}
            <LayoutBox className="prism-reports--selected-report-type-title">
              <LayoutCluster style={{ justifyContent: "space-between" }}>
                <LayoutBox>
                  <h2>{selectedReportType || title}</h2>
                </LayoutBox>

                <LayoutBox>
                  <h2>{moment(selectedDate).format("LL")}</h2>
                </LayoutBox>

                {/* timestamp to be displayed once printed */}
                <LayoutBox>
                  <h3>
                    <i>{`Printed at ${moment().format("MM/DD/YY LT")}`}</i>
                  </h3>
                </LayoutBox>
              </LayoutCluster>

              <Hr style={{ marginTop: 0 }} />
            </LayoutBox>

            {/* Table */}
            <LayoutBox padding="scale-0">
              <LayoutBox>
                {isLoading ? (
                  <LoadingWrapper isLoading={isLoading} />
                ) : selectedReportType ? (
                  <ReportTable />
                ) : (
                  <TextPassage align="center">
                    <h6>Please select a report type and date</h6>
                    <SummarizeOutlinedIcon style={{ fontSize: "20rem", color: color.gray100 }} />
                  </TextPassage>
                )}
              </LayoutBox>
            </LayoutBox>
          </LayoutBox>
        ) : null}
      </LayoutStack>

      {/* 
        Print labels: 
          * Mush stay outside of the Stack so no other CSS affects it 
          * Will Hide if table is visible
        */}
      {labelsVisible ? (
        <LayoutBox padding="scale-0">
          <PrintFoodAndMedLabels />
        </LayoutBox>
      ) : null}
    </LayoutBox>
  );
};

/**
 * Redux Connect function for the Reports Header
 * @summary Located on the /reports page
 * @memberOf Views.Associate
 * @function
 * @name ReportsHeader
 * @example <ReportsHeader />
 */
export const ReportsHeader = connect(() => {
  return {
    backgroundImage: HeaderImage,
    componentId: "ReportsHeader",
    title: "Reports",
  };
})(AppHeader);

/**
 * Redux Connect function for the Reports
 * @summary Located on the /reports page
 * @memberOf Views.Associate
 * @function
 * @name ReportsPage
 * @example <ReportsPage />
 */
export const ReportsPage = withRouteProps(
  connect(
    state => {
      const isReportsFeatureFlagOn = getReportsWorkflowFeatureFlag(state)?.isHidden;
      const systemBookingFlow = getSystemBookingFlow(state);
      const isLoading = createLoadingSelector([GET_AVAILABLE_REPORTS, GET_SELECTED_REPORT])(state);
      const reportsList = selectAvailableReportsList(state);
      const selectedDate = selectSelectedReportDate(state) || formatCalendarDateMoment();
      const selectedReportType = selectSelectedReportType(state);
      const selectedFilters = selectSelectedFilters(state);

      return {
        componentId: "ReportsPage",
        headerComp: ReportsHeader,
        isHidden: !(!isReportsFeatureFlagOn && systemBookingFlow === systemName.HOTEL),
        isLoading,
        reportsList,
        reportSelectionComp: ReportSelectionContainer,
        reportDateSelectionComp: ReportDateSelectionContainer,
        reportTableComp: ReportTable,
        selectedDate,
        selectedFilters,
        selectedReportType,
        title: "Report",
      };
    },
    (dispatch, { router: { navigate } }) => ({
      clearAllFilters: () => {
        dispatch(setSelectedFilters([]));
      },
      clearSelectedReportAndDate: () => {
        // Reset selected report and date
        dispatch(setSelectedReportType(""));
        dispatch(setSelectedReportDate(moment()));
      },
      navigateTo: () => {
        navigate(routePaths.DASHBOARD);
      },
      fetchSelectedReport: (reportType, selectedDate) => {
        dispatch(getSelectedReport({ reportType, selectedDate }));
      },
    }),
    (propsFromState, propsFromDispatch) => {
      const {
        componentId,
        headerComp,
        isHidden,
        isLoading,
        reportsList,
        reportSelectionComp,
        reportDateSelectionComp,
        reportTableComp,
        selectedDate,
        selectedFilters,
        selectedReportType,
        title,
      } = propsFromState;

      const {
        clearAllFilters,
        clearSelectedReportAndDate,
        navigateTo,
        fetchSelectedReport,
      } = propsFromDispatch;

      return {
        componentId,
        clearAllFilters,
        headerComp,
        isHidden,
        isLoading,
        reportSelectionComp,
        reportDateSelectionComp,
        reportTableComp,
        selectedDate,
        selectedFilters,
        selectedReportType,
        title,

        // Actions
        clearSelectedReportAndDate,
        navigateTo,
        getSelectedReport: (selectedReportType, selectedDate) => {
          const reportType =
            reportsList.find(report => report.label === selectedReportType)?.endpoint || "";
          fetchSelectedReport(reportType, formatCalendarDateMoment(selectedDate));
        },
        printPage: isReport => {
          isReport ? printWithZoomByElementWidth("#ReportsTable") : window.print();
        },
      };
    },
  )(Reports),
);
