import { color, lineThickness } from "../theme";

/**
 * Responsible for setting the border properties such as color and thickness
 *
 * @param {*} error
 * @returns {string}
 */
export default function setTextInputBorder(error) {
  let borderColor = color.gray300;

  if (error) {
    borderColor = color.red700;
  }

  return `${lineThickness.sliver} solid ${borderColor}`;
}
