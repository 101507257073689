import React from "react";
import styled from "styled-components";
import { color } from "web/common/styles/theme";
import { fontSizes } from "web/common/styles/responsive/fonts";
import ClickableText from "../../ClickableText";
import CommonButton from "../../commonButton";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  opacity: ${props => (props.disabled ? "0.3" : "1")};
`;

const Header = styled.div`
  font-size: ${fontSizes.regular};
  font-weight: bold;
  text-align: center;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Message = styled.div`
  margin: 30px auto;
  text-align: center;
  max-width: 240px;
`;

export default ({ heading, message, buttonLabel, disabled, handleRemove, onClose }) => (
  <Container disabled={disabled}>
    <Header>{heading}</Header>
    <Message>{message}</Message>
    <ButtonsContainer>
      <ClickableText isVisible styleObj={{ color: color.blue500 }} onClick={onClose}>
        Go back
      </ClickableText>
      <CommonButton disabled={disabled} label={buttonLabel} onClick={handleRemove} />
    </ButtonsContainer>
  </Container>
);
