import React from "react";
import { connect } from "react-redux";
import { getOtherFoodNameById } from "./otherFoodNameSelectors";
import InputWithBlur from "../common/InputWithBlur";
import { getFoodNameByExternalId, getFoodNameExternalIdById } from "../foodName/foodNameSelectors";
import { REQUIRED_FIELD_ERROR_MESSAGE } from "../../core/constants/validationErrors";
import { OTHER_FOOD_NAME } from "../newFood/newFoodConstants";
import { setFoodName } from "../foodName/actions/setFoodNameActions";
import { isValidString } from "@/core/utils/validationUtils/stringValidation";
import { getFoodIsDiscontinuedById } from "../food/foodsSelector";

export const OtherFoodNameField = props => {
  return <InputWithBlur {...props} title="Other food name" name="otherFoodName" />;
};

const mapStateToProps = (state, ownProps) => {
  const { foodId, petId } = ownProps;
  const currentValue = getOtherFoodNameById(state, { foodId, petId });
  const isDiscontinued = getFoodIsDiscontinuedById(state, { petId, foodId });
  const disabled = getFoodNameByExternalId(state, { foodId, petId }) !== OTHER_FOOD_NAME;
  const isValid = isValidString(currentValue);
  const hasError = !disabled && !isValid;

  return {
    foodId,
    petId,
    componentId: `feedingDetails-otherFoodName-${foodId}`,
    currentValue,
    foodExternalId: getFoodNameExternalIdById(state, { foodId, petId }),
    disabled: disabled || isDiscontinued,
    error: hasError && REQUIRED_FIELD_ERROR_MESSAGE,
  };
};

const mapDispatchToProps = dispatch => ({
  onChangeFoodType: ({ value, foodExternalId, foodId, petId }) => {
    dispatch(setFoodName({ name: value, externalId: foodExternalId, foodId, petId }));
  },
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  onChange: value => {
    const { foodId, foodExternalId, petId } = propsFromState;
    propsFromDispatch.onChangeFoodType({ value, foodExternalId, foodId, petId });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(OtherFoodNameField);
