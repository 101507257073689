import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { getAssociate } from "core/selectors/entitiesSelector";
import { getAssociateNumber } from "core/selectors/persistentSelectors";
import InitialsCircle from "../common/InitialsCircle";
import { color } from "../common/styles/theme";

/**
 * React view component that will display the associate name, the group they belong to (e.g. Salon Manager ) along with an Icon of their initials.
 *
 * @summary Located on the associate-scheduling page within the WeeklyTableComponent
 * @memberOf Views.Associate
 * @function
 * @name  AssociateCell
 * @param { Object } props
 * @param { String } props.componentId - Block level Id for the component
 * @param { Boolean } props.isHidden - determines if the component is visible
 * @param { Boolean } props.backgroundColor - Associate initials Icon's background color
 * @param { Boolean } props.className - The class name to palce on the root components.
 * @param { Boolean } props.styles - any additional styles placed on the root components.
 * @returns {JSX.Element|null}
 * @todo remove styled components and convert to Layout
 *
 * @example <AssociateCell />
 */
export const AssociateCell = props => {
  // Short circuit view if it isHidden
  const { isHidden } = props;
  if (isHidden) return null;

  // value props
  const { componentId, associate, backgroundColor, className, styles } = props;
  const { associateName, associateGroup } = associate;

  // fn props
  // const { someHandler } = props;

  return (
    <Container
      id={componentId}
      style={styles}
      backgroundColor={backgroundColor}
      className={className}
    >
      <InitialsCircle displayName={associateName} margin="0 10px 0 0" />

      <Associate>
        <Name>{associateName}</Name>
        <Group>{associateGroup}</Group>
      </Associate>
    </Container>
  );
};

// TODO: refactor and remove all styled components
export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  border-left: 1px solid ${color.gray300};
  border-right: 1px solid ${color.gray300};
  background-color: ${({ backgroundColor }) => backgroundColor || "white"};
`;

const Associate = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.div`
  font-weight: bold;
  font-size: 1.125rem;
`;
const Group = styled.div`
  color: ${color.gray600};
`;

/**
 * Redux Connect function for the AssociateCell
 *
 * @see {@link WeeklyTableAssociateCell}
 * @summary Located on the associate-scheduling page within the WeeklyTableComponent
 * @memberOf Views.Associate
 * @function
 * @name  WeeklyTableAssociateCell
 * @param { Object } props
 * @param { Number } props.associateId - Id that represents an associate
 * @returns {JSX.Element|null}
 *
 * @example <WeeklyTableAssociateCell associateId={123456} />
 */
export const WeeklyTableAssociateCell = connect((state, { associateId }) => {
  const associate = getAssociate(state, { associateId });

  return {
    componentId: "WeeklyTableAssociateCell",
    isHidden: !associate,
    associate,
    styles: {
      border: "0px",
      padding: "0px",
    },
  };
})(AssociateCell);

/**
 * Redux Connect function for the AssociateCell
 *
 * @see {@link SubtotalsTable}
 * @summary Located schedules page within the SubtotalsTable Component
 * @memberOf Views.Associate
 * @function
 * @name  SubTotalAssociateCell
 * @param { Object } props
 * @param { Number } props.associateId - Id that represents an associate
 * @returns {JSX.Element|null}
 *
 * @example <SubTotalAssociateCell associateId={123456} />
 */
export const SubTotalAssociateCell = connect((state, { associateId }) => {
  const associate = getAssociate(state, { associateId });

  return {
    componentId: "SubTotalAssociateCell",
    associate,
    styles: {},
    isHidden: !associate,
  };
})(AssociateCell);

/**
 * Redux Connect function for the AssociateCell
 *
 * @see {@link MyScheduleComponent}
 * @summary Located on the my-schedule page
 * @memberOf Views.Associate
 * @function
 * @name  MayScheduleAssociateCell
 * @returns {JSX.Element|null}
 *
 * @example <MayScheduleAssociateCell />
 */
export const MayScheduleAssociateCell = connect(state => {
  const associateId = getAssociateNumber(state);
  const associate = getAssociate(state, { associateId });

  return {
    componentId: "SubTotalAssociateCell",
    associate,
    styles: { border: "none" },
    isHidden: !associate,
  };
})(AssociateCell);
