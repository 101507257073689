import { connect } from "react-redux";
import { history } from "@/dux/utils/browser/browserHistory";
import { color } from "../../../../web/common/styles/theme";
import { hideBookingModal } from "../../../../core/actionCreators/bookingActionCreators";
import { getCurrentCustomerKey } from "../../../../core/selectors/persistent/customer/customerSelectors";
import { getCustomer } from "../../../../core/selectors/entitiesSelector";
import ClickableText from "../../../../web/common/ClickableText";

const mapStateToProps = state => {
  const customerKey = getCurrentCustomerKey(state);
  const customer = getCustomer(state, { customerKey });
  return {
    label: `${customer?.firstName}'s profile`,
    styleObj: { color: color.blue500, padding: "10px" },
    isVisible: true,
    customerKey,
  };
};

const mapDispatchToProps = dispatch => ({
  redirect: customerKey => history.push(`/pet-parent-profile/${customerKey}`),
  closeModal: () => dispatch(hideBookingModal()),
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  onClick: () => {
    propsFromDispatch.redirect(propsFromState.customerKey);
    propsFromDispatch.closeModal();
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ClickableText);
