// https://medium.com/eightshapes-llc/naming-tokens-in-design-systems-9e86c7444676

// SBW -----------------------------------------------------------------------------------------------------------------
const SBW_COLORS = {
  'loyal-blue': '#007db4',
  'loving-red': '#e52823',
  'deep-blue': '#003b49',
  'dark-gray': '#333333',
  'warm-gray': '#6e6766',
  'light-gray': '#f2f2f2',
  'accent-1': '#dee1ec',
  'true-blue': '#43c7ee',
  'accent-3': '#d3f5ff',
  'accent-4': '#990000',
  'accent-5': '#f2f7fc',
  'accent-6': '#ecf6fa',
  'lighter-gray': '#f9f9fa',
  'border-gray': '#999999',
  'medium-gray': '#b9b9b9',
  'pale-gray': '#f3f3f7',
  'border-color': '#dee1ec',
  white: '#ffffff',
  black: '#000000',
  teal: '#00a56f',
  'teal-green': '#06847f',
  'light-blue': '#d2f5ff',
  'error-orange': '#cd7712',
  'error-orange-with-opacity': '#cd771280',
  'success-green': '#66a61e',
  'success-green-with-opacity': '#66a61e80',
  'translucent-gray': 'rgba(122, 122, 122, 0.2)',
};

// Prism ---------------------------------------------------------------------------------------------------------------
const PRISM_COLORS = {
  black0: '#000',
  blue500: '#1777f2',
  white0: '#fff',
  gray700: '#565a5c',
  gray400: '#b6b6b6',
  gray800: '#333',
  gray200: '#dedede',
  gray300: '#bec0c4',
  gray50: '#f2f2f2',
  gray600: '#6b6b6b',
  gray100: '#eaeaea',
  red700: '#e62722',
  lightGreen: '#23c76a',
  blue50: '#dbe4f0',
  green300: '#23c7a1',
  blue400: '#007db4',
  blue600: '#465f89',
  tableTotalsBackground: '#eaeef4',
  yellow700: '#f4c43a',
};

export const PRISM_FONT_FAMILY = {
  mainFamily: 'Lato, sans-serif',
  secondaryFamily: 'Montserrat, sans-serif',
};

const PRISM_FONT_SIZES = {
  small: '0.75rem',
  medium: '1rem',
  regular: '1.125rem',
  big: '1.25rem',
  extraBig: '1.5rem',
  large: '1.75rem',
  extraLarge: '2rem',
  magnificent: '2.25rem',
  fantastic: '2.375rem',
  godlike: '2.625rem',
};

const PRISM_PIXELS = {
  tiny: 12,
  small: 14,
  base: 16,
  regular: 18,
  big: 20,
  extraBig: 24,
  large: 28,
  extraLarge: 32,
  magnificent: 36,
  fantastic: 38,
  godlike: 42,
};

// export default function CalculateEMFromPX(pxValue) {
//   const emValue = pxValue / ValuesInPixels.base;

//   return emValue;
// }

// export const fontSizes = {
//   small: `${CalculateEMFromPX(ValuesInPixels.small)}rem`, // '0.75rem',
//   medium: `${CalculateEMFromPX(ValuesInPixels.base)}rem`, // '1rem',
//   regular: `${CalculateEMFromPX(ValuesInPixels.regular)}rem`, // '1.125rem',
//   big: `${CalculateEMFromPX(ValuesInPixels.big)}rem`, // '1.25rem',
//   extraBig: `${CalculateEMFromPX(ValuesInPixels.extraBig)}rem`, // '1.5rem',
//   large: `${CalculateEMFromPX(ValuesInPixels.large)}rem`, // '1.75rem',
//   extraLarge: `${CalculateEMFromPX(ValuesInPixels.extraLarge)}rem`, // '2 rem',
//   magnificent: `${CalculateEMFromPX(ValuesInPixels.magnificent)}rem`, // '2.25 rem',
//   fantastic: `${CalculateEMFromPX(ValuesInPixels.fantastic)}rem`, // '2.375 rem',
//   godlike: `${CalculateEMFromPX(ValuesInPixels.godlike)}rem`, // '2.625 rem',
// };

/**
 * The Default breakpoints found in Chrome Dev tools
 * @type {{tablet: string, desktop: string, laptopL: string, mobileS: string, mobileM: string, laptop: string, mobileL: string}}
 */
export const chromeDefaultBreakPoints = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

/**
 * Custom Break points for custom devices.
 * @type {{galaxyTabProHeight: string, desktopWidth: string, galaxyTabProWidth: string, desktopHeight: string}}
 */
export const customBreakPoints = {
  desktopWidth: '1920px',
  desktopHeight: '1080px',
  galaxyTabProWidth: '1920px',
  galaxyTabProHeight: '1200px',
};

/**
 * Select the Device to target in a media in a media query
 * @type {{galaxyTabProLandscape: string, tablet: string, desktopL: string, galaxyTabProPortrait: string, desktop: string, laptopL: string, mobileS: string, mobileM: string, laptop: string, mobileL: string, prismDesktop: string}}
 */
export const device = {
  mobileS: `(min-width: ${chromeDefaultBreakPoints.mobileS})`,
  mobileM: `(min-width: ${chromeDefaultBreakPoints.mobileM})`,
  mobileL: `(min-width: ${chromeDefaultBreakPoints.mobileL})`,
  tablet: `(min-width: ${chromeDefaultBreakPoints.tablet})`,
  laptop: `(min-width: ${chromeDefaultBreakPoints.laptop})`,
  laptopL: `(min-width: ${chromeDefaultBreakPoints.laptopL})`,
  desktop: `(min-width: ${chromeDefaultBreakPoints.desktop})`,
  desktopL: `(min-width: ${chromeDefaultBreakPoints.desktop})`,

  // NOTE: some device targets may use a min-width and max-height to target very specific
  // resolutions in "PC Browser" dev tools. This was the way I used to target different devices
  // In Chrome Dev tools with the same width resolution, eg. both are 1920px without having the physical device.
  prismDesktop: `
    (min-width: ${customBreakPoints.desktopWidth}) and (max-height: ${customBreakPoints.desktopHeight})
  `,
  galaxyTabProLandscape: `
    (min-width: ${customBreakPoints.galaxyTabProWidth}) and (max-height: ${customBreakPoints.galaxyTabProHeight})
  `,
  galaxyTabProPortrait: `
    (min-width: ${customBreakPoints.galaxyTabProHeight}) and (max-height: ${customBreakPoints.galaxyTabProWidth})
  `,
};

// Colors --------------------------------------------------------------------------------------------------------------
const UTILITY_COLORS = {
  debug: 'hsl(72, 100%, 50%)',
  hotpink: 'hotpink',
};

// Fonts ---------------------------------------------------------------------------------------------------------------
const FONT_WEIGHT = {
  normal: 'normal',
  bold: 'bold',
};

const FONT_ALIGN = {
  left: 'left',
  right: 'right',
  center: 'center',
};

// Border --------------------------------------------------------------------------------------------------------------
const BORDER_WIDTH = {
  'psm-border-width-0': '0px',
  'psm-border-width-2': '2px',
  'psm-border-width-4': '4px',
  'psm-border-width-8': '8px',
  'psm-border-width': '1px',
};

const BORDER_STYLE = {
  'psm-border-solid': 'solid',
  'psm-border-dashed': 'dashed',
  'psm-border-dotted': 'dotted',
  'psm-border-double': 'double',
  'psm-border-none': 'none',
};

const BORDER_COLOR = {
  'psm-border-color-black-100': PRISM_COLORS.black0,
  'psm-border-color-gray-100': PRISM_COLORS.gray200,
};

const BORDER_RADIUS = {
  'psm-rounded-none': '0px',
  'psm-rounded-sm': '0.125rem',
  'psm-rounded': '0.25rem',
  'psm-rounded-md': '0.375rem',
  'psm-rounded-lg': '0.5rem',
  'psm-rounded-xl': '0.75rem',
  'psm-rounded-2xl': '1rem',
  'psm-rounded-3xl': '1.5rem',
  'psm-rounded-full': '9999px',
};

export const BORDER = {
  WIDTH: BORDER_WIDTH,
  STYLE: BORDER_STYLE,
  COLOR: BORDER_COLOR,
  RADIUS: BORDER_RADIUS,
};

// Space ---------------------------------------------------------------------------------------------------------------
export const TAILWIND_SPACE = {
  'psm-spacing-0': { size: '0px', pixels: '0px' },
  'psm-spacing-px': { size: '1px', pixels: '1px' },
  'psm-spacing-0.5': { size: '0.125rem', pixels: '2px' },
  'psm-spacing-1': { size: '0.25rem', pixels: '4px' },
  'psm-spacing-1.5': { size: '0.375rem', pixels: '6px' },
  'psm-spacing-2': { size: '0.5rem', pixels: '8px' },
  'psm-spacing-2.5': { size: '0.625rem', pixels: '10px' },
  'psm-spacing-3': { size: '0.75rem', pixels: '12px' },
  'psm-spacing-3.5': { size: '0.875rem', pixels: '14px' },
  'psm-spacing-4': { size: '1rem', pixels: '16px' },
  'psm-spacing-5': { size: '1.25rem', pixels: '20px' },
  'psm-spacing-6': { size: '1.5rem', pixels: '24px' },
  'psm-spacing-7': { size: '1.75rem', pixels: '28px' },
  'psm-spacing-8': { size: '2rem', pixels: '32px' },
  'psm-spacing-9': { size: '2.25rem', pixels: '36px' },
  'psm-spacing-10': { size: '2.5rem', pixels: '40px' },
  'psm-spacing-11': { size: '2.75rem', pixels: '44px' },
  'psm-spacing-12': { size: '3rem', pixels: '48px' },
  'psm-spacing-14': { size: '3.5rem', pixels: '56px' },
  'psm-spacing-16': { size: '4rem', pixels: '64px' },
  'psm-spacing-20': { size: '5rem', pixels: '80px' },
  'psm-spacing-24': { size: '6rem', pixels: '96px' },
  'psm-spacing-28': { size: '7rem', pixels: '112px' },
  'psm-spacing-32': { size: '8rem', pixels: '128px' },
  'psm-spacing-36': { size: '9rem', pixels: '144px' },
  'psm-spacing-40': { size: '10rem', pixels: '160px' },
  'psm-spacing-44': { size: '11rem', pixels: '176px' },
  'psm-spacing-48': { size: '12rem', pixels: '192px' },
  'psm-spacing-52': { size: '13rem', pixels: '208px' },
  'psm-spacing-56': { size: '14rem', pixels: '224px' },
  'psm-spacing-60': { size: '15rem', pixels: '240px' },
  'psm-spacing-64': { size: '16rem', pixels: '256px' },
  'psm-spacing-72': { size: '18rem', pixels: '288px' },
  'psm-spacing-80': { size: '20rem', pixels: '320px' },
  'psm-spacing-96': { size: '24rem', pixels: '384px' },
};

const SIZE = {
  'psm-size-8': 8,
  'psm-size-12': 12,
  'psm-size-16': 16,
  'psm-size-20': 20,
  'psm-size-24': 24,
  'psm-size-32': 32,
  'psm-size-48': 48,
  'psm-size-96': 96,
  'psm-size-164': 164,
};

export const THEME_ONE = {
  COLOR: { UTILITY_COLORS, PRISM_COLORS, SBW_COLORS },
  SPACE: { TAILWIND: TAILWIND_SPACE, SIZE },
  FONT: { FAMILY: PRISM_FONT_FAMILY, SIZE: PRISM_FONT_SIZES, PX: PRISM_PIXELS, WEIGHT: FONT_WEIGHT, ALIGN: FONT_ALIGN },
  BORDER,
};
