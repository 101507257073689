import React from "react";
import { color } from "web/common/styles/theme";
import { LayoutBox } from "@/layout/box/Box";
import { Heading } from "@petsmart-ui/sparky";

const PetParentColumnHeader = ({ title, componentID }) => (
  <LayoutBox
    id={componentID}
    padding="scale-G1"
    style={{ borderBottom: `0.0625rem solid ${color.gray300}` }}
  >
    <Heading tagName="h2" size="title">
      {title}
    </Heading>
  </LayoutBox>
);

export default PetParentColumnHeader;
