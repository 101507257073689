/* * ----------------------------------------------------------------------- * *\
  This is a common view component responsible for displaying what job role
  the user is.
\* * ----------------------------------------------------------------------- * */

import React from "react";
import styled from "styled-components";
import { color } from "../styles/theme";
import MockJobRoleResetContainer from "../../login/profileButton/MockJobRoleResetContainer";

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: ${props => (props.isClickable ? "pointer" : "default")};
`;

const RoleIndicator = styled.div`
  background-color: ${color.green300};
  color: ${color.white0};
  text-align: center;
  font-family: Lato;
  font-size: 0.8em;
  font-weight: bold;
  padding: 3px 8px;
  letter-spacing: 0.8px;
  border-radius: 2px;
  margin-left: 5px;
`;

export default function JobRoleIndicator(props) {
  const { jobRole, isVisible, onHandleClick, isClickable } = props;
  if (isVisible) {
    return (
      <SectionWrapper {...props}>
        <RoleIndicator {...props} onClick={isClickable ? onHandleClick : null}>
          {jobRole}
        </RoleIndicator>
        <MockJobRoleResetContainer />
      </SectionWrapper>
    );
  }

  return null;
}
