import { connect } from "react-redux";
import TextComponent from "dux/_components/genericLayoutComponents/TextComponent";
import { color } from "web/common/styles/theme";

const mapStateToProps = () => {
  return {
    isHidden: false,
    styleType: "div",
    text: "Package Add-On Discount",
    styleObj: { color: color.gray600 },
  };
};

export default connect(mapStateToProps)(TextComponent);
