import React from "react";
import { FoodFormLayout } from "@/dux/_components/feeding/FoodFormLayout";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";
import FoodNameContainer from "../foodName/FoodNameContainer";
import AddNewFoodButtonContainer from "../addFood/buttons/AddNewFoodButtonContainer";
import CancelNewFoodButtonContainer from "../addFood/buttons/CancelNewFoodButtonContainer";
import OtherFoodNameContainer from "../otherFoodName/OtherFoodNameContainer";
import FoodTypeAmountContainer from "../foodTypeAmount/FoodTypeAmountContainer";
import FoodInstructionsContainer from "../foodInstructions/FoodInstructionsContainer";
import { FoodTimeOfDay } from "@/dux/timeOfDayCheckboxes/TimeOfDayCheckboxes";
import { isNewFood } from "../food/foodUtils";
import FoodTypeContainer from "../foodType/FoodTypeContainer";
import withClickOutside from "./hoc/withClickOutside";

class PetProfileFeedingFormComponent extends React.Component {
  handleClickOutside = this.handleClickOutside.bind(this);

  handleClickOutside(e) {
    const { onSubmit } = this.props;
    onSubmit && onSubmit();
  }

  render() {
    const {
      componentId,
      isHidden,
      foodId,
      petId,
      foodIndex,
      foodIds,
      clickOutsideRef,
    } = this.props;
    const newFoodIndex = isNewFood(foodId) ? foodIds.length + 1 : foodIndex;
    return !isHidden ? (
      <div ref={clickOutsideRef} handleClickOutside={this.handleClickOutside}>
        <FoodFormLayout componentId={componentId}>
          <FoodTypeContainer foodIndex={newFoodIndex} foodId={foodId} petId={petId} />
          <FoodNameContainer foodIndex={newFoodIndex} foodId={foodId} petId={petId} />
          <OtherFoodNameContainer foodId={foodId} petId={petId} />
          <FoodTypeAmountContainer foodId={foodId} foodIndex={newFoodIndex} petId={petId} />
          <FoodTimeOfDay foodId={foodId} foodIndex={newFoodIndex} petId={petId} />
          <FoodInstructionsContainer foodId={foodId} foodIndex={newFoodIndex} petId={petId} />

          <LayoutBox padding="scale-0">
            <LayoutCluster style={{ justifyContent: "flex-end" }}>
              <AddNewFoodButtonContainer foodId={foodId} petId={petId} />
              <CancelNewFoodButtonContainer foodId={foodId} petId={petId} />
            </LayoutCluster>
          </LayoutBox>
        </FoodFormLayout>
      </div>
    ) : null;
  }
}

export default withClickOutside(PetProfileFeedingFormComponent);
