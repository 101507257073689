import { store } from "core/store";
import removeNANPFormat from "core/utils/searchUtils/removeNANPFormat";
import {
  clearSearchApplied,
  clearSearchFieldValue,
  setSearchApplied,
  setSearchField,
  setSearchFieldType,
  showSearchResults,
} from "core/actionCreators/searchSalonActionCreator";
import {
  ASSOCIATE_ID_VALUE_TYPE,
  PHONE_NUMBER_VALUE_TYPE,
  STORE_NUMBER_VALUE_TYPE,
} from "core/constants/dropdownOptions";
import { loadCustomers } from "core/actionCreators/customersActionCreators";
import { setSearchResultsToDisplay } from "core/actionCreators/searchActionCreators";
import { searchConstants } from "core/constants/searchConstants";
import { history } from "dux/utils/browser/browserHistory";
import {
  categoryStore,
  isCategoryAssociateSearch,
  isCategoryStoresByLocation,
  searchFieldTypeConstants,
} from "core/constants/searchFieldTypeConstants";
import { loadSalonInfoWithServices } from "core/actionCreators/salonActionCreator";
import { setStoreNumber } from "core/actionCreators/ui/web/generalActionCreators";
import { clearAssociatesSchedule } from "core/actionCreators/schedulesActionCreators";
import { getSearchFieldMainStoresByLocation } from "dux/searchFieldMain/searchFieldMainActions";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";

/*
  --- Interfaces ---
 */
export interface Sfv {
  searchFieldValue?: string;
}
interface Sft {
  searchFieldType?: string;
}
interface HandlerProps extends Sfv, Sft {}
interface FactoryProps extends Sft {
  dispatch: any; // TODO: pull in actual Dispatch<A> types
}
interface Handlers {
  onFieldUpdate: ({ searchFieldValue, searchFieldType }: HandlerProps) => void;
  onSetDefaultSearchFieldType: (defaultSearchFieldType?: string) => void;
  onSelectionChange: ({ searchFieldType }: HandlerProps) => void;
  onSearch: ({ searchFieldType, searchFieldValue }: HandlerProps) => void;
  onFieldUnMount: () => void;
  inputType: (searchFieldType?: string) => string;
  inputMask: (searchFieldType?: string) => string;
}

/*
  --- Helper functions ---
 */
/*
  Helper function - most search categories/products will redirect, however if it is on the pet parent profile page
  and is one of redirecting categories this helper will stop the redirect
 */
const redirectToSearchPage = () => {
  // Only allow the search to redirect if it is not on pe parent profile
  const shouldRedirect = !history.location.pathname.includes("/pet-parent-profile/");

  shouldRedirect && history.push("/search");

  return !!shouldRedirect;
};

/*
  Helper function - The categoryStore product returns handlers to search by Store, if the search is on
  The Pet parent profile page, it needs to redirect  the booking select service page
 */
const redirectToBooking = () => {
  // Only allow the search to redirect if it is not on pe parent profile
  const shouldRedirect = history.location.pathname.includes("/pet-parent-profile/");

  if (shouldRedirect) {
    const customerKey: string = getCurrentCustomerKey(store.getState());

    history.push(`/booking/${customerKey}/select-service`);
  }

  return !!shouldRedirect;
};

/* Helper function - used for formatting phone numbers */
const inputType = (sft?: string) => (sft === PHONE_NUMBER_VALUE_TYPE ? "tel" : "search");

/* Helper function - used for formatting phone numbers */
const inputMask = (sft?: string) => (sft === PHONE_NUMBER_VALUE_TYPE ? "(000) 000-0000" : "");

/*
  --- Products ---
 */
/**
 * Factory product set of handler functions for handling customer searches such as by phone,
 * email name, etc.
 * @param dispatch
 */
const customerTypeHandlers = ({ dispatch }: FactoryProps): Handlers => {
  return {
    onFieldUnMount: () => {
      // If the User happens to navigate to the associates page with content in search field, it should be cleared
      if (location.pathname === "/associates") {
        dispatch(setSearchFieldType({ searchFieldType: ASSOCIATE_ID_VALUE_TYPE }));
        dispatch(clearSearchFieldValue());
      }
    },
    onFieldUpdate: ({ searchFieldValue, searchFieldType }: HandlerProps) => {
      // Set NANP formatted phoneNumber Back to a series of numbers.
      const unFormattedPhoneNumber = removeNANPFormat({ searchFieldType, searchFieldValue });

      dispatch(setSearchField({ searchFieldValue: unFormattedPhoneNumber.searchFieldValue }));
    },

    onSetDefaultSearchFieldType: (defaultSearchFieldType: string) => {
      dispatch(
        setSearchFieldType({ searchFieldType: defaultSearchFieldType || PHONE_NUMBER_VALUE_TYPE }),
      );
    },

    onSelectionChange: ({ searchFieldType }: HandlerProps) => {
      dispatch(clearSearchFieldValue());
      dispatch(clearSearchApplied());
      dispatch(setSearchFieldType({ searchFieldType }));
    },

    onSearch: ({ searchFieldType, searchFieldValue }: HandlerProps) => {
      dispatch(setSearchApplied());
      dispatch(loadCustomers({ [searchFieldType]: searchFieldValue }));
      dispatch(showSearchResults({ showSearchResults: true }));
      dispatch(setSearchResultsToDisplay({ searchResultsToDisplay: searchConstants.CUSTOMER }));

      // redirect to the search page if the user is not on the pet-parent-profile
      redirectToSearchPage();
    },
    inputType,
    inputMask,
  };
};

/**
 * Factory product set of handler functions for handling store searches such as by store number
 * @param dispatch
 */
const storeTypeHandlers = ({ dispatch }: FactoryProps): Handlers => {
  return {
    onFieldUnMount: () => {
      // currently just returns as no un-mount logic at this time
      return;
    },
    onFieldUpdate: ({ searchFieldValue, searchFieldType }: HandlerProps) => {
      dispatch(setSearchField({ searchFieldValue }));
    },

    onSetDefaultSearchFieldType: () => {
      dispatch(setSearchFieldType({ searchFieldType: STORE_NUMBER_VALUE_TYPE }));
      dispatch(clearSearchFieldValue());
    },

    onSelectionChange: ({ searchFieldType }: HandlerProps) => {
      dispatch(clearSearchFieldValue());
      dispatch(clearSearchApplied());
      dispatch(setSearchFieldType({ searchFieldType }));
    },

    onSearch: ({ searchFieldType, searchFieldValue }: HandlerProps) => {
      /* NOTE both these actions will load the same store data with the exception that one provides
      StandardStoreHours. this data gets placed into to different spots in the redux state, this
       needs to be refactored to only use one state and reduce this down to just the setStoreNumber
       action*/
      dispatch(loadSalonInfoWithServices({ storeNumber: searchFieldValue }));
      dispatch(setStoreNumber({ storeNumber: searchFieldValue }));

      /* When the page redirects from /searchSRC to /search the default search type should be
      defaulted back to customers phone */
      dispatch(setSearchFieldType({ searchFieldType: searchFieldTypeConstants.PHONE_NUMBER }));

      dispatch(clearSearchFieldValue());
      dispatch(clearSearchApplied());
      dispatch(clearAssociatesSchedule());

      dispatch(setSearchResultsToDisplay({ searchResultsToDisplay: searchConstants.STORE }));
      dispatch(showSearchResults({ showSearchResults: true }));

      // redirect to the search page if the user is not on the pet-parent-profile
      redirectToSearchPage();

      // redirect to the booking page if the user is on the pet-parent-profile
      redirectToBooking();
    },
    inputType,
    inputMask,
  };
};

/**
 * Factory product set of handler functions for handling store searches by location such as,
 * zip code address, cityState.
 * @param dispatch
 */
const storesByLocationTypeHandlers = ({ dispatch }: FactoryProps): Handlers => {
  return {
    onFieldUnMount: () => {
      // currently just returns as no un-mount logic at this time
      return;
    },
    onFieldUpdate: ({ searchFieldValue, searchFieldType }: HandlerProps) => {
      dispatch(setSearchField({ searchFieldValue }));
    },

    onSetDefaultSearchFieldType: () => {
      // no action is currently needed in this method
      // dispatch(setSearchFieldType({ searchFieldType: STORE_NUMBER_VALUE_TYPE }));
    },

    onSelectionChange: ({ searchFieldType }: HandlerProps) => {
      dispatch(clearSearchFieldValue());
      dispatch(clearSearchApplied());
      dispatch(setSearchFieldType({ searchFieldType }));
    },

    onSearch: ({ searchFieldType, searchFieldValue }: HandlerProps) => {
      dispatch(getSearchFieldMainStoresByLocation({ searchFieldType, searchFieldValue }));

      /* When the page redirects from /searchSRC to /search the default search type should be
      defaulted back to customers phone */
      dispatch(setSearchFieldType({ searchFieldType: searchFieldTypeConstants.PHONE_NUMBER }));

      dispatch(clearSearchFieldValue());
      dispatch(clearSearchApplied());
      dispatch(clearAssociatesSchedule());

      dispatch(
        setSearchResultsToDisplay({ searchResultsToDisplay: searchConstants.STORES_BY_LOCATION }),
      );
      dispatch(showSearchResults({ showSearchResults: true }));

      // redirect to the search page if the user is not on the pet-parent-profile
      redirectToSearchPage();
    },
    inputType,
    inputMask,
  };
};

/**
 * Factory product set of handler functions for handling Associate search by name or id
 * @param dispatch
 */
const associateSearchTypeHandlers = ({ dispatch }: FactoryProps): Handlers => {
  return {
    onFieldUnMount: () => {
      // Clear search field on unmount
      dispatch(clearSearchFieldValue());
    },
    /*
      this is where the live search happens
     */
    onFieldUpdate: ({ searchFieldValue, searchFieldType }: HandlerProps) => {
      dispatch(setSearchField({ searchFieldValue }));
      dispatch(setSearchApplied());
      dispatch(showSearchResults({ showSearchResults: true }));
    },

    onSetDefaultSearchFieldType: () => {
      dispatch(setSearchFieldType({ searchFieldType: ASSOCIATE_ID_VALUE_TYPE }));
    },

    onSelectionChange: ({ searchFieldType }: HandlerProps) => {
      dispatch(clearSearchFieldValue());
      dispatch(clearSearchApplied());
      dispatch(setSearchFieldType({ searchFieldType }));
    },

    onSearch: ({ searchFieldType, searchFieldValue }: HandlerProps) => {
      // No work needs to happen currently as in this use case, all search happens during onFieldUpdate
    },
    inputType,
    inputMask,
  };
};

/*
  --- Factory ---
 */
export const searchTypeHandlerFactory = ({ searchFieldType, dispatch }: FactoryProps): Handlers => {
  if (categoryStore({ searchFieldType })) {
    return storeTypeHandlers({ dispatch });
  }

  if (isCategoryStoresByLocation({ searchFieldType })) {
    return storesByLocationTypeHandlers({ dispatch });
  }

  if (isCategoryAssociateSearch({ searchFieldType })) {
    return associateSearchTypeHandlers({ dispatch });
  }

  return customerTypeHandlers({ dispatch });
};
