import { connect } from "react-redux";
import featureFlagUIWrapper from "../common/featureFlagging/FeatureFlagUIWrapper";
import getFeatureFlagsList from "../enableDisableFeature/selectors/enableDisableFeatureFlagsList";
import getFeatureFlagVisibleState from "../toggleFeatureFlagWindow/selectors/getFeatureFlagVisibleState";
import getWorkflowFeatureFlagsList from "../enableDisableWorkflowFeatureFlag/selectors/enableDisableWorkflowFeatureFlagsList";
import * as workflowFeatureTypes from "../enableDisableWorkflowFeatureFlag/workflowFeatureTypes";
import EnableDisableWorkflowFeatureFlagBGMContainer from "../enableDisableWorkflowFeatureFlag/enableDisableWorkflowFeatureFlagContainers/EnableDisableWorkflowFeatureFlagBGMContainer";
import EnableDisableWorkflowFeatureFlagHotelContainer from "../enableDisableWorkflowFeatureFlag/enableDisableWorkflowFeatureFlagContainers/EnableDisableWorkflowFeatureFlagHotelContainer";
import EnableDisableWorkflowFeatureFlagFTCOContainer from "../enableDisableWorkflowFeatureFlag/enableDisableWorkflowFeatureFlagContainers/EnableDisableWorkflowFeatureFlagFTCOContainer";
import EnableDisableWorkflowFeatureFlagManualApplyContainer from "../enableDisableWorkflowFeatureFlag/enableDisableWorkflowFeatureFlagContainers/EnableDisableWorkflowFeatureFlagManualApplyContainer";
import EnableDisableWorkflowFeatureFlagTrainingContainer from "web/enableDisableWorkflowFeatureFlag/enableDisableWorkflowFeatureFlagContainers/EnableDisableWorkflowFeatureFlagTrainingContainer";
import EnableDisableWorkflowFeatureFlagEnhancedServicesContainer from "web/enableDisableWorkflowFeatureFlag/enableDisableWorkflowFeatureFlagContainers/EnableDisableWorkflowFeatureFlagEnhancedServicesContainer";
import EnableDisableWorkflowFeatureFlagCatGroomingContainer from "../enableDisableWorkflowFeatureFlag/enableDisableWorkflowFeatureFlagContainers/EnableDisableWorkflowFeatureFlagCatGroomingContainer";
import EnableDisableWorkflowFeatureFlagStoreEventNotificationsContainer from "web/enableDisableWorkflowFeatureFlag/enableDisableWorkflowFeatureFlagContainers/EnableDisableWorkflowFeatureFlagStoreEventNotificationsContainer";
import EnableDisableWorkflowFeatureFlagEnhancedServicesBulkPackagesContainer from "web/enableDisableWorkflowFeatureFlag/enableDisableWorkflowFeatureFlagContainers/EnableDisableWorkflowFeatureFlagEnhancedServicesBulkPackagesContainer";
import EnableDisableWorkflowFeatureFlagControlledOverbookContainer from "web/enableDisableWorkflowFeatureFlag/enableDisableWorkflowFeatureFlagContainers/EnableDisableWorkflowFeatureFlagControlledOverbookContainer";
import EnableDisableWorkflowFeatureFlagSearchFieldMainV2Container from "../enableDisableWorkflowFeatureFlag/enableDisableWorkflowFeatureFlagContainers/EnableDisableWorkflowFeatureFlagSearchFieldMainV2Container";
import EnableDisableWorkflowFeatureFlagStandAloneBulkPackagesContainer from "../enableDisableWorkflowFeatureFlag/enableDisableWorkflowFeatureFlagContainers/EnableDisableWorkflowFeatureFlagStandAloneBulkPackagesContainer";
import EnableDisableWorkflowFeatureFlagHotelAdminContainer from "../enableDisableWorkflowFeatureFlag/enableDisableWorkflowFeatureFlagContainers/EnableDisableWorkflowFeatureFlagHotelAdminContainer";
import EnableDisableWorkflowFeatureFlagReportsContainer from "../enableDisableWorkflowFeatureFlag/enableDisableWorkflowFeatureFlagContainers/EnableDisableWorkflowFeatureFlagReportsContainer";
import EnableDisableWorkflowFeatureFlagDiscontinuedFoodsContainer from "../enableDisableWorkflowFeatureFlag/enableDisableWorkflowFeatureFlagContainers/EnableDisableWorkflowFeatureFlagDiscontinuedFoodsContainer";
import EnableDisableWorkflowFeatureFlagDiscontinuedMedsContainer from "../enableDisableWorkflowFeatureFlag/enableDisableWorkflowFeatureFlagContainers/EnableDisableWorkflowFeatureFlagDiscontinuedMedsContainer";
import EnableDisableWorkflowFeatureFlagDdcPackagesContainer from "../enableDisableWorkflowFeatureFlag/enableDisableWorkflowFeatureFlagContainers/EnableDisableWorkflowFeatureFlagDdcPackagesContainer";
import EnableDisableWorkflowFeatureFlagSalonCartMigrationContainer from "../enableDisableWorkflowFeatureFlag/enableDisableWorkflowFeatureFlagContainers/EnableDisableWorkflowFeatureFlagSalonCartMigrationContainer";
import EnableDisableWorkflowFeatureFlagUpdateRoomSharingContainer from "../enableDisableWorkflowFeatureFlag/enableDisableWorkflowFeatureFlagContainers/EnableDisableWorkflowFeatureFlagUpdateRoomSharingContainer";

const mapStateToProps = state => ({
  isHidden: getFeatureFlagVisibleState(state),
  workflowFeatureFlags: getWorkflowFeatureFlagsList(state),
  featureFlags: getFeatureFlagsList(state),
  diComp: {
    [workflowFeatureTypes.BGM]: EnableDisableWorkflowFeatureFlagBGMContainer,
    [workflowFeatureTypes.HOTEL]: EnableDisableWorkflowFeatureFlagHotelContainer,
    [workflowFeatureTypes.TRAINING]: EnableDisableWorkflowFeatureFlagTrainingContainer,
    [workflowFeatureTypes.FTCO]: EnableDisableWorkflowFeatureFlagFTCOContainer,
    [workflowFeatureTypes.MANUAL_APPLY]: EnableDisableWorkflowFeatureFlagManualApplyContainer,
    [workflowFeatureTypes.ENHANCED_SERVICES]: EnableDisableWorkflowFeatureFlagEnhancedServicesContainer,
    [workflowFeatureTypes.ENHANCED_SERVICES_BULK_PACKAGES]: EnableDisableWorkflowFeatureFlagEnhancedServicesBulkPackagesContainer,
    [workflowFeatureTypes.CAT_GROOMING]: EnableDisableWorkflowFeatureFlagCatGroomingContainer,
    [workflowFeatureTypes.STORE_EVENT_NOTIFICATIONS]: EnableDisableWorkflowFeatureFlagStoreEventNotificationsContainer,
    [workflowFeatureTypes.SEARCH_FIELD_MAIN_V2]: EnableDisableWorkflowFeatureFlagSearchFieldMainV2Container,
    [workflowFeatureTypes.CONTROLLED_OVERBOOK]: EnableDisableWorkflowFeatureFlagControlledOverbookContainer,
    [workflowFeatureTypes.STAND_ALONE_BULK_PACKAGES]: EnableDisableWorkflowFeatureFlagStandAloneBulkPackagesContainer,
    [workflowFeatureTypes.HOTEL_ADMIN]: EnableDisableWorkflowFeatureFlagHotelAdminContainer,
    [workflowFeatureTypes.REPORTS]: EnableDisableWorkflowFeatureFlagReportsContainer,
    [workflowFeatureTypes.DISCONTINUED_FOODS]: EnableDisableWorkflowFeatureFlagDiscontinuedFoodsContainer,
    [workflowFeatureTypes.DISCONTINUED_MEDS]: EnableDisableWorkflowFeatureFlagDiscontinuedMedsContainer,
    [workflowFeatureTypes.DDC_PACKAGES]: EnableDisableWorkflowFeatureFlagDdcPackagesContainer,
    [workflowFeatureTypes.SALON_CART_MIGRATION]: EnableDisableWorkflowFeatureFlagSalonCartMigrationContainer,
    [workflowFeatureTypes.UPDATE_ROOM_SHARING]: EnableDisableWorkflowFeatureFlagUpdateRoomSharingContainer,
  },
});

export default connect(mapStateToProps)(featureFlagUIWrapper);
