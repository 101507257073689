import React from "react";
import styled, { css } from "styled-components";
import { color, font } from "../../../web/common/styles/theme";

const ComponentRoot = styled.section`
  display: flex;
  flex-direction: row;
  font-family: ${font.mainFamily};
  font-size: 1.3rem;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  color: ${color.black0};

  /* Add any additional CSS (as an object literal) to customize this component */
  ${({ styleObj }) =>
    css`
      ${{ ...styleObj }}
    `};
`;

export default function GenericTitle(props) {
  const { isHidden, componentID, styleObj, title } = props;

  if (!isHidden) {
    return (
      <ComponentRoot id={componentID} styleObj={styleObj}>
        {title}
      </ComponentRoot>
    );
  }

  return null;
}
