import React, { Component } from "react";
import { history } from "@/dux/utils/browser/browserHistory";
import { Formik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";

import { font, color } from "../common/styles/theme";
import { SET_PIN_INITIAL_FORM_VALUES } from "../../core/constants/quickPin";
import { setPinValidationSchema } from "../../core/constants/validationSchema";
import { routePaths } from "../../core/constants/routePaths";

import Img from "../common/Img";
import CommonButton from "../common/commonButton";
import CloseSVG from "../../assets/icons/closeButton.svg";
import SmileySVG from "../../assets/icons/smiley.svg";
import { LoadingSpinner } from "../common/LoadingSpinner";

const StyledHeading = styled.h3`
  font-family: Montserrat;
  font-weight: 800;
  font-size: 24px;
  line-height: 1.25;
  text-align: center;
  white-space: pre-line;
  color: white;
`;

const StyledSubHeading = styled.h4`
  font-family: Lato;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  color: white;
`;

const UsersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 2.5em 0;
  background-color: #505050;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: 0 0 1em 0;
`;

const ButtonImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 4em 0;
  cursor: pointer;
`;

const ButtonImage = styled(Img)`
  border-radius: 50%;
  border: 0.25em solid #fff;
  margin: 2em 3em 1em 3em;
  height: 50px;
  width: 50px;
  ${({ isHighlighted }) =>
    isHighlighted &&
    `
        border-color: #7cffe2;
    `};
`;

const ButtonText = styled.div`
  color: ${({ isHighlighted }) => (isHighlighted ? "#7cffe2" : "#fff")};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 10% 0 10%;
  padding-bottom: 0.5em;
`;

const InputBox = styled.input`
  height: 45px;
  width: 250px;
  box-sizing: border-box;
  border: 0;
  outline: none;
  font-family: ${font.mainFamily};
  font-size: 18px;
  color: white;
  padding: 0.5em 0.7em;
  background-color: rgba(250, 250, 250, 0.2);
  box-shadow: inset 0 1px 0 0 ${color.gray400}, inset -1px 0 0 ${color.gray400},
    ${props => props.lastChild && `inset 0 -1px 0 ${color.gray400},`} inset 1px 0 0 ${color.gray400};
  &:focus {
    box-shadow: inset 0 2px 0 0 ${color.white0}, inset -2px 0 0 ${color.white0},
      inset 0 -2px 0 ${color.white0}, inset 2px 0 0 ${color.white0};
  }
  ::placeholder {
    color: white;
  }
`;

const SubmitButton = styled(CommonButton)`
  font-size: 18px;
  border-radius: 0;
  &:hover {
    background-color: ${color.blue400};
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: ${color.blue500};
  }
`;

const ErrorText = styled.div`
  color: #ff7f7f;
  text-align: center;
  margin-top: 1em;
  max-width: 250px;
`;

class ForgotPinComponent extends Component {
  componentDidMount() {
    const { redirectToForgotPin } = this.props;
    if (!redirectToForgotPin) {
      history.push(routePaths.DASHBOARD);
    }
  }

  componentWillUnmount() {
    const { clearInvalidPinError, clearRedirectToForgotPin } = this.props;
    clearInvalidPinError();
    clearRedirectToForgotPin();
  }

  render() {
    const { isLoading, clearInvalidPinError, invalidPinError, setOrUpdateUserPin } = this.props;
    return (
      <UsersContainer>
        <StyledHeading>
          {"Did you forgot your password?\nNo worries, we can help you with that..."}
        </StyledHeading>
        <StyledSubHeading>Insert your details to make a new PIN Code</StyledSubHeading>
        <Formik
          initialValues={SET_PIN_INITIAL_FORM_VALUES}
          validationSchema={setPinValidationSchema}
          onSubmit={({ pin }) => setOrUpdateUserPin({ pin })}
          render={({
            handleSubmit,
            handleChange,
            values: { pin, confirmPin },
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <InputBox
                type="password"
                placeholder="Personal PIN Code"
                name="pin"
                maxLength="6"
                onChange={e => {
                  if (invalidPinError) {
                    clearInvalidPinError();
                  }
                  handleChange(e);
                }}
                value={pin}
                firstChild
              />
              <InputBox
                type="password"
                placeholder="Confirm PIN"
                name="confirmPin"
                maxLength="6"
                onChange={handleChange}
                value={confirmPin}
                lastChild
              />
              <SubmitButton
                type="Submit"
                disabled={isLoading}
                label={isLoading ? <LoadingSpinner /> : "Submit"}
              />
              <ErrorText>
                {(touched.pin && errors.pin) ||
                  (touched.confirmPin && errors.confirmPin) ||
                  invalidPinError}
              </ErrorText>
            </Form>
          )}
        />
        <ButtonContainer>
          <ButtonImageWrapper onClick={() => history.push(routePaths.SELECT_USER)}>
            <ButtonImage src={CloseSVG} />
            <ButtonText>Cancel</ButtonText>
          </ButtonImageWrapper>
          <ButtonImageWrapper onClick={() => history.push(routePaths.SELECT_USER)}>
            <ButtonImage isHighlighted src={SmileySVG} />
            <ButtonText isHighlighted>Switch User</ButtonText>
          </ButtonImageWrapper>
        </ButtonContainer>
      </UsersContainer>
    );
  }
}

export default ForgotPinComponent;
