import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { isUndefined } from "lodash/fp";
import Section from "web/common/Section";
import Form from "web/common/Form";
import FormInput from "web/common/FormInput";
import { REQUIRED_FIELD_ERROR_MESSAGE } from "core/constants/validationErrors";
import withSaveOnClickOutside from "web/common/hoc/withSaveOnClickOutside";
import { color } from "web/common/styles/theme";

const petFavorabilityValidationSchema = Yup.object().shape({
  ratingTypeId: Yup.number().required(REQUIRED_FIELD_ERROR_MESSAGE),
  // eslint-disable-next-line consistent-return
  ratingReason: Yup.string().when("ratingTypeId", ratingTypeId => {
    if (ratingTypeId !== 2) {
      return Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE);
    }
  }),
});

const PetFavorabilityComponent = props => {
  const {
    isHidden,
    options,
    onSubmit,
    fieldsToInclude,
    handleFocus,
    formikRef,
    petFavorabilityFormData,
    clickOutsideRef,
    topLine,
    componentID,
    label,
    isDisabledForNonManagement,
  } = props;

  if (isHidden) return null;

  return (
    <Section id={componentID} title="Favorability" topLine={isUndefined(topLine) ? true : topLine}>
      <Formik
        initialValues={petFavorabilityFormData}
        onSubmit={values =>
          onSubmit({ formValues: values, initialValues: petFavorabilityFormData })
        }
        ref={formikRef}
        validationSchema={petFavorabilityValidationSchema}
        enableReinitialize
        render={({ values }) => {
          return (
            <Form
              innerRef={clickOutsideRef}
              onSubmit={onSubmit}
              onFieldFocus={handleFocus}
              fieldsToInclude={fieldsToInclude}
            >
              <FormInput
                label={label}
                name="ratingTypeId"
                type="select"
                modifier="petFavorability"
                options={options}
                hasIcon={values.ratingTypeId === 1}
                disabled={isDisabledForNonManagement}
                nonEditable={isDisabledForNonManagement}
              />
              <FormInput
                label="Rating reason"
                name="ratingReason"
                type="textarea"
                modifier="petFavorability"
                disabled={!values.ratingTypeId || isDisabledForNonManagement}
              />
            </Form>
          );
        }}
      />
    </Section>
  );
};

export default withSaveOnClickOutside(PetFavorabilityComponent);
