import styled from "styled-components";
import { downArrowUri } from "./theme";
import Select from "../Select";
import { color } from "./theme";

export const StyledCalendar = styled.div`
  padding: ${props => (props.withPadding ? "0.625rem" : "initial")};
  margin: ${props => props.margin || "initial"};
  opacity: ${props => (props.disabled ? "0.5" : "1")};
  pointer-events: ${props => (props.disabled ? "none" : "auto")};
  position: relative;
  display: flex;
  align-items: center;
`;

export const DatePickerWrapper = styled.div`
  .CalendarDay__selected {
    border-top: 2px solid #ff0000;
    border-bottom: 2px solid #ff0000;
    color: #000000;
    background: #ffffff;
  }

  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    border-top: 2px solid #ff0000;
    border-bottom: 2px solid #ff0000;
    background: #eeeeee;
    color: #ff0000;
  }

  .CalendarDay__selected_span:hover,
  .CalendarDay__selected_span:active {
    border: 1px solid #eceeee;
    color: #ff0000;
  }
  .CalendarDay__selected_span {
    border: 1px solid #eceeee;
    background: #ff0000;
    color: #ffffff;
  }
  .CalendarDay__blocked_calendar,
  .CalendarDay__blocked_calendar:active,
  .CalendarDay__blocked_calendar:hover {
    background: #cacccd;
    border: none;
    color: #82888a;
  }
  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:active,
  .CalendarDay__blocked_out_of_range:hover {
    background: #fff;
    border: 1px solid #e4e7e7;
    color: #cacccd;
  }
  .CalendarDay__selected:first-of-type,
  :not(.CalendarDay__selected_span) + .CalendarDay__selected {
    border-left: 2px solid #ff0000;
    border-top: 2px solid #ff0000;
    border-bottom: 2px solid #ff0000;
  }

  .CalendarDay__selected:last-of-type,
  :not(.CalendarDay__selected_span) + .CalendarDay__selected {
    border-right: 2px solid #ff0000;
  }
  .CalendarDay__highlighted_calendar {
    background: #ffffff;
    color: #ff0000;
    border-top: 2px solid #ff0000;
    border-bottom: 2px solid #ff0000;
    border-left: 2px solid #ff0000;
    border-right: 2px solid #ff0000;
  }
  .CalendarDay__highlighted_calendar:hover {
    background: #ffffff;
    color: #ff0000;
    border-top: 2px solid #ff0000;
    border-bottom: 2px solid #ff0000;
  }
  .DayPicker_transitionContainer {
    position: relative;
    overflow: hidden;
    border-radius: 3px;
    border: 1px solid #eeeeee;
  }
  .DayPickerNavigation__horizontal {
    height: auto;
    display: flex;
    justify-content: space-between;
  }
  .CalendarMonth_caption {
    padding-top: 18px;
    font-size: 1em;
  }
  .DayPickerNavigation_button__horizontalDefault {
    padding: 9px;
  }
  .DayPicker_weekHeader_1 {
    display: none;
  }
  .DayPicker_weekHeader {
    z-index: auto;
  }
  .DayPicker_weekHeader_li {
    width: 39px;
  }

  .SingleDatePicker {
    //transform: translateY(-50%);
  }

  ${({ hideInput }) =>
    hideInput &&
    `
      .SingleDatePicker_picker {
        transform: translateY(-60px);
      }
      
      display: none;
  `} .DateInput_input__focused {
    border-bottom: 2px solid ${color.blue500};
    box-sizing: border-box;
  }

  margin: 0 5px;
`;

export const RightSideArrow = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  border: none;
  padding: 0;

  &:after {
    padding-left: 5px;
    content: url(${downArrowUri});
  }
`;

export const ScheduleText = styled.div`
  margin-right: 10px;
`;

export const StyledSelect = styled(Select)`
  flex: 1;
`;

export const MonthYearDropdowns = styled.div`
  display: flex;
  justify-content: space-between;

  > ${StyledSelect}:not(:last-child) {
    margin-right: 10px;
  }
`;

export const LabelWrapper = styled.div`
  color: ${({ error }) => (error ? color.red700 : "inherit")}
  cursor: pointer;
  margin: 0 10px 0 0;
  padding: 12px 2px;
  font-size: ${({ labelFontSize }) => labelFontSize || "inherit"};
  font-weight: ${({ labelFontWeight }) => labelFontWeight || "inherit"};
  align-items: center;
  display: flex;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-right: 5px;
`;
