import { toggleAccordionActionTypes } from "./toggleAccordionActions";

const initialState = {
  showStandaloneList: true,
  showBulkPackageOfferings: true,
  showHotelMergeRooms: false,
  showHotelUpdateServiceOpen: false,
};

const toggleAccordionReducer = (state = initialState, action) => {
  switch (action.type) {
    case toggleAccordionActionTypes.SHOW_STANDALONE_LIST:
      return {
        ...state,
        showStandaloneList: !action.showStandaloneList,
      };
    case toggleAccordionActionTypes.SHOW_BULK_PACKAGE_OFFERINGS:
      return {
        ...state,
        showBulkPackageOfferings: !action.showBulkPackageOfferings,
      };
    case toggleAccordionActionTypes.TOGGLE_HOTEL_MERGE_ROOMS:
      return {
        ...state,
        showHotelMergeRooms: action?.showHotelMergeRooms,
        showHotelUpdateServiceOpen: action?.showHotelMergeRooms
          ? false
          : state?.showHotelUpdateServiceOpen,
      };
    case toggleAccordionActionTypes.TOGGLE_HOTEL_UPDATE_SERVICE:
      return {
        ...state,
        showHotelUpdateServiceOpen: action?.showHotelUpdateServiceOpen,
        showHotelMergeRooms: action?.showHotelUpdateServiceOpen
          ? false
          : state?.showHotelMergeRooms,
      };
    default:
      return state;
  }
};

export default toggleAccordionReducer;
