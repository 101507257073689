import React from "react";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { bundlePackagesDetailsTypes } from "dux/bgm/availableBundlesByPet/availableBundlesList/bundlePackageDetailsConstents";
import { formatUTCDate } from "@/core/utils/dateUtils/formatDateTime";
import { headerComponents } from "@/dux/_constants/headerComponents";
import { ListItem } from "../../../_constants";

function BundleDatesListComponent({
  isHidden,
  componentId,
  customerId,
  bundleNumber,
  headerType,
  idEnum,
  dates,
  isListHidden,
  type,
  onClick,
  setCustomerKeyAndItineraryId,
  offerType,
}) {
  if (isHidden) return null;
  const Header = headerComponents[headerType];
  return (
    <LayoutStack id={componentId} space="scale-S3" style={{ alignItems: "flex-start" }}>
      <Header bundleNumber={bundleNumber} idEnum={idEnum} />
      <LayoutBox padding="scale-0">
        <LayoutStack>
          {!isListHidden &&
            dates.map(({ redemptionDate, appointmentDate, itineraryId }) => {
              const itemDate =
                type === bundlePackagesDetailsTypes.REDEEMED ? redemptionDate : appointmentDate;
              return (
                <ListItem
                  key={`${itemDate}-${itineraryId}`}
                  onClick={() =>
                    onClick(
                      itineraryId,
                      itemDate,
                      customerId,
                      offerType,
                      setCustomerKeyAndItineraryId,
                    )
                  }
                >
                  {formatUTCDate(itemDate)}
                </ListItem>
              );
            })}
        </LayoutStack>
      </LayoutBox>
    </LayoutStack>
  );
}

export default BundleDatesListComponent;
