import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { color, font } from "web/common/styles/theme";
import { Layout } from "@prism/psm-ui-components";
import { device } from "web/common/styles/responsive/devices";

const SearchInput = styled.input`
  flex-grow: 2;
  height: 38px;
  box-sizing: border-box;
  font-family: ${font.mainFamily};
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  color: ${color.gray400};
  padding: 9px 12px 9px 12px;
  outline: none;
  border: none;
`;

const SearchIcon = styled.a`
  display: block;
  width: 40px;
  height: 38px;
  position: relative;
  cursor: pointer;
  background: #fff;
  align-items: stretch;
`;

const Circle = styled.span`
  box-sizing: revert;
  position: relative;
  display: block;
  height: 12px;
  width: 12px;
  top: 8px;
  left: 9px;
  right: 7px;
  border-radius: 70px;
  border: 3px solid ${props => (props.isFocused ? "#2684ff" : "#000")};
`;

const Handle = styled.span`
  box-sizing: revert;
  position: absolute;
  background-color: ${props => (props.isFocused ? "#2684ff" : "#000")};
  top: 25px;
  left: 22px;
  width: 8px;
  height: 3px;
  display: block;
  transform: rotate(45deg);
  float: right;
  &:after {
    content: "";
    width: 90px;
    height: 10px;
    display: block;
    float: right;
    z-index: -1;
  }
`;

const CloseIcon = styled.span`
  position: relative;
  display: inline-block;
  width: 20px;
  height: 25px;
  &::before,
  &::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    top: 75%;
    left: 0;
    background: #000;
    background: ${props => (props.isFocused ? "#2684ff" : "#000")};
    height: 2px;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;

const CloseIconWrapper = styled.a`
  display: block;
  width: 30px;
  height: 38px;
  position: relative;
  cursor: pointer;
  background: #fff;
  align-items: stretch;
`;
const MediaQueryWrapper = styled.div`
  display: flex;
  @media ${device.mobileS} {
    width: 275px;
  }

  @media ${device.prismDesktop} {
    width: 635px;
  }
`;

class SearchInputWrapper extends Component {
  render() {
    const {
      inputRef,
      handleSearch,
      searchApplied,
      clearSearchApplied,
      isFocused,
      styles,
    } = this.props;
    return (
      <MediaQueryWrapper className="SearchInputWrapper" style={styles}>
        <SearchInput innerRef={inputRef} {...this.props} />

        {searchApplied ? (
          <CloseIconWrapper onClick={clearSearchApplied}>
            <CloseIcon isFocused={isFocused} />
          </CloseIconWrapper>
        ) : (
          <SearchIcon onClick={handleSearch}>
            <Fragment>
              {/* NOTE: when <Layout.[Component]> is applied to parent items, ti sets teh box-sizing to boarder box, this is*/}
              {/*reverting it so it is not applied to these objects.*/}
              <Circle id={"searchCircle"} isFocused={isFocused} style={{ boxSizing: "revert" }} />
              <Handle isFocused={isFocused} style={{ boxSizing: "revert" }} />
            </Fragment>
          </SearchIcon>
        )}
      </MediaQueryWrapper>
    );
  }
}

export default SearchInputWrapper;
