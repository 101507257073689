import React from "react";
import styled from "styled-components";
import { Formik } from "formik";
import * as Yup from "yup";
import { color } from "../../../../common/styles/theme";
import { phoneSchema } from "../../../../../core/constants/validationSchema";
import CommonButton from "../../../../common/commonButton";
import ClickableText from "../../../../common/ClickableText";
import Form from "../../../../common/Form";
import FormInput from "../../../../common/FormInput";
import withSaveOnClickOutside from "../../../../common/hoc/withSaveOnClickOutside";
import formatPhoneInputField from "../../../../../core/utils/stringManipulationUtils/formatPhoneInputField";

const PetFormWrapper = styled.div`
  margin-bottom: 3em;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SubmitButton = styled(CommonButton)`
  padding: 0.75em 3em;
`;

const petVetsFormValidation = Yup.object().shape({
  veterinarianName: Yup.string().test(
    "Has clinic or vet",
    "Clinic or vet is required",
    function validate(value) {
      const { clinicName } = this.parent;
      return !!(value || clinicName);
    },
  ),
  phoneNumber: phoneSchema.required("Phone is required"),
  clinicName: Yup.string().test("Has clinic or vet", "Clinic or vet is required", function validate(
    value,
  ) {
    const { veterinarianName } = this.parent;
    return !!(value || veterinarianName);
  }),
});

const PetVetsForm = ({
  goBack,
  onSubmit,
  isLoading,
  fieldsToInclude,
  handleFocus,
  formikRef,
  clickOutsideRef,
}) => (
  <Formik
    ref={formikRef}
    validationSchema={petVetsFormValidation}
    enableReinitialize
    render={({ values, isValid }) => (
      <React.Fragment>
        <PetFormWrapper>
          <Form
            innerRef={clickOutsideRef}
            onSubmit={onSubmit}
            onFieldFocus={handleFocus}
            fieldsToInclude={fieldsToInclude}
            disabled={isLoading}
          >
            <FormInput label="*Vet Name" name="veterinarianName" />
            <FormInput
              label="*Phone Number"
              name="phoneNumber"
              value={formatPhoneInputField(values.phoneNumber)}
            />
            <FormInput label="*Clinic" name="clinicName" />
          </Form>
        </PetFormWrapper>
        <Footer>
          <ClickableText isVisible styleObj={{ color: color.blue500 }} onClick={goBack}>
            Back to search
          </ClickableText>
          <SubmitButton
            label="Add vet profile"
            disabled={!isValid}
            onClick={() => onSubmit({ formValues: values })}
          />
        </Footer>
      </React.Fragment>
    )}
  />
);

export default withSaveOnClickOutside(PetVetsForm);
