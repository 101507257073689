import React, { useState } from "react";
import { connect } from "react-redux";
import { Text, Button } from "@prism/psm-ui-components";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { slice, sortBy } from "lodash/fp";
import moment from "moment";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { selectCustomerNotes } from "dux/notes/customerNotesSelector";
import { createLoadingSelector } from "core/selectors/utils";
import customersActionTypes from "core/actionTypes/customersActionTypes";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import AddButton from "web/common/buttons/addButton";
import { getCurrentPet, getLoggedInUser } from "core/selectors/persistentSelectors";
import TextArea from "web/common/TextArea";
import {
  addSalonCustomerNote,
  addGroomingNote,
  addHotelCustomerNote,
  addHotelPetNotes,
  ADD_GROOMING_NOTE,
} from "core/actionCreators/notesActionCreator";
import { selectGroomingNotes } from "core/selectors/ui/notes/groomingNotesentitiesSelector";
import { getSelectedPet } from "core/selectors/bookingUISelectors";
import { selectHotelNotes } from "core/selectors/ui/notes/hotelNotesEntitiesSelector";
import { getHotelCustomerNotes } from "dux/notes/HotelCustomerNotesSelector";
import { TextWithCharacterCountComponent } from "../_components/textWithCharacterCount/TextWithCharacterCountComponent";
import { color } from "web/common/styles/theme";
import { Heading, Hr } from "@petsmart-ui/sparky";

export const NotesV2 = props => {
  const { componentId, isHidden, notes, showAllNotesLabel, title, userInitials, addNote } = props;

  const [isShowingAllNotes, showIsShowingAllNotes] = useState(false);
  const [isNewNoteFormVisible, showIsNewNoteFormVisible] = useState(false);
  const [noteContent, setNoteContent] = useState("");

  const sortedNotes = sortBy(note => moment(note.timeStamp), notes).reverse();
  const notesToShow = isShowingAllNotes ? sortedNotes : slice(0, 3, sortedNotes);
  const showAllString = isShowingAllNotes ? "Show only last notes" : showAllNotesLabel;

  const iconStyles = {
    width: "100%",
    maxWidth: "30px",
    height: "30px",
    minWidth: "30px",
    fontSize: "12px",
    textAlign: "center",
    lineHeight: "20px",
    padding: "5px",
    color: "#fff",
    borderRadius: "30px",
    backgroundColor: "#000",
    boxSizing: "borderBox",
  };

  if (isHidden) {
    return null;
  }

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <Hr style={{ width: "100%", margin: "1rem 0" }} />
      <LayoutStack space="stack-space-8">
        {/* // fix this */}
        <LayoutBox padding="scale-0">
          <LayoutCluster style={{ justifyContent: "space-between" }}>
            {/* --- NOTES TITLE --- */}
            {title && (
              <Heading id={`${componentId}__title`} size="headline" tagName="h2">
                {title}
              </Heading>
            )}
            {/* --- Add New Note Button and icon ---*/}
            {!isNewNoteFormVisible && (
              <AddButton
                id={`${componentId}__addButton`}
                opacity="1"
                label="New Note"
                fontSize="1em"
                paddingTop="5px"
                buttonClickHandler={() => showIsNewNoteFormVisible(!isNewNoteFormVisible)}
              />
            )}
          </LayoutCluster>
        </LayoutBox>

        {!isNewNoteFormVisible && sortedNotes.length > 3 && (
          <LayoutBox padding="scale-0">
            {/* ---"Show all notes", "Show only last notes" ---*/}
            <Button
              id={`${componentId}__showAllNotesButton`}
              variant="link"
              size="xl"
              onClick={() => showIsShowingAllNotes(!isShowingAllNotes)}
            >
              {showAllString}
            </Button>
          </LayoutBox>
        )}

        {/* --- NOTES STACK --- */}
        {notesToShow && notesToShow.length > 0 && (
          <LayoutStack id={`${componentId}__notesList`}>
            {notesToShow.map((note, index) => (
              <LayoutBox
                key={index}
                borderWidth="scale-S6"
                borderColor="gray600"
                padding="scale-G1"
                style={{ backgroundColor: "rgba(219, 228, 240, 0.22)" }}
              >
                <LayoutCluster style={{ alignItems: "start", flexWrap: "nowrap" }}>
                  <LayoutBox style={iconStyles}>{note.associateInitials}</LayoutBox>
                  <LayoutStack>
                    <Text
                      id={`${componentId}__name`}
                      size="text-size-lg"
                      color="text-color-black"
                      bold={900}
                    >
                      {note.associateDisplayName}:{" "}
                    </Text>
                    <Text id={`${componentId}__copy`} size="text-size-md" color="text-color-black">
                      {note.content}
                    </Text>
                    <Text
                      id={`${componentId}__time`}
                      size="text-size-lg"
                      color="text-color-disabled"
                    >
                      {note.formatedTime}
                    </Text>
                  </LayoutStack>
                </LayoutCluster>
              </LayoutBox>
            ))}
          </LayoutStack>
        )}
      </LayoutStack>

      {!isNewNoteFormVisible && isShowingAllNotes && (
        <LayoutBox padding="scale-0">
          {/* ---"Show all notes", "Show only last notes" ---*/}
          <Button
            id={`${componentId}__showAllNotesButtonBottom`}
            variant="link"
            size="xl"
            onClick={() => showIsShowingAllNotes(!isShowingAllNotes)}
          >
            {showAllString}
          </Button>
        </LayoutBox>
      )}

      {/*ADD NOTE FORM */}
      {isNewNoteFormVisible && (
        <LayoutBox
          id={`${componentId}__noteForm`}
          borderWidth="scale-S4"
          borderColor="crayolaBlue"
          style={{ marginTop: "1rem" }}
        >
          <LayoutStack>
            {/* ---ICON and TEXTAREA ---*/}
            <LayoutCluster justify="left" space="scale-S2">
              {/* --- USERS INITIALS ---*/}
              <LayoutBox style={iconStyles}>{userInitials}</LayoutBox>
              {/* --- NOTES INPUT TEXT AREA ---*/}
              <LayoutBox style={{ flex: 1 }}>
                <TextWithCharacterCountComponent maxLength={255} value={noteContent}>
                  <TextArea
                    id={`${componentId}__noteInput`}
                    maxLength="255"
                    value={noteContent}
                    rows="3"
                    onChange={e => setNoteContent(e.target.value)}
                  />
                </TextWithCharacterCountComponent>
              </LayoutBox>
            </LayoutCluster>

            {/* ---CANCELS AND SAVE BUTTONS ---*/}
            <LayoutBox id={`${componentId}__cancelSaveButtons`}>
              <LayoutCluster style={{ justifyContent: "flex-end" }}>
                <Button
                  id={`${componentId}__cancelButton`}
                  variant="link"
                  size="xl"
                  onClick={() => {
                    showIsNewNoteFormVisible(!isNewNoteFormVisible);
                    setNoteContent("");
                  }}
                  style={{
                    color: color.red700,
                  }}
                >
                  Cancel
                </Button>
                <Button
                  id={`${componentId}__saveButton`}
                  variant="primary"
                  onClick={() => {
                    addNote(noteContent);
                    showIsNewNoteFormVisible(!isNewNoteFormVisible);
                    setNoteContent("");
                  }}
                >
                  Save
                </Button>
              </LayoutCluster>
            </LayoutBox>
          </LayoutStack>
        </LayoutBox>
      )}
    </LayoutBox>
  );
};

// SALON CUSTOMER NOTES CONTAINER ----------------------------------------------------------------------------------------
export const SalonCustomerNotesV2 = connect(
  state => {
    const customerKey = getCurrentCustomerKey(state);
    const user = getLoggedInUser(state);

    //all the items to determine if notes are loading here
    return {
      componentId: "SalonCustomerNotes",
      notes: selectCustomerNotes(state, { customerKey }),
      title: "Salon Customer Notes",
      newNote: "New Note",
      showAllNotesLabel: "Show all salon customer notes",
      isLoading: createLoadingSelector([customersActionTypes.LOAD_CUSTOMER])(state),
      systemName: systemName.SALON,
      userInitials: user?.initials,
      customerKey,
    };
  },
  dispatch => {
    return {
      handleAddSalonCustomerNote: ({ content, customerKey }) => {
        // TODO: Refactor/rename this action to be addSalonCustomerNote - and move file to this directory
        dispatch(addSalonCustomerNote({ customerKey, content }));
      },
    };
  },
  (mapProps, dispatchProps) => {
    const {
      componentId,
      isHidden,
      notes,
      showAllNotesLabel,
      title,
      userInitials,
      customerKey,
    } = mapProps;

    const { handleAddSalonCustomerNote } = dispatchProps;
    return {
      // control what props get passed to the view
      componentId,
      isHidden,
      notes,
      showAllNotesLabel,
      title,
      userInitials,

      // actions to pass to view
      addNote: content => handleAddSalonCustomerNote({ content, customerKey }),
    };
  },
)(NotesV2);

// HOTEL CUSTOMER NOTES CONTAINER ----------------------------------------------------------------------------------------
export const HotelCustomerNotesV2 = connect(
  state => {
    const customerKey = getCurrentCustomerKey(state);
    const user = getLoggedInUser(state);

    //all the items to determine if notes are loading here

    return {
      componentId: "HotelCustomerNotes",
      notes: getHotelCustomerNotes(state, { customerKey }),
      title: "PetsHotel Customer Notes",
      newNote: "New Note",
      showAllNotesLabel: "Show all hotel customer notes",
      isLoading: createLoadingSelector([customersActionTypes.LOAD_CUSTOMER])(state), //TODO: update to use Salon customer notes
      systemName: systemName.HOTEL,
      userInitials: user?.initials,

      customerKey,
    };
  },
  dispatch => {
    return {
      handleAddHotelCustomerNote: ({ content, customerKey }) => {
        dispatch(addHotelCustomerNote({ customerKey, content }));
      },
    };
  },
  (mapProps, dispatchProps) => {
    const {
      componentId,
      isHidden,
      notes,
      showAllNotesLabel,
      title,
      userInitials,
      customerKey,
    } = mapProps;

    const { handleAddHotelCustomerNote } = dispatchProps;
    return {
      // control what props get passed to the view
      componentId,
      isHidden,
      notes,
      showAllNotesLabel,
      title,
      userInitials,

      // actions to pass to view
      addNote: content => handleAddHotelCustomerNote({ content, customerKey }),
    };
  },
)(NotesV2);

// SALON GROOMING NOTES CONTAINER ----------------------------------------------------------------------------------------
export const SalonGroomingNotesV2 = connect(
  state => {
    const customerKey = getCurrentCustomerKey(state);
    const user = getLoggedInUser(state);
    const petId = getSelectedPet(state);

    //all the items to determine if notes are loading here

    return {
      componentId: "SalonGroomingNotes",
      notes: petId && selectGroomingNotes(state, { petId }),
      title: "Salon Grooming Notes",
      newNote: "New Note",
      showAllNotesLabel: "Show all salon grooming notes",
      isLoading: createLoadingSelector([ADD_GROOMING_NOTE])(state),
      systemName: systemName.SALON,
      userInitials: user?.initials,

      customerKey,
      petId,
    };
  },
  dispatch => {
    return {
      handleAddSalonGroomingNote: ({ content, customerKey, petId }) => {
        dispatch(addGroomingNote({ content, customerKey, petId }));
      },
    };
  },
  (mapProps, dispatchProps) => {
    const {
      componentId,
      isHidden,
      notes,
      showAllNotesLabel,
      title,
      userInitials,
      customerKey,
      petId,
    } = mapProps;

    const { handleAddSalonGroomingNote } = dispatchProps;
    return {
      // control what props get passed to the view
      componentId,
      isHidden,
      notes,
      showAllNotesLabel,
      title,
      userInitials,

      // actions to pass to view
      addNote: content => handleAddSalonGroomingNote({ content, customerKey, petId }),
    };
  },
)(NotesV2);

// HOTEL PET NOTES CONTAINER ----------------------------------------------------------------------------------------
export const HotelPetNotesV2 = connect(
  state => {
    const customerKey = getCurrentCustomerKey(state);
    const user = getLoggedInUser(state);
    const petId = getCurrentPet(state); //getSelectedPet(state);

    //all the items to determine if notes are loading here

    return {
      componentId: "HotelPetNotes",
      notes: petId && selectHotelNotes(state, { petId }), //selectCustomerNotes(state, { customerKey }), //TODO: set this to get hotel pet notes
      title: "Hotel Pet Notes",
      newNote: "New Note",
      showAllNotesLabel: "Show all hotel pet notes",
      isLoading: createLoadingSelector([customersActionTypes.LOAD_CUSTOMER])(state), //TODO: update to use hotel customer notes
      systemName: systemName.HOTEL,
      userInitials: user?.initials,

      customerKey,
      petId,
    };
  },
  dispatch => {
    return {
      handleAddHotelPetNote: ({ content, customerKey, petId }) => {
        dispatch(addHotelPetNotes({ customerKey, petId, content }));
      },
    };
  },
  (mapProps, dispatchProps) => {
    const {
      componentId,
      isHidden,
      notes,
      showAllNotesLabel,
      title,
      userInitials,
      customerKey,
      petId,
    } = mapProps;

    const { handleAddHotelPetNote } = dispatchProps;
    return {
      // control what props get passed to the view
      componentId,
      isHidden,
      notes,
      showAllNotesLabel,
      title,
      userInitials,

      // actions to pass to view
      addNote: content => handleAddHotelPetNote({ content, customerKey, petId }),
    };
  },
)(NotesV2);
