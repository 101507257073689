import { createSelector } from "reselect";

export const getAccordionViews = state => state?.accordionViews;

export const getIsBulkPackageOfferingsAccordionOpen = createSelector(
  getAccordionViews,
  accordionViews => accordionViews.showBulkPackageOfferings,
);

/**
 * Selector to get whether hotel merge rooms section is open or closed
 * @params {Object} state
 * @returns Boolean
 */
export const selectIsHotelMergeRoomsOpen = createSelector(
  getAccordionViews,
  accordionViews => !!accordionViews?.showHotelMergeRooms,
);

/**
 * Selector to get whether hotel update service dropdown section is open or closed
 * @params {Object} state
 * @returns Boolean
 */
export const selectIsHotelUpdateServiceOpen = createSelector(
  getAccordionViews,
  accordionViews => !!accordionViews?.showHotelUpdateServiceOpen,
);
