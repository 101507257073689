/** ----------------------------------------------------------------------- **\
    How to effectively use this component

    Component can have several configurations,
    In the parent component just pass the config type you want, e.g.
        config='DASHBOARD'

    CONFIG TYPES:
    * APPOINTMENT - has the following:
        - background iage
        - back button
        - HeaderTitle
          - Dashboard Link
        - None, One or Two  buttons {set via children}
        - Login/user

    * DASHBOARD  - has the following:
        - Background
        - Logo
        - Search [Just to the right of logo]
        - None, One or Two  buttons {set via children}
        - Login/user
        - Show Store bar

    * SRC - has the following:
        - No Background
        - Logo
        - Login/user
        - Border

    * LOGIN - has the following:
        - No Background
        - Logo
        - no border

    NOTE: YOU must still pass the image as a separate prop.
\** ----------------------------------------------------------------------- * */

// IMPORTS
import React from "react";
import styled from "styled-components";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import FeatureFlagPersistentCookieToggleContainer from "dux/featureFlagPersistentCookie/FeatureFlagPersistentCookieToggleContainer";
import GridArea from "../common/GridArea";
import { color } from "../common/styles/theme";
import Logo from "../common/Logo";
import ProfileButtonContainer from "../login/profileButton/profileButtonContainer";
import SearchFieldWrapperContainer from "../searchSalonSRC/searchFieldWrapper/searchFieldWrapperContainer";
import HeaderTitle from "../common/appHeader/HeaderTitle";
import { headerConfigConstants } from "../../core/constants/headerConfigConstants";
import OpenFeatureFlagWindowContainer from "../toggleFeatureFlagWindow/OpenFeatureFlagWindowContainer";
import FeatureFlagIconWrapper from "../common/featureFlagging/FeatureFlagIconWrapper";
import EnvironmentNotificationHeaderContainer from "../environmentNotificaion/EnvironmentNotificationHeaderContainer";
import QuickViewRebookContainer from "dux/quickViews/QuickViewRebookContainer";
import {
  CANCELLED_APPOINTMENT_UNAVAILABLE,
  CAN_NOT_REBOOK_PET_INACTIVE,
} from "dux/quickViews/QuickViewConstants";
import { Layout } from "@prism/psm-ui-components";
import PetAppointmentQuickViewContainer from "@/dux/quickViews/PetAppointmentQuickViewContainer";
import { SRC_StoreInfoBanner } from "dux/storeInfoBanner/StoreInfoBanner";
/** ----------------------------------------------------------------------- **\
    STYLED COMPONENTS
\** ----------------------------------------------------------------------- * */
const Header = styled.header`
  grid-area: ${props => props.area || "auto"};
`;

const StyledAppHeader = styled(GridArea)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* justify-content:space-between; */
  /* justify-content: space-between; */
  /* align-items: center; */
  background: ${props =>
    props.config == headerConfigConstants.SRC || props.config == headerConfigConstants.LOGIN
      ? `none`
      : `url(${props.backgroundImage})`};
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 8.125rem;
  font-family: Montserrat;
  border: ${props =>
    props.config == headerConfigConstants.SRC ? `0.0625rem solid ${color.gray300}` : `none`};
  padding: 0.625rem;
  & > * {
    margin: 0 0.5rem;
  }
`;

const Back = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  font: inherit;
  justify-self: flex-start;
  width: 50px;
  height: 50px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.23);
  margin-right: 25px;
`;

const BackArrow = styled.i`
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg);
`;

const SearchComponentWrapper = styled.div`
  width: 854px;
`;

const StoreSection = styled.div`
  margin: initial;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const BreadCumbsBackButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 25px;

  button {
    margin-right: 20px;
  }
`;

/** ----------------------------------------------------------------------- **\
    PARTIAL COMPONENTS
\** ----------------------------------------------------------------------- * */
const HeaderCols = (leftChildren, rightChildren) => (
  <Layout.Cluster justify="space-between" style={{ overflow: "initial" }}>
    <Layout.Box className="AppHeader__leftSection">{leftChildren}</Layout.Box>

    <Layout.Box className="AppHeader__rightSection">{rightChildren}</Layout.Box>
  </Layout.Cluster>
);

const BackPartial = (navigate, title, jobRole) => (
  <BreadCumbsBackButtonWrapper className="AppHeader__breadCrumbsBackButtonWrapper">
    <Back
      className="AppHeader__back"
      onClick={() => {
        navigate(-1);
      }}
    >
      <BackArrow />
    </Back>
    <HeaderTitle title={title} jobRole={jobRole} />
  </BreadCumbsBackButtonWrapper>
);

const LoginPartial = children => (
  <Layout.Cluster>
    {children ? <Buttons className="AppHeader__buttons">{children}</Buttons> : null}
    <ProfileButtonContainer className="AppHeader__profileButtonContainer" />
  </Layout.Cluster>
);

const SearchPartial = () => (
  <Layout.Box className="AppHeader__searchComponentWrapper">
    <SearchFieldWrapperContainer className="AppHeader__searchFieldWrapperContainer" />
  </Layout.Box>
);

const LogoPartial = children => (
  <Layout.Cluster
    space="cluster-space-20"
    className="AppHeader__logoSearchWrapper"
    style={{ overflow: "initial" }}
  >
    <Logo className="AppHeader__logo" />
    {children ? <div>{children}</div> : null}
  </Layout.Cluster>
);

/** ----------------------------------------------------------------------- **\
    CONFIG SWITCH COMPONENT
\** ----------------------------------------------------------------------- * */
const renderSwitch = ({ title, navigate, config, children, jobRole }) => {
  switch (config) {
    case headerConfigConstants.APPOINTMENT:
    case headerConfigConstants.ASSOCIATE:
      return HeaderCols(BackPartial(navigate, title, jobRole), LoginPartial(children));

    case headerConfigConstants.DASHBOARD:
      return <>{HeaderCols(LogoPartial(SearchPartial()), LoginPartial(children))}</>;

    case headerConfigConstants.SRC:
      return HeaderCols(LogoPartial(), LoginPartial(children));

    case headerConfigConstants.LOGIN:
      return HeaderCols(LogoPartial());
    default:
      break;
  }
};

/** ----------------------------------------------------------------------- **\
    VIEW COMPONENT
\** ----------------------------------------------------------------------- * */
const AppHeader = ({
  title,
  router: { navigate },
  gridArea,
  children,
  backgroundImage,
  config,
  jobRole,
  componentID,
  isHidden,
}) => {
  if (!isHidden) {
    return (
      <Header id={componentID} className="AppHeader__header" area={gridArea}>
        <SRC_StoreInfoBanner />

        <StyledAppHeader
          className="AppHeader__styledAppHeader"
          backgroundImage={backgroundImage}
          config={config}
        >
          {renderSwitch({ title, navigate, config, children, jobRole })}
        </StyledAppHeader>
        {/* <EnvironmentWarning styleObj={{ top: "130px" }} /> */}
        <EnvironmentNotificationHeaderContainer />

        {/*This is a tooltip or popover to show if a rebook button/text link is disabled due
        to pet being inactive*/}
        <QuickViewRebookContainer>{CAN_NOT_REBOOK_PET_INACTIVE}</QuickViewRebookContainer>
        <PetAppointmentQuickViewContainer>
          {CANCELLED_APPOINTMENT_UNAVAILABLE}
        </PetAppointmentQuickViewContainer>

        <FeatureFlagIconWrapper componentID="AppHeader-FeatureFlagIconWrapper">
          <OpenFeatureFlagWindowContainer />
          <FeatureFlagPersistentCookieToggleContainer />
        </FeatureFlagIconWrapper>
      </Header>
    );
  }
  return null;
};

export default withRouteProps(AppHeader);
