import { connect } from "react-redux";

import { getAppointmentPriceSalon } from "dux/appointmentPrice/appointmentPriceSelectors";
import { color } from "web/common/styles/theme";
import { getSelectedPet } from "core/selectors/bookingUISelectors";
import Text from "../../../_components/genericLayoutComponents/TextComponent";

const mapStateToProps = state => {
  const petId = getSelectedPet(state);
  const price = getAppointmentPriceSalon(state, { petId });
  return {
    componentID: "bundle-discount-price__Text",
    text: `-$${price}`,
    styleType: "div",
    styleObj: { color: color.gray600 },
    isHidden: false,
  };
};

export default connect(mapStateToProps)(Text);
