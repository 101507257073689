import { connect } from "react-redux";
import { medicationTypes } from "web/newMedication/newMedicationConstants";
import { getFoodIds } from "../food/foodsSelector";
import { getFoodIsShowingDetailsForType } from "../foodList/foodListSelectors";
import { NEW_FOOD_ID } from "./newFoodConstants";
import PetProfileFoodFormComponent from "../common/PetProfileFeedingFormComponent";

const mapStateToProps = (state, ownProps) => {
  const { petId } = ownProps;
  const foodId = NEW_FOOD_ID;
  const locationType = medicationTypes.PROFILE;
  return {
    componentId: `PetProfileFoodForm-${foodId}`,
    foodId,
    petId,
    foodIds: getFoodIds(state, { petId }),
    isShowingDetails: getFoodIsShowingDetailsForType(state, { foodId, petId, locationType }),
    isHidden: !getFoodIsShowingDetailsForType(state, { foodId, petId, locationType }),
  };
};

export default connect(mapStateToProps)(PetProfileFoodFormComponent);
