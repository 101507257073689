export const BGM = "BGM";
export const HOTEL = "HOTEL";
export const TRAINING = "TRAINING";
export const FTCO = "FTCO";
export const MANUAL_APPLY = "MANUAL APPLY";
export const ENHANCED_SERVICES = "ENHANCED SERVICES";
export const ENHANCED_SERVICES_BULK_PACKAGES = "ENHANCED SERVICES BULK PACKAGES";
export const CAT_GROOMING = "CAT GROOMING";
export const STORE_EVENT_NOTIFICATIONS = "STORE_EVENT_NOTIFICATIONS";
export const SEARCH_FIELD_MAIN_V2 = "SEARCH_FIELD_MAIN_V2";
export const CONTROLLED_OVERBOOK = "CONTROLLED_OVERBOOK";
export const STAND_ALONE_BULK_PACKAGES = "STAND_ALONE_BULK_PACKAGES";
export const HOTEL_ADMIN = "HOTEL_ADMIN";
export const REPORTS = "REPORTS";
export const DISCONTINUED_FOODS = "DISCONTINUED_FOODS";
export const DISCONTINUED_MEDS = "DISCONTINUED_MEDS";
export const DDC_PACKAGES = "DDC_PACKAGES";
export const SALON_CART_MIGRATION = "SALON_CART_MIGRATION";
export const UPDATE_ROOM_SHARING = "UPDATE_ROOM_SHARING";
