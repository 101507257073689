import React from "react";
import { connect } from "react-redux";
import {
  getIsPendingFrequencyDateChecked,
  getPendingFrequencyFoodDates,
  getPendingFrequencyMedicationDates,
  getPendingFrequency,
  getPendingFrequencyAddonDates,
} from "dux/frequency/frequencySelectors";
import {
  getCheckForSameDay,
  selectDateRangeForFrequency,
} from "@/dux/frequency/dateRangeForFrequencySelectors";
import {
  setCachedFrequency,
  setPendingFrequency,
  setPendingFrequencyAddonDates,
  setPendingFrequencyFoodDates,
  setPendingFrequencyMedicationDates,
} from "dux/frequency/actions/frequencyActions";
import { frequencyConstants, frequencyTypes } from "dux/frequency/FrequencyConstants";
import { formatDayNameMonthDay } from "@/core/utils/dateUtils/formatDateTime";
import { Checkbox, Label } from "@petsmart-ui/sparky";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { color } from "@/web/common/styles/theme";
import { LayoutBox } from "@/layout/box/Box";

export const CheckboxWrapper = ({ componentId, label, checked, disabled, onChange }) => {
  const id = `${componentId}_checkbox`;
  return (
    <LayoutBox id={componentId}>
      <LayoutCluster flexWrap="flex-noWrap">
        <Checkbox id={id} checked={checked} onChange={onChange} disabled={disabled} />
        <Label
          style={disabled ? { color: color.gray600 } : {}}
          for={id}
          text={label}
          bold={false}
        />
      </LayoutCluster>
    </LayoutBox>
  );
};

// FREQUENCY FOOD DATE SELECTION CONTAINER ----------------------------------------------------------------------------------------
export const FrequencyFoodDateSelection = connect(
  (state, ownProps) => {
    const { date } = ownProps;

    return {
      componentId: `FrequencyFoodDateSelection_${date}`,
      date,
      label: formatDayNameMonthDay(date),
      checked: getIsPendingFrequencyDateChecked(state, { date }),
      disabled: getCheckForSameDay(state),
      currentPendingFrequency: getPendingFrequency(state),
      medicationTimes: getPendingFrequencyFoodDates(state) || selectDateRangeForFrequency(state),
    };
  },
  dispatch => {
    return {
      setManualFrequency: ({ dates }) => {
        dispatch(setCachedFrequency({ cachedFrequency: null }));
        dispatch(setPendingFrequencyFoodDates({ dates }));
      },
      setPendingFrequencyToManual: () => {
        dispatch(setPendingFrequency({ frequency: frequencyConstants.MANUAL }));
      },
    };
  },
  (mapProps, dispatchProps, { date }) => {
    const {
      componentId,
      label,
      checked,
      disabled,
      medicationTimes,
      currentPendingFrequency,
    } = mapProps;
    const { setManualFrequency, setPendingFrequencyToManual } = dispatchProps;
    return {
      // control what props get passed to the view
      componentId,
      label,
      checked,
      disabled,

      // actions to pass to view
      onChange: () => {
        if (!checked) {
          const dates = [...medicationTimes, date];
          setManualFrequency({ dates });
        } else {
          const dates = medicationTimes.filter(medDate => medDate !== date);
          setManualFrequency({ dates });
        }

        if (currentPendingFrequency !== frequencyConstants.MANUAL) setPendingFrequencyToManual();
      },
    };
  },
)(CheckboxWrapper);

// FREQUENCY Medication DATE SELECTION CONTAINER ----------------------------------------------------------------------------------------
export const FrequencyMedicationDateSelection = connect(
  (state, ownProps) => {
    const { date } = ownProps;

    return {
      componentId: `FrequencyMedicationDateSelection_${date}`,
      label: formatDayNameMonthDay(date),
      checked: getIsPendingFrequencyDateChecked(state, { date }),
      disabled: getCheckForSameDay(state),
      currentPendingFrequency: getPendingFrequency(state),
      medicationTimes:
        getPendingFrequencyMedicationDates(state) || selectDateRangeForFrequency(state),
    };
  },
  dispatch => {
    return {
      setManualFrequency: ({ dates }) => {
        dispatch(setCachedFrequency({ cachedFrequency: null }));
        dispatch(setPendingFrequencyMedicationDates({ dates }));
      },
      setPendingFrequencyToManual: () => {
        dispatch(setPendingFrequency({ frequency: frequencyConstants.MANUAL }));
      },
    };
  },
  (mapProps, dispatchProps, { date }) => {
    const {
      componentId,
      label,
      checked,
      disabled,
      medicationTimes,
      currentPendingFrequency,
    } = mapProps;
    const { setManualFrequency, setPendingFrequencyToManual } = dispatchProps;
    return {
      // control what props get passed to the view
      componentId,
      label,
      checked,
      disabled,

      // actions to pass to view
      onChange: () => {
        if (!checked) {
          const dates = [...medicationTimes, date];
          setManualFrequency({ dates });
        } else {
          const dates = medicationTimes.filter(medDate => medDate !== date);
          setManualFrequency({ dates });
        }

        if (currentPendingFrequency !== frequencyConstants.MANUAL) setPendingFrequencyToManual();
      },
    };
  },
)(CheckboxWrapper);

// FREQUENCY ADDON DATE SELECTION CONTAINER ----------------------------------------------------------------------------------------
export const FrequencyAddonDateSelection = connect(
  (state, ownProps) => {
    const { date } = ownProps;

    return {
      componentId: `FrequencyAddonDateSelection_${date}`,
      label: formatDayNameMonthDay(date),
      checked: getIsPendingFrequencyDateChecked(state, { date }),
      currentPendingFrequency: getPendingFrequency(state),
      addonTimes: getPendingFrequencyAddonDates(state) || selectDateRangeForFrequency(state),
    };
  },
  dispatch => {
    return {
      setManualFrequency: ({ dates }) => {
        dispatch(setCachedFrequency({ cachedFrequency: null }));
        dispatch(setPendingFrequencyAddonDates({ dates }));
      },
      setPendingFrequencyToManual: () => {
        dispatch(setPendingFrequency({ frequency: frequencyConstants.MANUAL }));
      },
    };
  },
  (mapProps, dispatchProps, { date }) => {
    const { componentId, label, checked, addonTimes, currentPendingFrequency } = mapProps;
    const { setManualFrequency, setPendingFrequencyToManual } = dispatchProps;
    return {
      // control what props get passed to the view
      componentId,
      label,
      checked,

      // actions to pass to view
      onChange: () => {
        if (!checked) {
          const dates = [...addonTimes, date];
          setManualFrequency({ dates });
        } else {
          const dates = addonTimes.filter(addonDate => addonDate !== date);
          setManualFrequency({ dates });
        }

        if (currentPendingFrequency !== frequencyConstants.MANUAL) setPendingFrequencyToManual();
      },
    };
  },
)(CheckboxWrapper);

export const FREQUENCY_CHECKBOXES = {
  [frequencyTypes.FOOD]: FrequencyFoodDateSelection,
  [frequencyTypes.MEDICATION]: FrequencyMedicationDateSelection,
  [frequencyTypes.ADDON]: FrequencyAddonDateSelection,
};
