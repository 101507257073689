export const dateTimeErrors = {
  START_BEFORE_OPEN: "Appointment start time is before hotel opens.",
  START_AFTER_CLOSE: "Appointment start time is after the hotel is closed.",
  END_AFTER_CLOSE: "Appointment end time is after hotel closes.",
  END_BEFORE_OPEN: "Appointment end time is before hotel opens.",
  BEFORE_START_TIME: "Appointment start time is after end time.",
  CLOSED: "Hotel is closed.",
  SAME_DAY_CLOSED: "Hotel is closed for same day services.",
  START_END_MATCH: "Unable to select date due to same time of check in and check out",
};
