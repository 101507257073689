import React from "react";
import styled from "styled-components";
import { color, font } from "../../../../common/styles/theme";
import valueMatchesSearchString from "../../../../../core/utils//stringManipulationUtils/valueMatchesSearchString";
import formatPhoneNumberNANP from "../../../../../core/utils/stringManipulationUtils/formatPhoneNumber";

// TODO: move these styled components to abstract components that can be shared
// between customer card & vet card
const SectionWrapper = styled.section`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: center;
  align-items: center;
`;

const VetImage = styled.img`
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 60px;
  margin-right: 18px;
`;

const VetInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const VetInfo = styled.div`
  display: flex;
  flex-direction: row;
  font-family: ${font.mainFamily};
  font-size: 14px;
  font-weight: ${props => (props.isBold ? "bold" : "normal")};
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${color.black0};
  ${props =>
    props.marginRight &&
    `
        margin-right: 0.25em;
    `};
  justify-content: flex-end;
`;

const VetInfoContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;

const VetAddressContainer = styled.div`
  margin-left: auto;
  text-align: right;
  padding-left: 16px;
`;

const AddressInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  float: right;
  margin-left: auto;
  text-align: right;
`;

const AddressInfo = styled.div`
  font-weight: ${props => (props.isBold ? "bold" : "normal")};
`;

// TODO: Abstract out search highlighting to be reusable

const PetVetCard = ({
  isHidden,
  clinic,
  clinicId,
  vetId,
  phoneNumber,
  address,
  zip,
  city,
  state,
  searchParams,
  createVet,
}) => {
  if (isHidden) {
    return null;
  }

  const cityMatch = searchParams.find(searchParam => valueMatchesSearchString(city, searchParam));
  const stateMatch = searchParams.find(searchParam => valueMatchesSearchString(state, searchParam));
  return (
    <SectionWrapper
      className="PetVetCard"
      onClick={() =>
        createVet({
          clinicId,
          veterinarianId: vetId,
          clinicName: clinic.clinic,
          veterinarianName: clinic.fullName,
          phoneNumber,
          address,
          zip,
          city,
          state,
        })
      }
    >
      <VetImage className="PetVetCard__userImage" src="https://via.placeholder.com/40x40" />
      <VetInfoWrapper>
        {Object.keys(clinic).map((key, clinicIndex) => {
          const infoItem = clinic[key];

          const match = searchParams.find(searchParam =>
            valueMatchesSearchString(infoItem, searchParam),
          );

          if (match) {
            const index = infoItem
              .trim()
              .toLowerCase()
              .indexOf(match.trim().toLowerCase());

            const start = infoItem.slice(0, index);
            const middle = infoItem.slice(index, index + match.length);
            const end = infoItem.slice(index + match.length);
            return (
              <VetInfoContainer key={clinicIndex}>
                {start && <VetInfo marginRight>{start}</VetInfo>}
                <VetInfo isBold marginRight>
                  {middle}
                </VetInfo>
                <VetInfo marginRight>{end}</VetInfo>
              </VetInfoContainer>
            );
          }

          return (
            <VetInfoContainer key={clinicIndex}>
              <VetInfo>{infoItem}</VetInfo>
            </VetInfoContainer>
          );
        })}
      </VetInfoWrapper>
      <VetAddressContainer>
        <VetInfo>{address}</VetInfo>
        <VetInfo>
          <AddressInfoContainer>
            <AddressInfo isBold={cityMatch}>{city}</AddressInfo>
            <AddressInfo>,</AddressInfo>
            &nbsp;
            <AddressInfo isBold={stateMatch}>{state}</AddressInfo>
            &nbsp; {zip}
          </AddressInfoContainer>
        </VetInfo>
        <VetInfo>
          <AddressInfoContainer>{formatPhoneNumberNANP(phoneNumber)}</AddressInfoContainer>
        </VetInfo>
      </VetAddressContainer>
    </SectionWrapper>
  );
};

export default PetVetCard;
