import React from "react";
import styled from "styled-components";
import { color } from "../styles/theme";

const Bold = styled.p`
  font-weight: bold;
`;

const Error = styled.p`
  font-weight: bold;
  color: ${color.red700};
`;

export default function TableCell({ values }) {
  if (Array.isArray(values)) {
    return <p>{values.join("\n")} </p>;
  }

  // this handles the case when a component is passed down
  if (typeof values === "object") return values;

  // TODO: temporary styling, fix later - this is used in < MyScheduleComponent />
  if (values === "Relocation") return <Bold>{values}</Bold>;
  if (values === "Store Closed") return <Error>{values}</Error>;

  return <p>{values}</p>;
}
