// @ts-check
/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const POST_HOTEL_ROOM_SUSPENSION_REQUEST = "POST_HOTEL_ROOM_SUSPENSION_REQUEST";
export const PATCH_HOTEL_ROOM_SUSPENSION_REQUEST = "PATCH_HOTEL_ROOM_SUSPENSION_REQUEST";
// Below Action Types are patterned for using loading and error state
export const SET_HOTEL_ROOM_SUSPENSION = "SET_HOTEL_ROOM_SUSPENSION";
export const SET_HOTEL_ROOM_SUSPENSION_REQUEST = "SET_HOTEL_ROOM_SUSPENSION_REQUEST";
export const SET_HOTEL_ROOM_SUSPENSION_SUCCESS = "SET_HOTEL_ROOM_SUSPENSION_SUCCESS";
export const SET_HOTEL_ROOM_SUSPENSION_ERROR = "SET_HOTEL_ROOM_SUSPENSION_ERROR";
export const SET_HOTEL_ROOM_SUSPENSION_FAILURE = "SET_HOTEL_ROOM_SUSPENSION_FAILURE";
/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */

/**
 * @param {object} payload
 * @param {boolean} payload.isCanceled
 * @param {string} payload.suspensionId
 * @param {string} payload.details
 * @param {string} payload.reasonName
 * @returns
 */
export const postHotelRoomSuspensionRequest = payload => ({
  type: POST_HOTEL_ROOM_SUSPENSION_REQUEST,
  payload,
});

/**
 * @param {object} payload
 * @param {boolean} payload.isCanceled
 * @param {string} payload.suspensionId
 * @param {string} payload.details
 * @param {string} payload.reasonName
 * @returns
 */
export const patchHotelRoomSuspensionRequest = payload => ({
  type: PATCH_HOTEL_ROOM_SUSPENSION_REQUEST,
  payload,
});

export const setHotelRoomSuspensionRequest = () => ({
  type: SET_HOTEL_ROOM_SUSPENSION_REQUEST,
});

export const setHotelRoomSuspensionSuccess = () => ({
  type: SET_HOTEL_ROOM_SUSPENSION_SUCCESS,
});

/**
 *
 * @param {object} payload - response from API
 * @returns
 */
export const setHotelRoomSuspensionError = payload => ({
  type: SET_HOTEL_ROOM_SUSPENSION_ERROR,
  payload,
});

/**
 * @param {object} error - response from API
 * @returns
 */
export const setHotelRoomSuspensionFailure = error => ({
  type: SET_HOTEL_ROOM_SUSPENSION_FAILURE,
  error,
});
