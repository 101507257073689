import React from "react";
import { color } from "../../../web/common/styles/theme";
import { Layout, Text } from "@prism/psm-ui-components";

const setTextColor = value => {
  const slicedString = value?.slice(0, -1);
  if (slicedString >= 100) {
    return color.red700;
  }
  if (slicedString >= 85) {
    return color.yellow700;
  }
  return color.black0;
};

export default function CapacityRoomRowThreeCol(props) {
  const {
    isHidden,
    componentID,
    room,
    used,
    availableRooms,
    physical,
    overbooking,
    isDrawerOpen,
  } = props;

  if (!isHidden) {
    return (
      <Layout.Cluster
        justify="space-between"
        style={{
          padding: ".5rem 0",
          borderTop: `1px solid ${color.gray50}`,
          borderBottom: `1px solid ${color.gray50}`,
        }}
        id={componentID}
      >
        <Text
          style={{
            width: isDrawerOpen ? "30%" : "40%",
            fontWeight: props.isBold ? "bold" : "none",
          }}
          {...props}
        >
          {room}
        </Text>

        <Text
          style={{
            width: isDrawerOpen ? "15%" : "20%",
            fontWeight: props.isBold ? "bold" : "none",
            color: setTextColor(used),
          }}
          {...props}
        >
          {used}
        </Text>

        <Text
          style={{
            width: isDrawerOpen ? "20%" : "40%",
            fontWeight: props.isBold ? "bold" : "none",
            color: setTextColor(used),
          }}
          {...props}
        >
          {availableRooms}
        </Text>

        <Text
          style={{
            width: isDrawerOpen ? "15%" : "0%",
            fontWeight: props.isBold ? "bold" : "none",
            color: setTextColor(used),
          }}
          {...props}
        >
          {physical}
        </Text>

        <Text
          style={{
            width: isDrawerOpen ? "20%" : "0%",
            fontWeight: props.isBold ? "bold" : "none",
            color: setTextColor(used),
          }}
          {...props}
        >
          {overbooking}
        </Text>
      </Layout.Cluster>
    );
  }

  return null;
}
