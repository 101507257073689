import React, { useEffect } from "react";
import { connect } from "react-redux";

// Helpers
import {
  isBGMEligibilityHidden,
  isFTCOHidden,
} from "dux/eligiblePromotions/helpers/isHiddenHelpers";

// Selectors
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import { getSelectedPet } from "@/core/selectors/bookingUISelectors";

// Actions
import { getBulkPackageOfferings } from "../bulkPackageOfferings/bulkPackageOfferingsActions";

// Components
import FirstTimeCustomerIndicatorCheckoutModalContainer from "dux/bgm/ftco/FirstTimeCustomerIndicatorCheckoutModalContainer";
import EligiblePromotionsBGMCheckoutModalContainer from "dux/eligiblePromotions/EligiblePromotionsBGMCheckoutModalContainer";
import EligiblePromotionsBGMPackageListContainer from "dux/eligiblePromotions/EligiblePromotionsBGMPackageListContainer";
import EligiblePromotionsHeaderTextFTCO from "dux/eligiblePromotions/EligiblePromotionsHeaderTextFTCO";
import EligiblePromotionsMessageTextBGM from "dux/eligiblePromotions/EligiblePromotionsMessageTextBGM";
import EligiblePromotionsMessageTextFTCO from "dux/eligiblePromotions/EligiblePromotionsMessageTextFTCO";
import EligiblePromotionsHeaderTextBGM from "dux/eligiblePromotions/EligiblePromotionsHeaderTextBGM";
import EligiblePromotionsBGMDeclineButtonContainer from "dux/eligiblePromotions/EligiblePromotionsBGMDeclineButtonContainer";
import EligiblePromotionsBGMAcceptButtonContainer from "dux/eligiblePromotions/EligiblePromotionsBGMAcceptButtonContainer";
import EligiblePromotionsDescriptionTextBGM from "dux/eligiblePromotions/EligiblePromotionsDescriptionTextBGM";
import FTCOPackageListContainer from "@/dux/bgm/ftco/FTCOPackageListContainer";
import FirstTimeCustomerDeclineButtonContainer from "@/dux/bgm/ftco/FirstTimeCustomerDeclineButtonContainer";
import FirstTimeCustomerAcceptButtonContainer from "@/dux/bgm/ftco/FirstTimeCustomerAcceptButtonContainer";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutCluster } from "@/layout/culster/Cluster";

/**
 * React view component for package offer details
 * @summary /check-out
 * @memberOf Views.Salon
 * @function
 * @name PackageOfferDetailsComponent
 * @param {Object} props - props passed into the view component
 * @param {String} props.componentId
 * @param {Boolean} props.isHidden
 * @param {Function} props.getPackageOfferings - api call to get package offerings
 * @returns {JSX.Element|null}
 * @example const Component = connect()(PackageOfferDetailsComponent)
 */
const PackageOfferDetailsComponent = ({ componentId, isHidden, getPackageOfferings }) => {
  // On mount
  useEffect(() => {
    if (!isHidden) {
      getPackageOfferings();
    }
  }, []);

  if (isHidden) return null;

  return (
    <LayoutBox id={componentId} borderWidth="scale-S5" borderColor="gray600">
      <LayoutStack>
        {/* HEADER TEXT */}
        <EligiblePromotionsHeaderTextFTCO />
        <EligiblePromotionsHeaderTextBGM />

        {/* ELIGIBILITY INDICATOR */}
        <FirstTimeCustomerIndicatorCheckoutModalContainer />
        <EligiblePromotionsBGMCheckoutModalContainer />

        <LayoutBox>
          <LayoutStack>
            {/* WARNINGS/PROMO/DESCRIPTION */}
            <EligiblePromotionsDescriptionTextBGM />

            {/* PACKAGE OPTIONS TABLE */}
            <FTCOPackageListContainer />
            <EligiblePromotionsBGMPackageListContainer />

            {/* MESSAGE TEXT */}
            <EligiblePromotionsMessageTextFTCO />
            <EligiblePromotionsMessageTextBGM />

            <LayoutBox>
              <LayoutCluster style={{ justifyContent: "space-evenly" }}>
                {/* FTCO BUTTONS */}
                <FirstTimeCustomerDeclineButtonContainer />
                <FirstTimeCustomerAcceptButtonContainer />

                {/*  BGM BUTTONS */}
                <EligiblePromotionsBGMDeclineButtonContainer />
                <EligiblePromotionsBGMAcceptButtonContainer />
              </LayoutCluster>
            </LayoutBox>
          </LayoutStack>
        </LayoutBox>
      </LayoutStack>
    </LayoutBox>
  );
};

/**
 * Connect function for package offer details
 * @see {@link Views.Salon.PackageOfferDetailsComponent}
 * @summary /check-out
 * @memberOf Views.Salon
 * @function
 * @name PackageOfferDetails
 * @returns {JSX.Element|null}
 * @example <PackageOfferDetails />
 */
export const PackageOfferDetails = connect(
  state => {
    return {
      isHidden: isFTCOHidden(state) && isBGMEligibilityHidden(state),
      componentId: "PackageOfferDetails",

      // Props to pass to get bulk package offerings endpoint
      storeNumber: getStoreNumber(state),
      customerKey: getCurrentCustomerKey(state),
      petId: getSelectedPet(state),
    };
  },
  dispatch => ({
    handleGetPackageOfferings: ({ storeNumber, customerKey, petId, offerType = null }) =>
      dispatch(getBulkPackageOfferings({ storeNumber, customerKey, petId, offerType })),
  }),
  (propsFromState, propsFromDispatch) => {
    const { isHidden, componentId, storeNumber, customerKey, petId } = propsFromState;
    const { handleGetPackageOfferings } = propsFromDispatch;

    return {
      componentId,
      isHidden,
      getPackageOfferings: () => handleGetPackageOfferings({ storeNumber, customerKey, petId }),
    };
  },
)(PackageOfferDetailsComponent);
