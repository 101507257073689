import React from "react";
import styled from "styled-components";
import { color } from "../../../../web/common/styles/theme";
import BulkPackageOfferingsCartDetailsPetCardContainer from "../../../bgm/bulkPackageOfferings/cartDetails/BulkPackageOfferingsCartDetailsPetCardContainer";
import BulkPackageOfferingsCartDetailsPricingContainer from "../../../bgm/bulkPackageOfferings/cartDetails/BulkPackageOfferingsCartDetailsPricingContainer";
import BulkPackageOfferingsCartDetailsTotalPriceContainer from "../../../bgm/bulkPackageOfferings/cartDetails/BulkPackageOfferingsCartDetailsTotalPriceContainer";

const CartDetails = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${color.gray300};
  margin-top: 2rem;
`;

const CardWrapper = styled.div`
  padding: 1.5rem;
  border-bottom: ${({ dashed }) =>
    dashed ? `1px dashed ${color.gray300}` : `1px solid ${color.gray300}`};
`;

const TotalPriceWrapper = styled.div`
  padding: 1.5rem;
`;

const BulkPackageOfferingsCartComponent = ({ isHidden, componentID }) => {
  if (!isHidden) {
    return (
      <CartDetails componentID={componentID}>
        <CardWrapper>
          <BulkPackageOfferingsCartDetailsPetCardContainer />
        </CardWrapper>
        <CardWrapper dashed>
          <BulkPackageOfferingsCartDetailsPricingContainer />
        </CardWrapper>
        <TotalPriceWrapper>
          <BulkPackageOfferingsCartDetailsTotalPriceContainer />
        </TotalPriceWrapper>
      </CartDetails>
    );
  }

  return null;
};

export default BulkPackageOfferingsCartComponent;
