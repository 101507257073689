import { put, takeLatest, call, all, select } from "redux-saga/effects";

// Services
import {
  postHotelRoomSuspension,
  patchHotelRoomSuspension,
} from "core/services/systemServicesBooking/hotelRoomMananagementEndPoints";

// Selectors
import { getStoreNumber } from "core/selectors/persistentSelectors";
import { selectServiceSuspensionRoomReasonIdByName } from "@/dux/servicesManagementToggle/servicesManagementToggleSelectors";
import {
  selectHotelManagementDetailsStartDate,
  selectHotelManagementDetailsEndDate,
} from "@/dux/hotelManageRoomDetailsDatePickers/hotelManageRoomDetailsDatePickersSelectors";
import {
  getHotelSelectedRoomDetails,
  selectHotelRoomSelectedRoomTypeBucketId,
  selectHotelRoomNumber,
} from "@/dux/hotelManageRoomDetails/hotelManageRoomDetailsSelectors";

// Actions
import {
  getHotelManageRoomDetails,
  getHotelManageRoomDetailsSuccess,
  setHotelManageRoomScheduleInactivation,
} from "@/dux/hotelManageRoomDetails/hotelManageRoomDetailsActions";
import { loadHotelRoomTypes } from "@/dux/hotelRoomSelection/hotelRoomSelectionActions";

// Utils
import moment from "moment";
import {
  PATCH_HOTEL_ROOM_SUSPENSION_REQUEST,
  POST_HOTEL_ROOM_SUSPENSION_REQUEST,
  setHotelRoomSuspensionRequest,
  setHotelRoomSuspensionSuccess,
  setHotelRoomSuspensionError,
  setHotelRoomSuspensionFailure,
} from "./hotelManageRoomDetailsTableActions";

// Helpers
import { updateRoomSuspensionsData } from "./hotelManageRoomDetailsTableHelpers";

// Constants
import { SUSPENSION_TYPE_ID } from "./hotelManageRoomDetailsTableConstants";

/**
 * Generator Function to handle post room suspension success
 * @param {{ updatedSuspensions: Object[], roomNumber: string}} param
 */
function* onSuccuessRoomSuspension({ updatedSuspensions, roomNumber }) {
  yield put(getHotelManageRoomDetailsSuccess(updatedSuspensions));
  yield put(loadHotelRoomTypes());
  // Load room details to fetch alerts
  const fromDate = moment().format("YYYY-MM-DD");
  const toDate = moment(fromDate)
    .add(30, "days")
    .format("YYYY-MM-DD");
  yield put(getHotelManageRoomDetails({ roomNumber, fromDate, toDate }));
  // Clear the flag after saving the data successfully
  yield put(setHotelManageRoomScheduleInactivation(false));
  yield put(setHotelRoomSuspensionSuccess());
}

/**
 * Generator Function to handle post room suspension error
 * @param {error:Object} param
 */
function* onErrorRoomSuspension(error) {
  yield put(setHotelRoomSuspensionError(error?.response?.data?.errors));
  // Clear the flag after saving the data successfully
  yield put(setHotelManageRoomScheduleInactivation(false));
  // We are calling this action to remove loading spinner
  yield put(setHotelRoomSuspensionSuccess());
}

/**
 * Generator function to schedule a new room suspension
 */
function* onPostHotelRoomSuspension({ payload }) {
  try {
    const { details, reasonName } = payload;
    yield put(setHotelRoomSuspensionRequest());
    // Fetch payload information from store
    const storeNumber = yield select(getStoreNumber);
    const startDate = yield select(selectHotelManagementDetailsStartDate);
    const endDate = yield select(selectHotelManagementDetailsEndDate);
    const selectRoomSuspensionReasonIdByName = selectServiceSuspensionRoomReasonIdByName({
      reasonName,
    });
    const reasonId = yield select(selectRoomSuspensionReasonIdByName);
    const roomTypeBucketId = yield select(selectHotelRoomSelectedRoomTypeBucketId);
    const roomNumber = yield select(selectHotelRoomNumber);

    // Request payload for creating a new room suspension
    const data = {
      roomTypeBucketId,
      roomNumber,
      startDate,
      endDate,
      suspensionTypeId: SUSPENSION_TYPE_ID,
      reasonId,
      details,
      isCanceled: false,
    };

    // API REQUEST
    const response = yield call(postHotelRoomSuspension, {
      storeNumber,
      data,
    });

    // Initial Suspensions Data
    const initialRoomSuspensionDetails = yield select(getHotelSelectedRoomDetails);
    // New Suspension Data
    const newRoomSuspensionDetails = response?.data?.result;

    const updatedSuspensions = updateRoomSuspensionsData(
      initialRoomSuspensionDetails,
      newRoomSuspensionDetails,
    );
    yield onSuccuessRoomSuspension({ updatedSuspensions, roomNumber });
  } catch (error) {
    yield onErrorRoomSuspension(error);
    yield put(setHotelRoomSuspensionFailure(error));
  }
}

/**
 * Generator function to modify an existing room suspension
 */
function* onPatchHotelRoomSuspension({ payload }) {
  try {
    const { details, reasonName, isCanceled = false, suspensionId } = payload;
    yield put(setHotelRoomSuspensionRequest());
    // Fetch payload information from store
    const storeNumber = yield select(getStoreNumber);
    const startDate = yield select(selectHotelManagementDetailsStartDate);
    const endDate = yield select(selectHotelManagementDetailsEndDate);
    const selectRoomSuspensionReasonIdByName = selectServiceSuspensionRoomReasonIdByName({
      reasonName,
    });
    const reasonId = yield select(selectRoomSuspensionReasonIdByName);
    const roomTypeBucketId = yield select(selectHotelRoomSelectedRoomTypeBucketId);
    const roomNumber = yield select(selectHotelRoomNumber);

    // Request payload for updating an existing room suspension
    const data = {
      roomTypeBucketId,
      roomNumber,
      isCanceled,
      ...(!isCanceled && {
        startDate,
        endDate,
        suspensionTypeId: SUSPENSION_TYPE_ID,
        reasonId,
        details,
      }),
    };

    // API REQUEST
    const response = yield call(patchHotelRoomSuspension, {
      storeNumber,
      data,
      suspensionId,
    });

    // Initial Suspensions Data
    const initialRoomSuspensionDetails = yield select(getHotelSelectedRoomDetails);
    // New Suspension Data
    const newRoomSuspensionDetails = response?.data?.result;

    const updatedSuspensions = updateRoomSuspensionsData(
      initialRoomSuspensionDetails,
      newRoomSuspensionDetails,
    );
    yield onSuccuessRoomSuspension({ updatedSuspensions, roomNumber });
  } catch (error) {
    yield onErrorRoomSuspension(error);
  }
}

function* watchOnPostHotelRoomSuspension() {
  yield takeLatest(POST_HOTEL_ROOM_SUSPENSION_REQUEST, onPostHotelRoomSuspension);
}

function* watchOnPatchHotelRoomSuspension() {
  yield takeLatest(PATCH_HOTEL_ROOM_SUSPENSION_REQUEST, onPatchHotelRoomSuspension);
}

export default function* hotelManageRoomDetailsTableSaga() {
  yield all([watchOnPostHotelRoomSuspension(), watchOnPatchHotelRoomSuspension()]);
}
