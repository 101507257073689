import React from "react";
import { connect } from "react-redux";
import { getTemplateWeekId, getWeeklyEditChanged } from "@/core/selectors/schedulesSelectors";
import { Button } from "@petsmart-ui/sparky";
import { color } from "web/common/styles/theme";
import {
  setTemplateWeeklyEdit,
  showSchedulesModal,
} from "@/core/actionCreators/schedulesActionCreators";
import { LayoutBox } from "@/layout/box/Box";
import { modalTypes } from "@/core/constants/associateSchedulingConstants";

/**
 *  React view component for the week tabs on the associate template scheduling page
 *  @memberOf Views.Associate
 *  @function
 *  @name WeekTabComponent
 *  @param {Object} props - props passed into the view component
 *  @param {String} props.componentId
 *  @param {Function} props.onClick
 *  @param {String} props.title
 *  @param {Boolean} props.isActive
 *  @returns {JSX.Element}
 *  @example const component = connect()(WeekTabComponent);
 */
export const WeekTabComponent = ({ componentId, onClick, title, isActive }) => {
  return (
    <LayoutBox id={componentId} padding="scale-0">
      <Button
        variant="link"
        size="lg"
        text={title}
        onClick={onClick}
        style={isActive ? { textDecoration: "underline" } : { color: color.black0 }}
      />
    </LayoutBox>
  );
};

/**
 * Redux Connect function for the WeekTabComponent
 * @see {@link Views.Associate.WeekTabComponent}
 * @summary Located on the template-scheduling page
 * @memberOf Views.Associate
 * @function
 * @name WeekTab
 * @param {Object} props
 * @param {String} props.id
 * @param {String} props.title
 * @param {Function} props.onClick
 * @returns {JSX.Element|null}
 * @example <WeekTab id="week1" title="Week 1" setNewWeekId={() => {}} />
 */
export const WeekTab = connect(
  (state, { id }) => {
    const templateWeekId = getTemplateWeekId(state);
    return {
      componentId: `WeekTab_${id}`,
      isActive: templateWeekId === id,
      weeklyEditChanged: getWeeklyEditChanged(state),
    };
  },
  (dispatch, { id }) => {
    return {
      dispatchUnsavedChangesModal: () =>
        dispatch(showSchedulesModal(modalTypes.UNSAVE_CHANGES_MODAL_GO_TO_TEMPLATE_WEEK)),
      dispatchSetTemplateWeeklyEdit: () => dispatch(setTemplateWeeklyEdit(id)),
    };
  },
  (stateProps, dispatchProps, ownProps) => {
    const { componentId, isActive, weeklyEditChanged } = stateProps;
    const { dispatchUnsavedChangesModal, dispatchSetTemplateWeeklyEdit } = dispatchProps;
    const { title, onClick } = ownProps;

    return {
      componentId,
      isActive,
      title,
      onClick: () => {
        onClick();

        if (weeklyEditChanged) {
          dispatchUnsavedChangesModal();
          return;
        }

        dispatchSetTemplateWeeklyEdit();
      },
    };
  },
)(WeekTabComponent);
