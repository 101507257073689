/** ----------------------------------------------------------------------- **\
    CURRENT CONFIGURATIONS;
    Send the following as props to this component to configure CSS

    shadow      {Boolean} - true to show, false to hide
    isDisabled    {Boolean} - true to enable, false to disable ( color and cursor properties )
    inverted    {Boolean} - false for blue button, true for white button with blue border
    width:      {String}  - set explicit width of button
    height:     {String}  - set explicit height of button
\** ----------------------------------------------------------------------- * */

import React from "react";
import styled from "styled-components";
import { color, font } from "./styles/theme";

/** ----------------------------------------------------------------------- * */
// SET CSS CONFIGURATIONS(S)

function setBackgroundColor(props) {
  if (props.inverted && props.isDisabled) {
    return "#ddd";
  }
  if (props.inverted) {
    return color.white0;
  }
  if (props.isDisabled) {
    return "#999";
  }
  return color.blue500;
}

function setHoverBackgroundColor(props) {
  if (props.isDisabled) {
    return "none";
  }
  if (props.inverted) {
    return "#eee";
  }
  return "grey";
}

function setBorder(props) {
  if (props.inverted) {
    return `1px solid ${color.blue500} `;
  }
  return "none";
}

function setFontColor(props) {
  if (props.inverted) {
    return `${color.blue500}`;
  }
  return `${color.white0}`;
}

function setShadow(props) {
  if (props.shadow) {
    return "0 4px 24px 0 rgba(0, 0, 0, 0.51)";
  }
  return "none";
}

function setCursor(props) {
  if (props.isDisabled) {
    return "not-allowed ";
  }
  return "pointer";
}

function setMargin(props) {
  if (props.margin) {
    return "20px";
  }
  return "0";
}

function setFloat(props) {
  if (props.float) {
    return props.float;
  }
  return "none";
}

function setPointerEvents(props) {
  if (props.isDisabled) {
    return "none";
  }
  return "auto";
}
/** ----------------------------------------------------------------------- * */

const ComponentWrapper = styled.button`
  background-color: ${props => setBackgroundColor(props)};
  color: ${props => setFontColor(props)};
  border: ${props => setBorder(props)};
  box-shadow: ${props => setShadow(props)};
  cursor: ${props => setCursor(props)};
  margin-left: ${props => setMargin(props)};
  margin-top: ${props => (props.marginTop ? props.marginTop : 0)};
  float: ${props => setFloat(props)};
  pointer-events: ${props => setPointerEvents(props)};

  width: ${props => (props.width ? props.width : "initial")};
  height: ${props => (props.height ? props.height : "initial")};
  max-width: ${props => (props.maxWidth ? props.maxWidth : "initial")};

  padding: 10px;
  display: block;
  border-radius: 2px;
  text-align: center;
  font-family: ${font.mainFamily};
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;

  &:hover {
    background-color: ${props => setHoverBackgroundColor(props)};
  }
`;

export default function CommonButton({
  label,
  disabled,
  onClick,
  componentId = "CommonButton",
  isHidden = false,
  ...props
}) {
  if (!isHidden) {
    return (
      <ComponentWrapper
        {...props}
        data-testid={componentId}
        id={componentId}
        isDisabled={disabled}
        onClick={onClick}
      >
        {label}
      </ComponentWrapper>
    );
  }

  return null;
}
