import { createSelector } from "reselect";
import { find, map } from "lodash/fp";
import moment from "moment";
import {
  BOARDED_GUEST_DDC,
  ROOM,
} from "dux/servicesManagementToggle/serviceManagmentToggleConstants";
import { selectServiceSuspensionReasons } from "dux/hotelSuspensionReasons/hotelSuspensionReasonsSelectors";
import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";
import { selectHotelSelectedSuspensionType } from "dux/hotelSelectedSuspensionType/SelectedSuspensionTypeSelectors";
import { findSuspensionHelper } from "dux/servicesManagementToggle/serviceManagementToggleHelpers";

const BOARDED_GUEST_ID = "100";

/**
 * Helper selector - syntactical sugar to add the suspensionTypeId to selectors.
 *
 * @memberof Selectors.ScheduledSuspensions
 * @function
 * @param {Object} state - The state object.
 * @param {string} suspensionTypeId - The ID of the suspension type to be selected.
 * @returns {string} The selected suspension type ID.
 *
 * @example
 * // selector context
 * const mySelector = createSelector(
 *   [someSelector, selectSuspensionTypeId],
 *   (someSelector, selectSuspensionTypeId) => {
 *   //... code to use selectSuspensionTypeId
 *   }
 */
const selectSuspensionTypeId = (state, suspensionTypeId) => suspensionTypeId;

/**
 * Helper function that just abstracts out the process to get info the service manager page same day and boarded guest toggle components need for pre-hydration.
 * @param serviceSuspensionReason
 * @param scheduledSuspensions
 * @param id
 * @returns {{reasonName: (string), details: (string)}}
 */
const getReasonToggleInfo = (serviceSuspensionReason, scheduledSuspensions, id) => {
  const suspension = find(item => item.suspensionTypeId === id, scheduledSuspensions);
  const reasonId = suspension?.reasonId;
  const suspensionTypeId = suspension?.suspensionTypeId;
  const reasonObj = serviceSuspensionReason[suspensionTypeId];
  const reason = find(item => item.reasonId === reasonId, reasonObj?.reasons);

  return {
    reasonName: reason?.reasonName,
    details: suspension?.details,
    reasonId,
    suspensionTypeId,
  };
};

// TODO: move both these selectors to the /dux/hotelScheduleSuspension/hotelScheduleSuspensionSelectors.js
export const getIsScheduledSuspensionIsUpdated = (state, reasonTypeName) =>
  state.hotelScheduleSuspensions[`is${reasonTypeName.replace(/\s/g, "")}Updated`];

export const getIsScheduledSuspensionBGIsUpdated = state =>
  state.hotelScheduleSuspensions.isBGUpdate;

// TODO: move selector to the /dux/hotelScheduleSuspension/hotelScheduleSuspensionSelectors.js this will help reduce the the circular dependency
// creating a variable here instead of importing getScheduledSuspensions from scheduleSuspensionTable Selector
// to remove circular dependency since the scheduleSuspensionTableSelector imports this file,
const getScheduledSuspensions = state => state.hotelScheduleSuspensions?.suspensionsList;

/**
 * Retrieves a list of suspension reason names by the given suspension type ID.
 *
 * @memberof Selectors.ScheduledSuspensions
 * @function
 * @param {Object} params - The parameters for the selector.
 * @param {string} params.suspensionTypeId - The ID of the suspension type.
 * @returns {Array} - The suspension reasons mapped to their reason names.
 * @example:
 * // in connect context
 * const reasons - selectReasonBySuspensionTypeId({suspensionTypeId})(state),
 *
 * // Saga context
 * const selectListOfReasons = selectReasonBySuspensionTypeId({suspensionTypeId})
 * const reasonList = yield select(selectListOfReasons);
 */
export const selectReasonBySuspensionTypeId = ({ suspensionTypeId }) =>
  createSelector([selectServiceSuspensionReasons], serviceSuspensionReason => {
    const { reasons } = serviceSuspensionReason[suspensionTypeId];

    return reasons.map(reason => reason.reasonName);
  });

/**
 * Selects the service suspension reasons from the selected suspension type.
 *
 * @memberof Selectors.ScheduledSuspensions
 * @function
 * @param {Array} selectServiceSuspensionReasons - The available service suspension reasons.
 * @param {Object} selectHotelSelectedSuspensionType - The selected suspension type.
 *
 * @returns {Array} - An array of reason names from the selected suspension type.
 */
export const selectServiceSuspensionFromSelectedType = createSelector(
  [selectServiceSuspensionReasons, selectHotelSelectedSuspensionType],
  (serviceSuspensionReason, selectedSuspensionType) => {
    // serviceSuspensionReason comes in as a normalized set of objects, we are just concerting to an array of objects via lodash/fp map
    const listOfSuspensionReasons = map(item => item, serviceSuspensionReason);

    const reasons = find(
      type => type?.suspensionTypeId === selectedSuspensionType?.suspensionTypeId,
      listOfSuspensionReasons,
    );

    return reasons?.reasons?.map(item => item.reasonName);
  },
);

// Boarded Guest Reasons e.g. (dropdown options)
export const getServiceSuspensionBoardedGuestReasons = createSelector(
  [selectServiceSuspensionReasons],
  serviceSuspensionReason => {
    const boardedGuestReasons = find(
      type => type.reasonTypeName === BOARDED_GUEST_DDC,
      map(item => item, serviceSuspensionReason),
    );

    return boardedGuestReasons?.reasons?.map(item => item.reasonName);
  },
);

// Room Reasons e.g. (dropdown options)
/**
 * @return {array} - reasons - [{reasonName: 'name', reasonId: '123'}]
 */
export const getServiceSuspensionRoomReasons = createSelector(
  [selectServiceSuspensionReasons],
  serviceSuspensionReason => {
    const roomReasons = find(
      type => type.reasonTypeName === ROOM,
      map(item => item, serviceSuspensionReason),
    );

    return roomReasons?.reasons || [];
  },
);

/**
 * @param {string} - reasonName
 * @return {string} - reasonID for a reasonName
 */
export const selectServiceSuspensionRoomReasonIdByName = ({ reasonName }) =>
  createSelector([getServiceSuspensionRoomReasons], roomSuspensionReasons => {
    return roomSuspensionReasons?.find(item => item.reasonName === reasonName)?.reasonId || "";
  });

// DDC selected reason
/**
 * A selector function that returns the suspension reason for a given suspension type ID.
 *
 * @memberof Selectors.ScheduledSuspensions
 * @function selectServiceSuspensionReason
 * @param {Array} selectServiceSuspensionReasons - The array of suspension reasons.
 * @param {Array} getScheduledSuspensions - The array of scheduled suspensions.
 * @param {number} suspensionTypeId - The ID of the suspension type.
 * @returns {string} The suspension reason for the given suspension type ID.
 *
 * @example
 * // connect context
 * const suspensionReason = selectServiceSuspensionReason(state, suspensionTypeId)
 */
export const selectServiceSuspensionReason = createSelector(
  [selectServiceSuspensionReasons, getScheduledSuspensions, selectSuspensionTypeId],
  (reasons, scheduledSuspensions, suspensionTypeId) => {
    const reasonInfo = getReasonToggleInfo(reasons, scheduledSuspensions, suspensionTypeId);

    return reasonInfo.reasonName;
  },
);

// BoardedGuest selected reason
export const getServiceSuspensionBoardedGuestReason = createSelector(
  [selectServiceSuspensionReasons, getScheduledSuspensions],
  (reasons, scheduledSuspensions) => {
    const reasonInfo = getReasonToggleInfo(reasons, scheduledSuspensions, BOARDED_GUEST_ID);

    return reasonInfo.reasonName;
  },
);

/**
 * Retrieves the suspension details for a specific suspension type.
 *
 * @memberof Selectors.ScheduledSuspensions
 * @function selectServiceSuspensionDetails
 * @param {Array} selectServiceSuspensionReasons - The array of service suspension reasons.
 * @param {Array} getScheduledSuspensions - The array of scheduled suspensions.
 * @param {Function} suspensionTypeId - The function that returns the suspension type ID.
 * @returns {String} - Returns the details for the specified suspension type.
 *
 * @example
 * // connect context
 * const details = selectServiceSuspensionDetails(state, suspensionTypeId)
 */
export const selectServiceSuspensionDetails = createSelector(
  [selectServiceSuspensionReasons, getScheduledSuspensions, selectSuspensionTypeId],
  (reasons, scheduledSuspensions, suspensionTypeId) => {
    const reasonInfo = getReasonToggleInfo(reasons, scheduledSuspensions, suspensionTypeId);

    return reasonInfo.details;
  },
);

// provide a suspension type and reason name to get a reason id.
export const selectServiceSuspensionReasonId = ({ suspensionType, reasonName }) =>
  createSelector([selectServiceSuspensionReasons], suspensionReasons => {
    // since we need to cross ref. the first part of reason Name argument with the with reason name in the reasonType, we split at the hyphen
    const splitString = reasonName?.split(" - ");

    // Need to convert the object of suspension Reasons into an array so we can perform a find within that collection.
    const reasonList = Object.values(suspensionReasons);

    // Now that the object values are a list, we can perform a find to get reasonTypeName e.g. DDC, that matches the argument passed in.
    const reasonItem = reasonList.find(item => item?.reasonTypeName === suspensionType);

    // That narrows it down to only a particular type of reason, e.g. DDC and we can then cross that 1st part of the reasonName from the split string to the reason name in the reason type
    const reasonType = reasonItem?.reasons.find(thing => thing.reasonName === splitString[0]);

    return reasonType?.reasonId;
  });

// BoardedGuest details
export const getServiceSuspensionBoardedGuestDetails = createSelector(
  [selectServiceSuspensionReasons, getScheduledSuspensions],
  (reasons, scheduledSuspensions) => {
    const reasonInfo = getReasonToggleInfo(reasons, scheduledSuspensions, BOARDED_GUEST_ID);

    return reasonInfo.details;
  },
);

/**
 * Creates a selector to get the scheduled service based on selected date and suspension type ID.
 *
 * @memberof Selectors.ScheduledSuspensions
 * @function
 * @param {Array} getScheduledSuspensions - Array of scheduled suspensions.
 * @param {string} getSelectedDate - Selected date.
 * @param {string} suspensionTypeId - Suspension type ID.
 *
 * @returns {Object} The scheduled service.
 *
 * @example:
 * // Connect context
 * const scheduledService = selectScheduledService(state, suspensionTypeId);
 */
export const selectScheduledService = createSelector(
  [getScheduledSuspensions, getSelectedDate, selectSuspensionTypeId],
  (scheduledSuspensions, selectedDate, suspensionTypeId) => {
    return scheduledSuspensions
      ?.filter(item => {
        // Inject Moment.isSame to verify that the suspension startDate and the Selected start date are the same regardless of string passed in
        const itemStartDate = moment(item.startDate);
        return itemStartDate.isSame(selectedDate) && item.suspensionTypeId === suspensionTypeId;
      })
      .find(suspension => suspension.status === "Scheduled");
  },
);

/**
 * Creates a selector for determining if the status of a service suspension is Scheduled.
 *
 * @memberof Selectors.ScheduledSuspensions
 * @function selectServiceSuspensionStatus
 * @param {Array} getScheduledSuspensions - The selector function for retrieving scheduled suspensions.
 * @param {Date} getSelectedDate - The selector function for retrieving the selected date.
 * @param {number|string} suspensionTypeId - The identifier for the suspension type.
 * @returns {boolean} - The status of the service suspension. Returns `true` if the suspension is scheduled, or `false` otherwise.
 *
 * @example
 * // connect context
 * const status = selectServiceSuspensionStatus(state, suspensionTypeId)
 */
export const selectServiceSuspensionStatus = createSelector(
  [getScheduledSuspensions, getSelectedDate, selectSuspensionTypeId],
  (scheduledSuspensions, selectedDate, suspensionTypeId) => {
    const suspension = findSuspensionHelper(scheduledSuspensions, selectedDate, suspensionTypeId);
    return suspension?.status === "Scheduled";
  },
);

// BoardedGuest Status
export const selectServiceSuspensionBoardedGuestStatus = createSelector(
  [getScheduledSuspensions, getSelectedDate],
  (scheduledSuspensions, selectedDate) => {
    const suspension = scheduledSuspensions?.find(
      item => item.startDate === selectedDate && item.suspensionTypeId === BOARDED_GUEST_ID,
    );

    return suspension?.status;
  },
);

// Is the form/method/ability to add or modify a BoardedGuest suspension visible
export const selectServiceSuspensionBoardedGuestVisibility = createSelector(
  [selectServiceSuspensionBoardedGuestStatus],
  status => {
    return status === "Scheduled";
  },
);

/**
 * A selector function that returns the suspension ID for a selected service suspension.
 *
 * @memberof Selectors.ScheduledSuspensions
 * @function selectServiceSuspensionSuspensionId
 * @param {Array} getScheduledSuspensions - An array of scheduled suspensions.
 * @param {string} getSelectedDate - The selected date.
 * @param {string} selectSuspensionTypeId - The selected suspension type ID.
 * @returns {string} - The suspension ID for the selected service suspension, or null if not found.
 *
 * @example:
 * // connect context
 * const suspensionId = selectServiceSuspensionSuspensionId(state, suspensionTypeId);
 */
export const selectServiceSuspensionSuspensionId = createSelector(
  [getScheduledSuspensions, getSelectedDate, selectSuspensionTypeId],
  (scheduledSuspensions, selectedDate, suspensionTypeId) => {
    const suspension = findSuspensionHelper(scheduledSuspensions, selectedDate, suspensionTypeId);

    return suspension?.suspensionId;
  },
);

/**
 * A selector function that retrieves the suspension start date for a selected service suspension.
 *
 * @memberof Selectors.ScheduledSuspensions
 * @function selectServiceSuspensionSuspensionStartDate
 * @param {Array} getScheduledSuspensions - An array of scheduled suspensions.
 * @param {Date} getSelectedDate - The selected date.
 * @param {string} selectSuspensionTypeId - The selected suspension type ID.
 * @returns {Date | undefined} The suspension start date, or undefined if not found.
 *
 * @example:
 * // connect context
 * const startDate = selectServiceSuspensionSuspensionStartDate(state, suspensionTypeId);
 */
export const selectServiceSuspensionSuspensionStartDate = createSelector(
  [getScheduledSuspensions, getSelectedDate, selectSuspensionTypeId],
  (scheduledSuspensions, selectedDate, suspensionTypeId) => {
    const suspension = findSuspensionHelper(scheduledSuspensions, selectedDate, suspensionTypeId);

    return suspension?.startDate;
  },
);

export const selectSuspensionTypeIdFromSuspensionType = suspensionType =>
  createSelector([selectServiceSuspensionReasons], serviceSuspensionReasons => {
    // convert collection of object values to an array to iterate over
    const reasonsList = Object.values(serviceSuspensionReasons);
    const reasonItem = reasonsList.find(item => item.reasonTypeName === suspensionType);
    return reasonItem?.suspensionTypeId;
  });
