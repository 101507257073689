import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// Components
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { Button, ButtonGroup, Heading, TextPassage } from "@petsmart-ui/sparky";
import {
  OverBookingExceptionDateRange,
  validateDateRange,
} from "@/dux/overBookingExceptionStartEndDates/OverBookingExceptionStartEndDates";
import {
  OverbookingRoomTypeDropdown,
  validateRoomType,
} from "../overbookingRoomTypeDropdown/OverbookingRoomTypeDropdown";
import {
  OverBookingExceptionNewCapacity,
  validatePercent,
} from "@/dux/overBookingExceptionNewCapacity/OverBookingExceptionNewCapacity";

// Constants
import {
  newExceptionTitle,
  modifiedExceptionTitle,
  newExceptionDescription,
} from "./overBookingNewExceptionDateConstants";

// Actions
import {
  postOverBookingNewExceptionDate,
  clearOverbookingExceptionForm,
  postOverBookingNewExceptionDateFailure,
} from "./overBookingNewExceptionDateActions";

// Selectors
import {
  selectOverBookingExceptionEnd,
  selectOverBookingExceptionError,
  selectOverBookingExceptionRoomType,
  selectOverBookingExceptionStart,
  selectOverBookingPercentage,
} from "@/dux/overBookingException/overBookingExceptionSelectors";
import { selectOverbookingNewExceptionError } from "./overBookingNewExceptionDateSelectors";

// Helpers
import { history } from "@/dux/utils/browser/browserHistory";

// Constants
import { routePaths } from "@/core/constants/routePaths";
import { color } from "web/common/styles/theme";
import {
  GET_OVERBOOKING_EXCEPTION,
  PATCH_OVERBOOKING_EXCEPTION,
  getOverbookingException,
  patchOverbookingException,
} from "../overBookingException/overBookingExceptionActions";
import { compose } from "redux";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import LoadingWrapper from "@/web/common/LoadingWrapper";
import { createLoadingSelector, getApiError } from "@/core/selectors/utils";
import { formatCalendarDateMoment } from "@/core/utils/dateUtils/formatDateTime";

/**
 * New Exception Date Component
 * @param {object} props
 * @param {string} props.componentId
 * @param {boolean} props.isHidden
 * @param {boolean} props.disabled
 * @param {boolean} props.isSaveDisabled
 * @param {string} props.exceptionTitle
 * @param {React.MouseEventHandler<Element>} props.handleOnClick
 * @param {React.MouseEventHandler<Element>} props.handleOnSave
 * @param {string} props.error
 * @returns
 */
export const ExceptionDate = props => {
  const {
    componentId,
    isHidden,
    exceptionTitle,
    disabled,
    isSaveDisabled,
    handleOnClick,
    handleOnSave,
    handleUnmount,
    error,
    isLoading,
    loadException = () => {},
  } = props;

  const [showRequiredErrors, setShowRequiredErrors] = useState(false);

  useEffect(() => {
    loadException();
  }, []);

  useEffect(() => {
    return handleUnmount;
  }, []);

  if (isHidden) return null;

  return (
    <LayoutBox id={componentId} padding="scale-0" style={{ maxWidth: "704px" }}>
      <LoadingWrapper isLoading={isLoading}>
        <LayoutStack space="scale-G2">
          {/* Title and message */}
          <LayoutBox padding="scale-0">
            <Heading tagName="h3" size="headline">
              {exceptionTitle}
            </Heading>
            <TextPassage capLinelength={false} size="lg">
              {newExceptionDescription}
            </TextPassage>
          </LayoutBox>

          {/* Select Dropdowns */}
          <LayoutBox padding="scale-0">
            <LayoutCluster>
              <LayoutBox padding="scale-0" style={{ flex: 1 }}>
                <OverbookingRoomTypeDropdown isRequired={showRequiredErrors} disabled={disabled} />
              </LayoutBox>

              <LayoutBox padding="scale-0" style={{ flex: 1 }}>
                <OverBookingExceptionNewCapacity isRequired={showRequiredErrors} />
              </LayoutBox>
            </LayoutCluster>
          </LayoutBox>

          {/* Date pickers */}
          <LayoutBox padding="scale-0">
            <OverBookingExceptionDateRange isRequired={showRequiredErrors} disabled={disabled} />
          </LayoutBox>

          {/* Alerts */}
          {error?.length > 0 && (
            <LayoutBox>
              {error.map((/** @type {any} */ alert, /** @type {Number} */ index) => {
                return (
                  <TextPassage
                    key={index}
                    capLinelength={false}
                    size="lg"
                    style={{ marginBottom: "10px", color: color.red700 }}
                  >
                    {alert?.errorMessage}
                  </TextPassage>
                );
              })}
            </LayoutBox>
          )}

          {/* Action Buttons */}
          <ButtonGroup align="right">
            <Button text="Back" onClick={handleOnClick} size="lg" />
            <Button
              text="Save"
              variant="primary"
              disabled={isSaveDisabled}
              onClick={() => {
                setShowRequiredErrors(true);
                handleOnSave();
              }}
              size="lg"
            />
          </ButtonGroup>
        </LayoutStack>
      </LoadingWrapper>
    </LayoutBox>
  );
};

export const validateNewExceptionFields = ({ percent, startDate, endDate, roomTypeBucket }) =>
  !!validatePercent(percent, true) ||
  !!validateDateRange(startDate, endDate, true) ||
  !!validateRoomType(roomTypeBucket, true);

// CONTAINER(s) ----------------------------------------------------------------------------------------
export const OverBookingNewExceptionDate = connect(
  state => {
    return {
      componentId: "OverBookingNewExceptionDate",
      exceptionTitle: newExceptionTitle,
      isHidden: false,
      error: selectOverbookingNewExceptionError(state),

      // merge props
      roomTypeBucket: selectOverBookingExceptionRoomType(state),
      startDate: selectOverBookingExceptionStart(state),
      endDate: selectOverBookingExceptionEnd(state),
      percent: selectOverBookingPercentage(state),
    };
  },
  dispatch => {
    return {
      handleOnClick: () => {
        history.push(routePaths.MANAGE_OVERBOOKING_EXCEPTION_DATES);
      },
      handleOnSave: ({ startDate, endDate, roomTypeBucket, percent }) => {
        dispatch(postOverBookingNewExceptionDate({ startDate, endDate, roomTypeBucket, percent }));
      },
      handleUnmount: () => {
        dispatch(clearOverbookingExceptionForm());
        dispatch(postOverBookingNewExceptionDateFailure(null));
      },
    };
  },
  (stateProps, dispatchProps) => {
    const { startDate, endDate, roomTypeBucket, percent, ...propsFromState } = stateProps;
    const { handleOnClick, handleOnSave, handleUnmount } = dispatchProps;
    return {
      ...propsFromState,
      isSaveDisabled: validateNewExceptionFields({ percent, startDate, endDate, roomTypeBucket }),
      handleOnClick,
      handleUnmount,
      handleOnSave: () => {
        handleOnSave({ startDate, endDate, roomTypeBucket, percent });
      },
    };
  },
)(ExceptionDate);

// Modify Exception
export const OverBookingModifyExceptionDate = compose(
  withRouteProps,
  connect(
    state => {
      const errorObj = selectOverBookingExceptionError(state);
      return {
        componentId: "OverBookingModifyExceptionDate",
        exceptionTitle: modifiedExceptionTitle,
        isHidden: false,
        error: errorObj && [
          { errorMessage: getApiError(errorObj), statusCode: errorObj?.response?.status },
        ],
        isLoading: createLoadingSelector([GET_OVERBOOKING_EXCEPTION, PATCH_OVERBOOKING_EXCEPTION])(
          state,
        ),

        // merge props
        roomTypeBucket: selectOverBookingExceptionRoomType(state),
        startDate: selectOverBookingExceptionStart(state),
        endDate: selectOverBookingExceptionEnd(state),
        percent: selectOverBookingPercentage(state),
      };
    },
    (dispatch, { router }) => {
      return {
        loadException: () => {
          const exceptionId = router?.params?.exceptionId;
          dispatch(getOverbookingException(exceptionId));
        },
        handleOnClick: () => {
          history.push(routePaths.MANAGE_OVERBOOKING_EXCEPTION_DATES);
        },
        handleUnmount: () => {
          dispatch(clearOverbookingExceptionForm());
          dispatch(postOverBookingNewExceptionDateFailure(null));
        },
        handleOnSave: ({ startDate, endDate, roomTypeBucket, percent }) => {
          const capacityExceptionId = router?.params?.exceptionId;
          dispatch(
            patchOverbookingException({
              capacityExceptionId,
              roomTypeBucketNumber: roomTypeBucket,
              startDate: formatCalendarDateMoment(startDate),
              endDate: formatCalendarDateMoment(endDate),
              overBookingPercentage: percent,
            }),
          );
        },
      };
    },
    (stateProps, dispatchProps) => {
      const { startDate, endDate, roomTypeBucket, percent, ...propsFromState } = stateProps;
      const { handleOnClick, handleOnSave, loadException, handleUnmount } = dispatchProps;
      return {
        ...propsFromState,
        loadException,
        isSaveDisabled: validateNewExceptionFields({ percent, startDate, endDate, roomTypeBucket }),
        handleOnClick,
        handleUnmount,
        handleOnSave: () => {
          handleOnSave({ startDate, endDate, roomTypeBucket, percent });
        },
      };
    },
  ),
)(ExceptionDate);
