// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ddc-packages-radio-list .sparky-c-radio-field__item-label.sparky-c-tile {
  align-items: normal;
  flex: 1;
}
`, "",{"version":3,"sources":["webpack://./src/dux/ddcPackages/DdcPackagesRadioList.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,OAAO;AACT","sourcesContent":[".ddc-packages-radio-list .sparky-c-radio-field__item-label.sparky-c-tile {\n  align-items: normal;\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
