import React, { useEffect, useState } from "react";
import { find, get } from "lodash/fp";
import moment from "moment";
import { connect } from "react-redux";
import { compose } from "redux";
import { Text } from "@prism/psm-ui-components";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName, engagementTypes } from "web/setSystemType/constants/setSystemTypeConstants";
import { getHotelItinerary } from "dux/hotelItinerary/hotelItinerarySelectors";
import { getConstructedHotelItinerary } from "dux/hotelItinerary/hotelConstructedItinerarySelectors";
import {
  formatCalendarDateMoment,
  formatHourTimeMoment,
} from "core/utils/dateUtils/formatDateTime";
import {
  getFormattedTimeRange,
  getHotelCheckInHourOutsideRange,
  getHotelCheckOutHourOutsideRange,
  getHotelIsClosedByDate,
  selectHoursByLengthOfStay,
} from "web/hotelHours/hotelHoursSelectors";
import {
  HotelCheckInDate,
  HotelCheckOutDate,
} from "@/dux/hotelCheckInOutDateTimeCart/hotelCheckInOutDateComponent";
import {
  HotelCheckInTime,
  HotelCheckOutTime,
} from "@/dux/hotelCheckInOutDateTimeCart/hotelCheckInOutTimeComponent";
import { hotelHoursActionTypes, loadHotelHours } from "web/hotelHours/hotelHoursActions";
import { getCurrentPet } from "@/core/selectors/persistentSelectors";
import { selectCheckInOutDateTimeCommonState } from "./hotelCheckInOutSelectors";
import { putHotelItinerary } from "../hotelItinerary/hotelItineraryActions";
import { LayoutGrid } from "@/layout/grid/Grid";
import {
  updateOvernightItineraryDates,
  updateSameDayItineraryDateTimes,
} from "@/core/utils/hotelEngagementUtils/updateItineraryDateTimes";
import { HotelCheckInOutEditIconContainer } from "@/dux/hotelCheckInOutDateTimeCart/hotelCheckInOutEditIcon";
import { isDateTimeEditable } from "./hotelCheckInOutUtils";
import { selectFoodOptions } from "../foodOptionsList/foodOptionsListSelectors";
import { showCheckInOutModal } from "@/core/actionCreators/checkInOutActionCreator";
import { modalTypes as checkInOutModalTypes } from "@/core/constants/checkInOutConstants";
import { getPetEngagementsFromItinerary } from "@/core/utils/hotelEngagementUtils/hotelEngagementUtils";
import {
  checkAllPetsHaveAnActiveFood,
  checkNoPetsHaveDiscontinuedFoods,
} from "@/core/utils/hotelItineraryUtils/validateItineraryFeedings";
import { checkIfPetsHaveDiscontinuedMeds } from "@/core/utils/hotelItineraryUtils/validateItineraryMedications";
import { selectMedicationOptions } from "../medications/medicationsSelectors";
import selectIsDiscontinuedMedsFeatureHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/discontinuedMeds/getIsDiscontinuedMedsWorkflowFeatureFlagHidden";
import { createLoadingSelector } from "@/core/selectors/utils";
import { dateTimeErrors } from "../hotelBookingDateTimeSelection/hotelBookingDateTimeConstants";
import { setTimeForDate } from "@/core/utils/dateUtils/byDateAndTime";
import {
  getHotelEngagementsPets,
  selectEarliestCheckOut,
} from "../hotelEngagements/hotelEngagementSelectors";

const foodUpdatesNeededStr =
  "Reservation has Discontinued Food that cannot be copied to the extended days. Please add a new Food before extending the stay";

/**
 * Get the type of error and any message associated to it.
 * @param isHotelClosed
 * @param isOutsideRange
 * @param hasAvailability
 * @param formattedOpenTime
 * @param formattedCloseTime
 * @returns {{errorMessage: *, error: *}}
 */
const getErrorType = ({
  isHotelClosed,
  isOutsideRange,
  hasAvailability,
  formattedOpenTime,
  formattedCloseTime,
  isDateInPast,
  isDateAfterCheckOut,
  foodUpdatesNeeded,
  isSameDay,
  isDateAfterOtherPetsCheckOut,
}) => {
  const errorTypes = {
    dateAfterCheckOut: {
      error: !!isDateAfterCheckOut,
      message: "Check-In Date needs to be before the Check-Out date",
      canSave: false,
    },
    isDateAfterOtherPetsCheckOut: {
      error: !!isDateAfterOtherPetsCheckOut,
      message:
        "New check-in date is after check-out date for some pets. Please update the check-out date for all pets before updating the check-in date",
      canSave: false,
    },
    closed: {
      error: !!isHotelClosed,
      message: isSameDay ? dateTimeErrors.SAME_DAY_CLOSED : dateTimeErrors.CLOSED,
      canSave: false,
    },
    outsideRange: {
      error: !!isOutsideRange,
      message: `Time must be between ${formattedOpenTime}-${formattedCloseTime}`,
      canSave: false,
    },
    availability: {
      // hasAvailability can come in undefined (api call not made yet), so it must show no error
      // If there is hasAvailability, it also mush show no error.
      error: !(hasAvailability === undefined || hasAvailability),
      message: "Selected date is not available",
      canSave: false,
    },
    foodUpdatesNeededError: {
      error: !!foodUpdatesNeeded,
      message: foodUpdatesNeededStr,
      canSave: false,
    },
    dateInPast: {
      error: !!isDateInPast,
      message: "Selected date is in the past",
      canSave: true,
    },
  };

  // Find 1st error from error types
  const errorObj = find(obj => obj.error === true, errorTypes);

  return {
    error: errorObj?.error,
    errorMessage: errorObj?.message,
    canSave: errorObj?.canSave,
  };
};

const HotelCheckInOutDateTimeComponent = props => {
  const {
    isHidden,
    componentId,
    label,
    getError,
    changeTime,
    getHotelHours,
    diComp,
    dateTime,
    setDateTime,
    canChangeDateTime,
    canChangeDate,
    resetPending,
  } = props;

  // Get hotel hours for selected date to verify store is open for selected time
  useEffect(() => {
    if (!isHidden) getHotelHours(dateTime);
  }, [dateTime]);

  const [isEditingDate, setIsEditingDate] = useState(false);
  const [isEditingTime, setIsEditingTime] = useState(false);

  if (isHidden) return null;

  const { error, errorMessage, canSave } = getError(dateTime) ?? {};

  const handleTimeChange = newTime => {
    if (error && !canSave) return;

    changeTime(newTime);
  };
  const Date = diComp.date ?? "div";
  const Time = diComp.time ?? "div";

  const handleOnClickEditIcon = () => {
    // If clicking pencil to stop editing then clear pending values
    if (isEditingDate || isEditingTime) resetPending();

    setIsEditingDate(isEditingDate => canChangeDate && !isEditingDate);
    setIsEditingTime(isEditingTime => !isEditingTime);
  };

  return (
    <>
      <LayoutGrid
        style={{ gridTemplateColumns: ".5fr 1.5fr 1fr .15fr", width: "100%" }}
        id={componentId}
        space="scale-0"
      >
        <Text>{label}</Text>
        <Date
          changeTime={handleTimeChange}
          time={dateTime}
          setTime={setDateTime}
          isEditing={isEditingDate}
          setIsEditing={setIsEditingDate}
        />
        <Time
          changeTime={handleTimeChange}
          time={dateTime}
          setTime={setDateTime}
          isEditing={isEditingTime}
          setIsEditing={setIsEditingTime}
        />
        <HotelCheckInOutEditIconContainer
          componentId={`${componentId}__edit-button`}
          isDisabled={!canChangeDateTime}
          onClick={handleOnClickEditIcon}
          style={{ textAlign: "center" }}
        />
      </LayoutGrid>

      {(isEditingDate || isEditingTime) && error ? (
        <Text color="text-color-red" align="right">
          {errorMessage}
        </Text>
      ) : null}
    </>
  );
};

export const checkIsExtendingPetStay = (petId, oldItinerary = [], newItinerary = []) => {
  const newEngagements = getPetEngagementsFromItinerary(petId, newItinerary);
  const oldEngagements = getPetEngagementsFromItinerary(petId, oldItinerary);
  return newEngagements?.length > oldEngagements?.length;
};

// HOTEL -------------------------------------------------------------------------------------------
export const HotelCheckInOutDateTimeCommonContainer = connect(
  (state, ownProps) => {
    const hotelItinerary = getHotelItinerary(state);
    const petId = getCurrentPet(state);
    const storeNumber = hotelItinerary?.storeNumber;
    const {
      firstEngagement,
      lastEngagement,
      startDateTime,
      endDateTime,
      storeTimeZone,
    } = selectCheckInOutDateTimeCommonState(state, { petId, storeNumber });
    const isLoading = !hotelItinerary?.itineraryId;
    const isSameDay = moment(startDateTime).isSame(moment(endDateTime), "day");
    const itinerary = getConstructedHotelItinerary(state);
    const foodExternalData = selectFoodOptions(state);
    const medExternalData = selectMedicationOptions(state);

    const getPendingStartError = newDate => {
      const { pendingEnd } = ownProps;
      const endDate = pendingEnd ?? endDateTime;

      const isMultiPet = getHotelEngagementsPets(state)?.length > 1;
      const earliestEnd = selectEarliestCheckOut(state);
      // coerce a boolean from the moment date object.
      const hasDate = !!newDate;
      const date = formatCalendarDateMoment(newDate);
      const time = formatHourTimeMoment(newDate);
      const end = isSameDay ? setTimeForDate(newDate, endDate, storeTimeZone) : endDate;

      const isOutsideRange =
        hasDate &&
        getHotelCheckInHourOutsideRange(state, { endDateTime: end, time, date, storeNumber });

      const { formattedOpenTime, formattedCloseTime } =
        hasDate && getFormattedTimeRange(state, { endDateTime: end, time, date, storeNumber });

      const isHotelClosed = getHotelIsClosedByDate(state, { date, endDateTime: end, storeNumber });

      const isDateInPast = newDate.isBefore(moment());

      const isDateAfterCheckOut = !isSameDay && newDate.isSameOrAfter(moment(end), "day");
      const isDateAfterOtherPetsCheckOut =
        isMultiPet && !isSameDay && newDate.isSameOrAfter(moment(earliestEnd), "day");

      const isExtendingStay = !isSameDay && newDate.isBefore(moment(startDateTime), "day");
      const hasOnlyDiscontinuedFood = !checkAllPetsHaveAnActiveFood({
        itinerary,
        foodExternalData,
      });
      const foodUpdatesNeeded = isExtendingStay && hasOnlyDiscontinuedFood;

      return getErrorType({
        isHotelClosed,
        isOutsideRange,
        formattedOpenTime,
        formattedCloseTime,
        isDateInPast,
        isDateAfterCheckOut,
        foodUpdatesNeeded,
        isSameDay,
        isDateAfterOtherPetsCheckOut,
      });
    };

    const getPendingEndError = date => {
      const { pendingStart } = ownProps;
      const startDate = pendingStart ?? startDateTime;

      const isDateBeforeCheckIn =
        !isSameDay && moment(date).isSameOrBefore(moment(startDate), "day");
      if (isDateBeforeCheckIn)
        return { error: true, errorMessage: "Check-Out Date needs to be after the Check-In date" };

      const error =
        date &&
        getHotelCheckOutHourOutsideRange(state, {
          startDateTime: startDate,
          time: formatHourTimeMoment(date),
          date: formatCalendarDateMoment(date),
          storeNumber,
        });

      if (error) return { error: true, errorMessage: error };

      const isExtendingStay = !isSameDay && moment(date).isAfter(moment(endDateTime), "day");
      const hasOnlyDiscontinuedFood = !checkAllPetsHaveAnActiveFood({
        itinerary,
        foodExternalData,
        petIds: [petId],
      });
      const foodUpdatesNeeded = isExtendingStay && hasOnlyDiscontinuedFood;
      if (foodUpdatesNeeded) return { error: true, errorMessage: foodUpdatesNeededStr };

      return { error: false };
    };

    return {
      ...ownProps,
      isHidden: getSystemBookingFlow(state) !== systemName.HOTEL || isLoading,
      isSameDay,
      firstEngagement,
      lastEngagement,
      startDateTime,
      endDateTime,
      petId,
      hotelHours: selectHoursByLengthOfStay(state, { startDateTime, endDateTime }),
      isLoadingHotelHours: createLoadingSelector([hotelHoursActionTypes.LOAD_HOTEL_HOURS])(state),
      storeTimeZone,
      storeNumber,
      itinerary,
      foodExternalData,
      medExternalData,
      isDiscontinuedMedsFeatureHidden: selectIsDiscontinuedMedsFeatureHidden(state),
      getPendingStartError,
      getPendingEndError,
    };
  },
  dispatch => {
    return {
      loadHours: (date, storeNumber) => dispatch(loadHotelHours({ date, storeNumber })),
      callItineraryPut: hotelItinerary => dispatch(putHotelItinerary({ hotelItinerary })),
      informUserOfDiscontinuedFoodAndMeds: petIds =>
        dispatch(
          showCheckInOutModal({
            modalType: checkInOutModalTypes.FOOD_MED_NOT_AVAILABLE,
            modalProps: { petIds },
          }),
        ),
    };
  },
  (propsFromState, propsFromDispatch) => {
    const {
      hotelHours,
      isLoadingHotelHours,
      storeNumber,
      itinerary,
      petId,
      foodExternalData,
      medExternalData,
      isDiscontinuedMedsFeatureHidden,
    } = propsFromState;
    const { loadHours, callItineraryPut, informUserOfDiscontinuedFoodAndMeds } = propsFromDispatch;

    return {
      ...propsFromState,
      callItineraryPut,
      getHotelHours: selectedDate => {
        const date = formatCalendarDateMoment(selectedDate);

        // If hours for date already exist or call is already being made, don't fire.
        if (!isLoadingHotelHours && !hotelHours?.[date]) {
          loadHours(date, storeNumber);
        }
      },
      getChangeTimeData: ({ newDateTime, originalDateTime }) => {
        const isDayChanged =
          formatCalendarDateMoment(originalDateTime) !== formatCalendarDateMoment(newDateTime);
        const isTimeChanged =
          formatHourTimeMoment(originalDateTime) !== formatHourTimeMoment(newDateTime);

        return { isDayChanged, isTimeChanged };
      },
      handleOvernightItineraryUpdates: ({ petIds, updatedHotelItinerary }) => {
        const isExtendingStay = checkIsExtendingPetStay(petId, itinerary, updatedHotelItinerary);
        const noPetsHaveDiscontinuedFood = checkNoPetsHaveDiscontinuedFoods({
          itinerary: updatedHotelItinerary,
          petIds,
          foodExternalData,
        });

        // If extending stay and pet has a discontinued food or med then show informational modal
        const petsHaveDiscontinuedMeds =
          !isDiscontinuedMedsFeatureHidden &&
          checkIfPetsHaveDiscontinuedMeds({
            itinerary: updatedHotelItinerary,
            petIds,
            medExternalData,
          });
        const hasDiscontinuedFoodOrMed = !noPetsHaveDiscontinuedFood || petsHaveDiscontinuedMeds;
        if (isExtendingStay && hasDiscontinuedFoodOrMed) {
          informUserOfDiscontinuedFoodAndMeds(petIds);
        }

        callItineraryPut(updatedHotelItinerary);
      },
    };
  },
);

export const HotelCheckInDateTime = compose(
  HotelCheckInOutDateTimeCommonContainer,
  connect((state, ownProps) => {
    // Props from HotelCheckInOutDateTimeCommonContainer
    const {
      isHidden,
      isSameDay,
      startDateTime,
      endDateTime,
      petId,
      storeTimeZone,
      itinerary,
      callItineraryPut,
      getHotelHours,
      getChangeTimeData,
      lastEngagement,
      foodExternalData,
      medExternalData,
      handleOvernightItineraryUpdates,
      getPendingStartError,
      getPendingEndError,
    } = ownProps;

    // props from parent
    const { pendingEnd } = ownProps;
    const isEndUpdated = !pendingEnd?.isSame(moment(endDateTime));
    const pendingEndError = isEndUpdated && getPendingEndError(pendingEnd);

    const originalDateTime = startDateTime;

    const canChangeDateTime = isDateTimeEditable(lastEngagement);

    return {
      componentId: "HotelCheckInDateTime",
      label: "Check-in:",
      isHidden,
      getError: getPendingStartError,
      petId,
      originalDateTime,
      canChangeDateTime,
      canChangeDate: true,
      diComp: {
        date: HotelCheckInDate,
        time: HotelCheckInTime,
      },
      getHotelHours,
      changeTime: newDateTime => {
        const { isDayChanged, isTimeChanged } = getChangeTimeData({
          newDateTime,
          originalDateTime,
        });

        if (!isDayChanged && !isTimeChanged) return;

        if (isSameDay) {
          const updatedHotelItinerary = updateSameDayItineraryDateTimes({
            itinerary,
            checkIn: newDateTime,
            currentPetId: petId,
            timeZone: storeTimeZone,
          });

          callItineraryPut(updatedHotelItinerary);
          return;
        }

        if (pendingEndError?.error) {
          return;
        }

        // Handle date time change for overnight itinerary
        const updatedHotelItinerary = updateOvernightItineraryDates({
          itinerary,
          checkInTime: newDateTime,
          checkOutTime: pendingEnd,
          timeZone: storeTimeZone,
          currentPetId: petId,
          foodExternalData,
          medExternalData,
        });

        handleOvernightItineraryUpdates({ updatedHotelItinerary });
      },
    };
  }),
)(HotelCheckInOutDateTimeComponent);

export const HotelCheckOutDateTime = compose(
  HotelCheckInOutDateTimeCommonContainer,
  connect((state, ownProps) => {
    // Props from HotelCheckInOutDateTimeCommonContainer
    const {
      isHidden,
      isSameDay,
      endDateTime,
      startDateTime,
      petId,
      itinerary,
      callItineraryPut,
      getHotelHours,
      getChangeTimeData,
      storeTimeZone,
      lastEngagement,
      foodExternalData,
      medExternalData,
      handleOvernightItineraryUpdates,
      getPendingStartError,
      getPendingEndError,
    } = ownProps;

    // props from parent
    const { pendingStart } = ownProps;
    const isStartUpdated = !pendingStart?.isSame(moment(startDateTime));
    const pendingStartError = isStartUpdated && getPendingStartError(pendingStart);

    const originalDateTime = endDateTime;

    // For same day reservations the check out date can't be updated if we are already checked in
    // For same day - we can edit time but not date
    const isOvernightReservation =
      lastEngagement?.engagementType === engagementTypes.OVERNIGHT_BOARDING;
    const canChangeDateTime = isDateTimeEditable(lastEngagement, true);

    return {
      componentId: "HotelCheckOutDateTime",
      label: "Check-out:",
      isHidden,
      getError: getPendingEndError,
      petId,
      originalDateTime,
      canChangeDateTime,
      canChangeDate: isOvernightReservation,
      diComp: {
        date: HotelCheckOutDate,
        time: HotelCheckOutTime,
      },
      getHotelHours,
      changeTime: newDateTime => {
        const { isDayChanged, isTimeChanged } = getChangeTimeData({
          newDateTime,
          originalDateTime,
        });

        if (!isDayChanged && !isTimeChanged) return;

        if (isSameDay) {
          const updatedHotelItinerary = updateSameDayItineraryDateTimes({
            itinerary,
            checkOut: newDateTime,
            currentPetId: petId,
            timeZone: storeTimeZone,
          });

          callItineraryPut(updatedHotelItinerary);
          return;
        }

        if (pendingStartError?.error && !pendingStartError?.canSave) {
          return;
        }

        // Handle date time change for overnight itinerary
        const updatedHotelItinerary = updateOvernightItineraryDates({
          itinerary,
          checkOutTime: newDateTime,
          checkInTime: pendingStart,
          timeZone: storeTimeZone,
          currentPetId: petId,
          foodExternalData,
          medExternalData,
        });

        handleOvernightItineraryUpdates({ petIds: [petId], updatedHotelItinerary });
      },
    };
  }),
)(HotelCheckInOutDateTimeComponent);
