import React from "react";
import styled from "styled-components";
import { color } from "web/common/styles/theme";
import { fontSizes } from "web/common/styles/responsive/fonts";

const StyledTab = styled.div`
  cursor: pointer;
  font-family: Montserrat;
  font-size: ${fontSizes.big};
  font-weight: bold;
  border-bottom: ${({ isActive }) => (isActive ? `0.0625rem solid ${color.blue500}` : "none")};
  color: ${({ isActive }) => (isActive ? `${color.blue500}` : "inherit")};
  opacity: ${props => (props.isPetActive ? "0.5" : "auto")};
  margin-right: 1em;
  margin-bottom: 1.5em;
`;

const UnderlinedTab = ({ isHidden, componentID, isActive, handleClick, label }) => {
  if (!isHidden) {
    return (
      <StyledTab id={componentID} isActive={isActive} onClick={handleClick}>
        {label}
      </StyledTab>
    );
  }

  return null;
};

export default UnderlinedTab;
