import { connect } from "react-redux";
import { getFoodCount } from "web/foodCount/foodCountSelector";
import { showHideFoodDetails } from "web/foodList/actions/foodListActions";
import AddButton from "web/common/buttons/addButton";
import { isFromBooking, isFromCheckInOut } from "core/utils/validationUtils/isFromValidation";
import { get } from "lodash/fp";
import { history } from "@/dux/utils/browser/browserHistory";
import { FOOD_MAX_COUNT, NEW_FOOD_ID } from "web/newFood/newFoodConstants";
import { setNewFood, showAddNewFood } from "web/newFood/actions/newFoodActions";
import { medicationTypes } from "web/newMedication/newMedicationConstants";
import {
  getFoodIsShowingDetails,
  getFoodIsShowingDetailsForType,
} from "web/foodList/foodListSelectors";

const mapStateToProps = (state, ownProps) => {
  const { petId, buttonDisabled } = ownProps;
  const foodId = NEW_FOOD_ID;
  const foodCount = getFoodCount(state, { petId, foodId });
  const locationType = medicationTypes.PROFILE;
  return {
    componentId: "FoodAddButton",
    buttonDisabled,
    petId,
    foodId,
    foodCount,
    locationType,
    isHidden: isFromCheckInOut(get(["location", "pathname"], history))
      ? false
      : isFromBooking(get(["location", "pathname"], history)) || foodCount >= FOOD_MAX_COUNT,
    opacity: "1",
    label: "Add Food",
    fontSize: "regular",
    isLocationTypeShowing: getFoodIsShowingDetailsForType(state, {
      foodId,
      petId,
      locationType,
    }),
    typesShowing: getFoodIsShowingDetails(state, { foodId, petId }),
  };
};

const mapDispatchToProps = (dispatch) => ({
  showFeedingForm: (foodCount, foodId, petId, locationTypes) => {
    dispatch(setNewFood({ petId }));
    dispatch(showAddNewFood({ petId }));
    dispatch(
      showHideFoodDetails({
        locationTypes,
        foodId,
        petId,
      }),
    );
  },
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  buttonClickHandler: () => {
    const { foodCount, foodId, petId, locationType, isLocationTypeShowing, typesShowing } =
      propsFromState;
    let locationTypes;
    if (!isLocationTypeShowing) {
      locationTypes = [...typesShowing, locationType];
    }
    propsFromDispatch.showFeedingForm(foodCount, foodId, petId, locationTypes);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AddButton);
