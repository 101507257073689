import { connect } from "react-redux";
import { bundlePackagesDetailsTypes } from "dux/bgm/availableBundlesByPet/availableBundlesList/bundlePackageDetailsConstents";
import BGM_STATUS from "../availableBundlesByPetConstants";
import { getBundleRedemptionsByStatus } from "../availableBundlesByPetSelectors";
import { headerComponentTypes } from "@/dux/_constants/headerComponents";
import { bundleDatesClickHandler } from "../../availableBundlesUtils";
import { loadTrainingClassDetails } from "@/dux/manageTrainingClassPage/manageTrainingClassPageActions";
import BundleDatesListComponent from "../../../_components/bgm/availableBundlesByPet/BundleDatesListComponent";

const mapStateToProps = (state, { bundleNumber, idEnum, offerType }) => {
  const { customerId, petId } = idEnum;
  return {
    type: bundlePackagesDetailsTypes.REDEEMED,
    customerId,
    bundleNumber,
    componentId: "bundleRedeemedDatesList_BundleDatesListComponent",
    dates: getBundleRedemptionsByStatus(state, {
      petId,
      bundleNumber,
      status: BGM_STATUS.COMPLETE,
    }),
    headerType: headerComponentTypes.REDEEMED_BUNDLES,
    offerType: offerType,
    onClick: bundleDatesClickHandler,
  };
};

const mapDispatchToProps = dispatch => ({
  setCustomerKeyAndItineraryId: (customerKey, itineraryId) => {
    dispatch(loadTrainingClassDetails({ customerKey, itineraryId }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BundleDatesListComponent);
