import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import styled from "styled-components";

import { AFontRegular } from "web/common/styles/abstractStyledComponents";
import { color } from "web/common/styles/theme";
import SectionHeader from "../../common/sectionHeader";
import { PetParentContactSettings } from "@/dux/petParentContactSettings/PetParentContactSettings";
import { SalonPetParentContactLink } from "web/petCheckInOut/petParentDetails/petParentContactInfo/PetParentContactInfoComponent";
import { DoNotBookSalonCheckInInfoLabel } from "dux/doNotBook/doNotBookInfoLabel";
import { SalonCustomerNotesV2 } from "dux/notes/Notes";

const ComponentWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 494px;
  padding: 53px 43px 48px 37px;
  box-sizing: border-box;
  box-shadow: ${props => (props.shadow ? "0 0.625rem 2.125rem 0 rgba(13, 18, 27, 0.12)" : "none")};
`;

export const SectionWrapper = styled.div`
  padding: 20px 0;
  box-sizing: border-box;
`;

export const SectionTitle = AFontRegular.extend`
  color: ${color.gray800};
  font-weight: bold;
`;

class PetParentDetails extends Component {
  constructor(props) {
    super(props);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    const { customerKey } = this.props;
    if (customerKey) {
      this.props.loadCustomer({ customerKey });
    }
  }

  handleError() {
    alert("This customer does not exist in SalesForce. You will be taken back to the dashboard.");
    return <Navigate replace to="/dashboard" />;
  }

  render() {
    const { customerKey } = this.props;

    return (
      <ComponentWrapper shadow>
        <SectionHeader title="Pet Parent Details" />

        <SectionWrapper>
          <SalonPetParentContactLink customerKey={customerKey} />
        </SectionWrapper>

        <SectionWrapper>
          <SectionTitle>Notify when pet is ready</SectionTitle>
        </SectionWrapper>

        <SectionWrapper>
          <DoNotBookSalonCheckInInfoLabel />
        </SectionWrapper>

        <PetParentContactSettings customerKey={customerKey} />

        <SalonCustomerNotesV2 />
      </ComponentWrapper>
    );
  }
}

export default PetParentDetails;
