import React from "react";
import { color } from "../../../web/common/styles/theme";
import { Layout, Text } from "@prism/psm-ui-components";

export default function CapacityRoomRowTwoCol(props) {
  const { isHidden, componentID, description, value } = props;

  if (!isHidden) {
    return (
      <Layout.Cluster
        justify="space-between"
        style={{
          padding: ".5rem 0",
          borderTop: `1px solid ${color.gray50}`,
          borderBottom: `1px solid ${color.gray50}`,
        }}
        id={componentID}
      >
        <Text>{description}</Text>

        <Text>{value}</Text>
      </Layout.Cluster>
    );
  }

  return null;
}
