import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { SelectField } from "@petsmart-ui/sparky";
import { LayoutBox } from "@/layout/box/Box";
import { getPrimaryServicesNameAndPriceByPet } from "@/web/pendingAppointment/selectors/pendingAppointmentSelectors";
import { getCurrentPet } from "@/core/selectors/persistentSelectors";
import { createLoadingSelector } from "core/selectors/utils";
import {
  LOAD_PET_SERVICES_AT_HOTEL_POSTBOOKING,
  loadPetServicesAtHotelPostBooking,
} from "@/web/services/hotelServices/actions/petServicesAtHotel";
import { GET_HOTEL_ITINERARY } from "dux/hotelItinerary/hotelItineraryActions";
import { PATCH_HOTEL_PRIMARY_SERVICE_CHANGE } from "dux/hotelPrimaryServiceChange/hotelPrimaryServiceChangeActions";
import { selectCurrentHotelPetService } from "dux/hotelEngagements/hotelEngagementSelectors";
import { selectIsReservationDisabled } from "dux/hotelItinerary/hotelItinerarySelectors";
import { LayoutStack } from "@/layout/stack/Stack";
import { color } from "@/web/common/styles/theme";
import LoadingWrapper from "@/web/common/LoadingWrapper";

export const SERVICE_NOT_AVAIL = "Service is not available during the selected dates.";

const getServiceDataById = (petServices, petServiceId) =>
  petServices?.find(({ value }) => value === Number(petServiceId))?.service;

export const PrimaryServiceDropdownComponent = ({
  componentId,
  isHidden,
  isLoading,
  petServices,
  petServiceId,
  isDisabled,
  label,
  onChange = () => {},
  loadPetServices = () => {},
}) => {
  const [currentPetServiceId, setCurrentPetServiceId] = useState(petServiceId);

  const serviceNotAvail = useMemo(
    () => getServiceDataById(petServices, currentPetServiceId)?.isAvailable === false,
    [petServices, currentPetServiceId],
  );

  useEffect(() => {
    setCurrentPetServiceId(petServiceId);
  }, [petServiceId]);

  // When the dropdown becomes visible, load pet services
  useEffect(() => {
    if (!isHidden) loadPetServices();
  }, [isHidden]);

  if (isHidden) return null;

  return (
    <LoadingWrapper isLoading={isLoading}>
      <LayoutBox id={componentId} style={{ flex: 1 }} padding="scale-0">
        <LayoutStack>
          <SelectField
            id={`${componentId}__primary--select`}
            label={label ?? "Primary Service"}
            items={petServices}
            name="primaryServiceSelection"
            onChange={e => {
              setCurrentPetServiceId(e?.target?.value);
              const service = getServiceDataById(petServices, e?.target?.value);
              onChange(service);
            }}
            defaultValue={petServiceId}
            disabled={isDisabled}
            style={{ textWrap: "wrap" }}
          />

          {serviceNotAvail && (
            <LayoutBox padding="scale-0" style={{ color: color.red700 }}>
              {SERVICE_NOT_AVAIL}
            </LayoutBox>
          )}
        </LayoutStack>
      </LayoutBox>
    </LoadingWrapper>
  );
};

export const HotelPrimaryServiceDropdown = connect(
  state => {
    const petId = getCurrentPet(state);
    const service = selectCurrentHotelPetService(state, { petId });

    return {
      componentId: "HotelPrimaryServiceDropdown",
      petServices: getPrimaryServicesNameAndPriceByPet({ petId })(state),
      serviceName: service?.petServiceName,
      petServiceId: service?.petServiceId,
      isLoading: createLoadingSelector([
        GET_HOTEL_ITINERARY,
        PATCH_HOTEL_PRIMARY_SERVICE_CHANGE,
        LOAD_PET_SERVICES_AT_HOTEL_POSTBOOKING,
      ])(state),
      isDisabled: selectIsReservationDisabled(state),
      petId,
    };
  },
  dispatch => {
    return {
      loadPetServices: petId => dispatch(loadPetServicesAtHotelPostBooking(petId)),
    };
  },
  ({ petId, ...stateProps }, { loadPetServices }, ownProps) => {
    return {
      ...stateProps,
      ...ownProps,
      loadPetServices: () => loadPetServices(petId),
    };
  },
)(PrimaryServiceDropdownComponent);
