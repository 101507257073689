import React from "react";
import { LayoutBox } from "@/layout/box/Box";
import { CheckboxField } from "@petsmart-ui/sparky";
import { CheckboxFieldItem } from "@petsmart-ui/sparky/dist/components/CheckboxField/CheckboxFieldItem";
import { connect } from "react-redux";
import {
  getFoodTimeOfDayById,
  getFoodTimeOfDayTouched,
  getIsFoodTimeOfDayEmpty,
} from "@/web/foodTimeOfDay/foodTimeOfDaySelectors";
import { REQUIRED_FIELD_ERROR_MESSAGE } from "@/core/constants/validationErrors";
import { getFoodIsDiscontinuedById } from "@/web/food/foodsSelector";
import {
  setFoodTimeOfDay,
  setTimeOfDayTouched,
} from "@/web/foodTimeOfDay/actions/foodTimeOfDayActions";
import { convertTimeOfDayObjToArr, convertTimeOfDayToObj } from "@/core/utils/checkInOutUtils";

export const TimeOfDayCheckboxes = props => {
  const {
    componentId,
    isHidden,
    label,
    error,
    morning,
    afternoon,
    evening,
    disabled,
    onChange,
    onBlur,
  } = props;

  if (isHidden) return null;

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <CheckboxField
        label={label}
        fieldNote={error}
        isError={!!error}
        variant="inline"
        size="lg"
        onBlur={onBlur}
      >
        <CheckboxFieldItem
          name="morning"
          text="Morning"
          checked={morning}
          onChange={checked => onChange({ morning: checked, afternoon, evening })}
          disabled={disabled}
          error={!!error}
          key={`morning-${morning}`}
        />
        <CheckboxFieldItem
          name="afternoon"
          text="Afternoon"
          checked={afternoon}
          onChange={checked => onChange({ morning, afternoon: checked, evening })}
          disabled={disabled}
          error={!!error}
          key={`afternoon-${afternoon}`}
        />
        <CheckboxFieldItem
          name="evening"
          text="Evening"
          checked={evening}
          onChange={checked => onChange({ morning, afternoon, evening: checked })}
          disabled={disabled}
          error={!!error}
          key={`evening-${evening}`}
        />
      </CheckboxField>
    </LayoutBox>
  );
};

export const FoodTimeOfDay = connect(
  (state, { petId, foodId, foodIndex }) => {
    const timeOfDay = getFoodTimeOfDayById(state, { foodId, petId });
    const { morning, afternoon, evening } = convertTimeOfDayToObj(timeOfDay);
    return {
      componentId: `FoodTimeOfDay-${foodId}`,
      label: `Food ${foodIndex} time of day`,
      morning,
      afternoon,
      evening,
      error:
        getFoodTimeOfDayTouched(state, { foodId, petId }) &&
        getIsFoodTimeOfDayEmpty(state, { foodId, petId }) &&
        REQUIRED_FIELD_ERROR_MESSAGE,
      disabled: getFoodIsDiscontinuedById(state, { petId, foodId }),
    };
  },
  (dispatch, { foodId, petId }) => ({
    onChange: ({ morning, afternoon, evening }) => {
      const timeOfDay = convertTimeOfDayObjToArr({ morning, afternoon, evening });
      dispatch(setFoodTimeOfDay({ foodId, timeOfDay, petId }));
    },
    onBlur: () => dispatch(setTimeOfDayTouched({ foodId, petId, timeOfDayTouched: true })),
  }),
)(TimeOfDayCheckboxes);
