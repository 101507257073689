import { put, call, all, select, takeLatest } from "redux-saga/effects";

// Actions
import {
  GET_DDC_PACKAGES,
  getDdcPackagesFailure,
  getDdcPackagesRequest,
  getDdcPackagesSuccess,
} from "./ddcPackagesActions";

// Endpoints
import { getDdcPackagesByCustomer } from "@/core/services/associateWebBff/ddcPackagesEndpoints";

// Selectors
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { getStoreRegion } from "@/core/selectors/entitiesSelector";

/**
 * Generator function to fetch ddc packages for a customer
 * @memberOf Sagas.ddcPackages
 * @generator
 * @name onGetDdcPackages
 * @returns {Generator<*, void, *>}
 */
function* onGetDdcPackages() {
  try {
    const storeNumber = yield select(getStoreNumber);
    const customerKey = yield select(getCurrentCustomerKey);
    const storeRegion = yield select(getStoreRegion, { storeNumber });

    yield put(getDdcPackagesRequest());

    const response = yield call(getDdcPackagesByCustomer, {
      customerKey,
      region: storeRegion,
      storeNumber,
    });

    yield put(getDdcPackagesSuccess(response?.data?.results));
  } catch (error) {
    yield put(getDdcPackagesFailure(error));
  }
}

function* watchOnGetDdcPackages() {
  yield takeLatest(GET_DDC_PACKAGES, onGetDdcPackages);
}

export default function* ddcPackagesSaga() {
  yield all([watchOnGetDdcPackages()]);
}
