import { color } from "../theme";

/**
 * Responsible for setting the color for the text
 *
 * @export SetInputFontColor
 * @param {boolean} { error, isLink, isStatic }
 * @returns
 */
export default function SetInputFontColor({ error, isLink, isStatic }) {
  if (error) {
    return color.red700;
  }

  if (isLink) {
    return color.blue500;
  }

  if (isStatic) {
    return color.gray300;
  }

  return color.gray800;
}
