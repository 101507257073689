import React, { useEffect } from "react";
import { connect } from "react-redux";
import { clearUiStore } from "core/actionCreators/ui/uiActionCreators";
import quickQuoteConstants from "core/constants/quickQuoteConstants";
import HeaderImage from "assets/images/bg-header-image.png";
import QuickQuoteHeaderContainer from "web/header/QuickQuoteHeaderContainer";
import { SalonQuickQuote } from "dux/_components/quickQuote/QuickQuoteServiceComponent";
import Cart from "web/cart/CartContainer";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutStack } from "@/layout/stack/Stack";
import { SalonQuickQuoteAppointmentColumn } from "@/dux/appointmentColumn/AppointmentColumn";

export const QuickQuoteComponent = ({ componentId, clearUiStore = () => {} }) => {
  // Unmount
  useEffect(() => {
    return () => {
      clearUiStore();
    };
  }, []);

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <QuickQuoteHeaderContainer backgroundImage={HeaderImage} />

      <LayoutCluster space="scale-0" style={{ flex: "1", alignItems: "normal" }}>
        <LayoutBox padding="scale-0" style={{ display: "flex", flex: "2.5" }}>
          <SalonQuickQuote petId={quickQuoteConstants.QUOTE_PET} />
        </LayoutBox>

        <LayoutStack
          padding="scale-0"
          style={{ flex: "1", boxShadow: "0 0.625rem 2.125rem 0 rgba(13, 18, 27, 0.12)" }}
        >
          <Cart petId={quickQuoteConstants.QUOTE_PET} multiplePets isQuickQuote />
          <SalonQuickQuoteAppointmentColumn />
        </LayoutStack>
      </LayoutCluster>
    </LayoutBox>
  );
};

export const QuickQuoteContainer = connect(
  () => ({ componentId: "QuickQuote" }),
  dispatch => ({
    clearUiStore: () => dispatch(clearUiStore()),
  }),
)(QuickQuoteComponent);
