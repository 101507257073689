import { difference, get, has } from "lodash/fp";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { APPOINTMENT_DETAILS_TYPES, APPOINTMENT_STATUS } from "core/constants";
import { modalTypes as bookingModalTypes } from "core/constants/bookingConstants";
import { modalTypes, pageNames } from "core/constants/checkInOutConstants";
import quickQuoteConstants from "core/constants/quickQuoteConstants";
import getUnknownPetImage from "core/utils/assetUtils/unknownPetImage";
import isAppointmentBookedOrConfirmed from "core/utils/checkInOutUtils/isAppointmentBookedOrConfirmed";
import calculateAge from "core/utils/dateUtils/calculateAge";
import { formatMoment } from "core/utils/dateUtils/formatDateTime";
import AvailableBGMBundlePriceContainer from "dux/bgm/availableBundlesByPet/applyAvailableBundle/AvailableBGMBundlePriceContainer";
import AvailableFTCOBundlePriceContainer from "dux/bgm/availableBundlesByPet/applyAvailableBundle/AvailableFTCOBundlePriceContainer";
import PreCheckedInIcon from "dux/digitalServiceCard/DigitalServiceCardPreCheckedIcon";
import HistoryImage from "assets/icons/history.svg";
import UncheckedStarImage from "assets/icons/star-unchecked.svg";
import StarImage from "assets/icons/star.svg";
import AssociateDropdown from "web/common/associateDropdown/AssociateDropdownContainer";
import Calendar from "web/common/Calendar";
import Checkbox from "web/common/Checkbox";
import ClickableText from "web/common/ClickableText";
import { SelectionCopy } from "web/common/customFlyout/customFlyoutSelection";
import ReduceMaxLimit from "web/common/ReduceMaxLimit/ReduceMaxLimitContainer";
import StarIcon from "web/common/starIcon/starIconContainer";
import StyledPopover from "web/common/StyledPopover";
import TimeRangePickerToggle from "web/common/TimeRange/TimeRangePickerToggleContainer";
import { SalonAppointmentNotes } from "dux/notes/appointmentNotes/AppointmentNotesComponent";
import InvoiceGeneratedContainer from "web/petCheckInOut/appointmentDetails/invoiceGenerated/InvoiceGeneratedContainer";
import PaidVoidTransactionContainer from "web/petCheckInOut/appointmentDetails/paidVoidTransaction/PaidVoidTransactionContainer";
import Icons from "web/upcoming_here/icons/iconsContainer";
import Addon from "web/cart/Addon";
import AddOnsFlyout from "web/cart/AddonsFlyoutContainer";
import ServiceSelectionFlyout from "web/cart/ServiceSelectionFlyout";
import Specials from "web/cart/SpecialsContainer";
import { fontSizes } from "web/common/styles/responsive/fonts";
import { color } from "../common/styles/theme";
import { default as EditSVG } from "assets/icons/edit.svg";
import { SalonAppointmentPrice } from "dux/appointmentPrice/AppointmentPriceComponent";
import { Layout } from "@prism/psm-ui-components";
import { SalonPriceChange } from "dux/priceChange/PriceChangeComponent";
import SalonApplySpeicalsList from "web/common/SalonApplySpeicalsList";
import EnhancedServicesFlyoutComponent from "./EnhancedServicesFlyoutContainer";
import { TextWithEllipsis } from "@/dux/_components/textWithEllipsis/TextWithEllipsis";
import { preferredNameConstants } from "@/core/constants/associatesConstants";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import {
  ApplyBGMAvailableBundle,
  ApplyFTCOAvailableBundle,
  ManualApplyBundle,
} from "dux/_components/bgm/availableBundlesByPet/applyAvailableBundle/ApplyAvailableBundleComponent";
import { SalonEnhancedServiceDetails } from "@/dux/enhancedServiceDetails/EnhancedServiceDetails";

const ServiceName = styled.div`
  font-size: ${fontSizes.regular};
  font-weight: bold;
`;

const CartDetails = styled.div`
  display: flex;
  flex-direction: column;
  ${({ multiplePets, isSelectedPet }) =>
    multiplePets &&
    `
        border: 1px solid ${isSelectedPet ? color.blue500 : color.gray300};
    `};
  margin-bottom: 1.25rem;
`;

const CartDetailsHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ multiplePets }) => (multiplePets ? 1.5 : 0)}rem 1.5rem 1.5rem 1.5rem;
  border-bottom: 1px solid ${color.gray300};
`;

const PetInfoWrapper = styled.div`
  display: flex;
  margin-bottom: 0.3125rem;
  align-items: center;
  flex-direction: row;
`;

const AssociateDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border-bottom: 1px solid ${color.gray300};
`;

const AppointmentDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ multiplePets }) => (multiplePets ? 1.5 : 0)}rem 1.5rem;
  border-bottom: 1px dashed ${color.gray300};
`;

const TotalPrice = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
`;

const CancelButton = styled.span`
  color: ${color.red700};
  cursor: pointer;
  display: flex;
  align-items: center;
  pointer-events: ${props => (props.disabled ? "none" : "auto")};
  opacity: ${props => (props.disabled ? "0.5" : "1")};
`;

const EligibilityWarning = styled.span`
  color: ${color.red700};
  margin-bottom: 5px;
`;

const HistoryIcon = styled.img`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 1.1em;
  cursor: pointer;
  margin-left: 5px;
`;

const SinglePetHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const PetImgAndInfo = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`;

const PetImg = styled.img`
  width: 57px;
  height: 57px;
`;

const AppointmentInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const PetInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;
  width: 100%;
`;

const PetName = styled.div`
  font-weight: bold;
`;

const TextInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3px;
`;

const AgeBreed = styled.div`
  margin-left: 5px;
`;

const InfoLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
`;

const StoreLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NameWithButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CheckboxWithButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const AppointmentSummaryHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
`;

const AppointmentNotes = styled.div`
  padding: 1.5em 1.5em 2.5em 1.5em;
`;

const PetImageNameWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PetImageName = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 15px;
`;

const ServiceNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const EditIcon = styled.img`
  margin: 0 8px;
  cursor: pointer;
`;

const formatTimeSlot = timeSlot => {
  if (timeSlot.startDateTime) {
    const startDateTime = moment(timeSlot.startDateTime);
    const endDateTime = startDateTime.clone().add(timeSlot.duration, "minutes");
    return `${startDateTime.format("dddd, MM/DD/YY hh:mm A")} - ${endDateTime.format("hh:mm A")}`;
  }
};

class CartDetailsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddons: false,
      showEnhancedServices: false,
      showSpecials: false,
      boardedGuest: false,
      invoicePaid: false,
      showServiceSelection: false,
      showEnhancedServicePriceAdjustFlyout: false,
    };

    this.addonsRef = React.createRef();
    this.enhancedServicesRef = React.createRef();
    this.specialRef = React.createRef();
    this.handleHistoryPopover = this.handleHistoryPopover.bind(this);
  }

  componentDidMount() {
    const {
      petServiceItem,
      appointment,
      loadServices,
      loadAddons,
      loadSpecials,
      currentStore,
      fetchStore,
      loadCancellationReasons,
    } = this.props;

    if (petServiceItem) {
      this.initializeCartData(petServiceItem);
      loadServices(petServiceItem);
      loadAddons(petServiceItem);
      loadSpecials(petServiceItem);
      loadCancellationReasons();
      if (!currentStore) {
        fetchStore({ storeNumber: currentStore });
      }
    } else {
      appointment && appointment.petService && loadAddons({ petServiceId: appointment.petService });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      petServiceItem,
      appointment,
      loadServices,
      loadAddons,
      loadSpecials,
      currentStore,
      fetchStore,
      pageName,
      onGetPetEligibility,
    } = this.props;
    if (!petServiceItem) {
      if (get("petService", appointment) !== get(["appointment", "petService"], prevProps)) {
        loadAddons({ petServiceId: appointment.petService });
      }
      return;
    }

    if (!currentStore) {
      fetchStore({ storeNumber: currentStore });
    }

    const didTimeChange =
      get("startDateTime", petServiceItem) !== get(["petServiceItem", "startDateTime"], prevProps);

    const shouldLoadServices = !prevProps.petServiceItem || didTimeChange;
    const shouldLoadAddonsAndSpecials =
      !prevProps.petServiceItem ||
      shouldLoadServices ||
      prevProps.petServiceItem.addOns.length !== petServiceItem.addOns.length ||
      petServiceItem.petServiceId !== prevProps.petServiceItem.petServiceId;

    const shouldCallEligibility = pageName === pageNames.CHECK_IN && didTimeChange;

    if (shouldLoadServices) {
      loadServices(petServiceItem);
    }

    if (shouldLoadAddonsAndSpecials) {
      loadAddons(petServiceItem);
      loadSpecials(petServiceItem);
    }

    if (shouldCallEligibility) {
      onGetPetEligibility({
        petId: petServiceItem.pet,
        startDateTime: petServiceItem.startDateTime,
      });
    }
  }

  initializeCartData = () => {
    const { petServiceItem, initializeCart } = this.props;
    initializeCart(petServiceItem);
  };

  renderSpecialsFlyout = () => {
    const { availableSpecials, selectedSpecials } = this.props;

    return (
      <SalonApplySpeicalsList
        idProperty="code"
        options={availableSpecials}
        selectedOptions={selectedSpecials}
        closeFlyout={this.handleSpecialsDoneClick}
      />
    );
  };

  renderTimePicker = () => {
    const {
      appointment: { timeSlot, isStandalone },
      petServiceItem,
      onUpdatePetServiceItem,
      isEditable,
    } = this.props;
    const startDateTime = petServiceItem.startDateTime
      ? moment(petServiceItem.startDateTime)
      : moment(timeSlot.startDateTime);
    const endDateTime = startDateTime.clone().add(timeSlot.duration, "minutes");
    return (
      <TimeRangePickerToggle
        startTime={startDateTime}
        disabled={isStandalone || !isEditable}
        petServiceItem={petServiceItem}
        endTime={endDateTime}
        onSave={({ startTime, endTime }) => {
          const newStartDateTime = moment(startDateTime).set({
            hours: startTime.hours(),
            minutes: startTime.minutes(),
          });
          const newEndDateTime = moment(startDateTime)
            .add(timeSlot.duration, "minutes")
            .set({ hours: endTime.hours(), minutes: endTime.minutes() });
          onUpdatePetServiceItem({
            petServiceItem,
            data: {
              startDateTime: formatMoment(newStartDateTime),
              duration: newEndDateTime.diff(newStartDateTime, "minutes"),
              isManualAppointment: true,
            },
          });
        }}
      />
    );
  };

  handleSpecialsDoneClick = specials => {
    // specials is the selectedOptions from flyOutWithCheckboxes. selectedOptions is the internal state but is set from and external selectedOptions property
    // specials is what initially comes in as applied specials from itinerary, but is then set to local state. when checked
    // specials is the list of (codes) what is checked.
    const { petServiceItem, appliedSpecials, postSpecials, removeSpecials } = this.props;
    this.setState({ showSpecials: false });

    if (!petServiceItem) {
      return;
    }

    // appliedSpecials comes from the itinerary.
    const specialsToAdd = difference(specials, appliedSpecials);
    const specialsToRemove = difference(appliedSpecials, specials);

    const { customer, itinerary } = petServiceItem;
    specialsToAdd.length &&
      postSpecials({
        customerId: customer,
        itineraryId: itinerary,
        specials: specialsToAdd,
      });
    specialsToRemove.length &&
      removeSpecials({
        customerId: customer,
        itineraryId: itinerary,
        specials: specialsToRemove,
      });
  };

  handleCancelClick = () => {
    this.props.showCancelAppointmentModal(modalTypes.CANCEL_APPOINTMENT);
  };

  handleBookingCancelClick = event => {
    const {
      petServiceItemId,
      showBookingModal,
      isModifying,
      onSetCurrentBookingPetServiceItemId,
      onCancelAppointment,
      setPetIdToRemoveFromCart,
      petId,
    } = this.props;
    event.stopPropagation();
    if (isModifying) {
      onSetCurrentBookingPetServiceItemId(petServiceItemId);
      onCancelAppointment();
    } else {
      setPetIdToRemoveFromCart(petId);
      showBookingModal(bookingModalTypes.CANCEL_BOOKING_APPOINTMENT);
    }
  };

  handleUndoClick = () => {
    const { petServiceItem, undoLastEngagementStatusUpdate, showConfirmationModal } = this.props;
    const {
      customer: customerId,
      engagement: engagementId,
      itinerary: itineraryId,
    } = petServiceItem;

    showConfirmationModal({
      header: "Are you sure?",
      content: "Are you sure want to undo?",
      confirmText: "Yes",
      confirm: () =>
        undoLastEngagementStatusUpdate({
          customerId,
          engagementId,
          itineraryId,
        }),
    });
  };

  handleUndoForAllClick = () => {
    const { petServiceItem, undoLastItineraryStatusUpdate, showConfirmationModal } = this.props;

    const { customer: customerId, itinerary: itineraryId } = petServiceItem;

    showConfirmationModal({
      header: "Are you sure?",
      content: "Are you sure want to undo for all?",
      confirmText: "Yes",
      confirm: () => undoLastItineraryStatusUpdate({ customerId, itineraryId }),
    });
  };

  getStoreFullAddress = () => {
    const { currentStore } = this.props;

    if (!currentStore) {
      return "";
    }

    const {
      StreetLine1,
      StreetLine2,
      City,
      StateProvinceAbbreviation,
      ZipPostalCode,
    } = currentStore;

    return `${StreetLine1} ${StreetLine2 ||
      ""} ${City}, ${StateProvinceAbbreviation} ${ZipPostalCode}`;
  };

  getStoreNameNumber = () => {
    const { currentStore } = this.props;

    if (!currentStore) {
      return "";
    }

    const { Name, StoreNumber } = currentStore;

    return `${Name} #${StoreNumber}`;
  };

  handleBoardedGuestChecked = () => {
    const {
      isBoarded,
      setIsBoarded,
      clearIsBoarded,
      petServiceItem,
      onUpdatePetServiceItem,
    } = this.props;

    onUpdatePetServiceItem({ petServiceItem, data: { isBoarded: !isBoarded } });

    if (isBoarded) {
      clearIsBoarded();
    } else {
      setIsBoarded();
    }
  };

  callServiceComplete = values => {
    const { onServiceCompleteForEngagement, onServiceCompleteForItinerary } = this.props;

    return () => {
      if (values && values.engagementId) {
        onServiceCompleteForEngagement({ engagementId: values.engagementId });
      } else {
        onServiceCompleteForItinerary();
      }
    };
  };

  handleServiceComplete = engagementId => {
    const { showConfirmationModal } = this.props;

    showConfirmationModal({
      header: "Service Complete Reminder",
      content: (
        <div>
          Have all services/add-ons been updated on the appointment?
          <br />
          Completing this step will send the invoice to POS and
          <br />
          no further changes can be made to the appointment.
        </div>
      ),
      confirmText: "Proceed Anyway",
      confirm: this.callServiceComplete(engagementId),
      cancelText: "Cancel",
    });
  };

  renderMultiplePetsHeader = () => {
    const {
      appointment,
      pet,
      canOverride,
      isCanceling,
      isBoarded,
      petServiceItem,
      petServiceItemId,
      isFromAnotherStore,
      canNotBookPets,
    } = this.props;
    const { timeSlot, isStandalone } = appointment;
    const { status } = petServiceItem;

    const isCanceled = status === APPOINTMENT_STATUS.CANCELED;

    return (
      <PetImageNameWrapper>
        {has(pet.petId, canNotBookPets) && (
          <EligibilityWarning>
            {`Error: ${pet.petName} is not eligible for booking`}
          </EligibilityWarning>
        )}
        <PetImageName>
          <PetImg src={getUnknownPetImage(pet)} />
          <PetInfo>
            <NameWithButton>
              <PetName>{pet.petName}</PetName>
              <CancelButton
                onClick={this.handleBookingCancelClick}
                disabled={isCanceling || isFromAnotherStore || isCanceled}
              >
                Cancel
              </CancelButton>
            </NameWithButton>
            <Icons pet={pet} appointment={petServiceItem} petServiceItemId={petServiceItemId} />
            {canOverride && !isStandalone ? (
              <>
                {this.renderCalendar()}
                {this.renderTimePicker()}
              </>
            ) : (
              <b>{timeSlot && formatTimeSlot(appointment.timeSlot)}</b>
            )}
          </PetInfo>
        </PetImageName>
        <Checkbox
          checked={isBoarded}
          label="Boarded Guest"
          labelPosition="right"
          onChange={this.handleBoardedGuestChecked}
        />
      </PetImageNameWrapper>
    );
  };

  renderDateLabel = () => {
    const { appointment } = this.props;
    const selectedDate =
      appointment.timeSlot && moment(appointment.timeSlot.startDateTime).format("dddd, MM/DD/YY");

    return <>{selectedDate}</>;
  };

  renderCalendar = () => {
    const { appointment, onUpdatePetServiceItem, petServiceItem, isEditable } = this.props;
    const { isStandalone } = appointment;

    const selectedDate =
      appointment.timeSlot && moment(appointment.timeSlot.startDateTime).format("YYYY-MM-DD");

    return (
      <Calendar
        selectedDate={selectedDate}
        disabled={isStandalone || !isEditable}
        displayFormat="dddd, MM/DD/YY"
        selectDate={date => {
          const d = moment(date);
          const newStartDateTime = moment(appointment.timeSlot.startDateTime).set({
            year: d.year(),
            month: d.month(),
            date: d.date(),
          });
          onUpdatePetServiceItem({
            petServiceItem,
            data: {
              startDateTime: formatMoment(newStartDateTime),
              isManualAppointment: true,
            },
          });
        }}
      />
    );
  };

  handleHistoryPopover = e => {
    e.stopPropagation();
    const { onShowAppointmentHistory, petServiceItem } = this.props;
    if (petServiceItem) {
      const { petServiceItemId } = petServiceItem;

      if (!petServiceItemId) {
        return;
      }

      onShowAppointmentHistory({ petServiceItemId });
    }
  };

  renderSinglePetHeader = () => {
    const {
      pet,
      appointment,
      petServiceItem,
      onModifyAppointment,
      canOverride,
      appointmentDetailsType,
      isEditable,
      isBoarded,
      shouldShowServiceCompleteForAll,
      numberOfPets,
      isFromAnotherStore,
      breedName,
      isSRCAgent,
      isPreCheckedIn,
      isMultiPet,
    } = this.props;

    const { petServiceItemId, status, engagement: engagementId } = petServiceItem;
    const { birthDate } = pet;
    const { timeSlot } = appointment;
    const age = calculateAge(birthDate);
    const isCanceled = status === APPOINTMENT_STATUS.CANCELED;
    const isCheckedIn = status === APPOINTMENT_STATUS.CHECKED_IN;
    const isCheckedOut = status === APPOINTMENT_STATUS.CHECKED_OUT;
    const showUndoForAll = numberOfPets > 1;

    return (
      <SinglePetHeader>
        <PetImgAndInfo>
          <PetImg src={getUnknownPetImage(pet)} />
          <PetInfo>
            <TextInfo>
              <PetName>{pet.petName}</PetName>
              <AgeBreed>{`${age}, ${breedName}`}</AgeBreed>
            </TextInfo>
            <Icons pet={pet} appointment={petServiceItem} petServiceItemId={petServiceItemId} />
          </PetInfo>
        </PetImgAndInfo>

        <AppointmentInfo>
          <InfoLine>
            <TextInfo>
              Appointment # {petServiceItemId}
              <HistoryIcon src={HistoryImage} onClick={this.handleHistoryPopover} />
            </TextInfo>
          </InfoLine>

          {isPreCheckedIn && (
            <InfoLine>
              <TextInfo>
                <PreCheckedInIcon petServiceItemId={petServiceItemId} />
              </TextInfo>
            </InfoLine>
          )}

          <InfoLine>
            Status: {status}
            <ClickableText
              isVisible={isCheckedOut}
              styleObj={{ color: color.red700 }}
              onClick={this.handleUndoClick}
              disabled={isFromAnotherStore || isSRCAgent}
            >
              Undo check-out
            </ClickableText>
            {(appointmentDetailsType === APPOINTMENT_DETAILS_TYPES.CHECK_OUT ||
              status === APPOINTMENT_STATUS.CONFIRMED) && (
              <ButtonsContainer>
                <ClickableText
                  isVisible
                  styleObj={{ color: color.red700 }}
                  onClick={this.handleUndoClick}
                  disabled={isFromAnotherStore}
                >
                  Undo
                </ClickableText>
                {showUndoForAll && (
                  <ClickableText
                    isVisible
                    styleObj={{ color: color.red700 }}
                    onClick={this.handleUndoForAllClick}
                    disabled={isFromAnotherStore}
                  >
                    Undo for all
                  </ClickableText>
                )}
                {isCheckedIn && (
                  <ClickableText
                    isVisible
                    styleObj={{ color: color.blue500 }}
                    onClick={() => this.handleServiceComplete({ engagementId })}
                    disabled={isFromAnotherStore || isSRCAgent}
                  >
                    Service complete
                  </ClickableText>
                )}
                {shouldShowServiceCompleteForAll && (
                  <ClickableText
                    isVisible
                    styleObj={{ color: color.blue500 }}
                    onClick={() => this.handleServiceComplete({ engagementId: null })}
                    disabled={isFromAnotherStore || isSRCAgent}
                  >
                    Service complete for all
                  </ClickableText>
                )}
              </ButtonsContainer>
            )}
          </InfoLine>
          <StoreLine>{this.getStoreNameNumber()}</StoreLine>
          <InfoLine>{this.getStoreFullAddress()}</InfoLine>
          <InfoLine>
            {canOverride ? (
              <>
                {isMultiPet ? (
                  <ClickableText
                    isVisible={
                      appointmentDetailsType !== APPOINTMENT_DETAILS_TYPES.PAST_APPOINTMENT
                    }
                    styleObj={{ color: color.blue500 }}
                    onClick={onModifyAppointment}
                    disabled={!isEditable}
                  >
                    {this.renderDateLabel()}
                  </ClickableText>
                ) : (
                  <>{this.renderCalendar()}</>
                )}

                {this.renderTimePicker()}
              </>
            ) : (
              <b>{timeSlot && formatTimeSlot(appointment.timeSlot)}</b>
            )}
          </InfoLine>
          <CheckboxWithButtons>
            <Checkbox
              disabled={!isEditable}
              checked={isBoarded}
              label="Boarded Guest"
              labelPosition="right"
              onChange={this.handleBoardedGuestChecked}
            />
            <ButtonsContainer>
              <ClickableText
                isVisible={appointmentDetailsType !== APPOINTMENT_DETAILS_TYPES.PAST_APPOINTMENT}
                styleObj={{ color: color.blue500 }}
                onClick={onModifyAppointment}
                disabled={!isEditable}
              >
                Modify Appointment
              </ClickableText>
              <ClickableText
                isVisible={
                  appointmentDetailsType == APPOINTMENT_DETAILS_TYPES.PAST_APPOINTMENT
                    ? isAppointmentBookedOrConfirmed(status)
                    : appointmentDetailsType !== APPOINTMENT_DETAILS_TYPES.CHECK_OUT
                }
                disabled={isCanceled || isFromAnotherStore}
                styleObj={{ color: color.red700 }}
                onClick={this.handleCancelClick}
              >
                Cancel
              </ClickableText>
            </ButtonsContainer>
          </CheckboxWithButtons>
        </AppointmentInfo>
      </SinglePetHeader>
    );
  };

  renderGuestPetHeader = () => {
    const { pet } = this.props;
    const { birthDate, breedName } = pet;
    const age = calculateAge(birthDate);

    return (
      <div>
        {breedName}, {age}
      </div>
    );
  };

  renderPetHeader = multiplePets => {
    const { pet } = this.props;

    if (pet.petId === quickQuoteConstants.QUOTE_PET) {
      return this.renderGuestPetHeader();
    }
    return multiplePets ? this.renderMultiplePetsHeader() : this.renderSinglePetHeader();
  };

  onChangeAssociate = associateId => {
    const {
      onUpdatePetServiceItem,
      petServiceItem,
      appointment,
      showConfirmationModal,
    } = this.props;

    if (!appointment.lockToAssociate) {
      onUpdatePetServiceItem({
        petServiceItem,
        data: { associateId, isManualAppointment: true },
      });
      return;
    }

    // Appointment is locked to associate
    showConfirmationModal({
      header: "Preferred associate change",
      content: (
        <div>
          The associate selected for this appointment is the preferred associate.
          <br />
          Are you sure you want to change the associate?
        </div>
      ),
      confirmText: "Continue",
      confirm: () => {
        onUpdatePetServiceItem({
          petServiceItem,
          data: {
            associateId,
            lockToAssociate: false,
            isManualAppointment: true,
          },
        });
      },
    });
  };

  renderAddonsFlyout() {
    const { appointment, selectedPet, pet, petServiceItem } = this.props;
    const mainHeading = appointment.isStandalone ? "Standalones" : "Add-ons";

    return (
      <AddOnsFlyout
        mainHeading={mainHeading}
        selectedAddons={appointment.addons}
        petId={selectedPet || (pet && pet.petId)}
        onClose={() => this.setState({ showAddons: false })}
        petServiceId={appointment.petService}
        petServiceItem={petServiceItem}
      />
    );
  }

  renderEnhancedServicesFlyout() {
    const {
      appointment: { isStandalone },
    } = this.props;
    return (
      <EnhancedServicesFlyoutComponent
        onClose={() => this.setState({ showEnhancedServices: false })}
        isStandalone={isStandalone}
      />
    );
  }

  render() {
    const {
      appointment,
      totalPrice,
      pet,
      associate,
      selectedPet,
      onSelectPet,
      service,
      addons,
      multiplePets,
      pageName,
      petServiceItem,
      petServiceId,
      isQuickQuote,
      router,
      isEditable,
      isSRCAgent,
      canOverride,
      isHidden,
      isEnhancedServicesVisible,
      isSelectedBundleEnhancedService,
      preSelectedBundleEnhancedServicePrice,
    } = this.props;
    const { customerKey, itineraryId } = router.params;
    const {
      showAddons,
      showEnhancedServices,
      showSpecials,
      showServiceSelection,
      showEnhancedServicePriceAdjustFlyout,
    } = this.state;
    const dynamicPrice = petServiceItem && petServiceItem.dynamicPrice;
    const showPaidVoidTransaction =
      itineraryId && !isQuickQuote && (!multiplePets || pageName !== pageNames.CHECK_IN);
    const showNotes = !(appointment.isStandalone || Boolean(isQuickQuote));
    const isCheckedOut = get("status", petServiceItem) === APPOINTMENT_STATUS.CHECKED_OUT;
    const timeSlot = appointment?.timeSlot;

    if (!service) {
      return null;
    }

    if (!isHidden) {
      return (
        <CartDetails
          multiplePets={multiplePets}
          isSelectedPet={pet.petId === selectedPet}
          onClick={() => multiplePets && onSelectPet(pet.petId)}
        >
          <CartDetailsHeader multiplePets={multiplePets}>
            <PetInfoWrapper>{this.renderPetHeader(multiplePets)}</PetInfoWrapper>
          </CartDetailsHeader>

          {associate && (
            <AssociateDetails>
              <div>
                {canOverride ? (
                  <AssociateDropdown
                    disabled={!isEditable}
                    selectedAssociate={associate.associateId}
                    includeAllSuitable={false}
                    onSelectAssociate={this.onChangeAssociate}
                  />
                ) : (
                  <TextWithEllipsis
                    style={{ fontWeight: "bold" }}
                    limit={preferredNameConstants.limit}
                  >
                    {associate.associateName}
                  </TextWithEllipsis>
                )}
                , {associate.associateGroup}
                <StarIcon
                  disabled={!isEditable}
                  src={appointment.lockToAssociate ? StarImage : UncheckedStarImage}
                  value={petServiceItem}
                />
              </div>
            </AssociateDetails>
          )}

          {petServiceItem && (
            <AppointmentSummaryHeader>
              <h4>Appointment summary</h4>

              <StyledPopover
                body={this.renderSpecialsFlyout()}
                isOpen={showSpecials}
                preferPlace="below"
                onOuterAction={() => this.setState({ showSpecials: false })}
                tipSize={7}
              >
                <ClickableText
                  isVisible
                  disabled={!isEditable || isSRCAgent}
                  styleObj={{ color: color.blue500 }}
                  onClick={() => this.setState({ showSpecials: true })}
                  innerRef={this.specialRef}
                >
                  Apply Special
                </ClickableText>
              </StyledPopover>
            </AppointmentSummaryHeader>
          )}

          <AppointmentDetails multiplePets={multiplePets}>
            {!appointment.isStandalone && (
              <DetailsRow>
                <ServiceNameWrapper>
                  <ServiceName>{service.name}</ServiceName>
                  {service && isEditable && (
                    <StyledPopover
                      body={
                        <ServiceSelectionFlyout
                          petId={pet.petId}
                          petServiceItem={petServiceItem}
                          onClose={() => this.setState({ showServiceSelection: false })}
                        />
                      }
                      isOpen={showServiceSelection}
                      preferPlace="below"
                      onOuterAction={() => this.setState({ showServiceSelection: false })}
                      tipSize={7}
                    >
                      <EditIcon
                        src={EditSVG}
                        onClick={() => this.setState({ showServiceSelection: true })}
                      />
                    </StyledPopover>
                  )}
                </ServiceNameWrapper>

                <DetailsRow>
                  {!timeSlot ? (
                    <AvailableBGMBundlePriceContainer
                      petId={pet.petId}
                      petServiceId={petServiceId}
                    />
                  ) : null}
                  {!timeSlot ? (
                    <AvailableFTCOBundlePriceContainer
                      petId={pet.petId}
                      petServiceId={petServiceId}
                    />
                  ) : null}

                  <Layout.Cluster space="cluster-space-3">
                    <SalonAppointmentPrice
                      petId={pet.petId}
                      isPriceChange={preSelectedBundleEnhancedServicePrice && !timeSlot}
                    />
                    <SalonPriceChange petId={pet.petId} />
                  </Layout.Cluster>
                </DetailsRow>
              </DetailsRow>
            )}

            <ApplyBGMAvailableBundle />
            <ApplyFTCOAvailableBundle />

            <ManualApplyBundle />

            {dynamicPrice && (
              <Specials
                key={dynamicPrice?.specials}
                isEditable={isEditable}
                dynamicPrice={dynamicPrice}
                customerId={customerKey}
                itineraryId={itineraryId}
                petId={pet.petId}
              />
            )}

            {isEnhancedServicesVisible && (
              <StyledPopover
                style={{ width: "30%" }}
                body={this.renderEnhancedServicesFlyout()}
                isOpen={showEnhancedServices}
                place="left"
                onOuterAction={() => this.setState({ showEnhancedServices: false })}
                tipSize={7}
              >
                <SelectionCopy
                  isEnabled={!!service && isEditable && !isCheckedOut}
                  innerRef={this.enhancedServicesRef}
                  onClick={() =>
                    this.setState({
                      showEnhancedServices: true,
                    })
                  }
                >
                  Enhanced Services
                </SelectionCopy>
              </StyledPopover>
            )}

            <SalonEnhancedServiceDetails petId={pet?.petId} />

            <StyledPopover
              body={this.renderAddonsFlyout()}
              isOpen={showAddons}
              place="left"
              onOuterAction={() => this.setState({ showAddons: false })}
              tipSize={7}
            >
              <SelectionCopy
                isEnabled={!!service && isEditable && !isCheckedOut}
                innerRef={this.addonsRef}
                onClick={() =>
                  this.setState({
                    showAddons: true,
                  })
                }
              >
                {appointment.isStandalone ? "Standalones" : "Addons"}
              </SelectionCopy>
            </StyledPopover>

            {/*ADDONS --------------------------------------> */}
            <LayoutBox id="CartDetails__Addons" padding="scale-0">
              <LayoutStack space="scale-S1">
                {addons &&
                  addons.map(
                    addon =>
                      addon.addOnId && (
                        <Addon
                          key={addon.addOnId}
                          addon={addon}
                          petId={pet.petId}
                          customerId={customerKey}
                          itineraryId={itineraryId}
                          petServiceItem={petServiceItem}
                          timeSlot={timeSlot}
                          isHidden={isSelectedBundleEnhancedService}
                        />
                      ),
                  )}
              </LayoutStack>
            </LayoutBox>
            {/* ADDONS <--------------------------------------*/}

            {!appointment.isStandalone && (
              <ReduceMaxLimit
                disabled={!isEditable}
                customerKey={customerKey}
                petId={pet.petId}
                currentAppointment={petServiceItem}
              />
            )}
          </AppointmentDetails>

          <TotalPrice>
            <DetailsRow>
              <div>Total Price</div>
              <div>${totalPrice && totalPrice.toFixed(2)}</div>
            </DetailsRow>

            {showPaidVoidTransaction && <PaidVoidTransactionContainer />}
            <InvoiceGeneratedContainer />
          </TotalPrice>

          {showNotes && (
            <AppointmentNotes>
              <SalonAppointmentNotes
                label="Appointment Notes"
                petId={pet.petId}
                customerId={customerKey}
                itineraryId={itineraryId || (petServiceItem && petServiceItem.itinerary)}
                petServiceItemId={petServiceItem && petServiceItem.petServiceItemId}
              />
            </AppointmentNotes>
          )}
        </CartDetails>
      );
    }

    return null;
  }
}

export default CartDetailsComponent;
