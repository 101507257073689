import React from "react";
import { connect } from "react-redux";

// Components
import { TextPassage } from "@petsmart-ui/sparky";
import { color } from "@/web/common/styles/theme";

/**
 * Over Booking Setup Table Row Component
 * @param {Object} props - props object
 * @param {Object} props.room - room information
 * @returns {(JSX.Element|null)}
 */
const SetupTableRow = ({ room }) => {
  const {
    alerts,
    roomTypeBucketName,
    physicalRoomCount,
    overBookingPercentage,
    overBookingAmount,
    totalCapacity,
  } = room;

  const style = {
    padding: "10px",
    textAlign: "left",
  };

  return (
    <>
      <tr
        style={{
          textAlign: "left",
        }}
      >
        <td style={style}>{roomTypeBucketName}</td>
        <td style={style}>{physicalRoomCount}</td>
        <td style={style}>{overBookingPercentage}%</td>
        <td style={style}>{overBookingAmount}</td>
        <td style={style}>{totalCapacity}</td>
      </tr>
      <tr
        style={{
          borderBottom: "1px solid gray",
          textAlign: "left",
        }}
      >
        <td colSpan="6">
          <TextPassage style={{ ...(alerts && style), color: color.red700 }}>
            {alerts?.map((alert, index) => (
              <p key={index}>{alert?.alertText}</p>
            ))}
          </TextPassage>
        </td>
      </tr>
    </>
  );
};

// CONTAINER(s) ----------------------------------------------------------------------------------------
export const OverBookingSetupTableRow = connect(
  state => {
    return {};
  },

  dispatch => {
    return {};
  },
)(SetupTableRow);
