import React from "react";
import { LayoutGrid } from "@/layout/grid/Grid";
import { LayoutStack } from "@/layout/stack/Stack";

export const FoodFormLayout = ({
  componentId,
  children: [type, name, otherName, amount, ...rest],
}) => {
  return (
    <LayoutStack id={componentId} space="scale-G1">
      <LayoutGrid style={{ gridTemplateColumns: "repeat(2, 1fr)", columnGap: "1em" }}>
        {type}
        {name}
        {otherName}
        {amount}
      </LayoutGrid>
      {rest}
    </LayoutStack>
  );
};
