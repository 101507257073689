import React from "react";
import { connect } from "react-redux";
import { Layout, Hover, Button } from "@prism/psm-ui-components";
import { getAvailableTrainingTimeSlotsForSelectedDate } from "dux/trainingClassAvailability/trainingClassAvailabilitySelectors";
import { color } from "web/common/styles/theme";
import { getStoreNumber, getCurrentPet } from "core/selectors/persistentSelectors";
import {
  getSelectedTrainingPetService,
  getTrainingEngagementNote,
} from "@/dux/scheduleTrainingPage/scheduleTrainingPageSelectors";
import {
  getSelectedTimeSlot,
  getCustomerTimeZone,
} from "@/dux/scheduleTrainingAppointment/scheduleTrainingAppointmentSelectors";
import { postTrainingClassItinerary } from "@/dux/trainingClassItineraries/trainingClassItinerariesActions";
import {
  getCurrentCustomerKey,
  getCurrentCustomerPrimaryPhoneNumber,
  getCurrentCustomerPrimaryPhoneType,
} from "@/core/selectors/persistent/customer/customerSelectors";
import { setSelectedTimeSlot } from "@/dux/scheduleTrainingAppointment/scheduleTrainingAppointmentActions";
import TrainingClassAvailabilityNoApptAvailableMessage from "./TrainingClassAvailabilityNoApptAvailableMessage";
import { ScheduleTrainingAppointmentDetailsComponent } from "@/dux/scheduleTrainingAppointment/ScheduleTrainingAppointmentDetails";

export function TrainingClassAvailabilityList(props) {
  const { isHidden, componentId, availableTimeSlots, selectedTimeSlot, onClick } = props;
  if (!isHidden) {
    return (
      <Layout.Box id={componentId}>
        <Layout.Stack space="stack-space-3">
          <TrainingClassAvailabilityNoApptAvailableMessage />
          {availableTimeSlots.map(timeSlot => (
            <Hover
              key={timeSlot.classSessionId}
              hoverStyle={{ boxShadow: `0 0 0 2px ${color.blue500}` }}
            >
              <Button
                id={`${componentId}__selectTimeSlot`}
                variant="no-outline"
                style={{ padding: 0, width: "100%" }}
                isSelected={selectedTimeSlot === timeSlot.classSessionId}
                onClick={() => onClick(timeSlot)}
              >
                <Layout.Box
                  padding="box-padding-6"
                  borderWidth="box-border-width-2"
                  borderColor="box-border-color-gray-100"
                  style={{ width: "100%" }}
                >
                  <ScheduleTrainingAppointmentDetailsComponent timeSlot={timeSlot} />
                </Layout.Box>
              </Button>
            </Hover>
          ))}
        </Layout.Stack>
      </Layout.Box>
    );
  }
  return null;
}

export const TrainingClassTimesContainer = connect(
  state => {
    const storeNumber = getStoreNumber(state);
    return {
      customerKey: getCurrentCustomerKey(state),
      petId: getCurrentPet(state),
      isHidden: !getSelectedTrainingPetService(state),
      customerPhone: getCurrentCustomerPrimaryPhoneNumber(state),
      customerPhoneType: getCurrentCustomerPrimaryPhoneType(state),
      componentId: "TrainingClassTimes",
      availableTimeSlots: getAvailableTrainingTimeSlotsForSelectedDate(state, { storeNumber }),
      selectedTimeSlot: getSelectedTimeSlot(state),
      notes: getTrainingEngagementNote(state),
      customerTimeZone: getCustomerTimeZone(state, { storeNumber }),
    };
  },
  dispatch => {
    return {
      dispatchPostTrainingClassItinerary: payload => {
        dispatch(setSelectedTimeSlot(payload.classSessionId));
        dispatch(postTrainingClassItinerary(payload));
      },
    };
  },
  (propsFromState, propsFromDispatch, ownProps) => ({
    ...propsFromState,
    ...propsFromDispatch,
    ...ownProps,
    onClick: timeSlot => {
      const {
        customerKey,
        petId,
        customerPhone,
        customerPhoneType,
        notes,
        customerTimeZone,
      } = propsFromState;
      propsFromDispatch.dispatchPostTrainingClassItinerary({
        customerKey,
        petId,
        customerPhone,
        customerPhoneType,
        notes,
        customerTimeZone,
        classSessionId: timeSlot.classSessionId,
        petServiceId: timeSlot.petServiceId,
        behaviors: timeSlot.behaviors,
        startDateTime: timeSlot.startDatetime,
        associateId: timeSlot.associateId,
        duration: timeSlot.serviceDurationTime,
        durationUnitOfMeasure: timeSlot.serviceDurationTimeUnit,
      });
    },
  }),
)(TrainingClassAvailabilityList);

export const RescheduleTrainingClassTimes = connect(
  state => {
    const storeNumber = getStoreNumber(state);
    const selectedTimeSlot = getSelectedTimeSlot(state);
    return {
      isHidden: false,
      selectedTimeSlot,
      componentId: "RescheduleTrainingClassTimes",
      availableTimeSlots: getAvailableTrainingTimeSlotsForSelectedDate(state, { storeNumber }),
    };
  },
  dispatch => {
    return {
      onClick: timeSlot => {
        dispatch(setSelectedTimeSlot(timeSlot.classSessionId));
      },
    };
  },
)(TrainingClassAvailabilityList);
