import React from "react";
import styled from "styled-components";
import { FlyoutToggleWithTitle } from "dux/_components/styledPopovers/FlyoutToggleWithTitle";
import { color } from "../../../web/common/styles/theme";
import { HERE_OR_COMPLETED_FILTER_OPTIONS } from "../../../core/constants/dropdownOptions";
import UpcomingSearchContainer from "../../../web/upcomingSearch/UpcomingSearchContainer";
import HereSearchContainer from "../../../web/hereSearch/HereSearchContainer";
import UpcomingBoardedGuestCheckboxContainer from "../../../web/upcomingSearch/UpcomingBoardedGuestCheckboxContainer";
import HereBoardedGuestCheckboxContainer from "../../../web/hereSearch/HereBoardedGuestCheckboxContainer";
import SpeciesCounts from "../SpeciesCount/SpeciesCounts";

// border-right is set to none if it is the first list column
// to prevent overlapping borders between the lists
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${color.gray200};
  border-right: ${({ titleName }) => (titleName === "Upcoming" ? "none" : "inherit")};
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 10px;
`;

const SectionWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  margin-bottom: 8px;
`;

const SearchContainer = styled.div`
  margin-left: 15px;
`;

export default function FlyoutToggleOptions(props) {
  const {
    titleName,
    isFilterableList,
    speciesCounts,
    setHereOrCompletedFilterApplied,
    hereOrCompletedFilterApplied,
  } = props;
  const { upcoming, here } = speciesCounts;

  return (
    <TitleWrapper titleName={titleName}>
      <SectionWrapper>
        <FlyoutToggleWithTitle
          componentID="FlyoutToggleWithTitle__Salon"
          isFilterableList={isFilterableList}
          selectionHandler={setHereOrCompletedFilterApplied}
          selectedOption={hereOrCompletedFilterApplied}
          title={titleName}
          options={HERE_OR_COMPLETED_FILTER_OPTIONS}
        />

        <SearchContainer>
          {titleName === "Upcoming" ? <UpcomingSearchContainer /> : <HereSearchContainer />}
        </SearchContainer>
      </SectionWrapper>

      <SpeciesCounts
        componentId="SpeciesCounts__SalonTitleCard"
        appointments={titleName === "Upcoming" ? upcoming : here}
      />

      {titleName === "Upcoming" ? (
        <UpcomingBoardedGuestCheckboxContainer />
      ) : (
        <HereBoardedGuestCheckboxContainer />
      )}
    </TitleWrapper>
  );
}
