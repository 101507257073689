import React from "react";
import Services from "../../services/ServicesContainer";
import { color } from "web/common/styles/theme";
import { Layout } from "@prism/psm-ui-components";

export default class BookingService extends React.Component {
  componentDidMount() {
    const { onClearActionClicked } = this.props;
    onClearActionClicked();
  }

  componentWillUnmount() {
    const { onClearActionClicked } = this.props;
    onClearActionClicked();
  }

  render() {
    const { isStandalone, profileDetails } = this.props;
    return (
      <Layout.Switcher>
        <Layout.Box style={{ borderRight: `1px solid ${color.gray300}` }}>
          {profileDetails}
        </Layout.Box>

        <Layout.Box>
          <Services isStandalone={isStandalone} />
        </Layout.Box>
      </Layout.Switcher>
    );
  }
}
