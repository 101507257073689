import { connect } from "react-redux";

import {
  setStoreNumber,
  setAssociateNumber,
  resetStoreNumber,
} from "../../core/actionCreators/ui/web/generalActionCreators";
import {
  getStoreNumber,
  getSearchComponentName,
  getRedirectToForgotPin,
} from "../../core/selectors/persistentSelectors";
import { setSearchComponentName } from "../../core/actionCreators/searchSalonActionCreator";
import { logoutUser, loginWithOkta } from "../../core/actionCreators/authActionCreators";
import getHotelWorkflowFeatureFlag from "../enableDisableWorkflowFeatureFlag/selectors/hotel/getHotelWorkflowFeatureFlag";
import Login from "./loginComponent";
import { systemName } from "@/web/setSystemType/constants/setSystemTypeConstants";
import { determineRedirectRoute } from "@/web/setSystemType/LoginSystemUtils";

const mapStateToProps = (state, ownProps) => {
  const storeNumber = getStoreNumber(state);
  const featureFlag = getHotelWorkflowFeatureFlag(state);
  return {
    searchComponentName: getSearchComponentName(state, ownProps),
    loginButtonTitle: "Login",
    storeNumberValue: storeNumber || "",
    redirectToForgotPin: getRedirectToForgotPin(state),
    isSVCS371Enabled: !featureFlag.isHidden,
  };
};

const mapDispatchToProps = dispatch => ({
  onSetSearchComponentName: ({ searchComponentName }) => {
    dispatch(setSearchComponentName({ searchComponentName }));
  },
  dispatchOnLogin: ({ storeNumber, redirectToForgotPin }) => {
    const redirectRoute = determineRedirectRoute(storeNumber, redirectToForgotPin);
    dispatch(
      loginWithOkta({
        route: redirectRoute,
        storeNumber,
        systemType: systemName.SALON,
      }),
    );
  },
  logoutUser: () => dispatch(logoutUser({ browserSessionOnly: true })),
  resetStoreNumber: () => dispatch(resetStoreNumber()),
});

const LoginContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);

export default LoginContainer;
