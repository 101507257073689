import React from "react";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { Formik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { isEmpty } from "lodash/fp";

import { font, color } from "../common/styles/theme";
import { SET_PIN_INITIAL_FORM_VALUES } from "../../core/constants/quickPin";
import { pinValidationSchema } from "../../core/constants/validationSchema";
import { routePaths } from "../../core/constants/routePaths";

import { LoadingSpinner } from "../common/LoadingSpinner";
import ProfileSVG from "../../assets/icons/profile.svg";
import SmileySVG from "../../assets/icons/smiley.svg";
import ForgotPinSVG from "../../assets/icons/forgotPin.svg";
import Img from "../common/Img";
import CommonButton from "../common/commonButton";
import EnvironmentWarning from "../common/EnvironmentWarning";
import EnvironmentNotificationPinContainer from "../environmentNotificaion/EnvironmentNotificationPinContainer";

const StyledSubHeading = styled.h4`
  font-family: Lato;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  color: white;
`;

const UsersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #505050;
`;

const ProfileImage = styled(Img)`
  border-radius: 50%;
  margin: 5px;
  height: 125px;
  width: 125px;
  box-shadow: 1px 1px 22px 3px #1e1e1e;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 10% 0 10%;
  padding-bottom: 0.5em;
`;

const InputBox = styled.input`
  height: 45px;
  width: 250px;
  box-sizing: border-box;
  border: 0;
  outline: none;
  font-family: ${font.mainFamily};
  font-size: 18px;
  color: white;
  padding: 0.5em 0.7em;
  background-color: rgba(250, 250, 250, 0.2);
  box-shadow: inset 0 1px 0 0 ${color.gray400}, inset -1px 0 0 ${color.gray400},
    inset 0 -1px 0 ${color.gray400}, inset 1px 0 0 ${color.gray400};
  &:focus {
    box-shadow: inset 0 2px 0 0 ${color.white0}, inset -2px 0 0 ${color.white0},
      inset 0 -2px 0 ${color.white0}, inset 2px 0 0 ${color.white0};
  }
  ::placeholder {
    color: white;
  }
`;

const SubmitButton = styled(CommonButton)`
  font-size: 18px;
  border-radius: 0;
  &:hover {
    background-color: ${color.blue400};
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: ${color.blue500};
  }
`;

const ErrorText = styled.div`
  color: #ff7f7f;
  text-align: center;
  margin-top: 1em;
  max-width: 250px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: 0 0 1em 0;
`;

const ButtonImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 4em 0;
  cursor: pointer;
`;

const ButtonImage = styled(Img)`
  border-radius: 50%;
  border: 0.25em solid #fff;
  margin: 2em 3em 1em 3em;
  height: 50px;
  width: 50px;
  ${({ isHighlighted }) =>
    isHighlighted &&
    `
        border-color: #7cffe2;
    `};
`;

const ButtonText = styled.div`
  color: ${({ isHighlighted }) => (isHighlighted ? "#7cffe2" : "#fff")};
`;

const enterPinValidationSchema = Yup.object().shape({
  pin: pinValidationSchema,
});

class EnterPinComponent extends React.Component {
  inputRef = React.createRef();

  componentDidMount() {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  componentWillUnmount() {
    const { clearInvalidPinError, clearSelectedUser } = this.props;
    clearInvalidPinError();
    clearSelectedUser();
  }

  render() {
    const {
      selectedUser,
      loggedInUser,
      authorizeUserByPin,
      invalidPinError,
      isLoading,
      clearInvalidPinError,
      router: { navigate },
      showModal,
    } = this.props;
    const nameToDisplay = !isEmpty(selectedUser)
      ? selectedUser.name
      : `${loggedInUser.name} ${loggedInUser.familyName}`;
    return (
      <UsersContainer>
        <EnvironmentNotificationPinContainer />

        <ProfileImage src={ProfileSVG} />
        <StyledSubHeading>{`Hey ${nameToDisplay}, please enter your PIN code`}</StyledSubHeading>
        <Formik
          initialValues={SET_PIN_INITIAL_FORM_VALUES}
          validationSchema={enterPinValidationSchema}
          onSubmit={({ pin }) => authorizeUserByPin({ pin })}
          render={({ handleSubmit, handleChange, values: { pin }, errors, touched }) => (
            <Form onSubmit={handleSubmit}>
              <InputBox
                type="password"
                placeholder="Personal PIN Code"
                name="pin"
                maxLength="6"
                innerRef={this.inputRef}
                onChange={e => {
                  if (invalidPinError) {
                    clearInvalidPinError();
                  }
                  handleChange(e);
                }}
                value={pin}
                firstChild
              />
              <SubmitButton
                type="Submit"
                disabled={isLoading}
                label={isLoading ? <LoadingSpinner /> : "Submit"}
              />
              <ErrorText>{(touched.pin && errors.pin) || invalidPinError}</ErrorText>
            </Form>
          )}
        />
        <ButtonContainer>
          <ButtonImageWrapper onClick={showModal}>
            <ButtonImage src={ForgotPinSVG} />
            <ButtonText>Forgot PIN Code</ButtonText>
          </ButtonImageWrapper>
          <ButtonImageWrapper onClick={() => navigate(routePaths.SELECT_USER)}>
            <ButtonImage isHighlighted src={SmileySVG} />
            <ButtonText isHighlighted>Switch User</ButtonText>
          </ButtonImageWrapper>
        </ButtonContainer>
      </UsersContainer>
    );
  }
}

export default withRouteProps(EnterPinComponent);
