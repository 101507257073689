import { Layout, Button } from "@prism/psm-ui-components";
import React, { useEffect, useState } from "react";
import { color } from "web/common/styles/theme";
import {
  HotelBookingAddonModifier,
  HotelCheckInOutAddonModifier,
} from "dux/hotelAddonsModal/HotelAddonQuantityModifierWithDetails";
import { HotelAddonInstructionsInput } from "@/dux/hotelAddonsModal/HotelAddonInstructionsInput";
import { connect } from "react-redux";
import {
  getFirstHotelEngagementByPet,
  getPetHotelEngagements,
  selectAddonByPetAndAddonId,
  selectAddonNotesFromEngagements,
  selectAddonTotalQtyByGroupingId,
  selectAppliedAddonsFromEngagementsByPet,
  selectEnhancedAddonChildrenFromEngagements,
} from "dux/hotelEngagements/hotelEngagementSelectors";
import { getCurrentPet, selectCurrentStoreTimeZone } from "core/selectors/persistentSelectors";
import { getHotelItinerary } from "dux/hotelItinerary/hotelItinerarySelectors";
import { createLoadingSelector } from "core/selectors/utils";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import usePrevious from "dux/utils/refUtils/usePrevious";
import { PUT_CART, putCart } from "dux/servicesCart/servicesCartActions";
import {
  removeHotelCartProducts,
  setHotelCartProduct,
} from "dux/servicesCartHotel/servicesCartHotelActions";
import { frequencyConstants, frequencyErrors } from "dux/frequency/FrequencyConstants";
import {
  selectAddonNotesFromCart,
  selectCartProductIsMissingDates,
  selectEnhancedAddonChildrenFromCart,
  selectHotelCartPetProductById,
} from "dux/servicesCartHotel/servicesCartHotelSelectors";
import {
  HotelBookingAddonsSetFrequency,
  HotelCheckInOutAddonsSetFrequency,
} from "../hotelAddonsSetFrequency/HotelAddonsSetFrequency";
import {
  getPendingFrequency,
  getPendingFrequencyAddonDates,
  getPendingId,
} from "../frequency/frequencySelectors";
import { clearPendingFrequency } from "../frequency/actions/frequencyActions";
import {
  HotelBookingAddonsRemoveButton,
  HotelCheckInOutAddonRemoveButton,
} from "../hotelRemoveButton/HotelRemoveButton";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";
import isEmpty from "lodash/isEmpty";
import { compose } from "redux";
import {
  PATCH_HOTEL_ITINERARY_ADDONS,
  patchHotelItineraryAddons,
} from "../hotelItineraryAddonPatch/hotelItineraryAddonPatchActions";
import { buildEngagementsWithNewOvernightAddons } from "@/core/utils/hotelEngagementUtils/buildEngagementsWithNewOvernightAddons";
import { buildEngagementAddonUpdatesForPut } from "@/core/utils/hotelEngagementUtils/buildEngagementsWithUpdatedAddons";
import { buildEngagementsForSingleDayAddon } from "@/core/utils/hotelEngagementUtils/buildEngagementsForSingleDayAddon";
import { getCartItemId } from "../servicesCart/servicesCartUtils";
import {
  PUT_HOTEL_ITINERARY_ADDONS,
  putHotelItineraryAddons,
} from "../hotelItineraryAddonPut/hotelItineraryAddonPutActions";
import { LayoutStack } from "@/layout/stack/Stack";
import { TextPassage } from "@petsmart-ui/sparky";
import {
  isOvernightSaveDisabled,
  isSingleDaySaveDisabled,
} from "@/dux/hotelAddonsModal/hotelAddonsModalUtils";

const HotelAddonsModal = ({
  showingAddonId,
  setShowingAddonId,
  componentId = "hotelAddons_modalContent",
  buttonLabel,
  isLoading,
  initialQuantity,
  initialInstructions,
  onSave,
  onClose,
  diComp = {},
  error,
  petId,
  isSaveDisabled = () => false,
  clearFrequency = () => {},
}) => {
  const [quantity, setQuantity] = useState(initialQuantity);
  const [instructions, setInstructions] = useState(initialInstructions);

  const updateInstructionsById = (id, notes) =>
    setInstructions(prev => ({ ...prev, [id]: { ...prev[id], notes } }));

  // Keep track of what the addon was when instantiated
  const prevAddonId = usePrevious(showingAddonId);

  useEffect(() => {
    // When done loading & if this was the modal that is in view, close modal and reset quantity & instructions.
    if (!isLoading && showingAddonId === prevAddonId) {
      if (onClose) {
        onClose();
      }

      setShowingAddonId(null);
      setQuantity(initialQuantity);
      setInstructions(initialInstructions);
    }
  }, [isLoading]);

  // If current addon id changes, reset quantity, instructions, & frequency
  useEffect(() => {
    setQuantity(initialQuantity);
    setInstructions(initialInstructions);
    clearFrequency();
  }, [showingAddonId]);

  // If current initialQuantity changes, reset quantity
  useEffect(() => {
    setQuantity(initialQuantity);
  }, [initialQuantity]);

  const SetFrequency = () => diComp?.frequencyButton;
  const RemoveButton = () => diComp?.removeButton;
  const QuantityModifierWithDetails = diComp?.quantityModifierWithDetails;

  return (
    <Layout.Box
      id={componentId}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "200px",
      }}
    >
      <Layout.Box id={`${componentId}__quantityModifier`}>
        <QuantityModifierWithDetails
          addonId={showingAddonId}
          quantity={quantity}
          setQuantity={setQuantity}
          petId={petId}
        />
      </Layout.Box>

      <LayoutBox style={{ padding: "1em 0 2.5em" }}>
        {Object.values(instructions).map(({ id, label, notes }, index) => (
          <HotelAddonInstructionsInput
            key={id ?? index}
            componentId={`${componentId}__notes--${id ?? index}`}
            label={label}
            instructions={notes}
            setInstructions={newNotes => updateInstructionsById(id, newNotes)}
            disabled={!id}
          />
        ))}

        <LayoutStack>
          <LayoutCluster
            id={`${componentId}__frequencyRemoveButtons`}
            style={{ justifyContent: "space-between" }}
          >
            <SetFrequency />
            <RemoveButton />
          </LayoutCluster>

          {!!error && <TextPassage style={{ color: color.red700 }}>{error}</TextPassage>}
        </LayoutStack>
      </LayoutBox>

      <Layout.Box id={`${componentId}__saveCancelButtons`}>
        <Layout.Cluster justify="space-between" space="cluster-space-4">
          <Button
            variant="link"
            onClick={() => {
              setShowingAddonId(null);
            }}
            style={{ color: color.red700 }}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            variant="prism-primary"
            disabled={isSaveDisabled({ quantity, instructions })}
            onClick={() => {
              onSave({ quantity, instructions });
            }}
          >
            {buttonLabel}
          </Button>
        </Layout.Cluster>
      </Layout.Box>
    </Layout.Box>
  );
};

const HotelBookingSharedPropsConnect = connect(
  (state, ownProps) => {
    const { showingAddonId, petId } = ownProps;
    const existingAddon = selectHotelCartPetProductById({ petId, productId: showingAddonId })(
      state,
    );
    const isLoading = createLoadingSelector([PUT_CART])(state);

    let buttonLabel;
    if (isLoading) buttonLabel = "Loading...";
    else if (existingAddon) buttonLabel = "Update";
    else buttonLabel = "Save";

    const initialInstructions = selectAddonNotesFromCart({ petId, productId: showingAddonId })(
      state,
    );
    const childAddons = selectEnhancedAddonChildrenFromCart({ petId, productId: showingAddonId })(
      state,
    );

    return {
      ...ownProps,
      initialInstructions,
      buttonLabel,
      isLoading,
      existingAddon,
      childAddons: Object.values(childAddons),
    };
  },
  dispatch => {
    return {
      /**
       * dispatch action to add/update addon to the booking cart.
       */
      dispatchOnSave: ({ products }) => {
        // Update the cart data with the new product, if enhanced then also updating children
        products.map(({ product, cartItemId }) =>
          dispatch(setHotelCartProduct({ product, cartItemId })),
        );

        dispatch(putCart());
      },
      /**
       * dispatch action to delete addon existing on the booking cart.
       * @param {String} cartItemIds
       */
      dispatchDeleteAddon: cartItemIds => {
        // Update the cart data to remove product
        dispatch(removeHotelCartProducts(cartItemIds));

        dispatch(putCart());
      },
    };
  },
);

// HOTEL BOOKING ADDON CONTAINER ----------------------------------------------------------------------------------------
export const SingleDayHotelBookingAddonModal = compose(
  HotelBookingSharedPropsConnect,
  connect((state, props) => {
    const { showingAddonId, setShowingAddonId, petId, onClose } = props;
    // props from HotelBookingSharedPropsConnect
    const { initialInstructions, buttonLabel, isLoading, existingAddon, childAddons } = props;
    // dispatch fns from HotelBookingSharedPropsConnect
    const { dispatchOnSave, dispatchDeleteAddon } = props;

    return {
      showingAddonId,
      setShowingAddonId,
      componentId: "SingleDayHotelBookingAddonModal",
      buttonLabel,
      isLoading,
      initialQuantity: existingAddon ? existingAddon.singleDayStayQuantity : 1,
      initialInstructions,
      onClose,
      isSaveDisabled: ({ quantity }) => isLoading || (!existingAddon && quantity === 0),
      petId,
      diComp: {
        quantityModifierWithDetails: HotelBookingAddonModifier,
      },
      onSave: ({ quantity, instructions }) => {
        const cartItemId = getCartItemId({ petId, item: { productId: showingAddonId } });
        if (quantity === 0 && existingAddon) {
          const childrenToRemove = childAddons?.map(item => getCartItemId({ petId, item }));
          dispatchDeleteAddon([cartItemId, ...childrenToRemove]);
        } else {
          const product = {
            ...existingAddon,
            productId: showingAddonId,
            isPrimaryService: false,
            frequency: frequencyConstants.DAILY,
            notes: instructions[showingAddonId]?.notes,
            customFrequencyDates: [],
            singleDayStayQuantity: quantity,
            petId,
          };

          const childProducts = childAddons?.map(child => ({
            product: {
              ...child,
              notes: instructions[child?.productId]?.notes,
            },
            cartItemId: getCartItemId({ petId, item: child }),
          }));

          dispatchOnSave({ products: [{ product, cartItemId }, ...childProducts] });
        }
      },
    };
  }),
)(HotelAddonsModal);

// HOTEL BOOKING ADDON CONTAINER ----------------------------------------------------------------------------------------
export const MultiDayHotelBookingAddonModal = compose(
  HotelBookingSharedPropsConnect,
  connect(
    (state, props) => {
      const { showingAddonId, setShowingAddonId, petId, onClose } = props;
      // props from HotelBookingSharedPropsConnect
      const { initialInstructions, buttonLabel, isLoading, existingAddon, childAddons } = props;
      // dispatch fns from HotelBookingSharedPropsConnect
      const { dispatchOnSave } = props;

      // Select pending frequency and make sure addonId matches
      const pendingFrequencyId = getPendingId(state);
      const hasPendingFrequency = pendingFrequencyId === showingAddonId;
      const frequency = hasPendingFrequency ? getPendingFrequency(state) : existingAddon?.frequency;
      const dates = hasPendingFrequency
        ? getPendingFrequencyAddonDates(state)
        : existingAddon?.customFrequencyDates;
      const customFrequencyDates = dates ?? [];
      const existingQty = existingAddon ? existingAddon?.pricing?.quantity : 0;
      const isMissingDates = selectCartProductIsMissingDates({ petId, productId: showingAddonId })(
        state,
      );

      return {
        componentId: "MultiDayHotelBookingAddonModal",
        showingAddonId,
        setShowingAddonId,
        buttonLabel,
        isLoading,
        initialQuantity: hasPendingFrequency ? customFrequencyDates.length : existingQty,
        initialInstructions,
        onClose,
        petId,
        diComp: {
          removeButton: <HotelBookingAddonsRemoveButton petId={petId} addonId={showingAddonId} />,
          frequencyButton: (
            <HotelBookingAddonsSetFrequency petId={petId} addonId={showingAddonId} />
          ),
          quantityModifierWithDetails: HotelBookingAddonModifier,
        },
        error: isMissingDates && frequencyErrors.MANUAL_MISSING_DATES,
        isSaveDisabled: ({ quantity, instructions }) =>
          isLoading ||
          isOvernightSaveDisabled({
            existingAddon,
            hasPendingFrequency,
            quantity,
            initialInstructions,
            instructions,
            frequency,
            customFrequencyDates,
          }),
        onSave: ({ instructions }) => {
          const cartItemId = getCartItemId({ petId, item: { productId: showingAddonId } });
          const product = {
            productId: showingAddonId,
            groupingId: existingAddon?.groupingId,
            isPrimaryService: false,
            frequency,
            notes: instructions[showingAddonId]?.notes,
            customFrequencyDates,
            singleDayStayQuantity: 1,
            petId,
          };

          const childProducts = childAddons?.map(child => ({
            product: {
              ...child,
              frequency,
              customFrequencyDates,
              notes: instructions[child?.productId]?.notes,
            },
            cartItemId: getCartItemId({ petId, item: child }),
          }));

          dispatchOnSave({ products: [{ product, cartItemId }, ...childProducts] });
        },
      };
    },
    dispatch => {
      return {
        clearFrequency: () => dispatch(clearPendingFrequency()),
      };
    },
  ),
)(HotelAddonsModal);

// HOTEL CHECK IN/OUT ADDON CONTAINERS ----------------------------------------------------------------------------------------
export const HotelCartCheckInOutAddonModalConnect = connect(
  (state, { showingAddonId }) => {
    const itineraryId = getHotelItinerary(state)?.itineraryId;
    const petId = getCurrentPet(state);
    const customerKey = getCurrentCustomerKey(state);
    const petAddons = selectAppliedAddonsFromEngagementsByPet(state, { petId });
    const existingAddon = selectAddonByPetAndAddonId(showingAddonId)(state, { petId });
    const hasExistingAddon = !isEmpty(existingAddon);
    const isLoading = createLoadingSelector([
      PATCH_HOTEL_ITINERARY_ADDONS,
      PUT_HOTEL_ITINERARY_ADDONS,
    ])(state);

    let buttonLabel = "Save";
    if (isLoading) buttonLabel = "Saving...";
    if (hasExistingAddon) buttonLabel = "Update";
    if (isLoading && hasExistingAddon) buttonLabel = "Updating...";

    return {
      initialInstructions: selectAddonNotesFromEngagements(showingAddonId)(state, { petId }),
      buttonLabel,
      isLoading,
      petId,
      itineraryId,
      existingAddon,
      hasExistingAddon,
      customerKey,
      petAddons,
      timeZone: selectCurrentStoreTimeZone(state),
      diComp: {
        quantityModifierWithDetails: HotelCheckInOutAddonModifier,
      },
    };
  },

  dispatch => {
    return {
      dispatchOnSave: ({ itineraryId, customerId, data }) => {
        dispatch(patchHotelItineraryAddons({ itineraryId, customerId, data }));
      },
      dispatchOnUpdate: ({ petId, updatedEngagements }) =>
        dispatch(putHotelItineraryAddons({ petId, updatedEngagements })),
    };
  },
);

const SingleDayHotelCartCheckInOutAddonModalConnect = connect((state, ownProps) => {
  const {
    showingAddonId,
    setShowingAddonId,
    isLoading,
    buttonLabel,
    initialInstructions,
    itineraryId,
    petId,
    existingAddon,
    customerKey,
    onClose,
    diComp,
    dispatchOnSave,
    dispatchOnUpdate,
    petAddons,
    timeZone,
  } = ownProps;
  const engagement = getFirstHotelEngagementByPet(state, { petId });
  const engagementId = engagement?.engagementId;
  const childAddons = selectEnhancedAddonChildrenFromEngagements(showingAddonId)(state, {
    petId,
  });

  return {
    componentId: "SingleDayHotelCartCheckInOutAddonModal",
    initialQuantity:
      selectAddonTotalQtyByGroupingId(existingAddon?.groupingId)(state, { petId }) ?? 1,
    initialInstructions,
    showingAddonId,
    setShowingAddonId,
    isLoading,
    buttonLabel,
    onClose,
    diComp,
    isSaveDisabled: ({ quantity }) =>
      isSingleDaySaveDisabled({
        quantity,
        isLoading,
        existingAddon,
      }),
    onSave: ({ quantity, instructions }) => {
      if (quantity === 0 && existingAddon) {
        // Remove addons with PUT call
        const updatedEngagements = buildEngagementAddonUpdatesForPut({
          petEngagements: [engagement],
          petAddons,
          addonToEditId: showingAddonId,
          timeZone,
          // sending empty dates array to remove this addon from all engagements
          dates: [],
        });

        dispatchOnUpdate({ petId, updatedEngagements });
      } else {
        const engagements = buildEngagementsForSingleDayAddon({
          engagementId,
          addon: { ...existingAddon, addOnProductNumber: showingAddonId },
          instructions,
          quantity,
          childAddons,
        });
        const data = { pets: [{ petKey: petId, engagements }] };

        dispatchOnSave({ itineraryId, customerId: customerKey, data });
      }
    },
  };
});

const MultiDayHotelCartCheckInOutAddonModalConnect = connect(
  (state, ownProps) => {
    const {
      showingAddonId,
      existingAddon,
      hasExistingAddon,
      initialInstructions,
      diComp,
      isLoading,
      petId,
    } = ownProps;

    // Select pending frequency and make sure addonId matches
    const pendingFrequencyId = getPendingId(state);
    const hasPendingFrequency = pendingFrequencyId === showingAddonId;
    const frequency = hasPendingFrequency ? getPendingFrequency(state) : existingAddon?.frequency;
    const dates = hasPendingFrequency
      ? getPendingFrequencyAddonDates(state)
      : existingAddon?.customFrequencyDates;
    const customFrequencyDates = dates ?? [];
    const existingQty = hasExistingAddon
      ? selectAddonTotalQtyByGroupingId(existingAddon?.groupingId)(state, { petId })
      : 0;

    const petEngagements = getPetHotelEngagements(state, { petId });

    return {
      componentId: "MultiDayHotelCartCheckInOutAddonModal",
      initialQuantity: hasPendingFrequency ? customFrequencyDates.length : existingQty,
      petEngagements,
      frequency,
      customFrequencyDates,
      diComp: {
        ...diComp,
        removeButton: <HotelCheckInOutAddonRemoveButton petId={petId} addonId={showingAddonId} />,
        frequencyButton: (
          <HotelCheckInOutAddonsSetFrequency petId={petId} addonId={showingAddonId} />
        ),
      },
      isSaveDisabled: ({ quantity, instructions }) =>
        isLoading ||
        isOvernightSaveDisabled({
          existingAddon,
          hasPendingFrequency,
          quantity,
          initialInstructions,
          instructions,
          frequency,
          customFrequencyDates,
        }),
    };
  },
  dispatch => ({
    clearFrequency: () => dispatch(clearPendingFrequency()),
  }),
  (stateProps, dispatchProps, ownProps) => {
    const {
      componentId,
      initialQuantity,
      diComp,
      isSaveDisabled,
      petEngagements,
      frequency,
      customFrequencyDates,
    } = stateProps;
    const {
      showingAddonId,
      setShowingAddonId,
      isLoading,
      buttonLabel,
      initialInstructions,
      petId,
      onClose,
      itineraryId,
      customerKey,
      hasExistingAddon,
      petAddons,
      dispatchOnSave,
      dispatchOnUpdate,
      timeZone,
    } = ownProps;
    const { clearFrequency } = dispatchProps;

    return {
      // control what props get passed to the view
      componentId,
      initialQuantity,
      initialInstructions,
      showingAddonId,
      setShowingAddonId,
      isLoading,
      buttonLabel,
      onClose,
      diComp,
      isSaveDisabled,
      clearFrequency,
      onSave: ({ instructions }) => {
        // Update existing addon objs on engagements with itinerary PUT
        if (hasExistingAddon) {
          const updatedEngagements = buildEngagementAddonUpdatesForPut({
            petEngagements,
            petAddons,
            addonToEditId: showingAddonId,
            instructions,
            frequency,
            dates: customFrequencyDates,
            timeZone,
          });

          dispatchOnUpdate({ petId, updatedEngagements });
        }

        // Add new addon objs to engagements with itinerary PATCH - no need to add child addons as SF auto applies them
        if (!hasExistingAddon) {
          const pet = { petKey: petId };
          pet.engagements = buildEngagementsWithNewOvernightAddons({
            petEngagements,
            showingAddonId,
            instructions,
            frequency,
            dates: customFrequencyDates,
            timeZone,
          });

          const data = { itineraryId, pets: [pet] };
          dispatchOnSave({ itineraryId, customerId: customerKey, data });
        }
      },
    };
  },
);

export const SingleDayHotelCartCheckInOutAddonModal = compose(
  HotelCartCheckInOutAddonModalConnect,
  SingleDayHotelCartCheckInOutAddonModalConnect,
)(HotelAddonsModal);

export const MultiDayHotelCartCheckInOutAddonModal = compose(
  HotelCartCheckInOutAddonModalConnect,
  MultiDayHotelCartCheckInOutAddonModalConnect,
)(HotelAddonsModal);
