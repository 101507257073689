import { connect } from "react-redux";
import FlexWrappedCheckBox from "@/web/common/formInputs/FlexWrappedCheckBox";
import { selectSalonInvoiceGenerated } from "@/core/selectors/entitiesSelector";
import { getCurrentItinerary } from "@/core/selectors/checkInOutSelector";

const mapStateToProps = state => {
  const itineraryId = getCurrentItinerary(state);

  return {
    checked: selectSalonInvoiceGenerated(state, { itineraryId }),
    disabled: true,
    labelPosition: "right",
    label: "Invoice generated",
  };
};

export default connect(mapStateToProps)(FlexWrappedCheckBox);
