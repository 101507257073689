import React from "react";
import styled from "styled-components";
import { color } from "./../styles/theme";
import { routePaths } from "../../../core/constants/routePaths";
import cell from "../../schedules/cell";

const StoreClosedHeader = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: ${color.red700};
  color: ${color.white0};
  width: 100%;
  font-weight: 700px;
`;

/**
 * React Component that displays a warning message of what environment the user is in.
 *
 * @export EnvWrapper
 * @returns {*} - JSX
 */
class StoreClosedWarning extends React.Component {
  render() {
    const { fromPage, isSalonClosed } = this.props;

    if (isSalonClosed) {
      return <StoreClosedHeader>Store Closed</StoreClosedHeader>;
    }
    return null;
  }
}

export default StoreClosedWarning;
