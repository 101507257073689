import React, { useEffect, useState } from "react";
import { Text, Form } from "@prism/psm-ui-components";
import { connect } from "react-redux";
import { color } from "web/common/styles/theme";
import { HotelCheckInOutEditIconContainer } from "@/dux/hotelCheckInOutDateTimeCart/hotelCheckInOutEditIcon";
import { getHotelItinerary } from "dux/hotelItinerary/hotelItinerarySelectors";
import {
  getPetHotelEngagements,
  selectAddonByPetAndAddonId,
} from "dux/hotelEngagements/hotelEngagementSelectors";
import { putCart } from "@/dux/servicesCart/servicesCartActions";
import { setHotelCartProduct } from "@/dux/servicesCartHotel/servicesCartHotelActions";
import { LayoutBox } from "@/layout/box/Box";
import { selectCurrentStoreTimeZone } from "@/core/selectors/persistentSelectors";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { patchHotelItineraryAddons } from "@/dux/hotelItineraryAddonPatch/hotelItineraryAddonPatchActions";
import { buildEngagementAddonUpdatesForPatch } from "@/core/utils/hotelEngagementUtils/buildEngagementsWithUpdatedAddons";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { getCartItemId } from "@/dux/servicesCart/servicesCartUtils";
import { selectHotelCartPetProductById } from "@/dux/servicesCartHotel/servicesCartHotelSelectors";

const HotelCartAddonInstructionsComponent = props => {
  const { componentId, isHidden, comment, updateInstructions, petId } = props;

  // To set if instructions are being edited
  const [isEditingInstructions, setIsEditingInstructions] = useState(false);

  // To keep track of instruction changes.
  const [updatedInstructions, setUpdatedInstructions] = useState(comment);

  useEffect(() => {
    // If comment changes, update instructions
    setUpdatedInstructions(comment);
  }, [comment]);

  useEffect(() => {
    setIsEditingInstructions(false);
  }, [petId]);

  if (isHidden) return null;

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <LayoutCluster style={{ justifyContent: "flex-start" }}>
        {isEditingInstructions ? (
          <Form.Field.TextArea
            rows={1}
            maxLength={255}
            style={{
              padding: ".5em .5em",
              fontSize: "1rem",
              fontFamily: "Lato-Bold, Lato",
            }}
            onInput={value => setUpdatedInstructions(value)}
            onBlur={() => {
              setIsEditingInstructions(false);
              updateInstructions({ updatedInstructions });
            }}
            value={updatedInstructions}
          />
        ) : (
          <Text color={color.gray300}>{updatedInstructions}</Text>
        )}
        <HotelCheckInOutEditIconContainer
          onClick={() => setIsEditingInstructions(!isEditingInstructions)}
        />
      </LayoutCluster>
    </LayoutBox>
  );
};

// HOTEL CHECK IN/OUT ADDON CONTAINER ----------------------------------------------------------------------------------------
export const HotelCheckInOutCartAddonInstructions = connect(
  (state, { addonId, petId }) => {
    const addon = selectAddonByPetAndAddonId(addonId)(state, { petId });

    return {
      componentId: "HotelCheckInOutCartAddonInstructions",
      comment: addon?.comment ?? "",
      itineraryId: getHotelItinerary(state)?.itineraryId,
      customerId: getCurrentCustomerKey(state),
      argsToBuildPatch: {
        petEngagements: getPetHotelEngagements(state, { petId }),
        petAddons: [addon],
        addonToEditId: addonId,
        dates: addon?.customFrequencyDates,
        timeZone: selectCurrentStoreTimeZone(state),
      },
    };
  },
  dispatch => {
    return {
      dispatchOnUpdateInstructions: ({ itineraryId, customerId, data }) => {
        dispatch(patchHotelItineraryAddons({ itineraryId, customerId, data }));
      },
    };
  },
  (stateProps, dispatchProps, { addonId, petId }) => {
    const { componentId, comment, itineraryId, customerId, argsToBuildPatch } = stateProps;
    const { dispatchOnUpdateInstructions } = dispatchProps;
    return {
      componentId,
      comment,
      petId,
      updateInstructions: ({ updatedInstructions }) => {
        // If notes haven't actually changed, then don't make any API calls
        if (comment === updatedInstructions) return;
        const engagements = buildEngagementAddonUpdatesForPatch({
          ...argsToBuildPatch,
          instructions: { [addonId]: { notes: updatedInstructions } },
        });

        const data = { itineraryId, pets: [{ petKey: petId, engagements }] };
        dispatchOnUpdateInstructions({ itineraryId, customerId, data });
      },
    };
  },
)(HotelCartAddonInstructionsComponent);

// HOTEL BOOKING ADDON CONTAINER ----------------------------------------------------------------------------------------
export const HotelBookingCartAddonInstructions = connect(
  (state, { addonId, petId }) => {
    const addon = selectHotelCartPetProductById({ petId, productId: addonId })(state);

    return {
      componentId: "HotelBookingCartAddonInstructions",
      comment: addon?.notes ?? "",
      addon,
    };
  },
  dispatch => {
    return {
      dispatchOnUpdateInstructions: ({ addon, updatedInstructions }) => {
        // If notes haven't actually changed, then don't make any API calls
        if (addon?.notes === updatedInstructions) return;

        const cartItemId = getCartItemId({ petId: addon?.petId, item: addon });
        const product = {
          ...addon,
          notes: updatedInstructions,
        };

        dispatch(setHotelCartProduct({ product, cartItemId }));
        dispatch(putCart());
      },
    };
  },
  (stateProps, dispatchProps, { petId }) => {
    const { componentId, comment, addon } = stateProps;
    const { dispatchOnUpdateInstructions } = dispatchProps;
    return {
      componentId,
      comment,
      petId,
      updateInstructions: ({ updatedInstructions }) =>
        dispatchOnUpdateInstructions({ addon, updatedInstructions }),
    };
  },
)(HotelCartAddonInstructionsComponent);
