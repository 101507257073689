import React from "react";
import { connect } from "react-redux";
import { formatPackagesLabel } from "../../_components/bgm/availableBundlesByPet/availableBundlesList/_Utils";
import { toggleBulkHistoryRedemptionsDropdown } from "../../toggleArrow/toggleArrowActions";
import { getIsBulkHistoryRedemptionsDropdownSelected } from "../../toggleArrow/toggleArrowSelectors";
import {
  getBundleRedeemedUnits,
  getIsBundleFullyRedeemed,
} from "../availableBundlesByPet/availableBundlesByPetSelectors";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { TextPassage } from "@petsmart-ui/sparky";
import SVGArrow from "@/web/common/SVGArrow";
import { color } from "@/web/common/styles/theme";

/**
 * React view component for bundle history cards
 * @summary Other Purchases column of pet parent profile
 * @memberOf Views.Customer
 * @function
 * @name BundleHistoryDateListHeaderComponent
 * @param {Object} props - props passed into the view component
 * @param {String} props.componentId
 * @param {Function} props.onClick
 * @param {String} props.label
 * @param {String} props.direction
 * @param {Object} props.idEnum - { customerId, petId }
 * @param {String} props.offerType
 * @returns {JSX.Element}
 */
function BundleHistoryDateListHeaderComponent({ componentId, onClick, label, direction }) {
  return (
    <LayoutCluster
      id={componentId}
      style={{ alignItems: "flex-end", justifyContent: "center", cursor: "pointer" }}
      onClick={onClick}
    >
      <TextPassage>{label}</TextPassage>
      <SVGArrow direction={direction} color={color.blue500} />
    </LayoutCluster>
  );
}

/**
 * Redux Connect function for the BundleHistoryDateListHeaderComponent
 * @see {@link Views.Customer.BundleHistoryDateListHeaderComponent}
 * @memberOf Views.Customer
 * @function
 * @name BundleHistoryDateListHeader
 * @param {Object} props
 * @param {String} props.bundleNumber
 * @param {Object} props.idEnum - { customerId, petId }
 * @returns {JSX.Element}
 * @example <BundleHistoryDateListHeader />
 */
export const BundleHistoryDateListHeader = connect(
  (state, { bundleNumber, idEnum }) => {
    const { petId } = idEnum;
    const count = getBundleRedeemedUnits(state, { bundleNumber, petId });
    const isSelected = getIsBulkHistoryRedemptionsDropdownSelected(state, { bundleNumber, petId });
    const isBundleFullyRedeemed = getIsBundleFullyRedeemed(state, { bundleNumber, petId });
    return {
      bundleNumber,
      componentId: "bundle-history-header",
      label: formatPackagesLabel(isBundleFullyRedeemed, count, "Redeemed"),
      direction: isSelected ? "up" : "down",
    };
  },
  dispatch => ({
    toggleBulkHistoryRedemptionsDropdown: petServiceId =>
      dispatch(toggleBulkHistoryRedemptionsDropdown(petServiceId)),
  }),
  (propsFromState, propsFromDispatch) => ({
    ...propsFromState,
    ...propsFromDispatch,
    onClick: () =>
      propsFromDispatch.toggleBulkHistoryRedemptionsDropdown(propsFromState.bundleNumber),
  }),
)(BundleHistoryDateListHeaderComponent);
