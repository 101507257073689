import React from "react";
import styled from "styled-components";
import { color } from "../../web/common/styles/theme";

import getUSTimeStringByDate from "../../core/utils/dateUtils/USTimeByDate";

const HourOption = ({ hourOption, onClick, selected }) => (
  <Container
    onClick={hourOption.enableHourAction ? onClick : () => {}}
    color={hourOption.available ? color.blue500 : color.red700}
    selected={selected}
    disabled={!hourOption.enableHourAction}
  >
    {getUSTimeStringByDate(hourOption.startDateTime)}
  </Container>
);

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ color, selected }) => (selected ? "white" : color)};
  background-color: ${({ color, selected }) => (selected ? color : "white")};
  border: ${({ color }) => `1px solid ${color}`};
  border-radius: 2px;
  margin-right: 5px;
  padding: 3px 5px;
  font-weight: bold;
  user-select: none;
  opacity: ${({ disabled }) => (disabled ? 0.65 : 1)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  &:hover {
    background-color: ${({ color, disabled }) => (!disabled ? color : "white")};
    color: ${({ color, disabled }) => (disabled ? color : "white")};
  }
`;

export default HourOption;
