import React from "react";
import { Layout } from "@prism/psm-ui-components";
import ResultListContainer from "../resultList/resultListContainer";

const Results = () => (
  <Layout.Box style={{ width: "100%" }} className="searchResultsWrapper__resultsCol">
    <ResultListContainer className="searchResultsWrapper__resultListContainer" />
  </Layout.Box>
);

export default function SearchResultsWrapper({ searchField, hasResults }) {
  const SearchField = searchField;

  return (
    <Layout.Stack space="stack-space-4" style={{ width: "850px", margin: "3em auto 0 auto" }}>
      <Layout.Box
        style={{ width: "100%" }}
        className="searchResultsWrapper__searchFieldPlusWrapper"
      >
        <SearchField />
      </Layout.Box>
      {hasResults ? <Results className="searchResultsWrapper__results" /> : null}
    </Layout.Stack>
  );
}
