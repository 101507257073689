import React from "react";
import styled from "styled-components";
import { color } from "../../common/styles/theme";

const Content = styled.div`
  padding: 0.5em 1em;
  min-height: 50px;
  min-width: 450px;
`;

const ErrorText = styled.p`
  font-size: 0.9em;
  margin: 0.5em;
  color: ${color.red700};
`;

const SearchFieldFlyout = ({ searchErrorMessage }) => (
  <Content>
    <ErrorText>{searchErrorMessage}</ErrorText>
    <ErrorText>Please enter correct search criteria to get results.</ErrorText>
  </Content>
);

export default SearchFieldFlyout;
