import { createSelector } from "reselect";
import {
  getAppointmentsFromPendingAppointment,
  selectHotelPetServiceById,
} from "@/web/pendingAppointment/selectors/pendingAppointmentSelectors";
import {
  selectHotelBookingPetById,
  selectHotelBookingPetServices,
  selectHotelBookingRoomsHostFirst,
  selectHotelBookingFlow,
} from "@/web/features/hotelBookingFlow/hotelBookingFlowSelectors";
import { buildEngagementsForPrimaryServicePatch } from "@/core/utils/hotelEngagementUtils/buildEngagementsForPrimaryServicePatch";
import {
  getFirstHotelEngagementByPet,
  getHotelEngagements,
  getPetHotelEngagements,
  selectActiveHotelEngagementsPets,
  selectRebookingPetServices,
} from "@/dux/hotelEngagements/hotelEngagementSelectors";

/**
 * Selector to get pet services from current itinerary to use in select service section when updating room config
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectPetServicesForMergingRooms
 * @param {Object} state
 * @returns Object
 * @example selectPetServicesForMergingRooms(state)
 */
export const selectPetServicesForMergingRooms = createSelector(
  [selectActiveHotelEngagementsPets, selectRebookingPetServices],
  (rebookingPets, initPetsWithServices) => {
    return rebookingPets?.reduce((petsWithServices, petId) => {
      if (petsWithServices[petId]) return petsWithServices;
      return { ...petsWithServices, [petId]: {} };
    }, initPetsWithServices);
  },
);

/**
 * Selector to get a roomNumber for a pet that has been moved into a different pets room
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectNewRoomNumberForMergingRooms
 * @param {string|number} petId
 * @returns roomNumber or undefined
 * @example selectNewRoomNumberForMergingRooms(petId)(state)
 */
export const selectNewRoomNumberForMergingRooms = petId =>
  createSelector(
    [selectHotelBookingRoomsHostFirst, selectHotelBookingPetById(petId), getHotelEngagements],
    (rooms, bookingPet, engagements) => {
      const pets = rooms?.[bookingPet?.roomId];

      // Get current room on itinerary for pets that will be sharing with petId
      const itineraryRooms = pets?.map(currentPetId => {
        if (currentPetId === petId) return {};
        const engagement = getFirstHotelEngagementByPet(
          { hotelEngagements: { engagements } },
          { petId: currentPetId },
        );
        return engagement?.metadata?.room;
      });

      // Find a room number for the new room bucket type if it exists
      const room = itineraryRooms?.find(
        ({ roomTypeBucketId, roomNumber } = {}) =>
          roomTypeBucketId?.toString() === bookingPet?.roomTypeBucketId?.toString() && !!roomNumber,
      );

      return room?.roomNumber;
    },
  );

/**
 * Selector to get all pets with updated services for merging rooms
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectMergedRoomUpdatedPetServices
 * @param {Object} state
 * @returns {{ petId: string|number, petServiceId: number }[]}
 * @example selectMergedRoomUpdatedPetServices(state)
 */
export const selectMergedRoomUpdatedPetServices = createSelector(
  [selectPetServicesForMergingRooms, selectHotelBookingPetServices],
  (originalServices, pendingPetServices = []) =>
    pendingPetServices?.filter(({ petId, petServiceId }) => {
      return petServiceId && originalServices[petId]?.petServiceId !== petServiceId;
    }),
);

/**
 * Selector to get all pets with updated services and roomNumbers for merging rooms
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectUpdatedServicesWithRoom
 * @param {Object} state
 * @returns {{ petId: string|number, petServiceId: number, roomNumber?: string|number  }[]}
 * @example selectUpdatedServicesWithRoom(state)
 */
export const selectUpdatedServicesWithRoom = createSelector(
  [selectMergedRoomUpdatedPetServices, getHotelEngagements, selectHotelBookingFlow],
  (updatedServices, engagements, hotelBookingFlowReducer) =>
    updatedServices?.map(updatedService => ({
      ...updatedService,
      roomNumber: selectNewRoomNumberForMergingRooms(updatedService?.petId)({
        hotelEngagements: { engagements },
        hotelBookingFlowReducer,
      }),
    })),
);

/**
 * Selector to get the pets array needed for an itinerary Patch call to update rooms and services
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectMergeRoomUpdatesForPatch
 * @param {Object} state
 * @returns {{ petKey: string|number, engagements: Object[] }[]}
 * @example selectMergeRoomUpdatesForPatch(state)
 */
export const selectMergeRoomUpdatesForPatch = createSelector(
  [selectUpdatedServicesWithRoom, getAppointmentsFromPendingAppointment, getHotelEngagements],
  (updatedServicesAndRooms, appointments, engagementsState) => {
    const servicesSlice = { pendingAppointment: { appointments } };
    const pets = updatedServicesAndRooms?.map(({ petId, petServiceId, roomNumber }) => {
      const petEngagements = getPetHotelEngagements(
        { hotelEngagements: { engagements: engagementsState } },
        { petId },
      );
      const service = selectHotelPetServiceById({ selectedService: petServiceId, petId })(
        servicesSlice,
      );

      const engagements = buildEngagementsForPrimaryServicePatch({
        petEngagements,
        petServiceId: service?.petServiceId,
        hostPetId: service?.hostPetId,
        roomTypeBucketId: service?.roomTypeBucketId,
        roomType: service?.roomTypeId,
        applyToEngagements: petEngagements?.map(({ engagementId }) => engagementId),
        pendingRooms: petEngagements?.reduce(
          (rooms, { engagementId }) => ({ ...rooms, [engagementId]: roomNumber }),
          {},
        ),
      });

      return { petKey: petId, engagements };
    });

    return pets;
  },
);
