import React from "react";
import styled from "styled-components";
import { color } from "web/common/styles/theme";
import { fontSizes } from "web/common/styles/responsive/fonts";
import ClickableText from "../../../ClickableText";
import CommonButton from "../../../commonButton";

export default class RemoveSpecial extends React.Component {
  componentWillUnmount() {
    const { clearSpecialToRemove } = this.props;

    clearSpecialToRemove();
  }

  handleRemove = () => {
    const { special, removeSpecial, customerId, itineraryId, onClose } = this.props;

    if (!special) {
      return;
    }

    removeSpecial({ code: special.code, customerId, itineraryId });
    onClose();
  };

  render() {
    const { onClose, special } = this.props;

    return (
      <Container>
        <Header>Remove discount</Header>

        <Message>{`Are you sure you want to remove "${special.name}" discount? This action can’t be undone.`}</Message>

        <ButtonsContainer>
          <ClickableText isVisible styleObj={{ color: color.blue500 }} onClick={onClose}>
            Go back
          </ClickableText>
          <CommonButton label="Remove Discount" onClick={this.handleRemove} />
        </ButtonsContainer>
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const Header = styled.div`
  font-size: ${fontSizes.regular};
  font-weight: bold;
  text-align: center;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Message = styled.div`
  margin: 30px auto;
  text-align: center;
  max-width: 240px;
`;
